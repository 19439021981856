import { gql } from '@apollo/client';
import { Ashrae2019Average, Ashrae2019Control, Ashrae2019View } from 'types/view/ashrae2019View';
import { useMutation } from 'urql';

const EDIT_ASHRAE_2019_VIEW_KEY = 'editAshrae2019View';

const EDIT_ASHRAE_2019_VIEW = gql`
  mutation ($viewId: String!, $data: AddAshrae2019ViewInput!) {
    ${EDIT_ASHRAE_2019_VIEW_KEY}(uuid: $viewId, data: $data) {
      id
      name
      type
      projectId
      updatedAt
    }
  }
`;

type MutVarEditAshrae2019View = {
  viewId: string;
  data: {
    name: string;
    average?: Ashrae2019Average;
    control?: Ashrae2019Control;
    projectSensorIds?: string[];
    autoAddNewDevices?: boolean;
  };
};

type MutResEditAshrae2019View = Pick<Ashrae2019View, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'>;

export const useEditAshrae2019View = () => {
  return useMutation<MutResEditAshrae2019View, MutVarEditAshrae2019View>(EDIT_ASHRAE_2019_VIEW);
};
