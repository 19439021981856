import { RoomType } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const ADD_ROOM_TYPE_KEY = 'addRoomType';

const ADD_ROOM_TYPE = `
  mutation ($organizationId: String!, $data: RoomTypeInput!) {
    ${ADD_ROOM_TYPE_KEY}(organizationId: $organizationId, data: $data) {
      id,
      name,
      updatedAt,
      createdAt,
    }
  }
`;

type MutVarAddRoomType = {
  organizationId: string,
  data: {
    name: string,
  }
}

export const useAddRoomType = () => {
  return useMutation<DefaultQueryResponse<typeof ADD_ROOM_TYPE_KEY, Pick<RoomType, 'id' | 'name' | 'updatedAt' | 'createdAt'>>, MutVarAddRoomType>(ADD_ROOM_TYPE);
}
