import React from 'react';
import styled from 'styled-components';
import { MdCheck } from 'react-icons/md';
import cx from 'classnames';
import { Text } from 'components/typography';

export const SCheckbox = styled.div<{
  borderWidth?: string | number;
  color?: string;
}>`
  position: relative;

  & .label {
    align-items: center;
    display: flex;
    position: relative;

    &:focus-within {
      & .container {
        box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colors.base[900]};
      }
    }

    &:hover {
      cursor: pointer;

      .container {
        opacity: 0.8;
      }

      .container.error {
        border-color: ${({ theme }) => {
          return theme.colors.error;
        }};
      }
    }

    &.labelDisabled {
      opacity: 0.4;
      pointer-events: none;
    }

    .labelTitle {
      align-items: center;
      display: flex;
      margin-left: 0.5rem;
      vertical-align: middle;
      color: ${({ theme }) => theme.colors.base[900]};
    }
  }

  .error {
    position: absolute;
    left: 1.75rem;
    color: ${({ theme }) => theme.colors.error};
  }

  .checkbox {
    border: none;
    height: 1.25rem;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 1.25rem;
  }

  .container.error {
    position: relative;
    left: unset;
    color: unset;
    border-color: ${({ theme }) => theme.colors.error};
  }

  .container {
    background-color: transparent;
    border: ${({ borderWidth }) => {
        if (borderWidth) {
          return `${borderWidth}px`;
        }
        return '2px';
      }}
      solid ${({ theme }) => theme.colors.base[600]};
    box-sizing: border-box;
    position: relative;
    vertical-align: middle;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    flex-shrink: 0;
    padding: 0.25rem;

    .checkbox {
      border: none;
      margin: 0;
      opacity: 0;
      position: absolute;
    }
  }

  .tick {
    color: ${({ theme }) => theme.colors.base[100]};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  & .checkbox:checked + .container {
    background-color: ${({ theme }) => theme.colors.brand};
    border: ${({ borderWidth }) => {
        if (borderWidth) {
          return `${borderWidth}px`;
        }
        return '2px';
      }}
      solid ${({ theme }) => theme.colors.brand};
  }

  & .checkbox:checked + .container.error {
    background-color: ${({ theme }) => {
      return theme.colors.error;
    }};
    border-color: ${({ theme }) => {
      return theme.colors.error;
    }};
  }

  & .checkbox:checked {
    &:hover,
    &:active {
      & + .container {
        background-color: ${({ theme }) => theme.colors.brand};
        border: ${({ borderWidth }) => {
            if (borderWidth) {
              return `${borderWidth}px`;
            }
            return '2px';
          }}
          solid ${({ theme }) => theme.colors.brand};
      }

      & + .container.error {
        background-color: ${({ theme }) => {
          return theme.colors.error;
        }};
        border-color: ${({ theme }) => {
          return theme.colors.error;
        }};
      }
    }
  }
`;

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Border width;
   */
  borderWidth?: string | number;
  /**
   * Checkbox checked state;
   * */
  checked?: boolean;
  /**
   * Error text;
   */
  error?: string;
  /**
   * Checkbox label;
   */
  label?: string | React.ReactNode;
  /**
   * Child label classname;
   */
  labelClassName?: string;
}

export const Checkbox = React.forwardRef<HTMLLabelElement, CheckboxProps>(
  (
    { label, className, checked, disabled, id, borderWidth, error, labelClassName, ...props }: CheckboxProps,
    ref,
  ): JSX.Element => {
    return (
      <SCheckbox borderWidth={borderWidth}>
        <label className={cx('label', disabled && 'labelDisabled')} htmlFor={id} ref={ref}>
          <input
            className={cx('checkbox', className)}
            type="checkbox"
            id={id}
            disabled={disabled}
            checked={checked}
            {...props}
          />
          <div className={cx('container', error && 'error')}>{checked && <MdCheck size="1rem" className="tick" />}</div>
          {label && <Text className={cx('labelTitle', labelClassName)}>{label}</Text>}
        </label>
        {error && (
          <Text variant="small" className="error">
            {error}
          </Text>
        )}
      </SCheckbox>
    );
  },
);

Checkbox.displayName = 'Checkbox';
