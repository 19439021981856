import { css } from '@styled-system/css';
import { Text } from 'components/typography';
import { Flex } from 'components/layout/Flex';
import { FC, Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import { colors } from 'theme/colors';

type Step = {
  label: string;
};

const HorizontalLine = styled.span`
  display: block;
  top: 1.125rem;
  width: 100%;
  position: relative;
  ${css({
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: 'black',
  })}
`;

type ActiveProps = {
  active: boolean;
};

const StepNumberText = styled.span<ActiveProps>`
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${(p) =>
    css({
      borderStyle: 'solid',
      borderWidth: 'px',
      borderColor: p.active ? colors.base[900] : colors.base[600],
      fontSize: ['xxs', 'xs', 'sm', 'md', 'lg'],
      backgroundColor: p.active ? colors.base[900] : colors.base[600],
      color: 'white',
    })}
`;

const StepText = styled.span<ActiveProps>((p) =>
  css({
    color: p.active ? colors.base[900] : colors.base[600],
  }),
);

type Props = {
  steps: Step[];
  activeStep: number;
};

export const Stepper: FC<Props> = (props) => {
  return (
    <Flex flex="1" justifyContent="space-evenly">
      {props.steps.map((step, idx) => (
        <Fragment key={idx}>
          <Flex flexWrap="wrap" flexDirection="row" alignItems="center" gap="0.5rem">
            <StepNumberText active={idx === props.activeStep}>{idx + 1}</StepNumberText>
            <StepText active={idx === props.activeStep}>{step.label}</StepText>
          </Flex>
          {props.steps.length > 1 && idx !== props.steps.length - 1 && (
            <Flex mx="1rem" flexGrow={1} flexShrink={1}>
              <HorizontalLine />
            </Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  );
};

type StepperButtonProps = {
  arrow: 'left' | 'right';
  stepText: string;
  onClick?: () => void;
  children: ReactNode;
  disabled?: boolean;
};

const StepperButtonContainer = styled(Flex)`
  ${css({
    padding: 4,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 'px',
    borderRadius: '0.3rem',
    cursor: 'pointer',
  })}
  &:hover {
    ${css({
      borderColor: 'grey.300',
      borderStyle: 'solid',
      borderWidth: 'px',
      borderRadius: '0.3rem',
    })}
  }
`;

export const StepperButton: FC<StepperButtonProps> = ({ arrow, stepText, children, onClick, disabled }) => {
  return (
    <StepperButtonContainer
      width="100%"
      flexDirection="column"
      alignItems={arrow === 'left' ? 'start' : 'end'}
      onClick={() => {
        if (!disabled) {
          onClick?.();
        }
      }}
    >
      <Text variant="tiny">{stepText.toUpperCase()}</Text>
      <Flex flexDirection="row" gap="1rem" alignItems="center">
        {arrow === 'left' ? (
          <>
            <MdArrowBack /> {children}
          </>
        ) : (
          <>
            {children} <MdArrowForward />
          </>
        )}
      </Flex>
    </StepperButtonContainer>
  );
};
