import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { TokenResponse } from 'types/tokenResponse';
import { auth } from 'utils/Authentication';

export type AuthContextType = {
  isLoggedIn: boolean;
  login: (token: TokenResponse) => void;
  logout: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  login: () => {},
  logout: async () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: FC = (props) => {
  const client = useApolloClient();
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const { isAuthenticated } = useAuth0();

  const login = useCallback(
    async (token: TokenResponse) => {
      auth.startSession(token);
      setLoggedIn(true);
      await client.resetStore();
    },
    [client],
  );

  const logout = useCallback(async () => {
    client.onClearStore(async () => {
      auth.destroySession();
      setLoggedIn(false);
    });
    await client.clearStore();
  }, [client]);

  useEffect(() => {
    // We need to remove this when we switch to Auth0
    // right now we'll use it to force a logout of the users that are using the universal auth0 login
    if (isAuthenticated) {
      logout();
      setLoggedIn(false);
    } else if (auth.isAuthenticated()) {
      setLoggedIn(true);
    }
  }, [isAuthenticated, logout]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
      }}
      {...props}
    />
  );
};
