import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Input } from 'components/form/InputPure';
import { Label } from 'components/form/Label';
import { Box } from 'components/layout/Box';
import { useSnackbar } from 'components/Snackbar';
import { Text } from 'components/typography';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import { AuthContext } from 'context/AuthContext';
import { useQryDevicePages, useQryOrganization } from 'graphql/generated';
import { useAddDevice } from 'graphql/mutation/useAddDevice';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Severity } from 'state/snackbarStore';
import {
  CharpDeviceTypes,
  charpDeviceTypes,
  CharpGatewayTypes,
  charpGatewayTypes,
  CharpTypes,
  ConnectionType,
  DeviceType,
  ElsysDeviceModels,
} from 'types/device';
import { dispatchErrors } from 'utils/util';
import { z } from 'zod';
import { deviceNameInput } from './AddStandaloneDevice';
import { useTranslation } from 'react-i18next';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { SelectInput } from 'components/form/SelectInput';
import { useLastDefinedValue } from 'hooks/useLastDefinedValue';
import { DeviceCreateConfirmationDialog } from 'components/DevicesCreateConfirmationDialog';
import { useAddExternalTag } from 'server/mutations/useAddExternalTag';
import { ObjectProperty, ObjectPropertyValue } from 'components/properties/ObjectProperty';
import { colors } from 'theme/colors';
import { Checkbox } from 'components/form/Checkbox';
import { Flex } from 'components/layout/Flex';
import { CombinedError } from 'urql';

const compulabInitials = 'imx';
const elsysInitials = 'A81758FFFE06';
const wattecoInitials = '70B3D5E75E02';

const charpDeviceInput = z
  .object({
    deviceName: deviceNameInput,
    deviceType: z.enum(
      [...Object.values(CharpDeviceTypes), ...Object.values(CharpGatewayTypes)] as [DeviceType, ...DeviceType[]],
      {
        invalid_type_error: 'The device type should be a known Charp type',
        required_error: 'The device type is required to determine the necessary configuration settings',
      },
    ),
    deviceEUI: z.string(),
    parentId: z.string(),
    locationId: z.string(),
    externalTag: z.string().optional(),
    swapDevice: z.boolean(),
    isGateway: z.boolean(),
  })
  .refine(
    (val) => {
      if (val.deviceType === CharpGatewayTypes.COMPULAB) return val.deviceEUI.startsWith(compulabInitials);
      if (val.deviceType === CharpDeviceTypes.XTEL) {
        const xtelRegex = /[[0-9A-F]{2}:[[0-9A-F]{2}:[[0-9A-F]{2}/;
        return val.deviceEUI.length === 8 && xtelRegex.test(val.deviceEUI);
      }
      const euiRegex = val.isGateway ? /[[0-9A-F]{18}/ : /[[0-9A-F]{16}/;
      return val.deviceEUI.length === (val.isGateway ? 18 : 16) && euiRegex.test(val.deviceEUI);
    },
    (val) => {
      const deviceTypeMessages = {
        [CharpGatewayTypes.COMPULAB]:
          'The device identifier for Compulab should follow the following pattern: imx80001C031DFTD',
        [CharpGatewayTypes.KERLINK]:
          'The device identifier for Kerlink should be 16 characters long and follow the following pattern: 70B3D5D7201C246F',
        [CharpDeviceTypes.ELSYS]:
          'The device identifier for Elsys should be 16 characters long and follow the following pattern: A81758FFFE06D03B',
        [CharpDeviceTypes.TALKPOOL]:
          'The device identifier for Talkpool should be 16 characters long and follow the following pattern: 70B3D5D7201C246F',
        [CharpDeviceTypes.WATTECO]:
          'The device identifier for Watteco should be 16 characters long and follow the following pattern: 70B3D5D75E1C246F',
        [CharpDeviceTypes.XTEL]:
          'The device identifier for Xtel should only use the last 6 digits and have the following pattern: FF:FF:FF',
      };

      const message = deviceTypeMessages[val.deviceType as CharpGatewayTypes | CharpDeviceTypes];

      return {
        path: ['deviceEUI'],
        message: message,
      };
    },
  )
  .refine((val) => val.swapDevice || val.locationId, {
    path: ['locationId'],
    message: 'The location is required to identify the device in the Charp network',
  });

export type CharpDeviceInput = z.infer<typeof charpDeviceInput>;

const TextList = styled.ul(
  css({
    marginLeft: '1rem',
  }),
);

type Props = {
  requestClose: (ignoreGuard?: boolean | undefined) => void;
  isGateway?: boolean;
  externalXtelTag?: string;
  editDeviceLocation: (args: {
    deviceId: string;
    locationId: string;
  }) => Promise<{ data?: { editDeviceLocation: { name: string } }; error?: CombinedError }>;
  locationOptions: { label: string; value: string }[];
  locations: { id: string; name: string }[];
  onCreateLocation: (objectName: string) => Promise<ObjectPropertyValue>;
};

export const AddCharpDevice: FC<Props> = ({
  requestClose,
  isGateway,
  externalXtelTag,
  editDeviceLocation,
  locationOptions,
  locations,
  onCreateLocation,
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm<CharpDeviceInput>({
    resolver: zodResolver(charpDeviceInput),
    defaultValues: {
      deviceName: '',
      deviceType: CharpDeviceTypes.TALKPOOL,
      deviceEUI: '',
      parentId: '',
      locationId: '',
      externalTag: externalXtelTag ?? '',
      isGateway: isGateway,
      swapDevice: false,
    },
  });
  const [{ data: org }] = useQryOrganization({ id: true, name: true }, {});
  const [, addDevice] = useAddDevice();
  const [addExternalTag] = useAddExternalTag();
  const devicesPageContext = useMemo(() => ({ additionalTypenames: ['Device'] }), []);
  const [{ data: devicesPage }] = useQryDevicePages(
    {
      items: {
        id: true,
        name: true,
      },
    },
    {
      isGateway: true,
    },
    {
      context: devicesPageContext,
    },
  );
  const devices = useLastDefinedValue(devicesPage?.items);
  const externalTag = useWatch({
    control,
    name: 'externalTag',
  });

  const selectedDeviceType = useWatch({
    control,
    name: 'deviceType',
  });
  const deviceEUI = useWatch({
    control,
    name: 'deviceEUI',
  });

  const sanitizedDevEUI = useMemo(() => deviceEUI.replace(/[\s-:]/g, ''), [deviceEUI]);

  const isXtelDevice = (value: string) => {
    const xtelRegex = /^[0-9A-F]{2}(:[0-9A-F]{2}){2,5}$/i;
    return xtelRegex.test(value);
  };

  useEffect(() => setValue('deviceName', sanitizedDevEUI.slice(-4)), [sanitizedDevEUI, setValue]);

  useEffect(() => {
    const deviceType = isXtelDevice(deviceEUI)
      ? CharpDeviceTypes.XTEL
      : isGateway
      ? sanitizedDevEUI.startsWith(compulabInitials)
        ? CharpGatewayTypes.COMPULAB
        : CharpGatewayTypes.KERLINK
      : sanitizedDevEUI.startsWith(elsysInitials)
      ? CharpDeviceTypes.ELSYS
      : sanitizedDevEUI.startsWith(wattecoInitials)
      ? CharpDeviceTypes.WATTECO
      : CharpDeviceTypes.TALKPOOL;

    setValue('deviceType', deviceType);
  }, [sanitizedDevEUI, isGateway, setValue, deviceEUI]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [swapDevice, setSwapDevice] = useState(false);
  const [formData, setFormData] = useState<CharpDeviceInput | null>(null);

  const onSubmit: SubmitHandler<CharpDeviceInput> = useCallback((data) => {
    setFormData(data);
    setShowConfirmation(true);
  }, []);

  const handleConfirm = useCallback(async () => {
    setShowConfirmation(false);
    if (formData && org) {
      const newDevice = await addDevice({
        organizationId: org.id,
        name: formData.deviceName,
        parentId: formData.parentId,
        connection: ConnectionType.CHARP,
        type: formData.deviceType,
        model: formData.deviceType === CharpDeviceTypes.ELSYS ? ElsysDeviceModels.ERS : undefined,
        devEUI: formData.deviceEUI,
        isGateway,
      });
      if (newDevice.error) {
        dispatchErrors(snackbar, newDevice.error, authContext, t);
        return;
      }
      if (formData.deviceType === CharpDeviceTypes.XTEL) {
        externalTag &&
          (await addExternalTag({
            data: {
              externalId: externalTag,
              provider: CharpDeviceTypes.XTEL,
              type: CharpTypes.DEVICE,
              devEUI: formData.deviceEUI,
            },
          }));
      }
      if (swapDevice) {
        snackbar.addAlert(
          t('Created new device: {{name}}', { name: newDevice.data?.addDevice.name }),
          Severity.SUCCESS,
        );
      } else {
        const updatedDevice = await editDeviceLocation({
          deviceId: newDevice.data?.addDevice?.id ?? '',
          locationId: formData.locationId,
        });
        updatedDevice.data &&
          snackbar.addAlert(
            t('Created new device: {{name}}', { name: updatedDevice.data.editDeviceLocation?.name }),
            Severity.SUCCESS,
          );
        updatedDevice.error && dispatchErrors(snackbar, updatedDevice.error, authContext, t);
      }
      requestClose();
    }
  }, [
    formData,
    org,
    addDevice,
    isGateway,
    externalTag,
    addExternalTag,
    swapDevice,
    snackbar,
    t,
    requestClose,
    editDeviceLocation,
    authContext,
  ]);

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const charpTypeOptions = useMemo(() => {
    const charpTypes = isGateway ? charpGatewayTypes : charpDeviceTypes;

    return Object.keys(charpTypes).map((type) => ({
      label: charpTypes[type as keyof typeof charpTypes],
      value: type,
    }));
  }, [isGateway]);

  const parentIdOptions = useMemo(
    () =>
      devices?.map((device) => ({
        label: device.name,
        value: device.id,
      })) ?? [],
    [devices],
  );

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Body>
          <Box>
            <Label>{t('The device identifier')}*</Label>
            <Text variant="tiny" mb="0.25rem">
              {t(
                'descriptions.The device identifier is a unique code that identifies each device and can be found on the back of the device',
              )}
              <TextList>
                <li>{t('Xtel should only use the last 6 digits and have the following pattern: FF:FF:FF')}</li>
                {isGateway && <li>{t('Compulab should follow the following pattern: imx80001C031DFTD')}</li>}
                <li>
                  {isGateway
                    ? t('Kerlink should be 18 characters long and follow the following pattern: 70B3D5D7201C246F02')
                    : t(
                        'Elsys, Watteco and Talkpool should be 16 characters long and follow the following pattern: 70B3D5D7201C246F',
                      )}
                </li>
              </TextList>
            </Text>
            <Input
              type="text"
              placeholder={t('placeholders.Enter the device identifier')}
              {...register('deviceEUI', {
                required: true,
                onChange: (e) => {
                  const parts = e.target.value.split(':');
                  e.target.value = isXtelDevice(e.target.value)
                    ? parts.slice(-3).join(':')
                    : e.target.value.replace(/[\s-]/g, '');
                },
              })}
              width="16.5rem"
            />
            <Box>{errors.deviceEUI && <ErrorMessage>{errors.deviceEUI.message}</ErrorMessage>}</Box>
          </Box>
          <Box mt="1.5rem">
            <Label>{t('The device name')}*</Label>
            <Text variant="tiny" mb="0.25rem">
              {t('descriptions.Each device should have a name to quickly identify it throughout Charp')}
            </Text>
            <Input
              type="text"
              width="16.5rem"
              placeholder={t('placeholders.Enter the device name')}
              {...register('deviceName', {
                required: true,
              })}
            />
            <Box>{errors.deviceName && <ErrorMessage>{errors.deviceName.message}</ErrorMessage>}</Box>
          </Box>
          <Box mt="1.5rem">
            <Label>{t('The device type')}*</Label>
            <Text variant="tiny" mb="0.25rem">
              {t('descriptions.At Charp we support multiple device types, each for a specific use case')}
            </Text>

            <Controller
              name="deviceType"
              control={control}
              rules={{ required: true }}
              render={({ field: { value } }) => {
                return (
                  <SelectInput
                    options={charpTypeOptions}
                    onChange={(selectedValue) =>
                      setValue('deviceType', selectedValue as CharpDeviceTypes, { shouldValidate: true })
                    }
                    placeholder={t('actions.Select a device type')}
                    value={charpTypeOptions.find((i) => i.value === value)}
                    width="16.5rem"
                  />
                );
              }}
            />
            <Box>{errors.deviceType && <ErrorMessage>{errors.deviceType.message}</ErrorMessage>}</Box>
          </Box>
          {selectedDeviceType === CharpDeviceTypes.XTEL && (
            <Box mt="1.5rem">
              <Label>{t('Code')}</Label>
              <Text variant="tiny" mb="0.25rem">
                {t('On the back of your Xtel device, you will find an code. Please enter it in this field')}
              </Text>
              <Input
                type="text"
                placeholder={t('Enter the code')}
                {...register('externalTag', {
                  required: false,
                })}
                width="16.5rem"
              />
            </Box>
          )}

          <Box mt="1.5rem">
            <Label>{t('Location')}</Label>
            <Flex gap="0.5rem" mt="0.5rem" mb="0.5rem">
              <Checkbox
                checked={swapDevice}
                onChange={async () => {
                  await setValue('swapDevice', !swapDevice);
                  setSwapDevice(!swapDevice);
                }}
              />
              <Text variant="tiny" mb="0.25rem">
                {t('Device has no location it is for swapping')}
              </Text>
            </Flex>
            <Controller
              name="locationId"
              control={control}
              rules={{ required: true }}
              render={({ field: { value } }) => {
                return (
                  <ObjectProperty
                    title={t('Location')}
                    value={swapDevice ? undefined : locationOptions.find((i) => i.value === value)}
                    values={locations ? locations.map((item) => ({ value: item.id, label: item.name })) : []}
                    onSave={async (selectedValue) =>
                      selectedValue ? await setValue('locationId', selectedValue) : undefined
                    }
                    disabled={swapDevice}
                    onCreateObject={onCreateLocation}
                    style={{ width: '16.5rem', height: '2.7rem', border: `1px solid ${colors.brandBlue[600]}` }}
                  />
                );
              }}
            />
            <Box>{errors.locationId && <ErrorMessage>{errors.locationId.message}</ErrorMessage>}</Box>
          </Box>
          {!isGateway && (
            <Box mt="1.5rem">
              <Label>{t('Gateway')}</Label>
              <Text variant="tiny" mb="0.25rem">
                {t('descriptions.Link this device to a gateway to enable communication with the Charp network')}
              </Text>
              <Controller
                name="parentId"
                control={control}
                render={({ field: { value } }) => {
                  return (
                    <SelectInput
                      options={parentIdOptions}
                      onChange={(selectedValue) => setValue('parentId', selectedValue)}
                      placeholder={t('actions.Select a gateway')}
                      value={parentIdOptions.find((i) => i.value === value)}
                      width="16.5rem"
                    />
                  );
                }}
              />
            </Box>
          )}
        </Body>
        <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
          <Button smallPad onClick={() => requestClose()} variant="plain">
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit" loading={isSubmitting}>
            {t('actions.Save')}
          </Button>
        </DrawerFooter>
      </Form>
      {showConfirmation && formData && (
        <DeviceCreateConfirmationDialog
          deviceData={{ ...formData, organization: org?.name }}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};
