import { CustomDataParser, DataParserSettings, ParseResult } from '@charphq/parser';
import type { TFunction } from 'i18next';

export function constructErrorMessage(linesOfError: ParseResult[]): string {
  const errorMessageMap = {
    columnLength: 'Column length is not valid',
    invalidDate: 'Date is not valid',
  };
  const errorMessage = `File could not be parsed due to the following error(s):\n`;
  const errorLines = linesOfError.map(
    (line) => `Line ${line.line + 1}: ${errorMessageMap[line.error?.columnLength ? 'columnLength' : 'invalidDate']}\n`,
  );
  return errorMessage + errorLines.join('');
}

export function fileParser(
  fileAsString: string,
  settings: DataParserSettings,
  t: TFunction,
): Error | { startDate: Date; endDate: Date } {
  const parser = new CustomDataParser(settings);
  const lines = fileAsString.split(/\r?\n/);
  const listOfErrors: ParseResult[] = [];
  let startDate: Date | undefined;
  let currentDate: Date | undefined;
  for (const line of lines) {
    const parsedData = parser.parseLine(line);
    if (parsedData.error !== undefined) {
      listOfErrors.push(parsedData);
    }
    if (parsedData.data?.timestamp) {
      currentDate = new Date(parsedData.data.timestamp);
      if (startDate === undefined) {
        startDate = new Date(currentDate);
      }
    }
    if (listOfErrors.length >= 5) {
      break;
    }
  }
  if (listOfErrors.length >= 5) {
    return new Error(constructErrorMessage(listOfErrors));
  }
  if (!startDate || !currentDate) {
    return new Error(t('File could not be parsed'));
  }
  return {
    startDate,
    endDate: currentDate,
  };
}
