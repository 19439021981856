import type { SystemStyleObject } from '@styled-system/css';
import { css } from '@styled-system/css';
import { Box } from 'components/layout/Box';
import styled from 'styled-components';
import { flexbox } from 'styled-system';
import { EditableProperty } from './EditableProperty';
import { ObjectProperty } from './ObjectProperty';
import { SelectProperty } from './SelectProperty';
import { StaticProperty } from './StaticProperty';
import { BadgeProperty } from './BadgeProperty';

export type Property = StaticProperty | ObjectProperty | SelectProperty | EditableProperty | BadgeProperty;

type ContainerProps = {
  editing?: boolean;
};

const defaultPropertyContainerStyle: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  flex: '1 1 auto',
  gap: '0.5rem',
  cursor: 'default',
  transition: 'background 20ms ease-in 0s',
};

export const PropertyContainer = styled(Box)<ContainerProps>(
  (props) =>
    css({
      ...defaultPropertyContainerStyle,
      ...(props.editing
        ? {
            px: 0,
            py: 0,
            cursor: 'none',
          }
        : {
            cursor: 'pointer',
          }),
    }),
  flexbox,
);
