import { css } from '@styled-system/css';
import { Flex } from 'components/layout/Flex';
import { FC, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import type { ColorProps } from 'styled-system';
import { color } from 'styled-system';
import { LeafMenuActionType, MenuNode } from './Menu';
import useIsMobile from 'hooks/useIsMobile';

type MenuRouteProps = ColorProps & {
  $isActive?: boolean;
  $indent?: boolean;
};

export const MenuRoute = styled(Link)<MenuRouteProps>`
  display: block;
  line-height: 1.125;
  font-weight: 600;
  border-radius: 0.25rem;
  margin-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(p) =>
    css({
      py: ['0.625rem'],
      px: p.$indent ? ['2.5rem'] : ['1.5rem'],
      fontSize: ['md', 'lg', 'xl', '2xl'],
    })}
  background-color: ${(p) => (p.$isActive ? 'rgba(255,255,255,0.15)' : 'transparent')};
  &:hover {
    background-color: ${(p) => (p.$isActive ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.25)')};
  }
  &:active {
    transform: scale(0.95);
    transition-duration: 24ms;
    opacity: 0.75;
  }
  transition-property: transform, background-color, opacity;
  transition-duration: 96ms;
  ${color}
`;

type MenuButtonProps = {
  $isActive?: boolean;
  $indent?: boolean;
};

export const MenuButton = styled.button<MenuButtonProps>`
  display: block;
  line-height: 1.125;
  font-weight: 600;
  border-radius: 0.25rem;
  margin-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  ${(p) =>
    css({
      py: ['0.625rem'],
      px: p.$indent ? ['2.5rem'] : ['1.5rem'],
      fontSize: ['md', 'lg', 'xl', '2xl'],
    })}
  background-color: ${(p) => (p.$isActive ? 'rgba(255,255,255,0.15)' : 'transparent')};
  &:hover {
    background-color: ${(p) => (p.$isActive ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.25)')};
  }
  &:active {
    transform: scale(0.95);
    transition-duration: 24ms;
    opacity: 0.75;
  }
  transition-property: transform, background-color, opacity;
  transition-duration: 96ms;
`;

type Props = {
  node: MenuNode;
  hideIcon?: boolean;
  active?: boolean;
  tabIndex?: number | undefined;
};

export const MenuLink: FC<Props> = ({ node, active, tabIndex, hideIcon }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const isMobile = useIsMobile();
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  if (node.action.type === LeafMenuActionType.route) {
    return (
      <>
        <MenuRoute
          to={node.action.value}
          $indent={node.indent}
          $isActive={active}
          tabIndex={tabIndex}
          {...(node.isSubMenu && { onClick: toggleDropdown })}
        >
          <Flex justifyContent="space-between">
            <Flex alignItems="center" gap="1rem" style={{ fontSize: isMobile ? '140%' : '100%' }}>
              {!hideIcon && node.icon}
              {node.label}
            </Flex>
            {node.isSubMenu && (dropdownVisible ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />)}
          </Flex>
        </MenuRoute>
        {dropdownVisible &&
          node.isSubMenu &&
          node.dropdownItems?.map((menuItem, index) => <MenuLink key={index} node={menuItem} />)}
      </>
    );
  }

  return (
    <MenuButton onClick={node.action.value} $indent={node.indent} $isActive={active} tabIndex={tabIndex}>
      <Flex alignItems="center" gap="1rem">
        {!hideIcon && node.icon}
        {node.label}
      </Flex>
    </MenuButton>
  );
};
