import { VerticalReveal } from 'components/layout/VerticalReveal';
import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import styled from 'styled-components';
import type { LayoutProps } from 'styled-system';
import { Box } from './Box';
import { Flex } from './Flex';

const SVerticalReveal = styled(VerticalReveal)`
  flex-shrink: 0;
`;

const ContentWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  overflow: auto;
  display: grid;
  place-content: stretch;
  place-items: stretch;
`;

type Props = LayoutProps &
  ComponentPropsWithoutRef<typeof Box> & {
    pushInChildren: ReactNode;
    showPushInChildren: boolean;
  };

export const WithPushIn: FC<Props> = ({ pushInChildren, showPushInChildren, children, ...props }) => {
  return (
    <Flex {...props} flexDirection="column" alignItems="stretch">
      <SVerticalReveal reveal={showPushInChildren}>{pushInChildren}</SVerticalReveal>
      <ContentWrapper>{children}</ContentWrapper>
    </Flex>
  );
};
