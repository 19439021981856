import { MonitorView } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_MONITOR_VIEW_Y_DOMAIN_KEY = 'editMonitorViewYDomain';

const EDIT_MONITOR_VIEW_Y_DOMAIN = `
 mutation ($viewId: String!, $data: [YDomainInput!]!) {
    ${EDIT_MONITOR_VIEW_Y_DOMAIN_KEY}(uuid: $viewId, yDomain: $data) {
      id
      yDomain {
        min,
        max,
        measurement
      }
    }
  }
`;

type MutVarEditMonitorViewYDomain = {
  viewId: string;
  data: {
    min?: number | null;
    max?: number | null;
    measurement?: string | null;
  }[];
};

type MutResEditMonitorViewYDomain = DefaultQueryResponse<
  typeof EDIT_MONITOR_VIEW_Y_DOMAIN_KEY,
  Pick<MonitorView, 'id' | 'yDomain'>
>;

export const useEditMonitorViewYDomain = () => {
  return useMutation<MutResEditMonitorViewYDomain, MutVarEditMonitorViewYDomain>(EDIT_MONITOR_VIEW_Y_DOMAIN);
};
