import { Flex } from 'components/layout/Flex';
import { useDomRect } from 'hooks/useDomRect';
import { ChangeEvent, forwardRef, InputHTMLAttributes, ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import type { FlexboxProps, MarginProps, MaxWidthProps, WidthProps } from 'styled-system';
import { layout } from 'styled-system';
import { css } from '@styled-system/css';
import { Label } from './Label';

type WrapperProps = WidthProps & FlexboxProps & MarginProps;

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

type InputCssProps = MaxWidthProps;

type InputProps = {
  valid?: boolean;
  extraPaddingRight?: number;
} & InputCssProps;

const Sinput = styled.input<InputProps>`
  width: 100%;
  min-height: 100%;
  outline: none;
  box-sizing: border-box;
  font-size: medium;
  padding-left: 0.438rem;
  padding-right: calc(1em + ${(p) => p.extraPaddingRight ?? 0}px);
  padding-top: 0.438rem;
  padding-bottom: 0.438rem;
  background-color: transparent;
  border: 1px solid ${(p) => (p.valid === false && p.theme.colors.red[500]) || p.theme.colors.base[600]};

  &:active,
  :focus {
    box-shadow: inset 0 0 0 1px #0b1dd9;
    border: 1px solid #0b1dd9;
  }
  ::placeholder {
    ${css({
      color: 'base.600',
    })}
  }
  &[disabled] {
    ${css({
      borderColor: 'base.600',
      borderWidth: '1px',
      boxShadow: 'none',
      backgroundColor: 'base.300',
      cursor: 'not-allowed',
    })}
  }
  ${layout}
  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`;

const ControlsWrapper = styled.div`
  position: absolute;
  right: 0.5em;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  font-size: 16px;
`;

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  /* @deprecated use css prop instead **/
  flexProps?: FlexboxProps;
  /* @deprecated use css prop instead **/
  marginProps?: MarginProps;
  css?: WrapperProps;
  inputCss?: InputCssProps;
  validate?: (value: string) => boolean;
  controls?: ReactNode;
};

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ label, validate, marginProps, flexProps, css, inputCss, controls, ...props }, ref) => {
    const [valid, setValid] = useState<boolean | undefined>(undefined);

    const controlsRef = useRef<HTMLDivElement | null>(null);
    const { width } = useDomRect(controlsRef) ?? {};

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (newValue === '' && !props.required) {
        setValid(undefined);
      } else if (validate === undefined) {
        setValid(undefined);
      } else if (newValue !== '' && validate(newValue)) {
        setValid(true);
      } else {
        setValid(false);
      }
      if (props.onChange) {
        props.onChange(e);
      }
    };

    return (
      <Wrapper {...marginProps} {...flexProps} {...css}>
        {label && (
          <Label mb="0.25rem">
            {label}
            {props.required && '*'}
          </Label>
        )}
        <InputWrapper>
          <Sinput
            {...props}
            ref={ref}
            valid={valid}
            onChange={onChange}
            extraPaddingRight={width}
            onWheel={props.type === 'number' ? (e) => e.currentTarget.blur() : undefined}
            {...inputCss}
          />
          {controls && <ControlsWrapper ref={controlsRef}>{controls}</ControlsWrapper>}
        </InputWrapper>
      </Wrapper>
    );
  },
);
Input.displayName = 'Input';
