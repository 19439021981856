import { CSSProperties, FC, ReactNode, useContext } from 'react';
import { css } from '@styled-system/css';
import { MdOutlinePostAdd } from 'react-icons/md';
import styled from 'styled-components';
import { border, color, compose, flexbox, layout, position, space } from 'styled-system';
import { Flex } from './Flex';
import { DrawerContext } from 'context/DrawerContext';
import { Button } from 'components/buttons';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/loader/loader';
import useIsMobile from 'hooks/useIsMobile';
import { auth } from 'utils/Authentication';

const Base = styled(Flex)(
  css({
    padding: '2rem 3rem 3rem 3rem',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'auto',
    justifyContent: 'center',
    minHeight: '40rem',
    '@media (max-width: 600px)': {
      padding: '1rem',
    },
  }),
  compose(flexbox, color, space, layout, border, position),
);

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 6px;
`;

export type ContentProps = {
  children: ReactNode;
  loading?: boolean;
  style?: CSSProperties;
  className?: string;
};

export const Content: FC<ContentProps> = ({ children, loading = false, ...props }) => {
  const { t } = useTranslation();
  const { requestDrawer } = useContext(DrawerContext);
  const isMobile = useIsMobile();
  const isAuthenticated = auth.isAuthenticated();

  return (
    <Base justifyContent={loading ? 'center' : 'flex-start'} {...props}>
      {loading ? <Loader /> : children}
      {!isMobile && isAuthenticated && (
        <ButtonContainer>
          <Button variant="note" iconLeft={MdOutlinePostAdd} onClick={() => requestDrawer('note')}>
            {t('actions.Add note')}
          </Button>
        </ButtonContainer>
      )}
    </Base>
  );
};
