import { useTranslation } from 'react-i18next';
import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { useState, VFC } from 'react';
import { VendorType } from 'types/vendor';
import { AddVersaSense } from './addVersaSense';
import { AddXtel } from './addXtel';
import { VendorSelection } from './VendorSelection';

type Props = DrawerComponentProps;

export const DataStreamDrawer: VFC<Props> = ({ requestClose }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();

  const [vendor, setVendor] = useState<VendorType | undefined>();

  return (
    <DrawerLayout title={t('actions.Add data stream')} onClose={requestClose} width={560}>
      {!vendor && <VendorSelection onSelect={setVendor} />}
      {vendor === VendorType.VERSASENSE && <AddVersaSense onClose={requestClose} />}
      {vendor === VendorType.XTEL && <AddXtel onClose={requestClose} />}
    </DrawerLayout>
  );
};
