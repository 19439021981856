import { Delimiter, Measurement, measurements, Unit, units } from '@charphq/types';
import { css } from '@styled-system/css';
import { AddSensorTr } from 'components/AddSensorTr';
import { Input } from 'components/form/InputPure';
import { Box } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import { Loading } from 'components/Loading';
import { Table, Tbody, Td, Th, Thead, Tr } from 'components/table/Table';
import { H4, SubtleMessage, Text } from 'components/typography';
import { Device, Maybe, Sensor, useQryOrganization } from 'graphql/generated';
import { StepperButton } from 'pages/project/views/alert/addRule/components/Stepper';
import { Dispatch, SetStateAction, useCallback, useMemo, useState, VFC } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import styled from 'styled-components';
import { DeviceLocationInput } from 'utils/device';
import { isDefined } from 'utils/types';
import { formatNumber, removeElement } from 'utils/util';
import { FilePreview } from './FilePreview';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/loader/loader';

const HorizontalLine = styled.span`
  display: block;
  width: 100%;
  ${css({
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: 'whiteGrey',
  })}
`;

const NewButton = styled(Flex)`
  ${css({
    py: 2,
    px: 4,
    cursor: 'pointer',
    borderRadius: '0.4rem',
  })}
  &:hover {
    ${css({
      backgroundColor: 'grey.100',
    })}
  }
`;

type SensorType = Pick<Sensor, 'id' | 'name' | 'measurement' | 'unit'>;
type DeviceType = Pick<Device, 'id' | 'name' | 'type'> & DeviceLocationInput & { sensors?: Maybe<Array<SensorType>> };

type Props = {
  fileName: string;
  fileContent: string;
  fileDelimiter: Delimiter;
  showFilePreview: boolean;
  toggleFilePreview: () => void;
  device?: DeviceType;
  sensorColumns: { [sensorId: string]: number };
  setSensorColumns: Dispatch<SetStateAction<{ [sensorId: string]: number }>>;
  onBack: () => void;
  onNext: () => void;
};

export const PrepareDatapoints: VFC<Props> = ({
  fileName,
  fileContent,
  fileDelimiter,
  device,
  onBack,
  onNext,
  showFilePreview,
  toggleFilePreview,
  sensorColumns,
  setSensorColumns,
}) => {
  const { t } = useTranslation();
  const [{ data: org, fetching: loadingOrganization }] = useQryOrganization({ id: true }, {});
  const [addSensor, setAddSensor] = useState<boolean>(false);

  const onSensorColumnChange = useCallback(
    (column: number | undefined, sensor) => {
      if (column !== undefined) {
        setSensorColumns((prevValue) => ({
          ...prevValue,
          [sensor.id]: column,
        }));
      } else {
        setSensorColumns((prevValue) => removeElement(prevValue, sensor.id));
      }
    },
    [setSensorColumns],
  );

  const onAddSensor = useCallback(
    (sensor: Sensor, column: number) => {
      onSensorColumnChange(column, sensor);
      setAddSensor(false);
    },
    [onSensorColumnChange],
  );

  const formValid = useMemo(() => {
    const validSensorColumn = Object.values(sensorColumns).filter(isDefined).length > 0;
    return validSensorColumn;
  }, [sensorColumns]);

  const onContinue = useCallback(() => {
    if (formValid) {
      onNext();
    }
  }, [formValid, onNext]);

  return (
    <>
      <Box mb={4}>
        <FilePreview
          fileName={fileName}
          fileContent={fileContent}
          hidePreview={showFilePreview}
          togglePreview={toggleFilePreview}
          delimiter={fileDelimiter}
        />
      </Box>
      {loadingOrganization && <Loader />}
      <Loading mt={2} loading={device === undefined} message={t('Retrieving measurements')} />
      {device !== undefined && (
        <Box mb={16}>
          <H4>{t('Measurements')}</H4>
          <Flex mt={2} mb={4}>
            <SubtleMessage>{t('Turn parsed data into valid datapoints')}</SubtleMessage>
          </Flex>
          <Table>
            <Thead>
              <Tr>
                <Th width="50%">{t('Measurement')}</Th>
                <Th width="20%">{t('Unit')}</Th>
                <Th width="20%">{t('Column')}</Th>
                <Th width="10%" />
              </Tr>
            </Thead>
            <Tbody>
              {device.sensors?.map((sensor) => (
                <Tr key={sensor.id}>
                  <Td>
                    {t(measurements[sensor.measurement as unknown as Measurement] ?? sensor.measurement, {
                      ns: 'db-values',
                    })}
                  </Td>
                  <Td>{t(units[sensor.unit as unknown as Unit] ?? sensor.unit, { ns: 'db-values' })}</Td>
                  <Td>
                    <Input
                      type="number"
                      height={8}
                      width={24}
                      value={sensorColumns[sensor.id] ?? ''}
                      min={1}
                      placeholder={t('Column')}
                      onChange={(e) => onSensorColumnChange(formatNumber(e), sensor)}
                    />
                  </Td>
                  <Td />
                </Tr>
              ))}
              {addSensor && org && (
                <AddSensorTr org={org} device={device} onAddSensor={onAddSensor} onBack={() => setAddSensor(false)} />
              )}
            </Tbody>
          </Table>
          {!addSensor && (
            <Flex flexDirection="column" gap="0.5rem">
              <HorizontalLine />
              <NewButton pl={4} alignItems="center" gap="2rem" onClick={() => setAddSensor(true)}>
                <MdOutlineAdd />
                <Text variant="small">{t('New')}</Text>
              </NewButton>
              <HorizontalLine />
            </Flex>
          )}
        </Box>
      )}
      <Flex mt={4} justifyContent="end" gap="1rem">
        <StepperButton arrow="left" stepText="previous" onClick={onBack}>
          <Text fontWeight="bold">{t('Change file')}</Text>
        </StepperButton>
        <StepperButton arrow="right" stepText="finish" onClick={onContinue}>
          <Text fontWeight="bold">{t('Upload data')}</Text>
        </StepperButton>
      </Flex>
    </>
  );
};
