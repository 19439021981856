import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { Room, useQryRoom, useQryOrganization } from 'graphql/generated';
import { useEditRoom } from 'graphql/mutation/useEditRoom';
import { FC, useCallback, useContext } from 'react';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { RoomForm } from './RoomForm';
import { useSnackbar } from './Snackbar';
import { useTranslation } from 'react-i18next';

type Props = DrawerComponentProps & {
  roomId: Room['id'];
};

export const EditRoomDrawer: FC<Props> = ({ roomId, requestClose }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();

  const [{ data: organization }] = useQryOrganization({ id: true }, {});
  const [{ data: room }] = useQryRoom(
    {
      id: true,
      name: true,
      site: true,
      level: true,
      surfaceArea: true,
      services: true,
      height: true,
      description: true,
      type: {
        id: true,
        name: true,
      },
    },
    {
      roomId,
    },
    {},
  );
  const [{ fetching: editingRoom }, editRoom] = useEditRoom();

  const onSubmit = useCallback(
    async ({
      name,
      site,
      level,
      surfaceArea,
      services,
      height,
      description,
      typeId,
    }: {
      name: string;
      site?: string;
      level?: string;
      surfaceArea?: string;
      services?: string;
      height?: string;
      description?: string;
      typeId?: string;
    }) => {
      if (organization) {
        const editedRoom = await editRoom({
          roomId,
          data: {
            name,
            typeId,
            site: site === '' ? undefined : site,
            level: level === '' ? undefined : level,
            surfaceArea: surfaceArea === '' ? undefined : surfaceArea,
            services: services === '' ? undefined : services,
            height: height === '' ? undefined : height,
            description: description === '' ? undefined : description,
          },
        });
        if (editedRoom.data) {
          snackbar.addAlert(t('Edited room: {{room}}', { room: editedRoom.data.editRoom.name }), Severity.SUCCESS);
          requestClose();
        }
        if (editedRoom.error) {
          dispatchErrors(snackbar, editedRoom.error, authContext, t);
        }
      }
    },
    [organization, editRoom, roomId, snackbar, requestClose, authContext, t],
  );

  return (
    <DrawerLayout title={t('Edit room')} onClose={requestClose}>
      {organization && room && (
        <RoomForm
          room={room}
          submitText={t('Edit room')}
          organizationId={organization.id}
          onSubmit={onSubmit}
          onClose={requestClose}
          submitting={editingRoom}
        />
      )}
    </DrawerLayout>
  );
};
