import { Dialog } from 'components/Dialog';
import { BasicNote } from 'components/notes/NotesTable';
import { useSnackbar } from 'components/Snackbar';
import { Text } from 'components/typography';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDeleteNote } from 'server';
import { Severity } from 'state/snackbarStore';

type DialogProps = {
  note: BasicNote;
  onCancel: () => void;
  onAfterDelete: () => void;
};

const NoteDeleteDialog: FC<DialogProps> = ({ note, onCancel, onAfterDelete }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [deleteNote, loading] = useDeleteNote();

  const handleDelete = useCallback(async () => {
    try {
      await deleteNote({ noteId: note.id });
      snackbar.addAlert(t('Note successfully removed'), Severity.SUCCESS);
      onAfterDelete();
    } catch (e) {
      snackbar.addAlert(t('Failed to remove note'), Severity.ERROR);
    }
  }, [deleteNote, note, snackbar, onAfterDelete, t]);

  return (
    <Dialog
      title={t('dialogs.Remove note?')}
      description={
        <Text>
          <Trans
            values={{ noteTitle: note.title }}
            i18nKey="confirmation_messages.Are you sure you want to remove <1>{{noteTitle}}</1> from notes?"
            components={{ 1: <strong /> }}
          />
        </Text>
      }
      maxWidth="640px"
      onCancel={onCancel}
      onConfirm={handleDelete}
      loading={loading}
    />
  );
};

export { NoteDeleteDialog };
