import { FC } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import styled, { keyframes } from 'styled-components';
import type { FlexboxProps, LayoutProps, MarginProps } from 'styled-system';
import { compose, flexbox, layout, margin } from 'styled-system';

type StyleProps = LayoutProps & MarginProps & FlexboxProps;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div<StyleProps>`
  ${compose(layout, margin, flexbox)}
`;

const Loading = styled(AiOutlineLoading)`
  animation: ${rotate} 0.5s linear infinite;
`;

type Props = {
  size?: string | number;
} & StyleProps;

const Spinner: FC<Props> = ({ size = '1rem', ...props }) => {
  return (
    <Container {...props}>
      <Loading size={size} />
    </Container>
  );
};

export default Spinner;
