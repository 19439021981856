import {
  dateFormatToDateFns,
  delimiterToDateFns,
  delimiterValues,
  parseNumber,
  timeFormatToDateFns,
} from '@charphq/parser';
import { DateFormat, Delimiter, Encoding, TimeFormat } from '@charphq/types';
import { isValid, parse } from 'date-fns';
import { FileFormatSettings } from 'types/data';
import { extractFilename } from 'utils/data';

export const easylog_EL_USB_2_PLUS_Settings: FileFormatSettings = {
  fileEncoding: Encoding.enum.latin1,
  fileDelimiter: Delimiter.enum.comma,
  dataRow: 2,
  startDate: undefined,
  sampleTime: undefined,
  dateFormat: DateFormat.enum['YYYY-MM-DD'],
  timeFormat: TimeFormat.enum['HH:MM:SS'],
  utcOffset: '+00:00',
  dateColumn: 2,
  timeColumn: undefined,
  dateDelimiter: Delimiter.enum.space,
};

export function create_EasyLog_EL_USB_2_PLUS_fileSettings(): FileFormatSettings {
  const settings: FileFormatSettings = {
    fileDelimiter: Delimiter.enum.comma,
    fileEncoding: Encoding.enum.latin1,
    dataRow: 2,
    utcOffset: '+00:00',
    sampleTime: undefined,
    startDate: undefined,
    timeColumn: undefined,
    dateColumn: 2,
    dateDelimiter: Delimiter.enum.space,
    dateFormat: DateFormat.enum['YYYY-MM-DD'],
    timeFormat: TimeFormat.enum['HH:MM:SS'],
  };
  return settings;
}

export function is_EasyLog_EL_USB_2_PLUS_file(file: File, completeFile: string): boolean {
  const filename = extractFilename(file.name);
  const lines = completeFile.split(/\r?\n/);
  for (let currentRow = 0; currentRow < lines.length; currentRow++) {
    const line = lines[currentRow];
    const columns = line.split(delimiterValues[easylog_EL_USB_2_PLUS_Settings.fileDelimiter]);
    if (currentRow === 0) {
      const validHeader = verifyHeader(filename, columns);
      if (!validHeader) {
        return false;
      } else {
        continue;
      }
    }
    if (currentRow === 1) {
      const validSecondRow = verifySerialRow(columns);
      if (!validSecondRow) {
        return false;
      } else {
        continue;
      }
    }
    if (currentRow === 2) {
      const validDataRow = verifyDataRow(columns);
      if (!validDataRow) {
        return false;
      } else {
        break;
      }
    }
  }
  return true;
}

function verifyHeader(filename: string, columns: string[]): boolean {
  if (columns.length !== 6) {
    console.error(
      `The easylog-el-usb-tc parser expects 6 elements on the first line separated by ",", received ${columns.length} elements`,
    );
    return false;
  }
  if (columns[0] !== filename) {
    console.error(
      `The first element of an easylog-el-usb-2-+ parser should contain the filename: ${filename}, received ${columns[0]}`,
    );
    return false;
  }
  if (columns[1] !== 'Time') {
    console.error(`The second element of an easylog-el-usb-2-+ parser should contain "Time", received ${columns[1]}`);
    return false;
  }
  if (columns[2] !== 'Celsius(°C)') {
    console.error(
      `The third element of an easylog-el-usb-2-+ parser should contain "Celsius(°C)", received ${columns[2]}`,
    );
    return false;
  }
  if (columns[3] !== 'Humidity(%rh)') {
    console.error(
      `The fourth element easylog-el-usb-2-+ parser should contain "Humidity(%rh)", received ${columns[3]}`,
    );
    return false;
  }
  if (columns[4] !== 'Dew Point(°C)') {
    console.error(
      `The fifth element of an easylog-el-usb-2-+ parser should contain "Dew Point(°C)", received ${columns[4]}`,
    );
    return false;
  }
  if (columns[5] !== 'Serial Number') {
    console.error(
      `The sixth element of an easylog-el-usb-2-+ parser should contain "Serial Number", received ${columns[5]}`,
    );
    return false;
  }
  return true;
}

function verifySerial(value: string): boolean {
  if (value.length !== 9) {
    console.error(`Expected a serial number of 9 numbers, received a serial of length: ${value.length}`);
    return false;
  }
  for (const char of value) {
    const numOfError = parseNumber(char);
    if (typeof numOfError !== 'number') {
      console.error(`A serial number should consist of number, received: ${char}`);
      return false;
    }
  }
  return true;
}

function verifyDate(value: string): boolean {
  if (
    easylog_EL_USB_2_PLUS_Settings.dateFormat === undefined ||
    easylog_EL_USB_2_PLUS_Settings.dateDelimiter === undefined ||
    easylog_EL_USB_2_PLUS_Settings.timeFormat === undefined
  ) {
    return false;
  }
  const datetime = parse(
    `${value} Z`,
    `${dateFormatToDateFns[easylog_EL_USB_2_PLUS_Settings.dateFormat]}${
      delimiterToDateFns[easylog_EL_USB_2_PLUS_Settings.dateDelimiter]
    }${timeFormatToDateFns[easylog_EL_USB_2_PLUS_Settings.timeFormat]} X`,
    new Date(),
  );
  if (!isValid(datetime)) {
    return false;
  }
  return true;
}

function verifyData(columns: string[]): boolean {
  const sensorReadings = columns.slice(2, 5);
  for (const sensorReading of sensorReadings) {
    if (parseNumber(sensorReading) === undefined) {
      console.error('Could not read sensor reading');
      return false;
    }
  }
  return true;
}

function verifySerialRow(columns: string[]): boolean {
  if (columns.length !== 6) {
    console.error(
      `The easylog-el-usb-2-+ parser expects 6 elements on the second line separated by ",", received ${columns.length} elements`,
    );
    return false;
  }
  if (columns[0] !== '1') {
    console.error(`The first element should contain number 1, received ${columns[0]}`);
    return false;
  }
  // The specified time is in UTC.
  const isValidDate = verifyDate(columns[1]);
  if (!isValidDate) {
    console.error(`The second element should contain a DateTime, received ${columns[1]}`);
    return false;
  }
  const isValidData = verifyData(columns);
  if (!isValidData) {
    console.error('Could not read sensor reading');
    return false;
  }
  const isValidSerial = verifySerial(columns[5]);
  if (!isValidSerial) {
    console.error('Invalid serial number');
    return false;
  }
  return true;
}

function verifyDataRow(columns: string[]): boolean {
  if (columns.length !== 5) {
    console.error(
      `The easylog-el-usb-2-+ parser expects 5 elements on a data row separated by ",", received: ${columns.length}`,
    );
    return false;
  }
  if (columns[0] !== '2') {
    console.error(`The first element should contain number 2, received ${columns[0]}`);
    return false;
  }
  const isValidDate = verifyDate(columns[1]);
  if (!isValidDate) {
    return false;
  }
  const isValidData = verifyData(columns);
  if (!isValidData) {
    return false;
  }
  return true;
}
