import { Dialog } from 'components/Dialog';
import { Text } from 'components/typography';
import { CharpDeviceInput } from 'pages/devices/AddCharpDevice';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type CreateDialogProps = {
  deviceData: CharpDeviceInput & { organization?: string };
  onCancel: () => void;
  onConfirm: () => void;
};

const DeviceCreateConfirmationDialog: FC<CreateDialogProps> = ({ deviceData, onCancel, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('Confirm device creation')}
      description={
        <Text>
          <ul>
            <li style={{ fontWeight: 'bold' }}>
              {t('Organization')}: {deviceData.organization}
            </li>
            <li>
              {t('Name')}: {deviceData.deviceName}
            </li>
            <li>
              {t('Type')}: {deviceData.deviceType}
            </li>
            <li>
              {t('DevEUI')}: {deviceData.deviceEUI}
            </li>
          </ul>
        </Text>
      }
      maxWidth="640px"
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export { DeviceCreateConfirmationDialog };
