import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { LayoutProps, MarginProps } from 'styled-system';
import { compose, fontSize, layout, margin } from 'styled-system';
import { H3 } from './Title';

type Props = LayoutProps & MarginProps;

export const BoxSubtitle = styled(H3)<Props>(
  css({
    lineHeight: 'tight',
    fontSize: '1.125rem',
    fontWeight: 400,
  }),
  compose(layout, margin, fontSize),
);
