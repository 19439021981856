import { gql } from '@apollo/client';
import { Ashrae2019Average, Ashrae2019Control, Ashrae2019View } from 'types/view/ashrae2019View';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';
import { useTranslation } from 'react-i18next';

const ADD_ASHRAE_2019_VIEW_KEY = 'addAshrae2019View';

const ADD_ASHRAE_2019_VIEW = gql`
  mutation ($projectId: String!, $data: AddAshrae2019ViewInput!) {
    ${ADD_ASHRAE_2019_VIEW_KEY}(uuid: $projectId, data: $data) {
      id
      name
      type
      projectId
      updatedAt
    }
  }
`;

type MutVarAddAshrae2019View = {
  projectId: string;
  data: {
    name: string;
    average?: Ashrae2019Average;
    control: Ashrae2019Control;
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
  };
};

type MutResAddAshrae2019View = Pick<Ashrae2019View, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'>;

export const useAddAshrae2019View = () => {
  const { t } = useTranslation();
  const update: MutationUpdateFunction<MutVarAddAshrae2019View, MutResAddAshrae2019View> = (
    cache,
    data,
    { variables },
  ) => {
    const result = data.data;
    if (result) {
      const newViewRef = cache.writeFragment({
        data: result[ADD_ASHRAE_2019_VIEW_KEY],
        fragment: gql`
          fragment NewView on View {
            id
          }
        `,
      });
      if (variables === undefined) {
        throw new Error(t('Missing mutation variables'));
      }
      cache.modify({
        id: cache.identify({
          id: variables.projectId,
          __typename: 'Project',
        }),
        fields: {
          views(views = []) {
            return [newViewRef, ...views];
          },
        },
      });
    }
  };

  return useDefaultMutation<MutResAddAshrae2019View, MutVarAddAshrae2019View>(
    ADD_ASHRAE_2019_VIEW,
    ADD_ASHRAE_2019_VIEW_KEY,
    update,
  );
};
