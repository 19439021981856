import React from 'react';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as SVGLogo } from 'images/charp-logo-icon.svg';
import { colors } from 'theme/colors';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const PulsatingLogo = styled(SVGLogo)`
  height: 1.75rem;
  animation: ${pulseAnimation} 1s ease-in-out infinite; /* Adjust the duration and timing function as needed */
`;

export const Loader: React.FC = () => (
  <LoaderContainer>
    <PulsatingLogo fill={colors.primary[900]} />
  </LoaderContainer>
);
