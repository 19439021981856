import { RefObject, useEffect, useState } from 'react';

export function useDomRect(ref: RefObject<Element>): DOMRect | undefined {
  const [domRect, setDomRect] = useState<DOMRect>();

  useEffect(() => {
    if (ref.current) {
      const el = ref.current;
      const updateRectangle = (target: Element) => {
        requestAnimationFrame(() => {
          const rect = target.getBoundingClientRect();
          setDomRect((prevRect) => {
            if (!prevRect || rect.width !== prevRect.width || rect.height !== prevRect.height) {
              return rect;
            }
            return prevRect;
          });
        });
      };

      if (ResizeObserver !== undefined) {
        updateRectangle(el);
        const obs = new ResizeObserver((entries) => {
          requestAnimationFrame(() => updateRectangle(entries[0].target));
        });
        obs.observe(el);
        return () => {
          obs.unobserve(el);
        };
      }
      // fallback for older browsers
      else {
        updateRectangle(el);
        const t = setTimeout(() => updateRectangle(el), 1000);
        return () => {
          clearTimeout(t);
        };
      }
    } else {
      setDomRect(undefined);
    }
  }, [ref]);

  return domRect;
}
