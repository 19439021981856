import { differenceInHours } from 'date-fns';
import { LightBudgetControl, ProjectSensor, Sensitivity } from 'graphql/generated';
import { LightBudgetControlWithoutOrganization } from 'services/view/lightBudgetView';
import { ViewType } from 'types/view';
import { Domain, TimeSeriesDataPoint } from 'types/data';
import { LinkedPeriod } from 'types/period';
import { View } from './view';

export enum TargetLifetimeType {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOWER = 'lower',
  CUSTOM = 'custom',
}

export type LightBudgetData = {
  control: LightBudgetControlWithoutOrganization;
  usedBudget: number;
  availableBudget: number;
  percentageUsed: number;
};

export type LightBudgetDetailData = LightBudgetData & {
  series: {
    hourly: TimeSeriesDataPoint[];
    hourlyCum: TimeSeriesDataPoint[];
  };
};

type TargetLifetimeConfig = {
  type: TargetLifetimeType;
  displayName: string;
  value?: number;
};

export const targetLifetimeConfigs: { [key in TargetLifetimeType]: TargetLifetimeConfig } & {
  [key: string]: TargetLifetimeConfig;
} = {
  [TargetLifetimeType.HIGH]: {
    type: TargetLifetimeType.HIGH,
    displayName: 'High (500 years)',
    value: 500,
  },
  [TargetLifetimeType.MEDIUM]: {
    type: TargetLifetimeType.MEDIUM,
    displayName: 'Medium (300 years)',
    value: 300,
  },
  [TargetLifetimeType.LOWER]: {
    type: TargetLifetimeType.LOWER,
    displayName: 'Lower (150 years)',
    value: 150,
  },
  [TargetLifetimeType.CUSTOM]: {
    type: TargetLifetimeType.CUSTOM,
    displayName: '# years',
  },
};

export const targetLifetimeValues = Object.values(targetLifetimeConfigs);

export enum SensitivityCategory {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export type SensitivityType = Sensitivity | SensitivityCategory;

type SensitivityConfig = {
  type: SensitivityType;
  displayName: string;
  value: Sensitivity;
  lux: number;
  luxUV: number;
};

export const sensitivityConfigs: { [key in SensitivityType]: SensitivityConfig } = {
  [SensitivityCategory.HIGH]: {
    displayName: 'High sensitivity (ISO 1-3)',
    type: SensitivityCategory.HIGH,
    value: Sensitivity.Iso1,
    luxUV: 2.2 * Math.pow(10, 5),
    lux: 3 * Math.pow(10, 5),
  },
  [SensitivityCategory.MEDIUM]: {
    displayName: 'Medium sensitivity (ISO 4-6)',
    type: SensitivityCategory.MEDIUM,
    value: Sensitivity.Iso4,
    luxUV: 3.5 * Math.pow(10, 6),
    lux: 10 * Math.pow(10, 6),
  },
  [SensitivityCategory.LOW]: {
    displayName: 'Low sensitivity (ISO 7-8)',
    type: SensitivityCategory.LOW,
    value: Sensitivity.Iso7,
    luxUV: 50 * Math.pow(10, 6),
    lux: 300 * Math.pow(10, 6),
  },
  [Sensitivity.Iso1]: {
    displayName: 'ISO 1',
    type: Sensitivity.Iso1,
    value: Sensitivity.Iso1,
    luxUV: 2.2 * Math.pow(10, 5),
    lux: 3 * Math.pow(10, 5),
  },
  [Sensitivity.Iso2]: {
    displayName: 'ISO 2',
    type: Sensitivity.Iso2,
    value: Sensitivity.Iso2,
    luxUV: 6 * Math.pow(10, 5),
    lux: 1 * Math.pow(10, 6),
  },
  [Sensitivity.Iso3]: {
    displayName: 'ISO 3',
    type: Sensitivity.Iso3,
    value: Sensitivity.Iso3,
    luxUV: 1.5 * Math.pow(10, 6),
    lux: 3 * Math.pow(10, 6),
  },
  [Sensitivity.Iso4]: {
    displayName: 'ISO 4',
    type: Sensitivity.Iso4,
    value: Sensitivity.Iso4,
    luxUV: 3.5 * Math.pow(10, 6),
    lux: 10 * Math.pow(10, 6),
  },
  [Sensitivity.Iso5]: {
    displayName: 'ISO 5',
    type: Sensitivity.Iso5,
    value: Sensitivity.Iso5,
    luxUV: 8 * Math.pow(10, 6),
    lux: 30 * Math.pow(10, 6),
  },
  [Sensitivity.Iso6]: {
    displayName: 'ISO 6',
    type: Sensitivity.Iso6,
    value: Sensitivity.Iso6,
    luxUV: 20 * Math.pow(10, 6),
    lux: 100 * Math.pow(10, 6),
  },
  [Sensitivity.Iso7]: {
    displayName: 'ISO 7',
    type: Sensitivity.Iso7,
    value: Sensitivity.Iso7,
    luxUV: 50 * Math.pow(10, 6),
    lux: 300 * Math.pow(10, 6),
  },
  [Sensitivity.Iso8]: {
    displayName: 'ISO 8',
    type: Sensitivity.Iso8,
    value: Sensitivity.Iso8,
    luxUV: 120 * Math.pow(10, 6),
    lux: 1000 * Math.pow(10, 6),
  },
};

export const sensitivityValues = Object.values(sensitivityConfigs);

export enum RoleOfColorType {
  LARGER = 'larger',
  SMALLER = 'smaller',
  CUSTOM = 'custom',
}

type RoleOfColorConfig = {
  type: RoleOfColorType;
  displayName: string;
  value?: number;
};

export const roleOfColorConfigs: { [key in RoleOfColorType]: RoleOfColorConfig } & {
  [key: string]: RoleOfColorConfig;
} = {
  [RoleOfColorType.LARGER]: {
    type: RoleOfColorType.LARGER,
    displayName: 'Larger (10 JNDs)',
    value: 10,
  },
  [RoleOfColorType.SMALLER]: {
    type: RoleOfColorType.SMALLER,
    displayName: 'Smaller (20 JNDs)',
    value: 20,
  },
  [RoleOfColorType.CUSTOM]: {
    type: RoleOfColorType.CUSTOM,
    displayName: '# JNDs',
  },
};

export const roleOfColorValues = Object.values(roleOfColorConfigs);

export enum UVType {
  PRESENT = 'present',
  REMOVED = 'removed',
}

type UVConfig = {
  type: UVType;
  displayName: string;
  value: boolean;
};

export const uvConfigs: { [key in UVType]: UVConfig } = {
  [UVType.PRESENT]: {
    type: UVType.PRESENT,
    displayName: 'UV present',
    value: true,
  },
  [UVType.REMOVED]: {
    type: UVType.REMOVED,
    displayName: 'UV removed',
    value: false,
  },
};

export const uvValues = Object.values(uvConfigs);

export function calcLightBudget({
  targetLifetime,
  roleOfColor,
  uv,
  sensitivity,
  period,
}: {
  targetLifetime: number;
  roleOfColor: number;
  uv: boolean;
  sensitivity: Sensitivity;
  period: Domain<Date>;
}): number {
  const yearsAfterJND = Math.ceil(targetLifetime / roleOfColor);
  if (yearsAfterJND === 0) {
    return 0;
  }
  const lightBudget = uv ? sensitivityConfigs[sensitivity].luxUV : sensitivityConfigs[sensitivity].lux;
  const hourlyLightBudget = lightBudget / (yearsAfterJND * 365 * 24);
  const numberOfHoursInPeriod = differenceInHours(period[1], period[0]);
  const availableLux = hourlyLightBudget * numberOfHoursInPeriod;
  return availableLux;
}

export interface LightBudgetView extends View {
  type: ViewType.LIGHT_BUDGET;
  period: LinkedPeriod;
  controls: LightBudgetControl[];
  sensors?: ProjectSensor[];
}
