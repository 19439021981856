import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { Alert, Severity, useSnackbarStore } from 'state/snackbarStore';
import styled, { css } from 'styled-components';
import type { ColorProps } from 'styled-system';
import { color } from 'styled-system';
import shallow from 'zustand/shallow';

const severityToColor = (severity: Severity): string =>
  ({
    [Severity.ERROR]: 'danger',
    [Severity.WARNING]: 'warning',
    [Severity.INFO]: 'subtle',
    [Severity.SUCCESS]: 'positive',
  }[severity]);

const Container = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 32rem;
  padding: 1.5rem 1rem;
`;

const Snackbar = styled.div<ColorProps>`
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  padding-right: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.5);
  line-height: 1.25;
  ${color};
`;
Snackbar.defaultProps = {
  bg: 'white',
};

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.25rem;
  min-width: 1.75rem;
  min-height: 1.75rem;
  line-height: 0;
  ${css({
    fontSize: '2xl',
  })}
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

type Props = {
  maxVisible?: number;
};

export const SnackbarContainer: FC<Props> = ({ children, maxVisible = 5 }) => {
  const { t } = useTranslation();
  const [alerts, dismissAlert] = useSnackbarStore((store) => [store.alerts, store.dismissAlert], shallow);

  const alertsToShow = alerts.slice(0, maxVisible).reverse();
  const overflowCount = Math.max(alerts.length - maxVisible, 0);

  return (
    <>
      {children}
      <Container>
        {overflowCount > 0 && (
          <Snackbar bg="white" color="brandDark">
            {t('+ {{count}} more notifications', { count: overflowCount })}
          </Snackbar>
        )}
        {alertsToShow.map((alert: Alert) => (
          <Snackbar key={alert.id} bg={severityToColor(alert.severity)} color="white">
            <div>{alert.message}</div>
            {alert.dismissable && (
              <CloseButton
                onClick={() => {
                  dismissAlert(alert.id);
                }}
              >
                <MdClose size="1rem" />
              </CloseButton>
            )}
          </Snackbar>
        ))}
      </Container>
    </>
  );
};

export const useSnackbar = () => useSnackbarStore();
