import { gql } from '@apollo/client';
import { ExternalTag } from 'graphql/generated';
import { useDefaultMutation } from './useDefaultMutation';

const ADD_EXTERNAL_TAG_KEY = 'addExternalTag';

const ADD_EXTERNAL_TAG = gql`
  mutation ($data: AddExternalTagInput!) {
    addExternalTag(data: $data) {
      id
      externalId
      devEUI
    }
  }
`;

type ExternalTagInput = {
  externalId: string;
  provider: string;
  type: string;
  devEUI: string;
};

type MutVarAddExternalTag = {
  data: ExternalTagInput;
};

type MutResAddExternalTag = Pick<ExternalTag, 'id' | 'externalId' | 'devEUI' | 'createdAt' | 'updatedAt'>;

export const useAddExternalTag = () => {
  return useDefaultMutation<MutResAddExternalTag, MutVarAddExternalTag>(ADD_EXTERNAL_TAG, ADD_EXTERNAL_TAG_KEY);
};
