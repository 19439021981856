import { FC } from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';

export const StyledTooltip = styled(ReactTooltip)`
  z-index: 10;
  white-space: pre-wrap;
  @media (max-width: 600px) {
    max-width: 15rem;
  }
`;

type Props = {
  id: string;
  place: PlacesType;
  content: string;
};

const Tooltip: FC<Props> = ({ id, place, content }) => (
  <StyledTooltip id={id} place={place}>
    {content}
  </StyledTooltip>
);

export default Tooltip;
