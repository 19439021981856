import { SampleTime } from '@charphq/types';
import { useSnackbar } from 'components/Snackbar';
import { AuthContext } from 'context/AuthContext';
import { useAddMonitorView } from 'graphql/mutation/useAddMonitorView';
import { FC, useCallback, useContext } from 'react';
import { Severity } from 'state/snackbarStore';
import type { FlexGrowProps } from 'styled-system';
import { DomainView } from 'types/data';
import { View, ProjectSensor } from 'graphql/generated';
import { dispatchErrors } from 'utils/util';
import { MonitorViewForm } from './MonitorViewForm';
import { useTranslation } from 'react-i18next';
import type { PeriodArray } from 'components/form/RangeDatePicker';

type Props = {
  projectId: string;
  afterCreate?: (view: View) => void;
  onClose: () => void;
} & FlexGrowProps;

export const AddMonitorView: FC<Props> = ({ projectId, afterCreate, ...props }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();
  const [{ fetching: loading }, addMonitorView] = useAddMonitorView();

  const addMonitorViewToProject = useCallback(
    async (
      name: View['name'],
      projectSensorIds: ProjectSensor['id'][],
      period?: PeriodArray,
      sampleTime?: SampleTime,
      autoAddNewDevices?: boolean,
      yDomain?: DomainView<number | undefined | null>[],
    ) => {
      const parsedYDomain =
        yDomain &&
        yDomain.map(({ min, max, measurement }) => ({
          min: min && +min,
          max: max && +max,
          measurement,
        }));
      const monitorViewResponse = await addMonitorView({
        projectId,
        data: {
          name,
          projectSensorIds,
          period:
            period && period[0]
              ? {
                  start: period[0].toISOString(),
                  end: period[1] !== undefined ? period[1].toISOString() : undefined,
                }
              : undefined,
          sampleTime,
          autoAddNewDevices,
          yDomain: parsedYDomain,
        },
      });

      if (monitorViewResponse.data) {
        if (afterCreate) {
          afterCreate(monitorViewResponse.data.addMonitorView);
        }
        snackbar.addAlert(
          t('Created monitor view: {{view}}', { view: monitorViewResponse.data.addMonitorView.name }),
          Severity.SUCCESS,
        );
      }

      if (monitorViewResponse.error) {
        dispatchErrors(snackbar, monitorViewResponse.error, authContext, t);
      }
    },
    [addMonitorView, projectId, afterCreate, snackbar, authContext, t],
  );

  return (
    <MonitorViewForm
      projectId={projectId}
      saveLabel={t('actions.Add view')}
      onSave={addMonitorViewToProject}
      loading={loading}
      {...props}
    />
  );
};
