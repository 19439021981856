import cloneDeepWith from 'lodash/cloneDeepWith';

export const omitTypenameDeep = <T extends Record<string, unknown>>(variables: T): T =>
  cloneDeepWith(variables, (value) => {
    if (value && value.__typename) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, ...valWithoutTypename } = value;
      return valWithoutTypename;
    }

    return undefined;
  });
