export function isObject(value: unknown): value is Record<string, unknown> {
  if (typeof value === 'object' && value !== null) {
    return true;
  }
  return false;
}

export function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}
