import { Note, SimplePeriod } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const UPDATE_NOTE_KEY = 'updateNote';

const UPDATE_NOTE = `
  mutation ($uuid: String!, $title: String, $description: String, $period: PeriodInput) {
    ${UPDATE_NOTE_KEY}(uuid: $uuid, data: { title: $title, description: $description, period: $period }) {
      id
      title
      period {
        start
        end
      }
      description
      updatedAt
    }
  }
`;

type MutVarUpdateNote = {
  uuid: string;
  title?: string;
  period?: SimplePeriod | null;
  description?: string;
};

type MutResUpdateNote = DefaultQueryResponse<
  typeof UPDATE_NOTE_KEY,
  Pick<Note, 'id' | 'title' | 'period' | 'description' | 'updatedAt'>
>;

export const useUpdateNote = () => {
  return useMutation<MutResUpdateNote, MutVarUpdateNote>(UPDATE_NOTE);
};
