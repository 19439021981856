import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const DELETE_RULES_KEY = 'deleteRules';

const DELETE_RULES = `
  mutation ($ruleIds: [String!]!) {
    ${DELETE_RULES_KEY}(query: { items: $ruleIds })
  }
`;

type MutVarDeleteRules = {
  ruleIds: string[];
};

type MutResDeleteRules = DefaultQueryResponse<typeof DELETE_RULES_KEY, string[]>;

export const useDeleteRules = () => {
  return useMutation<MutResDeleteRules, MutVarDeleteRules>(DELETE_RULES);
};
