import { Note, User } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { NoteUserInput } from 'types/note';
import { useMutation } from 'urql';

const ADD_NOTE_TO_PROJECT_KEY = 'addNoteToProject';

const ADD_NOTE_TO_PROJECT = `
  mutation ($projectId: String!, $title: String!, $description: String, $period: PeriodInput) {
    ${ADD_NOTE_TO_PROJECT_KEY}(uuid: $projectId, data: { title: $title, description: $description, period: $period }) {
      id
      title
      description
      period {
        start
        end
      }
      owner {
        id
        email
        firstName
        lastName
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;

type MutVarAddNoteToProject = {
  projectId: string;
} & NoteUserInput;

type MutResAddNoteToProject = DefaultQueryResponse<
  typeof ADD_NOTE_TO_PROJECT_KEY,
  Pick<Note, 'id' | 'title' | 'description' | 'period' | 'updatedAt' | 'createdAt'> & {
    owner: Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'createdAt'>;
  }
>;

export const useAddNoteToProject = () => {
  return useMutation<MutResAddNoteToProject, MutVarAddNoteToProject>(ADD_NOTE_TO_PROJECT);
};
