import { css } from '@styled-system/css';
import { smallTextStyle } from 'components/typography';
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import type { LayoutProps, SpaceProps } from 'styled-system';
import { layout, space } from 'styled-system';
import { colors } from 'theme/colors';
import { Range } from 'types/number';

const totalWidth = 100;

type TableProps = {
  fixed?: boolean;
};

// isolation:isolate creates a new stacking context,
// it makes sure the index:1 on Th doesn't make it pop in front of components outside Table
export const Table = styled.table<TableProps>`
  isolation: isolate;
  width: 100%;
  border-collapse: collapse;
  @media (min-width: 601px) {
    table-layout: ${(props) => (props.fixed ? 'fixed' : 'auto')};
  }
  & > thead > tr > th,
  & > tbody > tr > td {
    font-size: 1rem;
  }
  @media (max-width: 600px) {
    margin-bottom: 5rem;
  }
`;

export const Thead = styled.thead``;

type TbodyProps = {
  $loading?: boolean;
};

export const Tbody = styled.tbody<TbodyProps>`
  opacity: ${(p) => (p.$loading ? 0.25 : 1)};
  transition: opacity 96ms ease;
`;

type TrStyledProps = ComponentPropsWithoutRef<'tr'> & {
  bg?: string;
  hoverBg?: string;
  active?: boolean;
  height?: string;
};

export const Tr = styled.tr<TrStyledProps>(
  ({ onClick, bg = 'transparent', hoverBg = colors.base[200], active = false, height }) =>
    css({
      borderBottom: '1px solid',
      borderColor: 'base.400',
      transition: 'color 96ms ease',
      borderRadius: '0',
      '& > td': {
        bg: active ? hoverBg : bg,
      },
      '&:hover > td': {
        bg: hoverBg,
      },
      cursor: onClick !== undefined ? 'pointer' : 'default',
      height: height,
    }),
);

export const Td = styled.td<SpaceProps>`
  padding: 0.5rem;
  text-align: left;
  ${css({
    borderBottom: '1px solid base.400',
  })}

  ${css(smallTextStyle)}
  ${space}
`;

export type TdProps = {
  textTransform?: string;
};

export const TdOverflow = styled(Td)<TdProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: ${(p) => (p.textTransform ? p.textTransform : 'none')};
`;

export type ThProps = {
  col?: Range<0, 25>;
  backgroundColor?: string;
  accentColor?: string;
} & LayoutProps;

// padding bottom + fake bottom border in ::before, needed to create spacing between header row and first data row
export const Th = styled.th<ThProps>`
  top: 0;
  z-index: 1;
  line-height: 1;
  padding: 1rem 0.5rem;
  text-align: left;
  font-size: 1.125rem;
  font-weight: 600;
  width: ${(p) => (p.col !== undefined ? `${(p.col / 24) * totalWidth}%` : 'auto')};
  ${(p) =>
    css({
      backgroundColor: p.backgroundColor ?? 'white',
    })}
  ${css(smallTextStyle)}
  ${layout}
`;
