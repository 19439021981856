import styled from 'styled-components';
import type { BorderProps, ColorProps, FlexboxProps, LayoutProps, PositionProps, SpaceProps } from 'styled-system';
import { border, color, compose, flexbox, layout, position, space } from 'styled-system';

export type FlexProps = FlexboxProps &
  ColorProps &
  SpaceProps &
  LayoutProps &
  BorderProps &
  PositionProps & {
    // flexboxes now also support the gap property
    gap?: string;
  };

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${(p) => (p.gap ? `gap: ${p.gap};` : '')}
  ${compose(flexbox, color, space, layout, border, position)}
`;
