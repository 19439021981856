import { gql } from '@apollo/client';
import { Project } from 'types/project';
import { useDefaultMutation } from '../useDefaultMutation';

const EDIT_PROJECT_NAME_KEY = 'editProjectName';

const EDIT_PROJECT_NAME = gql`
  mutation ($projectId: String!, $name: String!) {
    ${EDIT_PROJECT_NAME_KEY}(uuid: $projectId, data: { item: $name }) {
      id
      name
    }
  }
`;

type MutVarEditProjectName = {
  projectId: string;
  name: string;
}

type MutResEditProjectName = Pick<Project, 'id' | 'name'>;

export const useEditProjectName = () => {
  return useDefaultMutation<MutResEditProjectName, MutVarEditProjectName>(EDIT_PROJECT_NAME, EDIT_PROJECT_NAME_KEY);
};
