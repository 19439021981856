import { FC } from 'react';
import styled from 'styled-components';

import { Flex } from './layout/Flex';

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 100%;
`;

const Img = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
`;

type Props = {
  image: string;
};

export const ResponsiveImage: FC<Props> = ({ image }) => {
  return (
    <Flex width="100%">
      <ImageContainer>
        <Img src={image} />
      </ImageContainer>
    </Flex>
  );
};
