import { gql } from '@apollo/client';
import { Calibration } from 'graphql/generated';
import { useDefaultMutation } from '../useDefaultMutation';

const EDIT_CALIBRATION_AND_SENSORS_KEY = 'editCalibrationAndCalibrationSensors';

const EDIT_CALIBRATION_AND_SENSORS = gql`
  mutation ($uuid: String!, $data: CalibrationAndCalibrationSensorsInput!) {
    editCalibration(uuid: $uuid, data: $data) {
      id
      name
      formula
      createdAt
      updatedAt
      calibrationSensors {
        id
        calibrationId
        sensorId
        startDate
        endDate
        createdAt
        updatedAt
      }
    }
  }
`;

type CalibrationInput = {
  name: string;
  formula: string;
  organizationId: string;
};

type CalibrationSensorInput = {
  sensorIds: string[];
  startDate?: string;
  endDate?: string;
};

type EditCalibrationAndCalibrationSensorsInput = {
  calibration: CalibrationInput;
  calibrationSensor: CalibrationSensorInput;
};

type MutVarEditCalibrationAndSensors = {
  uuid: string;
  data: EditCalibrationAndCalibrationSensorsInput;
};

type MutResEditCalibrationAndSensors = Pick<Calibration, 'id' | 'name' | 'formula' | 'createdAt' | 'updatedAt'> & {
  calibrationSensors: {
    id: string;
    sensorId: string;
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
  }[];
};

export const useEditCalibration = () => {
  return useDefaultMutation<MutResEditCalibrationAndSensors, MutVarEditCalibrationAndSensors>(
    EDIT_CALIBRATION_AND_SENSORS,
    EDIT_CALIBRATION_AND_SENSORS_KEY,
  );
};
