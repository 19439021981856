import { nanoid } from 'nanoid';
import { ReactNode } from 'react';
import create from 'zustand';

export enum Severity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export type Alert = {
  id: string;
  severity: Severity;
  duration: number;
  dismissable: boolean;
  message: ReactNode;
};

export type SnackbarStore = {
  alerts: Alert[];
  addAlert: (alert: string, severity?: Severity) => string;
  dismissAlert: (id: string) => void;
}

export const useSnackbarStore = create<SnackbarStore>((set) => ({
  alerts: [],
  addAlert: (alert: string, severity: Severity = Severity.ERROR) => {
    const id = nanoid();
    const newAlert = {
      id,
      severity,
      duration: 10000,
      dismissable: true,
      message: alert,
    };
    setTimeout(() => {
      set((state) => ({ alerts: state.alerts.filter(alert => alert.id !== newAlert.id) }));
    }, newAlert.duration);
    set((state) => ({
      alerts: [...state.alerts, newAlert]
    }))
    return id;
  },
  dismissAlert: (id: string) => set((state) => ({
    alerts: state.alerts.filter(alert => !alert.dismissable || alert.id !== id)
  }))
}));
