import { gql } from '@apollo/client';
import { ProjectAddon, ResponseTime } from 'types/addon';
import { useDefaultMutation } from '../../useDefaultMutation';

const EDIT_PROJECT_ADDON_RESPONSE_TIME_KEY = 'editProjectAddonResponseTime';

const EDIT_PROJECT_ADDON_RESPONSE_TIME = gql`
  mutation ($projectAddonId: String!, $responseTime: String!) {
    ${EDIT_PROJECT_ADDON_RESPONSE_TIME_KEY}(uuid: $projectAddonId, data: { responseTime: $responseTime }) {
      id
      data {
        responseTime
      }
      updatedAt
    }
  }
`;

type MutVarEditProjectAddonResponseTime = {
  projectAddonId: string;
  responseTime: ResponseTime;
}

type MutResEditProjectAddonResponseTime = Pick<ProjectAddon, 'id' | 'data' | 'updatedAt'>;

export const useEditProjectAddonResponseTime = () => {
  return useDefaultMutation<MutResEditProjectAddonResponseTime, MutVarEditProjectAddonResponseTime>(EDIT_PROJECT_ADDON_RESPONSE_TIME, EDIT_PROJECT_ADDON_RESPONSE_TIME_KEY);
};
