import { gql } from '@apollo/client';
import { ProjectSensor } from 'graphql/generated';
import { MutationUpdateFunction, useDefaultMutation } from './useDefaultMutation';
import { useTranslation } from 'react-i18next';

const ADD_PROJECT_SENSORS_KEY = 'addProjectSensors';

const ADD_PROJECT_SENSORS = gql`
  mutation ($projectId: String!, $sensorIds: [String!]!) {
    ${ADD_PROJECT_SENSORS_KEY}(uuid: $projectId, data: { items: $sensorIds }) {
      id
      name
      unit
      location {
        id
        name
      }
      sensorId
      measurement
      updatedAt
    }
  }
`;

type MutVarAddProjectSensors = {
  projectId: string;
  sensorIds: string[];
};

type MutProjectSensor = Pick<
  ProjectSensor,
  'id' | 'name' | 'unit' | 'location' | 'sensorId' | 'measurement' | 'updatedAt'
>;
type MutResAddProjectSensors = MutProjectSensor[];

export const useAddProjectSensors = () => {
  const { t } = useTranslation();
  const update: MutationUpdateFunction<MutVarAddProjectSensors, MutResAddProjectSensors> = (
    cache,
    data,
    { variables },
  ) => {
    if (!variables) {
      throw new Error(t('Missing mutation variables'));
    }
    const isDeletedFromCache = cache.evict({
      id: cache.identify({
        id: variables.projectId,
        __typename: 'Project',
      }),
    });
    if (isDeletedFromCache) {
      cache.gc();
    }
  };

  return useDefaultMutation<MutResAddProjectSensors, MutVarAddProjectSensors>(
    ADD_PROJECT_SENSORS,
    ADD_PROJECT_SENSORS_KEY,
    update,
  );
};
