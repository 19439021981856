import { Portal } from '@chakra-ui/portal';
import { WithGuardedDrawer } from 'components/layout/drawer/WithGuardedDrawer';
import { FC, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { WithDrawer } from './layout/drawer/WithDrawer';
import { MinimizableDrawerContent } from './MinimizableDrawerContent';
import { MainMenu } from './navigation/MainMenu';
import { MainMenuMinimized } from './navigation/MainMenuMinimized';
import useIsMobile from 'hooks/useIsMobile';
import { Flex } from './layout/Flex';
import { colors } from 'theme/colors';
import { ReactComponent as SVGLogo } from 'images/charp-logo-icon.svg';
import { MdOutlineClose, MdOutlineList } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

const ContentContainer = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
`;

const Content = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

export const MainContent: FC = ({ children }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const isMobile = useIsMobile();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <WithDrawer
      side="left"
      height="100%"
      drawerChildren={
        !isMobile && (
          <MinimizableDrawerContent
            id="main-menu-drawer"
            backgroundColor={colors.brandBlue[700]}
            pinButtonPrimaryColor="black"
            pinButtonSecondaryColor="white"
            collapsible
            initiallyMaximize={false}
            initialWidth={56}
            borderColor={colors.brandBlue[700]}
          >
            {{
              main: (
                <MainMenu
                  width="18rem"
                  minHeight="100%"
                  setScrollPosition={setScrollPosition}
                  scrollPosition={scrollPosition}
                />
              ),
              minimized: <MainMenuMinimized setScrollPosition={setScrollPosition} scrollPosition={scrollPosition} />,
            }}
          </MinimizableDrawerContent>
        )
      }
    >
      <WithGuardedDrawer side="right" height="100%">
        {isMobile && (
          <Flex style={{ background: colors.brandBlue[700] }} flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center" style={{ background: colors.brandBlue[700] }}>
              <SVGLogo style={{ margin: '1rem' }} fill="white" width="1.5rem" height="1.5rem" />
              {isDrawerOpen ? (
                <MdOutlineClose
                  style={{ margin: 'auto 1rem' }}
                  size="2.5rem"
                  color="white"
                  onClick={() => setIsDrawerOpen(false)}
                />
              ) : (
                <MdOutlineList
                  style={{ margin: 'auto 1rem' }}
                  size="2.5rem"
                  color="white"
                  onClick={() => setIsDrawerOpen(true)}
                />
              )}
            </Flex>
            {isDrawerOpen && <MainMenu />}
          </Flex>
        )}
        <ContentContainer ref={contentRef} />
        <Portal containerRef={contentRef} children={<Content>{children}</Content>} />
      </WithGuardedDrawer>
    </WithDrawer>
  );
};
