import { DeviceData } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const DOWNLOAD_DEVICE_DATA_KEY = 'downloadDeviceData';

const DOWNLOAD_DEVICE_DATA = `
  mutation ($organizationId: String!, $data: DeviceDataInput!) {
    ${DOWNLOAD_DEVICE_DATA_KEY}(organizationId: $organizationId, data: $data) {
      sensorData {
        sensorId
        data {
          time,
          value
        }
      },
    }
  }
`;

type MutVarDownloadDeviceData = {
  organizationId: string;
  data: {
    start: Date;
    end?: Date;
    sensorIds: string[];
  };
};

type MutResDownloadDeviceData = DefaultQueryResponse<typeof DOWNLOAD_DEVICE_DATA_KEY, DeviceData>;

export const useDownloadDeviceData = () => {
  return useMutation<MutResDownloadDeviceData, MutVarDownloadDeviceData>(DOWNLOAD_DEVICE_DATA);
};
