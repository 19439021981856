import type { ApolloError, ApolloQueryResult, FetchMoreQueryOptions, FetchPolicy } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { DefaultResponse } from 'types/defaultResponse';

export const useDefaultQuery = <Tvalue, Tvars = undefined>(
  query: DocumentNode,
  key: string,
  variables?: Tvars,
  fetchPolicy?: FetchPolicy,
  skip = false,
  pollInterval?: number,
): [
  Tvalue | undefined,
  boolean,
  (fetchMoreOptions: FetchMoreQueryOptions<Tvars, Tvalue>) => Promise<ApolloQueryResult<Tvalue>>,
  (variables?: Partial<Tvars> | undefined) => Promise<ApolloQueryResult<DefaultResponse<Tvalue>>>,
  ApolloError | undefined,
] => {
  const { data, error, loading, fetchMore, refetch } = useQuery<DefaultResponse<Tvalue>, Tvars>(query, {
    variables,
    fetchPolicy: fetchPolicy,
    skip,
    pollInterval,
  });

  return [data && data[key], loading, fetchMore, refetch, error];
};
