import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { LayoutProps } from 'styled-system';
import { ErrorScreen } from './ErrorScreen';

export const ProjectErrorScreen: FC<LayoutProps> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <ErrorScreen
      {...props}
      title="Project not found"
      description={t('The project you are trying to access does not exist or is not accessible by you')}
      rescue={{
        label: t('Go to projects overview'),
        location: {
          pathname: '/projects',
        },
      }}
    />
  );
};
