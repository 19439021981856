import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Input } from 'components/form/InputPure';
import { Label, LabelSmall } from 'components/form/Label';
import { Flex } from 'components/layout/Flex';
import { useSnackbar } from 'components/Snackbar';
import { useAuthContext } from 'context/AuthContext';
import { Project, ProjectSensorWithRule, Rule, useQryRuleTemplates } from 'graphql/generated';
import { useAddRule } from 'graphql/mutation/useAddRule';
import { useEditRule } from 'graphql/mutation/useEditRule';
import { useFocus } from 'hooks/useFocus';
import { FC, FormEvent, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Severity } from 'state/snackbarStore';
import {
  DownsampleOrRaw,
  DownsampleOrRawEnum,
  Every,
  EveryEnum,
  RuleOperator,
  RuleOperatorEnum,
  ruleOperators,
  Statistic,
  StatisticEnum,
  statisticValues,
  Threshold,
  ThresholdEnum,
  thresholdValues,
  TimeWindow,
  TimeWindowEnum,
} from 'types/rule';
import { OperationResult } from 'urql';
import { dispatchErrors } from 'utils/util';
import { isNonEmptyString } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { SelectInput } from 'components/form/SelectInput';
import Tooltip from 'components/tooltip/Tooltip';
import { MdOutlineInfo } from 'react-icons/md';
import { Paragraph } from 'components/typography';
import { colors } from 'theme/colors';
import useIsMobile from 'hooks/useIsMobile';
import { useAddAlertView } from 'server';
import { TextArea } from 'components/form/TextAreaPure';
import { useFilterStore } from 'state/filterStore';
import shallow from 'zustand/shallow';
import { LocationSelect } from 'components/form/LocationSelect';
import { Location } from 'types/location';
import Selector from 'components/Selector';
import { useProjectSensorLocation } from 'hooks/useProjectSensorLocation';
import { useDeleteRules } from 'graphql/mutation/useDeleteRules';
import { useHistory } from 'react-router-dom';
import { useProjectSensorLocationState } from 'hooks/useProjectSensorLocationState';
import { ViewType } from 'types';

const defaultAlertName = 'Default';

type Props = {
  alertId: string;
  rule?: Rule;
  project: Pick<Project, 'id' | 'name' | 'views'>;
  selectedSensors: ProjectSensorWithRule[];
  onSubmit: () => void;
};

export const RuleForm: FC<Props> = ({ alertId, rule, project, selectedSensors, onSubmit: onParentSubmit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { addAlert } = useSnackbar();
  const authContext = useAuthContext();
  const editing = useMemo(() => rule !== undefined, [rule]);
  const [name, setName] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [every, setEvery] = useState<Every | undefined>();
  const [downsample, setDownsample] = useState<DownsampleOrRaw>(DownsampleOrRaw.HOUR);
  const [template, setTemplate] = useState<Rule | undefined>();
  const [whenInTheLast, setWindow] = useState<TimeWindow | undefined>();
  const [statisticValue, setStatisticValue] = useState<Statistic | undefined>();
  const [thresholdOperator, setThresholdOperator] = useState<Threshold>(Threshold.FIXED);
  const [operator, setOperator] = useState<RuleOperator | undefined>();
  const [threshold, setThreshold] = useState<string>('');
  const [{ fetching: editingRule }, editRule] = useEditRule();
  const [{ fetching: addingRule }, addRule] = useAddRule();
  const [inputRef, focusInput] = useFocus<HTMLInputElement>();
  const [thresholdInputRef, focusThresholdInput] = useFocus<HTMLInputElement>();
  const isMobile = useIsMobile();
  const [initialSensorIds, setInitialSensorIds] = useState(new Set<string>());

  const view = project?.views?.find((view) => view.name === defaultAlertName);

  const {
    availableMeasurementTypes,
    measurementTypes,
    setMeasurementTypes,
    initialMeasurementTypes,
    selectedLocations,
    projectSensorsBySelectedLocations,
    setSelectedLocations,
    dynamicLocationsByProjectSensors,
  } = useProjectSensorLocationState({
    view,
    projectId: project.id,
    viewType: ViewType.ALERT,
    selectedRuleSensors: selectedSensors,
  });

  const {
    onSelectorMeasurementTypeChange,
    onRemoveLocation,
    loadLocationOptions,
    loadingProjectSensors,
    filter,
    handleSearch: handleSearchLocation,
    onAddLocations,
    setOffset,
  } = useProjectSensorLocation({
    projectId: project.id,
  });

  useEffect(() => {
    if (rule && initialSensorIds.size === 0) {
      const sensorIds = new Set(selectedSensors?.map((sensor) => sensor.id));
      setInitialSensorIds(sensorIds);
    }
  }, [selectedSensors]);

  const loading = useMemo(() => editingRule || addingRule, [editingRule, addingRule]);

  const [clearFilters] = useFilterStore((state) => [state.projectSensorsForm.clear], shallow);

  useEffect(() => {
    if (rule) {
      setName(rule.name);
      rule.comments && setComments(rule.comments);
      const everyParsed = EveryEnum.safeParse(rule.every);
      if (everyParsed.success) {
        setEvery(everyParsed.data);
      }
      if (rule.downsample) {
        const downsampleParsed = DownsampleOrRawEnum.safeParse(rule.downsample);
        if (downsampleParsed.success) {
          setDownsample(downsampleParsed.data);
        }
      }
      const windowParsed = TimeWindowEnum.safeParse(TimeWindow[rule.timeWindow as keyof typeof TimeWindow]);
      if (windowParsed.success) {
        setWindow(windowParsed.data);
      }
      const statisticParsed = StatisticEnum.safeParse(rule.statistic);
      if (statisticParsed.success) {
        setStatisticValue(statisticParsed.data);
      }
      if (rule.optStatistic) {
        const thresholdOperatorParsed = ThresholdEnum.safeParse(rule.optStatistic);
        if (thresholdOperatorParsed.success) {
          setThresholdOperator(thresholdOperatorParsed.data);
        }
      }
      const operatorParsed = RuleOperatorEnum.safeParse(rule.operator);
      if (operatorParsed.success) {
        setOperator(operatorParsed.data);
      }
      if (rule.threshold) {
        setThreshold(rule.threshold.toString());
      }
    }
  }, [rule]);

  useLayoutEffect(() => {
    if (thresholdOperator) {
      focusThresholdInput();
    }
  }, [focusThresholdInput, thresholdOperator]);

  useLayoutEffect(() => {
    focusInput();
  }, [focusInput]);

  const isValidRule = useMemo(() => {
    const isEvery = isNonEmptyString(every || '');
    const isNameSet = isNonEmptyString(name);
    const isRuleSet = isNonEmptyString(operator || '');
    const isWindowSet = isNonEmptyString(whenInTheLast || '');
    const isStatisticSet = isNonEmptyString(statisticValue || '');
    const isThresholdSet = operator === RuleOperator.EXISTS || isNonEmptyString(threshold);
    const isMeasurementTypesSet = measurementTypes && measurementTypes.length > 0;
    return (
      isEvery && isNameSet && isWindowSet && isStatisticSet && isRuleSet && isThresholdSet && isMeasurementTypesSet
    );
  }, [every, name, operator, whenInTheLast, statisticValue, threshold, measurementTypes]);

  const [, deleteRules] = useDeleteRules();

  const handleServerResponse = useCallback(
    (response: OperationResult<unknown, unknown>, successMessage: string) => {
      if (response.data) {
        addAlert(successMessage, Severity.SUCCESS);
        onParentSubmit();
      }
      if (response.error) {
        dispatchErrors({ addAlert }, response.error, authContext, t);
      }
    },
    [addAlert, authContext, onParentSubmit, t],
  );

  const [addAlertView] = useAddAlertView();

  const handleResponse = async (promise: Promise<any>, isLast: boolean, message: string) => {
    const response = await promise;
    if (isLast) handleServerResponse(response, message);
  };

  const getViewId = async () => {
    if (view) return view.id;
    const newView = await addAlertView({ projectId: project.id, name: defaultAlertName });
    return newView.id;
  };

  const processSensors = async (sensors: { id: string }[], isEdit: boolean) => {
    const numThreshold = isNonEmptyString(threshold) ? parseInt(threshold) : 0;
    const optStatistic = thresholdOperator === Threshold.FIXED ? undefined : thresholdOperator;
    const downsampleValue = downsample === DownsampleOrRaw.RAW ? undefined : downsample;
    const viewId = alertId || (await getViewId());
    const rulePromises = sensors.map((sensor, index) => {
      const ruleData = {
        name,
        every: every as Every,
        operator: operator as RuleOperator,
        statistic: statisticValue as Statistic,
        timeWindow: whenInTheLast as TimeWindow,
        threshold: numThreshold,
        optStatistic,
        downsample: downsampleValue,
        projectSensorId: sensor.id,
        comments,
      };
      const promise = isEdit
        ? rule && editRule({ ruleId: rule.id, data: ruleData })
        : addRule({ viewId, data: ruleData });

      const message = isEdit
        ? t('Edited rule: {{rule}}', { rule: ruleData.name })
        : t('Created new rule: {{rule}}', { rule: ruleData.name });

      return promise && handleResponse(promise, index === sensors.length - 1, message);
    });

    await Promise.all(rulePromises);
  };

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      clearFilters();
      if (!isValidRule) return;

      const selectedMeasurementValues = measurementTypes?.map((type) => type.value) ?? [];

      const filteredSensors = projectSensorsBySelectedLocations?.filter((sensor) =>
        selectedMeasurementValues.includes(sensor.sensor?.measurement ?? ''),
      );

      if (filteredSensors) {
        const currentSensorIds = new Set(filteredSensors.map((sensor) => sensor.id));
        const sensorsToAdd = filteredSensors.filter((sensor) => !initialSensorIds.has(sensor.id));
        const sensorsToEdit = filteredSensors.filter((sensor) => initialSensorIds.has(sensor.id));
        const ruleIdSensorsToRemove = selectedSensors
          .filter((sensor) => !currentSensorIds.has(sensor.id))
          .map((sensor) => sensor.ruleId);
        if (sensorsToEdit.length > 0) await processSensors(sensorsToEdit, true);
        if (sensorsToAdd.length > 0) await processSensors(sensorsToAdd, false);
        if (ruleIdSensorsToRemove.length > 0) {
          await deleteRules({ ruleIds: ruleIdSensorsToRemove });
        }
      }

      window.location.href = history.location.pathname;
    },
    [
      addRule,
      addAlertView,
      alertId,
      clearFilters,
      downsample,
      editRule,
      every,
      handleServerResponse,
      isValidRule,
      name,
      operator,
      project,
      rule,
      projectSensorsBySelectedLocations,
      statisticValue,
      threshold,
      thresholdOperator,
      whenInTheLast,
      initialSensorIds,
      t,
      measurementTypes,
    ],
  );

  const option = useCallback(
    (value) => ({
      label: t(value, { ns: 'db-values' }),
      value,
    }),
    [t],
  );
  const customOption = useCallback(
    (values) => (value: string) => ({
      label: t(values[value], { ns: 'db-values' }),
      value,
    }),
    [t],
  );
  const evaluateOptions = (Object.values(Every) as Every[]).map(option);
  const downsampleOptions = (Object.values(DownsampleOrRaw) as DownsampleOrRaw[]).map(option);
  const whenInTheLastOptions = (Object.values(TimeWindow) as TimeWindow[]).map(option);
  const statisticOptions = (Object.values(Statistic) as Statistic[]).map(customOption(statisticValues));
  const thresholdOptions = (Object.values(Threshold) as Threshold[]).map(customOption(thresholdValues));
  const operatorOptions = (Object.values(RuleOperator) as RuleOperator[]).map(customOption(ruleOperators));
  const [{ data: templates }] = useQryRuleTemplates(
    {
      id: true,
      name: true,
      operator: true,
      statistic: true,
      threshold: true,
      timeWindow: true,
      every: true,
      downsample: true,
      comments: true,
      optStatistic: true,
    },
    {},
  );

  const templateOptions = templates?.map((template) => ({
    value: template.id,
    label: t(template.name),
  }));

  useEffect(() => {
    if (template) {
      setOperator(template.operator as RuleOperator);
      setEvery(template.every as Every);
      setWindow(template.timeWindow as TimeWindow);
      setStatisticValue(template.statistic as Statistic);
      setComments(template.comments ? t(`${template.comments}`) : '');
      template.threshold && setThreshold(`${template.threshold}`);
      setThresholdOperator(template.optStatistic as Threshold);
    }
  }, [t, template]);

  const groups = [
    t('Bizot Green'),
    t('ASHRAE AA'),
    t('ASHRAE A1'),
    t('ASHRAE A2'),
    t('ASHRAE B'),
    t('ASHRAE C'),
    t('ASHRAE D'),
    t('Exposure of masterpieces on paper'),
  ];

  const templatesWithGroups = templateOptions?.map((template) => ({
    ...template,
    ruleTemplateGroup: groups.find((group) => template.label.includes(group)) || t('Other'),
  }));

  type GroupedTemplates = {
    [key: string]: typeof templateOptions;
  };

  const templatesGrouped = templatesWithGroups?.reduce((acc: GroupedTemplates, template) => {
    const group = template.ruleTemplateGroup;
    if (!acc[group]) acc[group] = [];
    acc[group]?.push(template);
    return acc;
  }, {} as GroupedTemplates);

  const groupedTemplateOptions =
    templatesGrouped &&
    Object.entries(templatesGrouped).map(([groupLabel, templates]) => ({
      label: t(groupLabel),
      options: templates,
    }));

  const loadOptions = useCallback(
    () => loadLocationOptions(dynamicLocationsByProjectSensors),
    [loadLocationOptions, dynamicLocationsByProjectSensors],
  );

  return (
    <Form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Body>
        {templateOptions && (
          <Flex
            justifyContent="space-between"
            flexDirection="column"
            gap="0.25em"
            mb="2rem"
            p="1rem"
            backgroundColor={colors.grey[100]}
            border={`1px solid ${colors.grey[200]}`}
          >
            <Label>{t('Templates')}</Label>
            <LabelSmall>
              {t('You can choose from the predefined templates that best match your monitoring needs.')}
            </LabelSmall>
            <SelectInput
              value={template && { value: template.id, label: template.name }}
              onChange={(value) => setTemplate(templates?.find((template) => template.id === value) as Rule)}
              groupedOptions={groupedTemplateOptions}
            />
          </Flex>
        )}
        <Flex flexDirection={isMobile ? 'column' : 'row'} gap="1.5rem" mb="1.5rem">
          <Flex justifyContent="space-between" flexDirection="column" gap="0.25em" flex={1}>
            <Label>{t('Name')}</Label>
            <Input
              ref={inputRef}
              type="text"
              name="ruleName"
              value={name}
              placeholder={t('placeholders.Enter the rule name')}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Flex>
          <Flex justifyContent="space-between" flexDirection="column" gap="0.25em" flex={1}>
            <Label htmlFor="evaluate-every">{t('Run every')}</Label>
            <SelectInput
              options={evaluateOptions}
              value={every && { value: every, label: t(every, { ns: 'db-values' }) }}
              onChange={(value: string) => setEvery(value as Every)}
            />
          </Flex>
        </Flex>
        <Flex flexDirection={isMobile ? 'column' : 'row'} gap="1.5rem" mb="1.5rem">
          <Flex justifyContent="space-between" flexDirection="column" gap="0.25em" flex={1}>
            <Flex>
              <Label htmlFor="window-select">{t('When in the last')}</Label>
              <MdOutlineInfo style={{ alignSelf: 'center', marginLeft: '1rem' }} data-tooltip-id="when-last" />
            </Flex>
            <LabelSmall>{t('actions.Select a time window')}</LabelSmall>
            <SelectInput
              options={whenInTheLastOptions}
              value={whenInTheLast && { value: whenInTheLast as string, label: t(whenInTheLast, { ns: 'db-values' }) }}
              onChange={(value) => setWindow(value as TimeWindow)}
            />
          </Flex>
          <Tooltip
            id="when-last"
            place="top"
            content={t('From when the rule is run, how far back in time should data be included')}
          />
          <Flex justifyContent="space-between" flexDirection="column" gap="0.25em" flex={1}>
            <Label htmlFor="sensor-statistic-select">{t('Aggregation')}</Label>
            <LabelSmall>{t('Statistical aggregation function')}</LabelSmall>
            <SelectInput
              options={statisticOptions}
              value={
                statisticValue && {
                  value: statisticValue,
                  label: t(statisticValues[statisticValue], { ns: 'db-values' }),
                }
              }
              onChange={(value) => setStatisticValue(value as Statistic)}
            />
          </Flex>
        </Flex>
        <Flex flexDirection={isMobile ? 'column' : 'row'} gap="1.5rem" mb="1.5rem">
          <Flex justifyContent="space-between" flexDirection="column" gap="0.25em" flex={1}>
            <Flex>
              <Label htmlFor="rule-select">{t('Comparison')}</Label>
              <MdOutlineInfo style={{ alignSelf: 'center', marginLeft: '1rem' }} data-tooltip-id="rule-operator" />
            </Flex>
            <LabelSmall>{t('actions.Select a comparison operator')}</LabelSmall>
            <SelectInput
              options={operatorOptions}
              value={
                operator && {
                  value: operator,
                  label: t(ruleOperators[operator], { ns: 'db-values' }),
                }
              }
              onChange={(value) => setOperator(value as RuleOperator)}
            />
          </Flex>
          <Tooltip id="rule-operator" place="top" content={t('How to compare the aggregated data to the threshold')} />
          {operator !== RuleOperator.EXISTS && (
            <>
              <Flex justifyContent="space-between" flexDirection="column" gap="0.25em" flex={1}>
                <Flex>
                  <Label>{t('Threshold')}</Label>
                  <MdOutlineInfo style={{ alignSelf: 'center', marginLeft: '1rem' }} data-tooltip-id="threshold" />
                </Flex>
                <LabelSmall>{t('Value to trigger condition')}</LabelSmall>
                <Flex flexDirection={isMobile ? 'column' : 'row'} gap="1.5em" flex="1">
                  <SelectInput
                    options={thresholdOptions}
                    value={
                      thresholdOperator && {
                        value: thresholdOperator,
                        label: t(thresholdValues[thresholdOperator], { ns: 'db-values' }),
                      }
                    }
                    onChange={(value) => setThresholdOperator(value as Threshold)}
                    style={{ width: '9.5rem' }}
                  />
                  <Input
                    ref={thresholdInputRef}
                    width="5.5rem"
                    type="number"
                    name="threshold"
                    placeholder={thresholdOperator === Threshold.FIXED ? `${t('Value')} > 0` : `+/- ${t('Value')}`}
                    value={threshold}
                    onChange={(e) => setThreshold(e.target.value)}
                    required
                  />
                </Flex>
              </Flex>
              <Tooltip
                id="threshold"
                place="top"
                content={t(
                  "If 'Fixed' then the threshold is a fixed value > 0.\nThe aggregate functions allow you to choose a threshold above/below (+/-) the aggregate result.",
                )}
              />
            </>
          )}
        </Flex>
        <LocationSelect
          label={`${t('Add measurements by location')}*`}
          selected={selectedLocations}
          loading={loadingProjectSensors}
          onChange={(locations) => onAddLocations(locations as Location[], setSelectedLocations, selectedLocations)}
          loadOptions={loadOptions}
          onRemoveItem={(locations) => onRemoveLocation(locations, setSelectedLocations, selectedLocations)}
          onInputChange={handleSearchLocation}
          onMenuClose={() => setOffset(0)}
          inputValue={filter.name}
        />

        <Flex mb="1.5rem" flexDirection="column">
          <Label style={{ marginBottom: '0.5rem' }}>{t('Measurement types')}</Label>
          <Selector
            options={
              availableMeasurementTypes.map((sensor) => ({
                value: sensor,
                label: sensor,
              })) ?? []
            }
            selectedValue={measurementTypes}
            setSelectedValue={setMeasurementTypes}
            style={{ width: isMobile ? '17rem' : '34.5rem', border: 'solid 1px' }}
            onChange={(type) => onSelectorMeasurementTypeChange(type, setMeasurementTypes)}
            initialValue={initialMeasurementTypes}
            isMulti
            customStylesSelect={(provided) => ({
              ...provided,
              marginTop: '0',
              padding: '0 0 0 0.5rem',
              alignItems: 'center',
              gap: '0.5rem',
              svg: {
                height: '1.3rem',
                width: '1.3rem',
              },
            })}
          />
        </Flex>
        <Flex justifyContent="space-between" flexDirection="column" gap="0.25em" flex={1}>
          <Label>{t('Comments')}</Label>
          <TextArea
            height="5rem"
            name="comments"
            value={comments}
            placeholder={t('placeholders.Enter the rule comments')}
            onChange={(e) => setComments(e.target.value)}
          />
        </Flex>
        <Paragraph fontSize="1.125rem" color={colors.primary[500]} mb="0.25rem">
          {t('Advanced').toUpperCase()}
        </Paragraph>
        <Flex flexDirection={isMobile ? 'column' : 'row'} gap="1.5rem" mb="1.5rem">
          <Flex justifyContent="space-between" flexDirection="column" gap="0.25em" flex={1}>
            <Flex>
              <Label htmlFor="downsample-select">{t('Downsample data')}</Label>
              <MdOutlineInfo style={{ alignSelf: 'center', marginLeft: '1rem' }} data-tooltip-id="downsample" />
            </Flex>
            <LabelSmall>
              {t('Group data in to buckets of time and use the average. Can usually be left at the default.')}
            </LabelSmall>
            <SelectInput
              options={downsampleOptions}
              value={{ value: downsample, label: t(downsample, { ns: 'db-values' }) }}
              onChange={(value) => setDownsample(value as DownsampleOrRaw)}
            />
          </Flex>
          <Tooltip
            id="downsample"
            place="top"
            content={t(
              "Only change this if your data is fluctuating a lot and you don't want unnecessary notifications.\nThe more your data fluctuates, the longer you should set this.\nIf the data is very noisy you might want to not include all values.\nDownsampling reduces the frequency of your data by averaging it over a specified period, such as converting 5-minute raw data into hourly averages.",
            )}
          />
        </Flex>
      </Body>

      <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
        <Button smallPad type="submit" loading={loading} disabled={!isValidRule}>
          {editing
            ? isMobile
              ? t('Edit')
              : t('actions.Edit rule')
            : isMobile
            ? t('actions.Add')
            : t('actions.Add rule')}
        </Button>
      </DrawerFooter>
    </Form>
  );
};
