import { Checkbox } from 'components/form/Checkbox';
import { SelectInput } from 'components/form/SelectInput';
import { Scrollable } from 'components/Scrollable';
import { Table, Tbody, Td, Th, Thead, Tr } from 'components/table/Table';
import { User } from 'graphql/generated';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isProjectUserRole, ProjectUserRole, projectUserRoles } from 'types/projectUser';
import { OptionType } from 'types/select';
import { upperFirst } from 'utils/visualize';

type ProjectUser = Pick<User, 'id' | 'firstName' | 'lastName'>;

type Props = {
  users: ProjectUser[];
  selectedUsers: SelectedUser[];
  setSelectedUsers: (users: SelectedUser[]) => void;
};

export type SelectedUser = {
  user: ProjectUser;
  role: ProjectUserRole;
};

export const ProjectUSerModalTable: FC<Props> = (props) => {
  const { t } = useTranslation();
  const onSelectUser = (user: ProjectUser, role: ProjectUserRole) => {
    const filteredUsers = props.selectedUsers.filter((selectedUser) => selectedUser.user.id !== user.id);
    props.setSelectedUsers(filteredUsers.concat([{ user, role }]));
  };

  const onRemoveUser = (user: ProjectUser): void => {
    const filteredUsers = props.selectedUsers.filter((selectedUser) => selectedUser.user.id !== user.id);
    props.setSelectedUsers(filteredUsers);
  };

  const isUserSelected = (user: ProjectUser): boolean => {
    return props.selectedUsers.map((selectedUser) => selectedUser.user.id).includes(user.id);
  };

  const onChange = (value: string, user: ProjectUser): void => {
    if (isProjectUserRole(value)) {
      onSelectUser(user, value);
    }
  };

  const onToggle = (user: ProjectUser) => {
    if (isUserSelected(user)) {
      onRemoveUser(user);
    } else {
      onSelectUser(user, ProjectUserRole.enum.member);
    }
  };

  const getSelectedOption = (user: ProjectUser): OptionType | undefined => {
    if (isUserSelected(user)) {
      const selectedUser = props.selectedUsers.find((selectedUser) => selectedUser.user.id === user.id);
      if (selectedUser?.role) {
        return { value: selectedUser?.role, label: upperFirst(t(selectedUser?.role)) };
      }
    }
  };

  const roleOptions = useMemo(() => projectUserRoles.map((role) => ({ label: upperFirst(t(role)), value: role })), [t]);

  return (
    <Scrollable height={35}>
      <Table>
        <Thead>
          <Tr>
            <Th col={1} />
            <Th col={4}>{t('Name')}</Th>
            <Th col={4}>{t('Role')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!props.users || props.users.length === 0 ? (
            <Tr>
              <Td colSpan={3} style={{ textAlign: 'center' }}>
                {t('All members have been assigned to this project')}
              </Td>
            </Tr>
          ) : (
            props.users.map((user) => {
              const isSelected = isUserSelected(user);
              return (
                <Tr
                  key={user.id}
                  bg={isSelected ? 'lightBlue.200' : undefined}
                  hoverBg={isSelected ? 'lightBlue.200' : undefined}
                >
                  <Td>
                    <Checkbox checked={isSelected} onChange={() => onToggle(user)} />
                  </Td>
                  <Td onClick={() => onToggle(user)}>{`${user.firstName} ${user.lastName}`}</Td>
                  <Td>
                    <SelectInput
                      options={roleOptions}
                      onChange={(value: string) => onChange(value, user)}
                      value={getSelectedOption(user)}
                      placeholder={t('Role')}
                    />
                  </Td>
                </Tr>
              );
            })
          )}
        </Tbody>
      </Table>
    </Scrollable>
  );
};
