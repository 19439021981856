import { gql } from '@apollo/client';
import { Vendor } from 'types/vendor';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';

const ADD_XTEL_VENDOR_KEY = 'addXtelVendor';

const ADD_XTEL_VENDOR = gql`
  mutation ($data: AddXtelVendorInput!) {
    ${ADD_XTEL_VENDOR_KEY}(data: $data) {
      id
      name
      type
      updatedAt
    }
  }
`;

type MutVarAddXtelVendor = {
  data: {
    name: string;
    addresses: string[];
  };
}

type MutResAddXtelVendor = Pick<Vendor, 'id' | 'name' | 'type' | 'updatedAt'>;

const update: MutationUpdateFunction<MutVarAddXtelVendor, MutResAddXtelVendor>
 = (cache, data) => {
  const result = data.data;
  if (result) {
    const vendor = result[ADD_XTEL_VENDOR_KEY];
    const newVendorRef = cache.writeFragment({
      data: vendor,
      fragment: gql`
        fragment NewVendor on Vendor {
          id
        }
      `,
    });
    cache.modify({
      fields: {
        vendors(vendors = []) {
          return [...vendors, newVendorRef];
        },
      },
    });
  }
};

export const useAddXtelVendor = () => {
  return useDefaultMutation<Vendor, MutVarAddXtelVendor>(ADD_XTEL_VENDOR, ADD_XTEL_VENDOR_KEY, update);
};
