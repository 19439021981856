import { useCallback, useMemo } from 'react';
import { useHistory } from './useHistoryEnhanced';

export function useSearchParameterObjectState<T>(key: string): [state: T, setState: (state: T) => void] {
  const history = useHistory();

  const state = useMemo<T>(() => {
    const rawValue = new URLSearchParams(history.location.search).get(key) ?? undefined;
    if (rawValue !== undefined) {
      try {
        const parsedValue = JSON.parse(rawValue);
        return parsedValue;
      } catch (e) {
        return undefined;
      }
    }
    else {
      return undefined;
    }
  }, [key, history]);

  const setState = useCallback((state) => {
    if (state !== undefined) {
      history.setQueryParameter(key, JSON.stringify(state));
    }
    else {
      history.setQueryParameter(key, undefined);
    }
  }, [history, key]);

  return [state, setState];
}

export function useSearchParameterStringState<T extends string>(key: string): [state: T | undefined, setState: (state: T | undefined) => void] {
  const history = useHistory();

  const state = useMemo(() => {
    return (new URLSearchParams(history.location.search).get(key) as unknown as T) ?? undefined
  }, [key, history]);

  const setState = useCallback((state) => {
    if (state !== undefined) {
      history.setQueryParameter(key, state);
    }
    else {
      history.setQueryParameter(key, undefined);
    }
  }, [history, key]);

  return [state, setState];
}
