import type { LocationDescriptor, LocationDescriptorObject } from 'history';

/** Normalizes a LocationDescriptor into a LocationDescriptorObject. */
export function normalizeLocationDescriptor<T>(location: LocationDescriptor<T>): LocationDescriptorObject<T> {
  if (typeof location === 'string') {
    const [pathname, search, hash] = location.split(/[?#]+/);
    const descriptor: LocationDescriptorObject<T> = { pathname };
    if (search !== undefined) {
      descriptor.search = search;
    }
    if (hash !== undefined) {
      descriptor.hash = hash;
    }
    return descriptor;
  } else {
    return location;
  }
}
