import { SampleTime } from '@charphq/types';

type SampleTimeValues = { [key in SampleTime]: string };

export const sampleTimeValues: SampleTimeValues = {
  [SampleTime.FIVE]: '5 mins',
  [SampleTime.TEN]: '10 mins',
  [SampleTime.FIFTEEN]: '15 mins',
  [SampleTime.THIRTY]: '30 mins',
  [SampleTime.HOUR]: '1 hour',
  [SampleTime.THREE_HOURS]: '3 hours',
  [SampleTime.SIX_HOURS]: '6 hours',
  [SampleTime.TWELVE_HOURS]: '12 hours',
  [SampleTime.DAY]: '1 day',
  [SampleTime.WEEK]: '1 week',
  [SampleTime.MONTH]: '1 month'
};
