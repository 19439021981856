import { TokenResponse } from 'types/tokenResponse';
import { WebAuth } from 'auth0-js';
import environment from './environment';

export enum Connection {
  PASSWORD = 'Username-Password-Authentication',
  GITHUB = 'github',
  GOOGLE = 'google-oauth2',
}

export interface Authentication {
  isRegisterFlow(): boolean;
  isAuthenticated(): boolean;
  destroySession(): void;
  startSession(tokenResponse: TokenResponse): void;
  getAccessToken(): string | undefined;
  checkIsAdmin(email: string): boolean;
  requestResetPassword(email: string): Promise<void>;
}

export type AuthState = {
  accessToken: string;
  expiresAt: number;
  registerFlow: boolean;
};

class DefaultAuthentication implements Authentication {
  private static instance: Authentication;
  private static LOCAL_STORAGE_KEY = 'charp_app';

  static getInstance(): Authentication {
    if (!DefaultAuthentication.instance) {
      DefaultAuthentication.instance = new DefaultAuthentication();
    }
    return DefaultAuthentication.instance;
  }
  private readonly _auth0: WebAuth;
  private _authState: AuthState | undefined;

  private constructor() {
    this._auth0 = new WebAuth({
      domain: environment.auth0.domain,
      clientID: environment.auth0.clientId,
    });
    const localState = localStorage.getItem(DefaultAuthentication.LOCAL_STORAGE_KEY);
    this._authState = localState ? JSON.parse(localState) : undefined;
  }

  isAuthenticated(): boolean {
    return !this._isTokenExpired() && !this.isRegisterFlow();
  }

  isRegisterFlow(): boolean {
    return this._authState?.registerFlow ?? false;
  }

  checkIsAdmin(email: string): boolean {
    return email.endsWith('@charp.be');
  }

  private _isTokenExpired(): boolean {
    if (!this._authState) {
      return true;
    }
    return new Date().getTime() >= this._authState.expiresAt;
  }

  getAccessToken(): string | undefined {
    return this._authState?.accessToken;
  }

  startSession(tokenResponse: TokenResponse): void {
    const accessToken = tokenResponse.accessToken;
    const expiresAt = tokenResponse.expires * 1000 + new Date().getTime();
    this._authState = {
      expiresAt,
      accessToken,
      registerFlow: false,
    };

    localStorage.setItem(DefaultAuthentication.LOCAL_STORAGE_KEY, JSON.stringify(this._authState));
    localStorage.setItem('showAdditionalFloorplanTutorial', JSON.stringify(true));
  }

  destroySession() {
    this._authState = undefined;
    localStorage.removeItem(DefaultAuthentication.LOCAL_STORAGE_KEY);
  }

  requestResetPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this._auth0.changePassword(
        {
          email,
          connection: environment.auth0.connection,
        },
        (error) => {
          if (error) {
            reject(`${error.error} ${error.errorDescription}`);
          } else {
            resolve();
          }
        },
      );
    });
  }
}

export const auth: Authentication = DefaultAuthentication.getInstance();
