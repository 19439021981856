import { z } from 'zod';
import { ProjectSensor } from 'graphql/generated';

export interface Rule {
  id: string;
  name: string;
  every: Every;
  downsample?: Downsample;
  timeWindow: TimeWindow;
  statistic: Statistic;
  optStatistic?: Statistic;
  threshold?: number;
  optThreshold?: number;
  operator: RuleOperator;
  sensor: ProjectSensor;
  updatedAt: string;
  comments?: string;
}

export interface RuleInput {
  name: string;
  window: TimeWindow;
  statistic: Statistic;
  optStatistic?: Statistic;
  threshold?: number;
  optThreshold?: number;
  operator: RuleOperator;
}

export const Every = {
  '5MIN': '5m',
  '10MIN': '10m',
  '15MIN': '15m',
  '30MIN': '30m',
  HOUR: '1h',
  '3HOUR': '3h',
  '6HOUR': '6h',
  '12HOUR': '12h',
  DAY: '1d',
} as const;
export const EveryEnum = z.nativeEnum(Every);
export type Every = z.infer<typeof EveryEnum>;

export const Template = {
  NO_DATA: 'Missing data',
} as const;
export const TemplateEnum = z.nativeEnum(Template);
export type Template = z.infer<typeof TemplateEnum>;

export const Downsample = {
  '5MIN': '5m',
  '10MIN': '10m',
  '15MIN': '15m',
  '30MIN': '30m',
  HOUR: '1h',
  '3HOUR': '3h',
  '6HOUR': '6h',
  '12HOUR': '12h',
  DAY: '1d',
  WEEK: '1w',
  MONTH: '1mo',
} as const;
export const DownsampleEnum = z.nativeEnum(Downsample);
export type Downsample = z.infer<typeof DownsampleEnum>;

const Raw = {
  RAW: 'None (Raw data)',
} as const;
export const DownsampleOrRaw = { ...Raw, ...Downsample } as const;
export const DownsampleOrRawEnum = z.nativeEnum(DownsampleOrRaw);
export type DownsampleOrRaw = z.infer<typeof DownsampleOrRawEnum>;

export const Statistic = {
  AVG: 'avg',
  MED: 'med',
  MIN: 'min',
  MAX: 'max',
  COUNT: 'count',
  FIRST: 'first',
  LAST: 'last',
} as const;
export const StatisticEnum = z.nativeEnum(Statistic);
export type Statistic = z.infer<typeof StatisticEnum>;

export const statisticValues: { [key: string]: string } = {
  [Statistic.AVG]: 'Mean',
  [Statistic.MED]: 'Median',
  [Statistic.MIN]: 'Minimum',
  [Statistic.MAX]: 'Maximum',
  [Statistic.COUNT]: 'Count',
  [Statistic.FIRST]: 'First',
  [Statistic.LAST]: 'Last',
};

// Threshold is an extension of the statistical aggregate functions with an additional value for fixed thresholds
const Fixed = {
  FIXED: 'fixed',
} as const;
// {@link https://stackoverflow.com/a/78494599/327074}
export const Threshold = { ...Fixed, ...Statistic } as const;
export const ThresholdEnum = z.nativeEnum(Threshold);
export type Threshold = z.infer<typeof ThresholdEnum>;

export const thresholdValues: { [key: string]: string } = {
  [Threshold.FIXED]: 'Fixed',
  ...statisticValues,
};

export const RuleOperator = {
  GREATERTHAN: '>',
  LESSERTHAN: '<',
  EXISTS: '?',
} as const;
export const RuleOperatorEnum = z.nativeEnum(RuleOperator);
export type RuleOperator = z.infer<typeof RuleOperatorEnum>;

export const ruleOperators: { [key: string]: string } = {
  [RuleOperator.GREATERTHAN]: 'Is greater than',
  [RuleOperator.LESSERTHAN]: 'Is less than',
  [RuleOperator.EXISTS]: 'Has no value',
};

export const TimeWindow = {
  fiveMinutes: '5m',
  tenMinutes: '10m',
  thirtyMinutes: '30m',
  oneHour: '1h',
  threeHours: '3h',
  sixHours: '6h',
  twelveHours: '12h',
  oneDay: '1d',
} as const;
export const TimeWindowEnum = z.nativeEnum(TimeWindow);
export type TimeWindow = z.infer<typeof TimeWindowEnum>;
