import { UserInvitation } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const DELETE_INVITATION_KEY = 'deleteInvitation';

const DELETE_INVITATION = `
  mutation($invitationId: String!) {
    ${DELETE_INVITATION_KEY}(uuid: $invitationId) {
      id
    }
  }
`;

type MutVarDeleteInvitation = {
  invitationId: string;
};

type MutResDeleteInvitation = DefaultQueryResponse<typeof DELETE_INVITATION_KEY, Pick<UserInvitation, 'id'>>;

export const useDeleteUserInvitation = () => {
  return useMutation<MutResDeleteInvitation, MutVarDeleteInvitation>(DELETE_INVITATION);
};
