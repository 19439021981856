import { Checkbox } from 'components/form/Checkbox';
import { Label, LabelSmall } from 'components/form/Label';
import { Flex } from 'components/layout/Flex';
import { useSnackbar } from 'components/Snackbar';
import { Paragraph } from 'components/typography';
import { useAuthContext } from 'context/AuthContext';
import { ProjectUser } from 'graphql/generated';
import { useEditProjectUserCommunicationChannels } from 'graphql/mutation/useEditProjectUserCommunicationChannels';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Severity } from 'state/snackbarStore';
import { colors } from 'theme/colors';
import { CommunicationChannel } from 'types/notification';
import { dispatchErrors } from 'utils/util';

type Props = {
  projectUser: ProjectUser;
  onChange?: (values: CommunicationChannel[]) => void;
  values?: CommunicationChannel[];
};

export const ChannelsConfiguration: FC<Props> = ({ projectUser, onChange, values }) => {
  const { t } = useTranslation();
  const { addAlert } = useSnackbar();
  const authContext = useAuthContext();
  const [channels, setChannels] = useState<CommunicationChannel[]>(
    projectUser.communicationChannel as CommunicationChannel[],
  );

  useEffect(() => {
    if (values) {
      setChannels(values);
    }
  }, [values]);
  const [, editProjectUserCommunicationChannels] = useEditProjectUserCommunicationChannels();
  const handleChange = async (value: CommunicationChannel) => {
    const includes = channels.includes(value);
    let newChannels;
    if (includes) {
      newChannels = channels.filter((channel) => channel !== value);
    } else {
      newChannels = [...channels, value];
    }

    if (onChange) {
      onChange(newChannels);
      return;
    }
    setChannels(newChannels);
    const response = await editProjectUserCommunicationChannels({
      projectUserId: projectUser.id,
      channels: newChannels,
    });
    if (response.data) {
      addAlert(t('Updated communication'), Severity.SUCCESS);
    } else if (response.error) {
      dispatchErrors({ addAlert }, response.error, authContext, t);
    }
  };

  const communicationChannels = projectUser.phone ? Object.values(CommunicationChannel) : [CommunicationChannel.MAIL];

  return (
    <Flex flexDirection="column">
      <Paragraph fontSize="1.125rem" color={colors.primary[500]} mb="0.25rem">
        {t('Personal').toUpperCase()}
      </Paragraph>
      <Paragraph fontSize="1.125rem" color={colors.base[600]} mt={0} mb="1.5rem">
        {t('This settings applies only to you and not to other organisation members')}
      </Paragraph>

      <Label mb="0.5rem">{t('Receive notifications via')}</Label>
      {!projectUser.phone && (
        <LabelSmall>
          <Trans
            i18nKey="To receive SMS notifications, update your <1>profile</1> and add your phone number."
            components={{
              1: <a href="/account"> </a>,
            }}
          />
        </LabelSmall>
      )}
      <Flex flexBasis="100%" mb="1.5rem">
        {communicationChannels.map((channel, index) => (
          <Flex key={`${channel}-${index}`} mr="1.5rem">
            <Checkbox
              key={`${channel}-${index}`}
              label={t(channel, { ns: 'db-values' })}
              checked={channels.includes(channel.toLowerCase() as CommunicationChannel)}
              onChange={() => handleChange(channel.toLowerCase() as CommunicationChannel)}
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
