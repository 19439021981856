import { ManualUpload } from '@charphq/types';
import { useMutation } from 'urql';

const ADD_MANUAL_UPLOAD_KEY = 'addManualUpload';

const ADD_MANUAL_UPLOAD = `
  mutation (
    $end: DateTimeISO,
    $start: DateTimeISO,
    $fileId: String!,
    $deviceId: String!,
    $fileParserSettingsId: String!
    $organizationId: String!,
    $sensors: [ManualUploadSensorInput!]!,
  ) {
    ${ADD_MANUAL_UPLOAD_KEY}(
      organizationId: $organizationId,
      data: {
        end: $end,
        start: $start,
        fileId: $fileId,
        deviceId: $deviceId,
        fileParserSettingsId: $fileParserSettingsId,
        sensors: $sensors,
      }
    ) {
      id
      status
      name
      deviceType
      start
      end
      updatedAt
      createdAt
    }
  }
`;

type MutVarAddManualUpload = {
  organizationId: string;
  deviceId: string;
  fileId: string;
  sensors: { sensorId: string; column: number }[];
  // ISO string
  start?: string;
  // ISO string
  end?: string;
  fileParserSettingsId: string;
};

type MutResAddManualUpload = Pick<
  ManualUpload,
  'id' | 'name' | 'deviceType' | 'status' | 'start' | 'end' | 'updatedAt' | 'createdAt'
>;

export const useAddManualUpload = () => {
  return useMutation<MutResAddManualUpload, MutVarAddManualUpload>(ADD_MANUAL_UPLOAD);
};
