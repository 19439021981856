import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const DELETE_RULE_KEY = 'deleteRule';

const DELETE_RULE = `
  mutation ($ruleId: String!) {
    ${DELETE_RULE_KEY}(uuid: $ruleId)
  }
`;

type MutVarDeleteRule = {
  ruleId: string;
};

type MutResDeleteRule = DefaultQueryResponse<typeof DELETE_RULE_KEY, string>;

export const useDeleteRule = () => {
  return useMutation<MutResDeleteRule, MutVarDeleteRule>(DELETE_RULE);
};
