import { useEffect, useRef } from 'react';
import { useHistory } from './useHistoryEnhanced';

/** When used in a mounted component, will block route transition whenever the passed value is true or empty. */
export function useRouteChangeBlock(when = true) {
  const history = useHistory();
  const unblock = useRef<(() => void) | null>(null);
  useEffect(() => {
    if (when) {
      unblock.current = history.block();
    }
    else if (!when && unblock.current) {
      unblock.current();
      unblock.current = null;
    }
    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [history, when]);
}
