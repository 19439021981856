import { css } from '@styled-system/css';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'theme/colors';
import { User } from 'types/user';
import { getAvatarColor, getColor, initials } from 'utils/visualize';

type CircleProps = {
  uuid: string;
  backgroundColor?: string;
  size: string;
  basic: boolean;
};

const Circle = styled.div<CircleProps>`
  border-radius: 50%;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  font-size: ${(p) => p.size};
  border: 1px solid;
  ${(p) =>
    css({
      borderColor: p.basic ? colors.base[400] : p.backgroundColor,
      color: p.basic ? colors.base[600] : 'white',
      backgroundColor: p.backgroundColor ? p.backgroundColor : getColor(p.uuid),
    })}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

type Props = {
  user: Pick<User, 'firstName' | 'lastName' | 'email'>;
  backgroundColor?: string;
  size?: string;
  basic?: boolean;
};

export const Avatar: FC<Props> = ({ user, backgroundColor, size = '2.2rem', basic = false }) => {
  const [defaultBackgroundColor, setDefaultAvatarColor] = useState<string | undefined>();
  const label = useMemo(() => initials(user), [user]);
  const fullName = useMemo(() => `${user.firstName} ${user.lastName}`, [user]);

  useEffect(() => {
    const getDefaultAvatarColor = async () => {
      const defaultColor = await getAvatarColor(user.email);
      setDefaultAvatarColor(defaultColor);
    };
    getDefaultAvatarColor();
  }, [user.email]);

  return (
    <Circle
      basic={basic}
      backgroundColor={backgroundColor ?? defaultBackgroundColor}
      uuid={user.email}
      size={size}
      title={fullName}
    >
      <Label>{label}</Label>
    </Circle>
  );
};
