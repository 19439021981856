import { Project } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_PROJECT_ALERT_KEY = 'editProjectAlert';

const EDIT_PROJECT_ALERT = `
  mutation ($projectId: String!, $alert: Boolean!) {
    ${EDIT_PROJECT_ALERT_KEY}(uuid: $projectId, data: { item: $alert }) {
      id
      alert
    }
  }
`;

type MutVarEditProjectAlert = {
  projectId: string;
  alert: boolean;
};

type MutResEditProjectalert = DefaultQueryResponse<typeof EDIT_PROJECT_ALERT_KEY, Pick<Project, 'id' | 'alert'>>;

export const useEditProjectAlert = () => {
  return useMutation<MutResEditProjectalert, MutVarEditProjectAlert>(EDIT_PROJECT_ALERT);
};
