import { gql } from '@apollo/client';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';

const DELETE_TEMPERATURE_HUMIDITY_VIEW_KEY = 'deleteTemperatureHumidityView';

const DELETE_TEMPERATURE_HUMIDITY_VIEW = gql`
  mutation ($uuid: String!) {
    ${DELETE_TEMPERATURE_HUMIDITY_VIEW_KEY}(uuid: $uuid)
  }
`;

type MutVarDeleteTemperatureHumidityView = {
  uuid: string;
};

type MutResDeleteTemperatureHumidityView = string;

const update: MutationUpdateFunction<MutVarDeleteTemperatureHumidityView, MutResDeleteTemperatureHumidityView> = (
  cache,
  data,
) => {
  const result = data.data;
  if (result) {
    const uuid = result[DELETE_TEMPERATURE_HUMIDITY_VIEW_KEY];
    const isDeletedFromCache = cache.evict({
      id: cache.identify({
        id: uuid,
        __typename: 'View',
      }),
    });
    if (isDeletedFromCache) {
      cache.gc();
    }
  }
};

export const useDeleteTempHumView = () => {
  return useDefaultMutation<MutResDeleteTemperatureHumidityView, MutVarDeleteTemperatureHumidityView>(
    DELETE_TEMPERATURE_HUMIDITY_VIEW,
    DELETE_TEMPERATURE_HUMIDITY_VIEW_KEY,
    update,
  );
};
