import { gql } from '@apollo/client';
import { LinkedPeriod } from 'types/period';
import { MutationUpdateFunction, useDefaultMutation } from './useDefaultMutation';
import { useTranslation } from 'react-i18next';

const ADD_PERIOD_KEY = 'addPeriod';

const ADD_PERIOD = gql`
  mutation ($organizationId: String!, $viewId: String!, $data: PeriodInput!) {
    ${ADD_PERIOD_KEY}(organizationId: $organizationId, viewId: $viewId, data: $data) {
      id
      start
      end
      updatedAt
      createdAt
    }
  }
`;

type MutVarAddPeriod = {
  organizationId: string;
  viewId: string;
  data: {
    start: string;
    end?: string | null;
  };
};

type MutResAddPeriod = Pick<LinkedPeriod, 'id' | 'start' | 'end' | 'updatedAt' | 'createdAt'>;

export const useAddPeriod = () => {
  const { t } = useTranslation();
  const update: MutationUpdateFunction<MutVarAddPeriod, MutResAddPeriod> = (cache, data, { variables }) => {
    const result = data.data;
    if (result) {
      const newPeriod = result[ADD_PERIOD_KEY];
      const newPeriodRef = cache.writeFragment({
        data: newPeriod,
        fragment: gql`
          fragment NewPeriod on Period {
            id
          }
        `,
      });
      if (variables === undefined) {
        throw new Error(t('Missing mutation variables'));
      }
      cache.modify({
        id: cache.identify({
          id: variables.viewId,
          __typename: 'View',
        }),
        fields: {
          period: () => newPeriodRef,
        },
      });
      cache.modify({
        id: cache.identify({
          id: variables.viewId,
          __typename: 'MonitorView',
        }),
        fields: {
          period: () => newPeriodRef,
        },
      });
    }
  };

  return useDefaultMutation<MutResAddPeriod, MutVarAddPeriod>(ADD_PERIOD, ADD_PERIOD_KEY, update);
};
