import { gql } from '@apollo/client';
import { useDefaultMutation } from './useDefaultMutation';
import { TokenResponse } from 'types/tokenResponse';

const ADD_SINGLE_USER_ORGANIZATION_KEY = 'addSingleUserOrganization';

const ADD_SINGLE_USER_ORGANIZATION = gql`
  mutation ($email: String!, $firstName: String!, $lastName: String!, $organizationName: String!, $password: String!, $language: String!) {
    ${ADD_SINGLE_USER_ORGANIZATION_KEY}(
      data: { email: $email, firstName: $firstName, lastName: $lastName, organizationName: $organizationName, password: $password, language: $language }
    ) {
      accessToken
      expires
    }
  }
`;

type MutVarAddSingleUserOrganization = {
  email: string;
  password: string;
  lastName: string;
  firstName: string;
  organizationName: string;
  language: string;
};

type MutResAddSingleUserOrganization = Pick<TokenResponse, 'accessToken' | 'expires'>;

export const useAddSingleUserOrganization = () => {
  return useDefaultMutation<MutResAddSingleUserOrganization, MutVarAddSingleUserOrganization>(
    ADD_SINGLE_USER_ORGANIZATION,
    ADD_SINGLE_USER_ORGANIZATION_KEY,
  );
};
