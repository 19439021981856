import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { LayoutProps, MarginProps, TypographyProps } from 'styled-system';
import { compose, layout, margin, typography } from 'styled-system';
import { H1 } from './Title';
import { fonts } from 'theme/typography';

type Props = LayoutProps & MarginProps & TypographyProps;

export const PageTitle = styled(H1)<Props>(
  css({
    lineHeight: 'wide',
    color: 'base.900',
  }),
  compose(typography, layout, margin),
);

export const HeaderTitle = styled.h1`
  font-family: ${fonts.headerTitle};
  ${css({
    color: 'base.900',
    padding: '0 1.5rem',
    fontWeight: '600',
    fontSize: '1.5rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '@media (max-width: 600px)': {
      maxWidth: '10rem',
      fontSize: '1.2rem',
    },
  })};
`;
