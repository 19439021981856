import { Maybe } from 'graphql/types';
import { useMemo } from 'react';
import { A, F } from 'ts-toolbelt';
import { useQuery, UseQueryArgs, UseQueryResponse } from 'urql';

export type QueryProps<QueryType, T extends GenerateQueryProps<QueryType>> = F.Exact<T, GenerateQueryProps<QueryType>>;
export type QueryResponse<QueryType, T extends GenerateQueryProps<QueryType>> = A.Compute<
  GenerateQueryResponse<QueryType, T>
>;

export type QueryHookWithVars<QueryType, QueryVars> = <T extends GenerateQueryProps<QueryType>>(
  props: F.Exact<T, GenerateQueryProps<QueryType>>,
  vars: QueryVars,
) => UseQueryResponse<A.Compute<GenerateQueryResponse<QueryType, typeof props>>, QueryVars>;

type GenerateQueryPropsObject<QueryObject> = {
  [key in keyof QueryObject]?: NonNullable<QueryObject[key]> extends Maybe<Array<infer Item>> | Array<infer Item>
    ? GenerateQueryProps<Item>
    : NonNullable<QueryObject[key]> extends object
    ? GenerateQueryProps<NonNullable<QueryObject[key]>>
    : true;
};

export type GenerateQueryProps<QueryType> = QueryType extends Array<infer QueryObject>
  ? GenerateQueryPropsObject<QueryObject>
  : GenerateQueryPropsObject<QueryType>;

export type QueryVarTypes<Tvar> = {
  [Tkey in keyof Required<Tvar>]: string;
};

type QueryVar<Tvar> = {
  values: Tvar;
  types: QueryVarTypes<Tvar>;
};

type GenerateQueryResponse<QueryType, QueryTypeProps> = QueryType extends Array<infer QueryObject>
  ? GenerateQueryResponseObject<QueryObject, QueryTypeProps>[]
  : GenerateQueryResponseObject<QueryType, QueryTypeProps>;

type GenerateQueryResponseObject<QueryType, QueryTypeProps> = {
  [keyProps in keyof QueryTypeProps]-?: keyProps extends keyof QueryType
    ? NonNullable<QueryType[keyProps]> extends Array<infer TarrayType>
      ? GenerateQueryResponse<TarrayType, NonNullable<QueryTypeProps[keyProps]>>[]
      : NonNullable<QueryType[keyProps]> extends object
      ? GenerateQueryResponse<QueryType[keyProps], QueryTypeProps[keyProps]>
      : QueryType[keyProps]
    : never;
};

export const generateQuery = <TProps, TVars extends object>(
  key: string,
  props: GenerateQueryPropsObject<TProps>,
  vars?: QueryVar<TVars>,
): string => {
  return `
    query ${vars ? `(${generateQueryVars(vars)})` : ''}{
      ${key} ${vars ? `(${generateKeyVars(vars.values)})` : ''}{
        ${generateQueryProps(props)}
      }  
    }
  `;
};

const generateQueryVars = <TVars extends object>(vars: QueryVar<TVars>) => {
  let varsString = '';
  const lastKey = Object.keys(vars.values)[Object.keys(vars.values).length - 1];
  for (const key in vars.values) {
    const keyType = vars.types[key];
    varsString += `$${key}: ${keyType}${key !== lastKey ? ',' : ''}`;
  }
  return varsString;
};

const generateKeyVars = <TVars extends object>(values: TVars) => {
  let varsString = '';
  const lastKey = Object.keys(values)[Object.keys(values).length - 1];
  for (const key in values) {
    varsString += `${key}: $${key}${key !== lastKey ? ',' : ''}`;
  }
  return varsString;
};

const generateQueryProps = <T>(props: GenerateQueryPropsObject<T>) => {
  let queryPropString = '';
  for (const key in props) {
    const currentProp = props[key];
    if (typeof currentProp === 'boolean') {
      if (currentProp) {
        queryPropString += `${key}, \n`;
      }
    } else if (currentProp !== undefined) {
      queryPropString += `${key} {\n${generateQueryProps(currentProp)}}, \n`;
    }
  }
  return queryPropString;
};

type DefaultQueryArgs<Variables = object, Data = unknown> = UseQueryArgs<Variables, Data> & {
  key: string;
};

export type DefaultQueryResponse<QueryKey extends string, Data = unknown> = {
  [key in QueryKey]: Data;
};

export const useDefaultQuery = <Data = unknown, Variables = object>(
  args: DefaultQueryArgs<Variables, Data>,
): UseQueryResponse<Data, Variables> => {
  const [queryState, executeQuery] = useQuery<
    DefaultQueryResponse<DefaultQueryArgs<Variables, Data>['key'], Data>,
    Variables
  >(args);
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.[args.key],
      },
      executeQuery,
    ],
    [args.key, executeQuery, queryState],
  );
};
