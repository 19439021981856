import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, LayoutProps, MarginProps } from 'styled-system';
import { color, compose, layout, margin } from 'styled-system';
import { H5 } from './Title';

type Props = LayoutProps & MarginProps & ColorProps;

export const CardSubtitle = styled(H5)<Props>(
  css({
    lineHeight: 'tight',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  }),
  compose(layout, margin, color),
);
