import { DataParserSettings, elsecParserSettings, extractElsecHeader, lascarDataParserSettings } from '@charphq/parser';
import type { TFunction } from 'i18next';
import { DeviceType, StandaloneDeviceTypes, standaloneDeviceTypes } from 'types/device';
import { calculateUTCOffset } from 'utils/date';

export function createDataParserSettings(fileAsString: string, deviceType: DeviceType, t: TFunction): Error | DataParserSettings {
  function elsecDeviceType(fileAsString: string): Error | DataParserSettings {
    const lines = fileAsString.split(/\r?\n/);
    const utcOffset = calculateUTCOffset();
    const header = extractElsecHeader(lines[0], elsecParserSettings, utcOffset);
    if (header instanceof Error) {
      return header;
    }
    return {
      ...elsecParserSettings,
      startDate: header.startDate,
      sampleTime: header.samplingTimeInSec,
    };
  }

  function lascarDevicetype(): DataParserSettings {
    return lascarDataParserSettings;
  }

  function otherDeviceType(): Error {
    return new Error(t('Device type is not supported'));
  }

  if (!Object.keys(standaloneDeviceTypes).includes(deviceType)) {
    return new Error(t('Device type is not supported'));
  }

  const standaloneDeviceType = deviceType as StandaloneDeviceTypes;
  const deviceTapMap = {
    [StandaloneDeviceTypes.ELSEC]: elsecDeviceType,
    [StandaloneDeviceTypes.HANWELL]: otherDeviceType,
    [StandaloneDeviceTypes.LASCAR_ELECTRONICS]: lascarDevicetype,
    [StandaloneDeviceTypes.OTHER]: otherDeviceType,
  };
  return deviceTapMap[standaloneDeviceType](fileAsString);
}
