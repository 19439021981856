import styled from 'styled-components';
import type { SpaceProps } from 'styled-system';
import { space } from 'styled-system';
import { colors } from 'theme/colors';

export const Label = styled.label<SpaceProps>`
  font-size: 1.25rem;
  font-weight: 600;
  ${space}
`;

export const ErrorLabel = styled(Label)`
  color: white;
  background: ${colors.red[500]};
  font-size: 1rem;
  padding: 0.5rem;
`;

export const LabelSmall = styled.label<{ active?: boolean }>`
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  color: ${(props) => (props.active ? colors.brand : colors.base[600])};
`;
