import { Input } from 'components/form/InputPure';
import { Flex } from 'components/layout/Flex';
import { datePickerStyles, modifiersStyles } from 'components/PeriodSelector';
import { useLocale } from 'context/LocaleContext';
import { format, parse } from 'date-fns';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import type { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { colors } from 'theme/colors';
import MaskedInput from 'react-text-mask';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { LabelSmall } from './Label';
import useIsMobile from 'hooks/useIsMobile';

export type PeriodArray = [Date | undefined, Date | undefined];

type Props = {
  period: PeriodArray;
  onChange: (period: PeriodArray) => void;
  onBlur?: () => void;
  defaultValue?: string;
};

const DatePickerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${css({
    color: colors.base[900],
  })};
`;

export const RangeDatePicker: FC<Props> = ({ period, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const endInputRef = useRef<MaskedInput>(null);
  const isMobile = useIsMobile();

  const { t } = useTranslation();
  const [range, setRange] = useState<DateRange>({ from: undefined, to: undefined });
  const [show, setShow] = useState<string>('');
  const { locale } = useLocale();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (period) {
      setRange({ from: period[0], to: period[1] });
    }
  }, [period]);

  useOnClickOutside(ref, () => setShow(''));

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value.includes('_')) {
      const dateObject = parse(value, 'dd.MM.yyyy', new Date());

      if (!isNaN(dateObject.getTime())) {
        if (show === 'from') {
          onChange([dateObject, range.to]);
          if (endInputRef.current?.inputElement?.focus) {
            endInputRef.current.inputElement.focus();
          }
          setShow('to');
        } else {
          onChange([range.from, dateObject]);
          setShow('');
        }
      }
    }
  };

  const onRange = (newRange: DateRange) => {
    const { from, to } = newRange;
    if (show === 'from') {
      onChange([from, range.to]);
      setShow('to');
    } else {
      onChange([range.from, to]);
      setShow('');
    }
  };

  const inputProps = {
    placeholder: 'dd.mm.yyyy',
    mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
    width: isMobile ? '100%' : '16.5rem',
    render: (
      inputRef: (inputElement: HTMLInputElement) => void,
      props: {
        onChange: (event: React.ChangeEvent<HTMLElement>) => void;
        onBlur: (event: React.FocusEvent<HTMLElement>) => void;
        defaultValue: string | undefined;
      },
    ) => <Input type="text" name="start" ref={inputRef} {...props} />,

    onChange: handleInputChange,
  };

  return (
    <Flex flexDirection={`${isMobile ? 'column' : 'row'}`} gap="1.5rem" ref={ref}>
      <DatePickerContainer>
        <LabelSmall active={show === 'from'}>{t('Start date')}</LabelSmall>
        <MaskedInput
          {...inputProps}
          value={range.from ? format(range.from, 'dd.MM.yyyy') : ''}
          id="my-input-id-from"
          onClick={() => setShow('from')}
        />

        {show === 'from' && (
          <div
            style={{
              position: 'absolute',
              top: '68px',
              left: '-1rem',
              zIndex: 9999,
              borderRadius: '4px',
              background: 'white',
              border: '1px solid',
              borderColor: colors.base[400],
            }}
          >
            <DayPicker
              id="period-daypicker-from"
              locale={locale?.date}
              mode="range"
              onSelect={onRange as SelectRangeEventHandler}
              selected={range}
              styles={datePickerStyles}
              defaultMonth={range.from || new Date()}
              formatters={{ formatWeekdayName: (day) => format(day, 'EEEEE', { locale: locale?.date }) }}
              modifiersStyles={modifiersStyles}
              captionLayout="dropdown-buttons"
              fromYear={currentYear - 10}
              toYear={currentYear}
            />
          </div>
        )}
      </DatePickerContainer>
      <DatePickerContainer>
        <LabelSmall active={show === 'to'}>{t('End date')}</LabelSmall>
        <MaskedInput
          {...inputProps}
          ref={endInputRef}
          value={range.to && format(range.to, 'dd.MM.yyyy')}
          id="my-input-id-to"
          onClick={() => setShow('to')}
        />

        {show === 'to' && (
          <div
            style={{
              position: 'absolute',
              top: '68px',
              left: '-1rem',
              zIndex: 9999,
              borderRadius: '4px',
              background: 'white',
              border: '1px solid',
              borderColor: colors.base[400],
            }}
          >
            <DayPicker
              id="period-daypicker-to"
              locale={locale?.date}
              mode="range"
              defaultMonth={range.to || new Date()}
              onSelect={onRange as SelectRangeEventHandler}
              selected={range}
              styles={datePickerStyles}
              disabled={[{ after: new Date() }]}
              formatters={{ formatWeekdayName: (day) => format(day, 'EEEEE', { locale: locale?.date }) }}
              modifiersStyles={modifiersStyles}
              captionLayout="dropdown-buttons"
              fromYear={currentYear - 10}
              toYear={currentYear}
            />
          </div>
        )}
      </DatePickerContainer>
    </Flex>
  );
};
