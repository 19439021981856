import { format, formatDistanceToNow } from 'date-fns';
import { ComponentPropsWithoutRef, useMemo, VFC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = ComponentPropsWithoutRef<'span'> & {
  /** Creation date. Must be ISO time format string. */
  createdDate: string;
  /** Creation date. Must be ISO time format string. */
  updatedDate?: string;
};

export const ContentRelativeTime: VFC<Props> = ({ createdDate, updatedDate, ...props }) => {
  const { t } = useTranslation();
  const relativeTimeRender = useMemo(() => {
    try {
      if (createdDate && updatedDate) {
        const createdAt = new Date(createdDate);
        const updatedAt = new Date(updatedDate);
        const createdStr = format(createdAt, 'p P');
        const updatedStr = format(createdAt, 'p P');
        const createdDistance = formatDistanceToNow(createdAt);
        const updatedDistance = formatDistanceToNow(updatedAt);
        return (
          <>
            <span title={createdStr}>{t('{{date}} ago', { date: createdDistance })}</span> -{' '}
            <span title={updatedStr}>{t('edited {{date}} ago', { date: updatedDistance })}</span>
          </>
        );
      } else if (createdDate) {
        const createdAt = new Date(createdDate);
        const createdStr = format(createdAt, 'p P');
        const createdDistance = formatDistanceToNow(createdAt);
        return <span title={createdStr}>{t('{{date}} ago', { date: createdDistance })}</span>;
      }
    } catch (e) {
      return <span>{t('Invalid date format')}</span>;
    }
  }, [createdDate, updatedDate, t]);

  return <span {...props}>{relativeTimeRender}</span>;
};
