import { format, isValid, startOfDay } from 'date-fns';
import { ChangeEvent, ComponentProps, useCallback, VFC } from 'react';
import { Input } from '.';

type Props = {
  value?: Date;
  onChange?: (date?: Date) => void;
  includeTime?: boolean;
  allowPastDates?: boolean;
} & Omit<ComponentProps<typeof Input>, 'value' | 'onChange'>;
const DateInput: VFC<Props> = ({ value, onChange, includeTime = false, allowPastDates = true, ...props }) => {
  const dateFormat = includeTime ? "yyyy-MM-dd'T'HH:mm" : 'yyyy-MM-dd';

  const onDateChange = useCallback((event: ChangeEvent<HTMLInputElement>, handler: (date?: Date) => void) => {
    const input = new Date(event.target.value);
    if (isValid(input)) {
      return handler(input);
    }
    return handler(undefined);
  }, []);

  const minDate = !allowPastDates ? format(startOfDay(new Date()), dateFormat) : undefined;

  return (
    <Input
      {...props}
      onChange={onChange ? (e) => onDateChange(e, onChange) : undefined}
      value={value ? format(value, dateFormat) : ''}
      type={includeTime ? 'datetime-local' : 'date'}
      min={minDate}
    />
  );
};

export { DateInput };
