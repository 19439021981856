import { useCallback, useMemo, useRef, useState } from 'react';

export function useGuard<T>(setValue: (value: T) => void) {
  // ref to reduce callbacks updating due to dependening on guarded state
  const guardedRef = useRef(false);
  const [guarded, _setGuarded] = useState(false);
  // plain undefined (no value obj) means there is no value, but the next value can still be undefined (when set as value obj)
  const [nextValue, setNextValue] = useState<{ value: T } | undefined>();

  const setGuarded = useCallback((guarded: boolean) => {
    guardedRef.current = guarded;
    _setGuarded(guarded);
  }, []);

  const requestNext = useCallback((value: T, force = false) => {
    if (guardedRef.current && !force) {
      setNextValue({ value });
    } else {
      setValue(value);
      if (value === undefined) {
        setGuarded(false);
      }
    }
  }, [setGuarded, setValue]);

  const acceptNext = useCallback(() => {
    if (nextValue && guardedRef.current) {
      setValue(nextValue.value);
      setNextValue(undefined);
      setGuarded(false);
    }
  }, [nextValue, setGuarded, setValue]);

  const denyNext = useCallback(() => {
    if (guardedRef.current) {
      setNextValue(undefined);
    }
  }, []);

  return useMemo(() => ({
    guarded,
    setGuarded,
    nextValue,
    requestNext,
    acceptNext,
    denyNext,
  }), [acceptNext, denyNext, guarded, nextValue, requestNext, setGuarded]);
}
