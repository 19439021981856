import { Room, RoomType } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_ROOM_KEY = 'editRoom';

const EDIT_ROOM = `
  mutation ($roomId: String!, $data: EditRoomInput!) {
    ${EDIT_ROOM_KEY}(uuid: $roomId, data: $data) {
      id,
      name,
      level,
      site,
      surfaceArea,
      services,
      height,
      description,
      type {
        id,
        name,
      },
      updatedAt,
      createdAt,
    }
  }
`;

type MutVarEditRoom = {
  roomId: string,
  data: {
    name?: string,
    site?: string,
    level?: string,
    services?: string,
    surfaceArea?: string,
    height?: string,
    description?: string,
    typeId?: string,
  }
}

type MutResEditRoom = DefaultQueryResponse<typeof EDIT_ROOM_KEY, Pick<Room, 'id' | 'name' | 'site' | 'level' | 'services' | 'surfaceArea' | 'height' | 'description' | 'updatedAt' | 'createdAt'> & {
  type: Pick<RoomType, 'id' | 'name'>
}>

export const useEditRoom = () => {
  return useMutation<MutResEditRoom, MutVarEditRoom>(EDIT_ROOM);
}
