import { Location } from 'graphql/generated';
import { urqlClient } from 'graphql/urql';
import { OperationResult } from 'urql';
import { DefaultQueryResponse } from './default';

const QUERY_LOCATION_KEY = 'location';

const QUERY_LOCATION = `
query ($locationId: String!) {
  ${QUERY_LOCATION_KEY}(locationId: $locationId) {
    id
    name
  }
}
`;

type QryVarLocation = {
  locationId: string;
};

export const queryLocation = async (
  variables: QryVarLocation,
): Promise<OperationResult<DefaultQueryResponse<typeof QUERY_LOCATION_KEY, Pick<Location, 'id' | 'name'>>>> => {
  return urqlClient
    .query<DefaultQueryResponse<typeof QUERY_LOCATION_KEY, Pick<Location, 'id' | 'name'>>, QryVarLocation>(
      QUERY_LOCATION,
      variables,
    )
    .toPromise();
};
