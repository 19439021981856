import { Notification } from './notification';
import { ProjectUser } from './projectUser';
import { ProjectNotificationConfiguration } from './projectNotificationConfiguration';
import { Paginated } from './page';
import { Note, View, ProjectSensor } from 'graphql/generated';

export enum CascadeTime {
  '5MIN' = '5m',
  '10MIN' = '10m',
  '15MIN' = '15m',
  '30MIN' = '30m',
  HOUR = '1h',
}

export interface Project {
  id: string;
  name: string;
  alert: boolean;
  maintenance: boolean;
  maintenanceEndDate?: string;
  isShareable: boolean;
  alertCascadeTime?: CascadeTime;
  alertCascadeOrder?: string[];
  files?: string;
  notes?: Note[];
  views?: View[];
  users?: ProjectUser[];
  sensors?: ProjectSensor[];
  sensorPages?: Paginated<ProjectSensor>;
  notifications?: Notification[];
  notificationConfigurations?: ProjectNotificationConfiguration[];
  description?: string;
  updatedAt: string;
}
