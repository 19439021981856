import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { Flex } from 'components/layout/Flex';
import { FC, FormEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddAddon, useEditAddonCustomerId } from 'server';
import { Addon, AddonName, AddonType } from 'types/addon';
import { isNonEmptyString } from 'utils/validation';

type Props = {
  addon: Addon | undefined;
  onBack: () => void;
  onSubmit: () => void;
};

export const SerisAddonCreation: FC<Props> = ({ addon, onBack, onSubmit }) => {
  const { t } = useTranslation();
  const [customerId, setCustomerId] = useState<string>(addon?.data.customerId ?? '');
  const [addAddon, loading] = useAddAddon();
  const [editAddonCustomerId, loadingUpdate] = useEditAddonCustomerId();

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (addon) {
      await editAddonCustomerId({
        addonId: addon.id,
        customerId,
      });
    } else {
      await addAddon({
        name: AddonName.SERIS,
        type: AddonType.CONTROL_ROOM,
        data: {
          customerId,
        },
      });
    }
    onSubmit();
  };

  return (
    <Form onSubmit={onFormSubmit}>
      <Input
        validate={isNonEmptyString}
        label={t('Customer ID')}
        type="text"
        name="customerId"
        value={customerId}
        marginProps={{ mb: 4 }}
        placeholder={t('placeholders.Enter your customer ID')}
        onChange={(e: { target: { value: SetStateAction<string> } }) => setCustomerId(e.target.value)}
        required
      />
      <Flex justifyContent="space-between">
        <Flex>
          <Button width="100%" type="button" onClick={onBack}>
            {t('actions.Back')}
          </Button>
        </Flex>
        <Flex>
          <Button
            width="100%"
            type="submit"
            loading={loading || loadingUpdate}
            disabled={!isNonEmptyString(customerId)}
            variant="positive"
          >
            {t('actions.Activate seris addon')}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
