import { css } from '@styled-system/css';
import { Flex } from 'components/layout/Flex';
import { FC, useCallback } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import styled from 'styled-components';
import { Order } from 'types/order';
import { Th, ThProps } from './Table';
import { colors } from 'theme/colors';

const ThInner = styled.div`
  display: flex;
  align-items: center;
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

const IconBtn = styled.button<{ active?: boolean }>`
  ${(p) =>
    css({
      color: p.active ? 'brand' : colors.base[400],
    })}
  transition-duration: 96ms;
  transition-timing-function: ease;
  transition-property: color;
`;

const OrderIcon = styled.div<{ active?: boolean }>`
  ${(p) =>
    css({
      color: p.active ? colors.brand : 'inherit',
    })}
  margin: -5px 0;
`;

type ThFilterProps = {
  order?: {
    value: Order | undefined;
    onChange?: (order: Order | undefined) => void;
  };
  filter?: {
    value: string | undefined;
    onChange: React.Dispatch<React.SetStateAction<string | undefined>>;
    onClear: () => void;
    onSubmit: () => void;
    isActive: boolean;
  };
} & ThProps;

type OrderControlsProps = {
  order?: Order;
  handleOrder: () => void;
};

export const TableOrderControls: FC<OrderControlsProps> = ({ order, handleOrder }) => (
  <BtnContainer>
    <IconBtn type="button" onClick={handleOrder}>
      <Flex flexDirection="column" gap="1px">
        <OrderIcon active={order === Order.Ascending}>
          <MdKeyboardArrowUp size="1.125rem" />
        </OrderIcon>
        <OrderIcon active={order === Order.Descending}>
          <MdKeyboardArrowDown size="1.125rem" />
        </OrderIcon>
      </Flex>
    </IconBtn>
  </BtnContainer>
);

export const ThFilter: FC<ThFilterProps> = ({ children, order, ...props }) => {
  const handleClickOrder = useCallback(() => {
    if (order?.onChange) {
      if (order.value === Order.Ascending) {
        order.onChange(Order.Descending);
      } else if (order.value === Order.Descending) {
        order.onChange(undefined);
      } else {
        order.onChange(Order.Ascending);
      }
    }
  }, [order]);

  return (
    <Th {...props}>
      <ThInner>
        <div>{children}</div>
        {order && <TableOrderControls order={order.value} handleOrder={handleClickOrder} />}
      </ThInner>
    </Th>
  );
};
