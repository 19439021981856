import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const DELETE_DEVICE_DATA_KEY = 'deleteDeviceData';

const DELETE_DEVICE_DATA = `
  mutation ($organizationId: String!, $data: DeviceDataInput!) {
    ${DELETE_DEVICE_DATA_KEY}(organizationId: $organizationId, data: $data)
  }
`;

type MutVarDeleteDeviceData = {
  organizationId: string;
  data: {
    start: Date;
    end?: Date;
    sensorIds: string[];
  };
};

type MutResDeleteDeviceData = DefaultQueryResponse<typeof DELETE_DEVICE_DATA_KEY, boolean>;

export const useDeleteDeviceData = () => {
  /**
   * @todo We should use MutationDeleteDeviceDataArgs here
   * But then the types don't match up because we pass through a Date
   * When the backend expects a string (DateTimeISO)
   * The coercion works, but it is still a type mismatch
   * {@link https://the-guild.dev/graphql/scalars/docs/scalars/date-time#input-coercion}
   */
  return useMutation<MutResDeleteDeviceData, MutVarDeleteDeviceData>(DELETE_DEVICE_DATA);
};
