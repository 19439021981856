import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useAddLocation } from 'graphql/mutation/useAddLocation';
import { useQryOrganization } from 'graphql/generated';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { FC, useCallback, useContext } from 'react';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { LocationForm } from './LocationFrom';
import { useSnackbar } from './Snackbar';
import { useTranslation } from 'react-i18next';

export const AddLocationDrawer: FC<DrawerComponentProps> = ({ requestClose }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();

  const [{ data: organization }] = useQryOrganization({ id: true }, {});
  const [{ fetching: addingLocation }, addLocation] = useAddLocation();

  const onSubmit = useCallback(
    async ({
      name,
      height,
      description,
      typeId,
      roomId,
    }: {
      name: string;
      height?: string;
      description?: string;
      typeId?: string;
      roomId?: string;
    }) => {
      if (organization) {
        const newLocation = await addLocation({
          organizationId: organization.id,
          data: {
            name,
            typeId,
            roomId,
            height: height === '' ? undefined : height,
            description: description === '' ? undefined : description,
          },
        });
        if (newLocation.error) {
          dispatchErrors(snackbar, newLocation.error, authContext, t);
        }
        if (newLocation.data?.addLocation) {
          snackbar.addAlert(
            t('Created new location: {{location}}', { location: newLocation.data.addLocation.name }),
            Severity.SUCCESS,
          );
          requestClose();
        }
      }
    },
    [addLocation, authContext, organization, requestClose, snackbar, t],
  );

  return (
    <DrawerLayout title={t('actions.Add location')} onClose={requestClose}>
      {organization && (
        <LocationForm
          submitText={t('actions.Add location')}
          organizationId={organization.id}
          onSubmit={onSubmit}
          onClose={requestClose}
          submitting={addingLocation}
        />
      )}
    </DrawerLayout>
  );
};
