import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, LayoutProps, MarginProps } from 'styled-system';
import { color, compose, layout, margin } from 'styled-system';
import { commonTextStyle } from './Text';

type Props = LayoutProps & MarginProps & ColorProps;

export const Description = styled.p<Props>(
  css({
    ...commonTextStyle,
    marginTop: 0,
    marginBottom: 0,
    wordBreak: 'normal',
    overflowWrap: 'break-word',
    fontSize: '0.875rem',
  }),
  compose(layout, margin, color),
);
