import { Avatar } from 'components/Avatar';
import { Table, Tbody, Td, TdOverflow, Th, Thead, Tr } from 'components/table/Table';
import { Organization, User, useQryUserRole } from 'graphql/generated';
import { FC, useCallback, useContext, useMemo } from 'react';
import { OrganizationUserRole } from 'types/organizationUser';
import { TableMoreMenu } from './TableMoreMenu';
import { DrawerContext } from 'context/DrawerContext';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'utils/visualize';
import { DraftTableRow } from './DraftTableRow';

export type UserTableType = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>;

type Props = {
  me: Pick<User, 'id'> | undefined;
  org: Pick<Organization, 'id'> | undefined;
  users: UserTableType[];
  onDelete?: (user: UserTableType) => void;
};

export const UserTable: FC<Props> = ({ me, org, users, onDelete }) => {
  const { t } = useTranslation();
  const [{ data: organizationUserRole }] = useQryUserRole(
    true,
    { orgId: org?.id ?? '', uuid: me?.id ?? '' },
    { pause: !org?.id || !me?.id },
  );
  const showActionsColumn = organizationUserRole === OrganizationUserRole.enum.owner;
  return organizationUserRole ? (
    <>
      <Table fixed>
        <Thead>
          <Tr>
            <Th width="8rem">{t('Name')}</Th>
            <Th width="8rem">{t('Email')}</Th>
            <Th width="5rem">{t('Avatar')}</Th>
            <Th width="5rem">{t('Role')}</Th>
            {organizationUserRole === OrganizationUserRole.enum.owner ? <Th width="5rem" /> : null}
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <UserTableRow
              key={user.id}
              isMe={user.id === me?.id}
              org={org}
              user={user}
              myRole={organizationUserRole}
              showActionsColumn={showActionsColumn}
              onDelete={onDelete}
            />
          ))}
        </Tbody>
      </Table>
      <DraftTableRow show={users.length === 0} label={t('No users')} />
    </>
  ) : null;
};

type UserTableRowProps = {
  isMe: boolean;
  myRole: string;
  org: Pick<Organization, 'id'> | undefined;
  user: UserTableType;
  showActionsColumn: boolean;
  onDelete?: (user: UserTableType) => void;
};

export const UserTableRow: FC<UserTableRowProps> = ({ org, user, isMe, myRole, showActionsColumn, onDelete }) => {
  const { t } = useTranslation();
  const [{ data: organizationUserRole }, refetchRole] = useQryUserRole(
    true,
    { orgId: org?.id ?? '', uuid: user.id },
    { pause: !org?.id || !user?.id, requestPolicy: 'cache-and-network' },
  );
  const { requestDrawer } = useContext(DrawerContext);
  const onChangeRole = useCallback(
    (user: UserTableType) => requestDrawer('editOrganizationUser', { user, callback: refetchRole }),
    [refetchRole, requestDrawer],
  );

  const menuItems = useMemo(() => {
    const deleteItem =
      onDelete && !isMe
        ? [
            {
              text: t('actions.Remove member'),
              onClick: () => onDelete(user),
              icon: MdDeleteOutline,
            },
          ]
        : [];
    const editItem =
      myRole === OrganizationUserRole.enum.owner && !isMe && onChangeRole
        ? [
            {
              text: t('actions.Change role'),
              onClick: () => onChangeRole(user),
              icon: MdEdit,
            },
          ]
        : [];
    return [...deleteItem, ...editItem];
  }, [onDelete, isMe, myRole, user, onChangeRole, t]);

  return (
    <Tr key={user.id}>
      <TdOverflow title={`${user.firstName} ${user.lastName}`}>
        {user.firstName} {user.lastName} {isMe ? `(${t('You')})` : ''}
      </TdOverflow>
      <TdOverflow title={user.email}>{user.email}</TdOverflow>
      <Td>
        <Avatar user={user} />
      </Td>
      <TdOverflow title={organizationUserRole ? upperFirst(t(organizationUserRole, { ns: 'db-values' })) : ''}>
        {organizationUserRole ? upperFirst(t(organizationUserRole, { ns: 'db-values' })) : ''}
      </TdOverflow>
      {showActionsColumn ? (
        <Td>
          <TableMoreMenu items={menuItems} />
        </Td>
      ) : null}
    </Tr>
  );
};
