import { FC, useCallback, useMemo, useState } from 'react';
import type { MarginProps } from 'styled-system';
import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { colors } from 'theme/colors';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import type { DropdownIndicatorProps, StylesConfig } from 'react-select';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { OptionType } from 'types/select';

import { Label } from './Label';
import { isNumber } from 'utils/validation';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (value: string) => void;
  loading?: boolean;
  options?: OptionType[];
  value?: OptionType | undefined;
  placeholder?: string;
  width?: string;
  label?: string;
  formatCreateLabel?: (label: string) => string;
  inputType?: 'string' | 'numeric';
  min?: number;
  max?: number;
  creatable?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  onNewItem?: (value: string) => Promise<void>;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  groupedOptions?: {
    label: string;
    options:
      | {
          value: string;
          label: string;
        }[]
      | undefined;
  }[];
} & MarginProps;

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <SMdKeyboardArrowUp /> : <SMdKeyboardArrowDown />}
      </components.DropdownIndicator>
    )
  );
};

const SMdKeyboardArrowDown = styled(MdKeyboardArrowDown)`
  position: absolute;
  font-size: 1.5rem;
  right: 1.125rem;
  bottom: 0.5rem;

  pointer-events: none;
  ${css({
    color: colors.base[600],
  })}
`;

const SMdKeyboardArrowUp = styled(SMdKeyboardArrowDown)`
  transform: rotate(180deg);
`;

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    fontSize: '1.125rem',
    fontWeight: 400,
    background: 'transparent',
    border: 'none',
    color: colors.base[900],
    height: '100%',
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    color: colors.base[900],
    padding: '0.5rem 0 0.5rem 0.5rem',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    '@media (max-width: 600px)': {
      flexDirection: 'row',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    '@media (max-width: 600px)': {
      maxWidth: '6rem',
    },
  }),
  container: (provided) => ({
    ...provided,
    border: '1px solid',
    borderColor: colors.base[600],
    height: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: 0,
    padding: 0,
    marginTop: '1px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.base[600],
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.base[400] : '',
    color: colors.base[900],
    borderBottom: '1px solid',
    borderColor: colors.base[400],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.base[200],
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

const SSelector = styled.div<{ width?: string }>((p) =>
  css({
    width: p.width || '100%',
  }),
);

export const SelectInput: FC<Props> = ({
  onChange,
  loading,
  label,
  options,
  value,
  placeholder,
  width,
  inputType = 'string',
  min,
  max,
  creatable = false,
  formatCreateLabel,
  disabled = false,
  onNewItem,
  menuPlacement = 'auto',
  groupedOptions,
  ...props
}) => {
  const { t } = useTranslation();
  if (!formatCreateLabel) {
    formatCreateLabel = (inputText: string) => t('Create', { name: inputText });
  }
  const handleChange = useCallback(
    (option: unknown) => {
      if (option) {
        const typedOption = option as OptionType;
        if (typedOption.default) {
          onNewItem?.(typedOption.value);
        } else {
          onChange(typedOption.value);
        }
      }
    },
    [onChange, onNewItem],
  );

  const [inputValue, setInputValue] = useState('');

  const onInputChange = (input: string) => {
    if (inputType === 'numeric') {
      let correctedValue = '';
      if (isNumber(input)) {
        correctedValue = input;
        if (min && max) {
          correctedValue = Math.max(min, Math.min(parseInt(input), max)).toString();
        }
      }
      setInputValue(correctedValue);
      return;
    }
    setInputValue(input);
  };

  const onCreateOption = useCallback(
    async (inputValue: string) => {
      if (inputType === 'numeric' && creatable) {
        await onChange(inputValue);
      }
      if (onNewItem) {
        await onNewItem(inputValue);
      }
      setInputValue('');
    },
    [onChange, inputType, creatable, onNewItem],
  );

  const commonSelectProps = useMemo(
    () => ({
      components: { DropdownIndicator, IndicatorSeparator: () => null },
      styles: customStyles,
      value,
      placeholder,
      onChange: handleChange,
      options,
      isDisabled: loading || disabled,
      menuPlacement: menuPlacement,
    }),
    [value, placeholder, handleChange, options, loading, disabled, menuPlacement],
  );

  return (
    <SSelector width={width}>
      {label && <Label mb="0.25rem">{label}</Label>}
      {creatable ? (
        <CreatableSelect
          {...commonSelectProps}
          onCreateOption={onCreateOption}
          onInputChange={onInputChange}
          inputValue={inputValue}
          formatCreateLabel={formatCreateLabel}
          {...props}
        />
      ) : (
        <Select
          {...commonSelectProps}
          isSearchable={false}
          {...props}
          options={groupedOptions ?? commonSelectProps.options}
        />
      )}
    </SSelector>
  );
};
