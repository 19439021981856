import { Data, QueryQuerySensorDataArgs } from 'graphql/generated';
import { urqlClient } from 'graphql/urql';
import { OperationResult } from 'urql';
import { DefaultQueryResponse } from './default';

const QUERY_SENSOR_DATA_KEY = 'querySensorData';

const QUERY_SENSOR_DATA = `
  query ($query: QuerySensorDataInput!) {
    ${QUERY_SENSOR_DATA_KEY}(query: $query) {
      time
      value
    }
  }
`;

export const querySensorData = async (
  variables: QueryQuerySensorDataArgs,
): Promise<OperationResult<DefaultQueryResponse<typeof QUERY_SENSOR_DATA_KEY, Data[]>>> => {
  return urqlClient
    .query<DefaultQueryResponse<typeof QUERY_SENSOR_DATA_KEY, Data[]>, QueryQuerySensorDataArgs>(
      QUERY_SENSOR_DATA,
      variables,
    )
    .toPromise();
};
