import { createClient } from 'urql';
import { auth } from 'utils/Authentication';
import { extractFromEnv } from 'utils/environment';

export const urqlClient = createClient({
  url: extractFromEnv('VITE_CHARP_API', 'https://api.charp.be/graphql'),
  fetchOptions: () => {
    const token = auth.getAccessToken();
    const projectId = localStorage.getItem('projectId');
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        'x-project-id': projectId ?? '',
      },
    };
  },
  requestPolicy: 'cache-and-network',
});
