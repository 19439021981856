export function isNonEmptyString(value: string): boolean {
  return value !== '';
}

export function isNumber(value: unknown): value is number {
  if (typeof value === 'string') {
    return !isNaN(parseInt(value, 10)) && !isNaN(parseFloat(value));
  }
  return false;
}

export function isEmailAddress(value: string): boolean {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(value.toLowerCase());
}

export function containsSpecialCharacter(value: string): boolean {
  return /[!@#$%^&*.-]+/.test(value);
}

export function containsUpperCaseCharacter(value: string): boolean {
  return /[A-Z]+/.test(value);
}

export function containsLowerCaseCharacter(value: string): boolean {
  return /[a-z]+/.test(value);
}

export function containsNumericalCharacter(value: string): boolean {
  return /[0-9]+/.test(value);
}

export function isAboveMinimumPasswordLength(value: string): boolean {
  return value.length >= 8;
}

export function isStringWithMaxLength(value: string, maxLen: number): boolean {
  return value.length <= maxLen;
}

export function isValidPassword(value: string): boolean {
  return (
    isAboveMinimumPasswordLength(value) &&
    containsSpecialCharacter(value) &&
    containsUpperCaseCharacter(value) &&
    containsLowerCaseCharacter(value) &&
    containsNumericalCharacter(value)
  );
}
