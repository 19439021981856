import { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useParams } from 'react-router-dom';

type Props<T extends { [Key: string]: string }> = RouteComponentProps<T> & {
  parameter: keyof T;
  query: (variables: T) => Promise<unknown>;
  errorPage: ReactElement;
};

const RouteValidGuard = <T extends { [Key: string]: string }>({
  parameter,
  query,
  errorPage,
  children,
}: PropsWithChildren<Props<T>>) => {
  const IdFromURL = useParams<T>();
  const [validParam, setValidParam] = useState<boolean>(true);

  const isParamValid = useCallback(async () => {
    try {
      if (!Object.keys(IdFromURL).includes(parameter as string)) {
        setValidParam(false);
      }
      await query(IdFromURL);
    } catch {
      setValidParam(false);
    }
  }, [IdFromURL, parameter, query]);

  useEffect(() => {
    try {
      isParamValid();
    } catch {
      setValidParam(false);
    }
  }, [isParamValid]);

  return validParam ? <>{children}</> : <>{errorPage}</>;
};

export { RouteValidGuard };
