import { DataParserSettings } from '@charphq/parser';
import { useCallback, useState } from 'react';
import { DeviceType } from 'types/device';
import { fileParser } from './useDataFileParser';
import { createDataParserSettings } from './useDataFileParserSettings';
import { detectFileEncoding } from './useDetectFileEncoding';
import { readFile } from './useFileReader';
import type { TFunction } from 'i18next';

export type ManualUploadResult = {
  error?: string;
  fileAsString?: string;
  fileEncoding?: string;
  startDate?: Date;
  endDate?: Date;
  parserSettings?: DataParserSettings;
};

type Props = {
  onFinish: (result: ManualUploadResult) => void;
  t: TFunction;
};

type Return = [loading: boolean, processFile: (file: File, deviceType: DeviceType) => void];

export const useManualUpload = ({ onFinish, t }: Props): Return => {
  const [loading, setLoading] = useState<boolean>(false);

  const onInternalFinish = useCallback(
    (data: ManualUploadResult) => {
      setLoading(false);
      onFinish(data);
    },
    [onFinish],
  );

  const processFile = useCallback(
    async (file: File, deviceType: DeviceType) => {
      setLoading(true);
      const encoding = await detectFileEncoding(file, t);
      if (encoding instanceof Error) {
        onInternalFinish({ error: encoding.message });
        return;
      }
      const fileContent = await readFile(file, encoding, t);
      if (fileContent instanceof Error) {
        onInternalFinish({ error: fileContent.message });
        return;
      }
      const parserSettings = createDataParserSettings(fileContent, deviceType, t);
      if (parserSettings instanceof Error) {
        onInternalFinish({ error: parserSettings.message });
        return;
      }
      const parseResult = fileParser(fileContent, parserSettings, t);
      if (parseResult instanceof Error) {
        onInternalFinish({ error: parseResult.message });
        return;
      }
      onInternalFinish({
        fileAsString: fileContent,
        fileEncoding: encoding,
        startDate: parseResult.startDate,
        endDate: parseResult.endDate,
        parserSettings,
      });
    },
    [onInternalFinish, t],
  );

  return [loading, processFile];
};
