import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { LayoutProps, MarginProps } from 'styled-system';
import { compose, layout, margin } from 'styled-system';
import { H4 } from './Title';
import { colors } from 'theme/colors';

type Props = LayoutProps & MarginProps;

export const PageSubtitle = styled(H4)<Props>(
  css({
    textTransform: 'uppercase',
    lineHeight: 'tight',
    color: colors.primary[500],
    fontWeight: '400',
    fontSize: '1.125rem',
  }),
  compose(layout, margin),
);
