import { animated, useSpring } from '@react-spring/web';
import { css } from '@styled-system/css';
import { Button, DefaultButton } from 'components/buttons';
import { WithPushIn } from 'components/layout/WithPushIn';
import { H4 } from 'components/typography/Title';
import { ComponentPropsWithoutRef, FC, MouseEvent, ReactNode, useCallback } from 'react';
import { MdArrowBack, MdClose } from 'react-icons/md';
import styled from 'styled-components';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme/colors';
import { fonts } from 'theme/typography';

export const headerHeight = 49;

const Header = styled(Flex)`
  flex-shrink: 0;
  height: ${headerHeight}px;
  border-bottom: 1px solid;
  padding-right: 1.5rem;
  align-items: center;
  ${css({
    borderColor: colors.base[400],
  })};
`;

export const DrawerFooter = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${headerHeight}px;
  border-top: 1px solid;
  padding: 0 0.375rem;
  ${css({
    borderColor: colors.base[400],
  })};
`;

const DrawerTitle = styled(H4)(
  css({
    alignSelf: 'center',
    color: colors.base[900],
    fontSize: '1.25rem',
    fontWeight: '700',
    paddingLeft: '1.5rem',
    fontFamily: fonts.headerTitle,
  }),
);

export const Body = styled(Box)`
  flex-grow: 1;
  padding: 1.5rem;
`;

const GuardInner = styled.div`
  box-shadow: inset 0 -0.125rem 0.5rem rgba(0, 0, 0, 0.1);
`;

export const BackButton = styled(animated(DefaultButton))(
  css({
    color: colors.base[600],
    transition: 'color 96ms ease',
    borderRight: '1px solid',
    borderColor: colors.base[400],
    borderRadius: 0,
    padding: '0 1.5rem',
    height: '100%',
    '&:hover': {
      color: 'black',
    },
  }),
);

type DrawerSettingsProps = ComponentPropsWithoutRef<typeof Box> & {
  title: string;
  titleProps?: ComponentPropsWithoutRef<typeof H4>;
  onBack?: () => void;
  onClose: () => void;
  onCancelClose?: () => void;
  guardChildren?: ReactNode;
  isClosing?: boolean;
};

export const DrawerLayout: FC<DrawerSettingsProps> = ({
  title,
  titleProps,
  onClose,
  onCancelClose = () => {},
  children,
  guardChildren,
  isClosing = false,
  onBack,
  ...props
}) => {
  const { t } = useTranslation();

  const handleClickClose = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onClose();
    },
    [onClose],
  );

  const onClickContent = useCallback(() => {
    if (isClosing) {
      onCancelClose();
    }
  }, [isClosing, onCancelClose]);

  const backArrowStyle = useSpring({
    opacity: onBack ? 1 : 0,
    fontSize: onBack ? '1.125rem' : '0rem',
  });

  return (
    <WithPushIn
      pushInChildren={<GuardInner>{guardChildren}</GuardInner>}
      showPushInChildren={isClosing}
      height="100%"
      bg="white"
      width="37.5rem"
      {...props}
    >
      <Flex height="100%" flexDirection="column" alignItems="stretch" onClick={onClickContent} overflowY="auto">
        <Header justifyContent="space-between" alignItems="center">
          <Flex height="100%">
            {onBack && (
              <BackButton onClick={onBack} style={backArrowStyle}>
                <MdArrowBack size="1.5rem" style={{ marginRight: '0.25rem' }} />
                {t('Back')}
              </BackButton>
            )}
            <DrawerTitle {...titleProps}>{title}</DrawerTitle>
          </Flex>
          <Button iconLeft={MdClose} variant="plain" onClick={handleClickClose}>
            {t('Close')}
          </Button>
        </Header>
        {children}
      </Flex>
    </WithPushIn>
  );
};
