import { DefaultQueryResponse } from 'graphql/query/default';
import { Device } from 'types/device';
import { Location } from 'types/location';
import { useMutation } from 'urql';

const EDIT_DEVICE_LOCATION_KEY = 'editDeviceLocation';

const EDIT_DEVICE_LOCATION = `
  mutation ($locationId: String, $deviceId: String!) {
    ${EDIT_DEVICE_LOCATION_KEY}(deviceId: $deviceId, locationId: $locationId) {
      id
      name
      locations {
        id,
        location {
          id,
          name,
        },
        createdAt
      }
      updatedAt,
    }
  }
`;

type MutVarEditDeviceLocation = {
  locationId?: string | null;
  deviceId: string;
};

type MutResEditDeviceLocation = DefaultQueryResponse<
  typeof EDIT_DEVICE_LOCATION_KEY,
  Pick<Device, 'id' | 'name' | 'updatedAt'> & {
    locations: Pick<Location, 'id' | 'name' | 'createdAt'>[];
  }
>;

export const useEditDeviceLocation = () => {
  return useMutation<MutResEditDeviceLocation, MutVarEditDeviceLocation>(EDIT_DEVICE_LOCATION);
};
