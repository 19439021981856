import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { BackgroundColorProps } from 'styled-system';
import { color } from 'styled-system';

type GuardBoxProps = BackgroundColorProps;

export const GuardBox = styled.div<GuardBoxProps>(
  css({
    padding: '2rem 4rem',
  }),
  color,
);
