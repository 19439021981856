import { DefaultQueryResponse } from 'graphql/query/default';
import { LocationType, Location } from 'types/location';
import { useMutation } from 'urql';

const ADD_LOCATION_KEY = 'addLocation';

const ADD_LOCATION = `
  mutation ($organizationId: String!, $data: AddLocationInput!) {
    ${ADD_LOCATION_KEY}(organizationId: $organizationId, data: $data) {
      id,
      name,
      height,
      description,
      type {
        id,
        name,
      },
      updatedAt,
      createdAt,
    }
  }
`;

type MutVarAddLocation = {
  organizationId: string,
  data: {
    name: string,
    height?: string,
    description?: string,
    typeId?: string,
    roomId?: string,
  }
}

type MutResAddLocation = DefaultQueryResponse<typeof ADD_LOCATION_KEY, Pick<Location, 'id' | 'name' | 'height' | 'description' | 'updatedAt' | 'createdAt'> & {
  type: Pick<LocationType, 'id' | 'name'>
}>

export const useAddLocation = () => {
  return useMutation<MutResAddLocation, MutVarAddLocation>(ADD_LOCATION);
}
