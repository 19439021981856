import styled from 'styled-components';
import type {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PaddingProps,
  PositionProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';
import { border, color, compose, flexbox, layout, padding, position, space, typography } from 'styled-system';

export type BoxProps = ColorProps &
  SpaceProps &
  LayoutProps &
  BorderProps &
  PositionProps &
  TypographyProps &
  PaddingProps &
  FlexboxProps;

export const Box = styled.div<BoxProps>(compose(color, space, layout, border, position, typography, padding, flexbox));
