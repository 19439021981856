import ColorHash from 'color-hash';
import { User } from 'types/user';
import { hash, hexToInt } from './util';
import { scaleOrdinal } from 'd3-scale';
import type { ScaleOrdinal } from 'd3-scale';

const colors = [
  '#e6194B',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#0ecaf4',
  '#ea0fdf',
  '#9ad111',
  '#cf2462',
  '#136a5f',
  '#8b35ed',
  '#9A6324',
  '#e0d01a',
  '#800000',
  '#2eeb66',
  '#808000',
  '#f28b24',
  '#000075',
  '#333333',
];

export const initials = (user: Pick<User, 'firstName' | 'lastName'>): string => {
  return `${ucFirstLe(user.firstName)}${ucFirstLe(user.lastName)}`;
};

export const ucFirstLe = (variable: string): string => {
  return variable.charAt(0).toUpperCase();
};

export const upperFirst = (variable: string): string => {
  return `${variable.charAt(0).toUpperCase()}${variable.substring(1)}`;
};

export const createColorSpace = (): ScaleOrdinal<string, string> => {
  return scaleOrdinal<string, string>().range(colors);
};

const userColorSpace = createColorSpace();

export const getColor = (uuid: string): string => {
  const colorHash = new ColorHash();
  return colorHash.hex(uuid);
};

export const getAvatarColor = async (uuid: string): Promise<string> => {
  const hex = await hash(uuid);
  const value = hexToInt(hex);
  return userColorSpace(value.toString());
};

/**
 * Get the CSS color for a chart line
 * @param viewOrSensorId string UUID of either a single sensor or a view with multiple devices
 * @param index number The unique index of the device for a view
 * @returns string CSS color
 */
export const getDeviceColor = async (viewOrSensorId: string, index?: number): Promise<string> => {
  const hex = await hash(viewOrSensorId);
  const value = hexToInt(hex) + (index ?? 0);
  const normalizedIndex = value % colors.length;
  return colors[normalizedIndex];
};
