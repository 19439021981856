import { css } from '@styled-system/css';
import type { LinkProps } from 'react-router-dom';
import { Link as RRDLink } from 'react-router-dom';
import styled from 'styled-components';
import type { TypographyProps, LayoutProps, SpaceProps, ColorProps } from 'styled-system';
import { color, compose, layout, space, typography } from 'styled-system';
import { colors } from 'theme/colors';

type Props = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LinkProps & {
    textDecoration?: string;
  };

export const Link = styled(RRDLink)<Props>`
  text-decoration: ${({ textDecoration }) => (textDecoration ? textDecoration : 'underline')};
  ${css({
    color: colors.brand,
  })}
  &:hover,
  :active,
  :focus {
    ${css({
      color: colors.primary[500],
    })}
  }
  ${compose(color, space, layout, typography)}
`;
