import { TimeSeriesDataPoint } from './data';

export interface Sensor {
  id: string;
  value?: TimeSeriesDataPoint;
  unit: string;
  name: string;
  battery?: number;
  measurement: string;
  updatedAt: string;
}

export enum Unit {
  KELVIN = 'kelvin',
  CELSIUS = 'celsius',
  FAHRENHEIT = 'fahrenheit',
  LUX = 'lux',
  PERCENTAGE = 'percentage',
  MICRO_WATT_LUMEN = 'microWattLumen',
  MICRO_WATT_SQUARE_METRE = 'microWattSquareMetre',
};

export enum UnitGQ {
  KELVIN = 'KELVIN',
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT',
  LUX = 'LUX',
  PERCENTAGE = 'PERCENTAGE',
  MICRO_WATT_LUMEN = 'MICRO_WATT_LUMEN',
  MICRO_WATT_SQUARE_METRE = 'MICRO_WATT_SQUARE_METRE',
}

export type UnitToGQ = {
  [key in Unit]: UnitGQ;
}

export const unitToGQ: UnitToGQ = {
  [Unit.KELVIN]: UnitGQ.KELVIN,
  [Unit.CELSIUS]: UnitGQ.CELSIUS,
  [Unit.FAHRENHEIT]: UnitGQ.FAHRENHEIT,
  [Unit.LUX]: UnitGQ.LUX,
  [Unit.PERCENTAGE]: UnitGQ.PERCENTAGE,
  [Unit.MICRO_WATT_LUMEN]: UnitGQ.MICRO_WATT_LUMEN,
  [Unit.MICRO_WATT_SQUARE_METRE]: UnitGQ.MICRO_WATT_SQUARE_METRE
}

export enum Measurement {
  TEMPERATURE = 'temperature',
  HUMIDITY = 'humidity',
  DEWPOINT = 'dewpoint',
  LIGHT = 'light',
  UV = 'uv'
};

export enum MeasurementGQ {
  TEMPERATURE = 'TEMPERATURE',
  HUMIDITY = 'HUMIDITY',
  DEWPOINT = 'DEWPOINT',
  LIGHT = 'LIGHT',
  UV = 'UV'
}

export type MeasurementToGQ = {
  [key in Measurement]: MeasurementGQ
}

export const measurementToGQ: MeasurementToGQ = {
  [Measurement.TEMPERATURE]: MeasurementGQ.TEMPERATURE,
  [Measurement.HUMIDITY]: MeasurementGQ.HUMIDITY,
  [Measurement.DEWPOINT]: MeasurementGQ.DEWPOINT,
  [Measurement.LIGHT]: MeasurementGQ.LIGHT,
  [Measurement.UV]: MeasurementGQ.UV,
}

export type Units = {
  [key in Unit]: string;
};

export type Measurements = {
  [key in Measurement]: string;
};

export type MeasurementUnits = {
  [key in Measurement]: Unit[];
};

export const units: Units = {
  [Unit.CELSIUS]: '°C',
  [Unit.FAHRENHEIT]: '°F',
  [Unit.KELVIN]: 'K',
  [Unit.LUX]: 'lux',
  [Unit.PERCENTAGE]: '%',
  [Unit.MICRO_WATT_LUMEN]: 'µW/lumen',
  [Unit.MICRO_WATT_SQUARE_METRE]: 'µW/m2'
};

export const measurements: Measurements = {
  [Measurement.TEMPERATURE]: 'Temperature',
  [Measurement.DEWPOINT]: 'Dew point',
  [Measurement.HUMIDITY]: 'Humidity',
  [Measurement.LIGHT]: 'Light',
  [Measurement.UV]: 'Ultraviolet'
}

export const measurementUnits: MeasurementUnits = {
  [Measurement.TEMPERATURE]: [Unit.KELVIN, Unit.CELSIUS , Unit.FAHRENHEIT],
  [Measurement.DEWPOINT]: [Unit.KELVIN, Unit.CELSIUS, Unit.FAHRENHEIT],
  [Measurement.HUMIDITY]: [Unit.PERCENTAGE],
  [Measurement.LIGHT]: [Unit.LUX],
  [Measurement.UV]: [Unit.MICRO_WATT_LUMEN, Unit.MICRO_WATT_SQUARE_METRE],
}