import { gql } from '@apollo/client';
import { ProjectNotificationConfiguration } from 'types/projectNotificationConfiguration';
import { useDefaultMutation } from '../../useDefaultMutation';
import { ProjectNotificationTime } from 'graphql/generated';

const EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE_KEY = 'editProjectNotificationConfigurationValue';

const EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE = gql`
  mutation ($projectNotificationConfigurationId: String!, $value: ProjectNotificationTime!) {
    ${EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE_KEY}(uuid: $projectNotificationConfigurationId, data: { value: $value }) {
      id
      value
    }
  }
`;

type MutVarEditProjectNotificationConfigurationValue = {
  projectNotificationConfigurationId: string;
  value: ProjectNotificationTime;
};

type MutResEditProjectNotificationConfigurationValue = Pick<ProjectNotificationConfiguration, 'id' | 'value'>;

export const useEditProjectNotificationConfigurationValue = () => {
  return useDefaultMutation<
    MutResEditProjectNotificationConfigurationValue,
    MutVarEditProjectNotificationConfigurationValue
  >(EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE, EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE_KEY);
};
