import { gql } from '@apollo/client';
import { Addon, AddonData } from 'types/addon';
import { useDefaultMutation } from '../useDefaultMutation';

const EDIT_ADDON_CUSTOMERID_KEY = 'editAddonCustomerId';

const EDIT_ADDON_CUSTOMERID = gql`
  mutation ($addonId: String!, $customerId: String!) {
    ${EDIT_ADDON_CUSTOMERID_KEY}(uuid: $addonId, data: { item: $customerId }) {
      id
      data {
        customerId
      }
    }
  }
`;

type MutVarEditAddonCustomerId = {
  addonId: string;
  customerId: string;
}

type MutAddonData = Pick<AddonData, 'customerId'>;

type MutResEditAddonCustomerId = Pick<Addon, 'id'> & {
  data: MutAddonData;
}

export const useEditAddonCustomerId = () => {
  return useDefaultMutation<MutResEditAddonCustomerId, MutVarEditAddonCustomerId>(EDIT_ADDON_CUSTOMERID, EDIT_ADDON_CUSTOMERID_KEY);
};
