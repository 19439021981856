export function extractFromEnv(envKey: string, fallback?: string): string {
  const value = import.meta.env[envKey];
  if (typeof value === 'string') {
    return value;
  } else if (fallback) {
    return fallback;
  } else {
    throw new Error(`Could not find environment variable "${envKey}".`);
  }
}

function extractBoolFromEnv(envKey: string): boolean {
  const value = import.meta.env[envKey];
  if (value === 'true') {
    return true;
  } else {
    return false;
  }
}

const REACT_ENV_PREFIX = 'VITE_';

const environment = {
  app: {
    update: extractBoolFromEnv(`${REACT_ENV_PREFIX}APP_UPDATE`),
  },
  auth0: {
    domain: extractFromEnv(`${REACT_ENV_PREFIX}AUTH0_DOMAIN`),
    clientId: extractFromEnv(`${REACT_ENV_PREFIX}AUTH0_CLIENT_ID`),
    connection: extractFromEnv(`${REACT_ENV_PREFIX}AUTH0_CONNECTION`),
  },
  sentry: {
    dsn: extractFromEnv(`${REACT_ENV_PREFIX}SENTRY_DSN`),
  },
};

export default environment;
