import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { LayoutProps, MarginProps } from 'styled-system';
import { compose, layout, margin } from 'styled-system';
import { H3 } from './Title';

type Props = LayoutProps & MarginProps;

export const AsideTitle = styled(H3)<Props>(
  css({
    lineHeight: 'tight',
    fontWeight: 'bold',
  }),
  compose(layout, margin),
);
