import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { LayoutProps, SpaceProps } from 'styled-system';
import { compose, layout, space } from 'styled-system';
import { colors } from 'theme/colors';

type InputProps = SpaceProps & LayoutProps;

export const Input = styled.input<InputProps>`
  outline: none;
  padding: 0.438rem;
  font-size: 1.125rem;
  ${css({
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: colors.base[600],
  })}
  &:active:not([disabled]) {
    ${css({
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'base.600',
    })}
  }
  &:active,
  :focus {
    box-shadow: inset 0 0 0 1px #0b1dd9;
    border: 1px solid #0b1dd9;
  }
  ::placeholder {
    ${css({
      color: 'base.600',
    })}
  }
  &[disabled] {
    ${css({
      borderColor: 'base.600',
      borderWidth: '1px',
      boxShadow: 'none',
      backgroundColor: 'base.300',
      cursor: 'not-allowed',
    })}
  }
  ${compose(space, layout)}
`;
