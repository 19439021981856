import { z } from 'zod';

export interface OrganizationUser {
  id: string;
  userId: string;
  role: OrganizationUserRole;
}
export const OrganizationUserRole = z.enum(['owner', 'collaborator', 'member']);
export type OrganizationUserRole = z.infer<typeof OrganizationUserRole>;

export const organizationUserRoles = OrganizationUserRole.options;
