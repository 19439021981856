import { useCallback, useMemo, useState } from 'react';
import { tryPromise } from 'utils/util';

type UploadFileReturn = [
  upload: (url: string, file: File) => Promise<void>,
  loading: boolean,
  progress: number,
  timedout: boolean,
  aborted: boolean,
  errored: boolean,
];

export const useUploadFile = (): UploadFileReturn => {
  const [timedout, setTimedout] = useState<boolean>(false);
  const [aborted, setAborted] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const upload = useCallback((url: string, file: File) => {
    const generateUploadPromise = () =>
      new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener('loadstart', () => {
          setLoading(true);
          setAborted(false);
          setErrored(false);
          setTimedout(false);
          setProgress(0);
        });
        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            const percentage = Math.round((event.loaded * 100) / event.total);
            setProgress(percentage);
          }
        });
        xhr.upload.addEventListener('abort', () => {
          setAborted(true);
          reject();
        });
        xhr.upload.addEventListener('error', () => {
          setErrored(true);
          reject();
        });
        xhr.upload.addEventListener('load', () => {
          setProgress(100);
          resolve();
        });
        xhr.upload.addEventListener('timeout', () => {
          setTimedout(true);
          reject();
        });
        xhr.upload.addEventListener('loadend', () => {
          setLoading(false);
        });
        xhr.open('PUT', url);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.setRequestHeader('Content-Disposition', `attachment; filename="${file.name}"`);
        xhr.send(file);
      });
    return tryPromise(generateUploadPromise);
  }, []);

  return useMemo<UploadFileReturn>(
    () => [upload, loading, progress, timedout, aborted, errored],
    [aborted, errored, loading, progress, timedout, upload],
  );
};
