import { Container, Content } from 'components/layout';
import { Box } from 'components/layout/Box';
import { Header } from 'components/layout/Header';
import { NoteDrawerArgs } from 'components/notes/NoteDrawer';
import { BasicNote, NotesTable } from 'components/notes/NotesTable';
import { DrawerContext } from 'context/DrawerContext';
import { useQryProject } from 'graphql/generated';
import { FC, useContext, useMemo, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { RouteProps } from 'types/route';
import { NoteDeleteDialog } from '../../../components/NoteDeleteDialog';

import { useTranslation } from 'react-i18next';
import { Button } from 'components/buttons';
import { MdOutlinePostAdd } from 'react-icons/md';
import useIsMobile from 'hooks/useIsMobile';
import { isEmpty } from 'lodash';

type Props = RouteComponentProps<RouteProps>;

const ProjectNotes: FC<Props> = ({ match }) => {
  const { t } = useTranslation();

  const projectId = match.params.projectId;
  const [{ data: project, fetching: loading }, refetchProjects] = useQryProject(
    {
      id: true,
      name: true,
      notes: {
        id: true,
        title: true,
        description: true,
        period: {
          start: true,
          end: true,
        },
        owner: {
          id: true,
          email: true,
          firstName: true,
          lastName: true,
          createdAt: true,
        },
        views: {
          id: true,
          name: true,
        },
        updatedAt: true,
        createdAt: true,
      },
    },
    { uuid: projectId },
    { pause: isEmpty(projectId) },
  );
  const { drawerRequest } = useContext(DrawerContext);
  const notes = useMemo(() => project?.notes ?? [], [project]);
  const [attemptDeleteNote, setAttemptDeleteNote] = useState<BasicNote>();

  const selectedNoteId = useMemo(() => {
    if (drawerRequest?.drawerId === 'note') {
      return (drawerRequest.args as NoteDrawerArgs).noteId;
    }
  }, [drawerRequest]);

  const handleOndDeleteNote = () => {
    refetchProjects();
    setAttemptDeleteNote(undefined);
  };

  const { requestDrawer } = useContext(DrawerContext);
  const isMobile = useIsMobile();

  return (
    <Container backgroundColor="note.100">
      {project && (
        <Header
          variant="notes"
          title={t('Notes')}
          controls={
            isMobile && (
              <Button variant="note" onClick={() => requestDrawer('note')}>
                <MdOutlinePostAdd />
              </Button>
            )
          }
        />
      )}
      <Content loading={loading}>
        <Box flexGrow={1} overflowY="auto" height={128} paddingBottom={40}>
          {project?.notes && (
            <NotesTable
              notes={notes}
              onSelectNote={(note) => requestDrawer('note', { noteId: note.id })}
              onDeleteNote={setAttemptDeleteNote}
              selectedNoteId={selectedNoteId}
            />
          )}
        </Box>
      </Content>
      {attemptDeleteNote && (
        <NoteDeleteDialog
          note={attemptDeleteNote}
          onCancel={() => setAttemptDeleteNote(undefined)}
          onAfterDelete={handleOndDeleteNote}
        />
      )}
    </Container>
  );
};

export default ProjectNotes;
