import type { Locale as DateLocale } from 'date-fns';
import { enGB, fr, de, nlBE, nl, be } from 'date-fns/locale';
import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

export const locales: { [key: string]: DateLocale } = {
  be,
  en: enGB,
  de,
  fr,
  nlBE,
  nl,
  default: enGB,
};

export const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
];

export type NumberLocale = {
  formatNumber(value: number | undefined): string;
  parseNumber(value: string): number;
};

export type Locale = {
  date: DateLocale;
  number: NumberLocale;
};

type LocaleContextType = {
  locale: Locale | undefined;
  setLocale: React.Dispatch<React.SetStateAction<Locale | string | undefined>>;
};

export const useLocale = () => useContext(LocaleContext);

export const LocaleContext = createContext<LocaleContextType>({
  locale: undefined,
  setLocale: () => {},
});

function numberFormatterFactory(locale: string): Intl.NumberFormat {
  const numberFormatter = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
  });
  return numberFormatter;
}

function parseNumber(numberFormatter: Intl.NumberFormat, value: string): number {
  if (value.includes('.') && !value.includes(',')) {
    // Check if value is NaN
    // eslint-disable-next-line no-self-compare
    if (+value === +value) {
      return +value;
    }
  }
  const numberExample = 1234.5;
  const numberParts = numberFormatter.formatToParts(numberExample);
  const group = numberParts.find((part) => part.type === 'group')?.value;
  const decimal = numberParts.find((part) => part.type === 'decimal')?.value;
  let realNumber = value;
  if (group) {
    realNumber = realNumber.replace(group === '.' ? '\\.' : group, 'g');
  }
  if (decimal) {
    realNumber = realNumber.replace(decimal === '.' ? '\\.' : decimal, 'g');
  }
  return +realNumber;
}

function createNumberLocale(locale: string): NumberLocale {
  const numberFormatter = numberFormatterFactory(locale);
  return {
    formatNumber: (value: number | undefined) => (value !== undefined ? numberFormatter.format(value) : '/'),
    parseNumber: (value: string) => parseNumber(numberFormatter, value),
  };
}

export const LocaleProvider: FC = (props) => {
  const [locale, setLocale] = useState<Locale>();

  const loadLocale = useCallback(async (localeParam) => {
    let localeString = navigator.languages ? navigator.languages[0] : navigator.language;
    if (localeParam) {
      localeString = localeParam;
    }
    try {
      const dateLocale = locales[localeString] || locales.default;
      const numberLocale = createNumberLocale(localeString);
      // Note: dateLocale is restricted to the ones we recognise
      // However numberLocale will match which ever locale is passed in
      // There is a fallback set
      // user.language > organisation.language > browser language > 'en'
      // @see OrgContext.tsx
      setLocale({
        date: dateLocale,
        number: numberLocale,
      });
    } catch (error) {
      console.error(`Unable to load locale: ${localeString}`, error);
    }
  }, []);

  useEffect(() => {
    loadLocale(null);
  }, [loadLocale]);

  return <LocaleContext.Provider value={{ locale, setLocale: loadLocale }} {...props} />;
};
