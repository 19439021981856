import { gql } from '@apollo/client';
import { SampleTime } from '@charphq/types';
import { ViewType } from 'types';
import { TemperatureHumidityView } from 'types/view/temperatureHumidityView';
import { useMutation } from 'urql';

const EDIT_TEMPERATURE_HUMIDITY_VIEW_KEY = 'editTemperatureHumidityView';

const EDIT_TEMPERATURE_HUMIDITY_VIEW = gql`
  mutation ($viewId: String!, $data: AddTemperatureHumidityViewInput!) {
    ${EDIT_TEMPERATURE_HUMIDITY_VIEW_KEY}(uuid: $viewId, data: $data) {
      id
      name
      type
      projectId
      updatedAt
    }
  }
`;

type MutVarEditTemperatureHumidityView = {
  viewId: string;
  data: {
    name: string;
    projectSensorIds?: string[];
    autoAddNewDevices?: boolean;
    sampleTime?: SampleTime;
    viewType: ViewType.DEW_POINT | ViewType.ABSOLUTE_HUMIDITY;
  };
};

type MutResEditTemperatureHumidityView = Pick<
  TemperatureHumidityView,
  'id' | 'name' | 'type' | 'updatedAt' | 'projectId'
>;

export const useEditTemperatureHumidityView = () => {
  return useMutation<MutResEditTemperatureHumidityView, MutVarEditTemperatureHumidityView>(
    EDIT_TEMPERATURE_HUMIDITY_VIEW,
  );
};
