import { css } from '@styled-system/css';
import { Box } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import { Pagination, PaginationProps } from 'components/Pagination';
import { useDomRect } from 'hooks/useDomRect';
import { useHover } from 'hooks/useHover';
import { useCallback, useMemo, useRef, VFC } from 'react';
import styled from 'styled-components';
import { Order } from 'types/order';
import { BadgeProperty } from './BadgeProperty';
import { Property } from './DefaultProperty';
import { EditableProperty } from './EditableProperty';
import { ObjectProperty } from './ObjectProperty';
import { SelectProperty } from './SelectProperty';
import { StaticProperty } from './StaticProperty';
import { TableOrderControls } from 'components/table/ThFilter';
import { MoreMenuItem, TableMoreMenu } from 'components/table/TableMoreMenu';
import { colors } from 'theme/colors';
import { DraftTableRow } from 'components/table/DraftTableRow';
import { Checkbox } from 'components/form/Checkbox';
import useIsMobile from 'hooks/useIsMobile';

type TableHeadProps = {
  maxWidth: number;
  cells: (TableHeadCellProperties & { width?: string })[];
  allSelected?: boolean;
  onSelectAll?: () => void;
};

export type TableHeadProperties = Omit<TableHeadProps, 'maxWidth'>;

export const TableHead: VFC<TableHeadProps> = ({ cells, maxWidth, allSelected, onSelectAll }) => {
  const widthPerCell = useMemo(() => {
    if (cells.length === 0) {
      return 0;
    }
    return Math.floor(maxWidth / cells.length);
  }, [maxWidth, cells]);

  const isMobile = useIsMobile();

  return (
    <TableHeadContainer>
      {onSelectAll && !isMobile && (
        <TableHeadCellContainer style={{ padding: '0.5rem' }} width="5%">
          <Checkbox checked={allSelected} onChange={onSelectAll} />
        </TableHeadCellContainer>
      )}
      {cells.map((cell, index) => (
        <TableHeadCell widthPerCell={cell.width ?? `${widthPerCell}px`} key={index} {...cell} />
      ))}
    </TableHeadContainer>
  );
};

const TableHeadContainer = styled.div(
  css({
    display: 'flex',
    borderBottom: '1px solid',
    borderColor: 'base.400',
    fontSize: '1.125rem',
  }),
);

export type TableHeadCellProps = {
  widthPerCell: string;
  title: string;
  order?: {
    value: Order | undefined;
    onChange?: (order: Order | undefined) => void;
  };
  filter?: {
    value: string | null;
    onChange: (filter: string | null) => void;
  };
};

export type TableHeadCellProperties = Omit<TableHeadCellProps, 'widthPerCell'>;

export const TableHeadCell: VFC<TableHeadCellProps> = ({ title, order, widthPerCell }) => {
  const handleClickOrder = useCallback(() => {
    if (order?.onChange) {
      if (order.value === Order.Ascending) {
        order.onChange(Order.Descending);
      } else if (order.value === Order.Descending) {
        order.onChange(undefined);
      } else {
        order.onChange(Order.Ascending);
      }
    }
  }, [order]);

  return (
    <TableHeadCellContainer width={widthPerCell}>
      <TableHeadCellContentContainer>
        <Title>{title}</Title>
        <Flex gap="0.25rem">{order && <TableOrderControls order={order.value} handleOrder={handleClickOrder} />}</Flex>
      </TableHeadCellContentContainer>
    </TableHeadCellContainer>
  );
};

const TableHeadCellContentContainer = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
  }),
);

type TableHeadCellContainerProps = {
  clickable?: boolean;
  width: string;
};

const TableHeadCellContainer = styled.div<TableHeadCellContainerProps>((props) =>
  css({
    padding: '0 0.5rem 1rem 1rem',
    width: props.width,
    cursor: props.clickable ? 'pointer' : 'default',
    transition: 'background 20ms ease-in 0s',
    ':hover': {
      background: props.clickable ? 'rgba(55, 53, 47, 0.08)' : 'none',
    },
  }),
);

const Title = styled.span(
  css({
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: '600',
  }),
);

type TableRowProps = {
  widthPerCell: number;
  onClick?: () => void;
  actions?: MoreMenuItem[];
  properties: (Property & { width?: string })[];
  active?: boolean;
  selected?: boolean;
  onSelectRow?: (id: string) => void;
  id?: string;
};

export type TableRowProperties = Omit<TableRowProps, 'widthPerCell'>;

export const TableRow: VFC<TableRowProps> = ({
  widthPerCell,
  onClick,
  properties,
  actions,
  active = false,
  selected,
  onSelectRow,
  id,
}) => {
  const [ref, hoveringRef] = useHover<HTMLDivElement>();

  const lastIndex = useMemo(() => properties.length - 1, [properties.length]);

  const firstProperties = useMemo(() => {
    return properties.slice(0, lastIndex);
  }, [lastIndex, properties]);

  const lastProperty = useMemo(() => {
    return properties[lastIndex];
  }, [lastIndex, properties]);
  const hovering = hoveringRef || active;

  const isMobile = useIsMobile();

  return (
    <Flex>
      <TableRowContainer ref={ref} clickable={onClick !== undefined} active={active}>
        {onSelectRow && id && !isMobile && (
          <TableCell width="5%">
            <Checkbox checked={selected} onChange={() => onSelectRow(id)} />
          </TableCell>
        )}
        {firstProperties.map((property, index) => (
          <TableCell onClick={onClick} width={property.width ?? `${widthPerCell}px`} key={property.id || index}>
            {property.type === 'badge' && <BadgeProperty {...property} />}
            {property.type === 'editable' && <EditableProperty {...property} hovering={hovering} />}
            {property.type === 'static' && <StaticProperty {...property} />}
            {property.type === 'object' && <ObjectProperty {...property} hovering={hovering} />}
            {property.type === 'select' && <SelectProperty {...property} />}
          </TableCell>
        ))}
        <TableCell width={lastProperty.width ?? `${widthPerCell}px`}>
          <Flex width="100%" alignItems="center" justifyContent="space-between" px={2}>
            {lastProperty.type === 'badge' && <BadgeProperty {...lastProperty} />}
            {lastProperty.type === 'editable' && <EditableProperty {...lastProperty} hovering={hovering} />}
            {lastProperty.type === 'static' && <StaticProperty {...lastProperty} />}
            {lastProperty.type === 'object' && <ObjectProperty {...lastProperty} hovering={hovering} />}
            {lastProperty.type === 'select' && <SelectProperty {...lastProperty} />}
            {actions && hovering && <TableMoreMenu stopPropagation items={actions} />}
          </Flex>
        </TableCell>
      </TableRowContainer>
    </Flex>
  );
};

type TableRowContainerProps = {
  clickable?: boolean;
  active: boolean;
};

const TableRowContainer = styled.div<TableRowContainerProps>(({ clickable, active }) =>
  css({
    display: 'flex',
    position: 'relative',
    width: '100%',
    cursor: clickable ? 'pointer' : 'default',
    background: active ? colors.base[200] : 'inherit',
    ':hover': {
      background: colors.base[200],
    },
    borderBottom: '1px solid',
    borderColor: 'base.400',
  }),
);

type TableCellProps = {
  width: string;
};

export const TableCell = styled.div<TableCellProps>((props) =>
  css({
    alignItems: 'center',
    display: 'flex',
    py: '0.75rem',
    px: '0.5rem',
    width: props.width,
    fontSize: '1.125rem',
  }),
);

export type TableBodyProps = {
  maxWidth: number;
  rows: TableRowProperties[];
  emptyState?: boolean;
  loading?: boolean;
  label?: string;
  selectedDevices?: string[];
  onSelectRow?: (id: string) => void;
};

export type TableBodyProperties = Omit<TableBodyProps, 'maxWidth'>;

export const TableBody: VFC<TableBodyProps> = ({
  rows,
  maxWidth,
  loading = false,
  emptyState = false,
  label,
  selectedDevices,
  onSelectRow,
}) => {
  const widthPerCell = useMemo(() => {
    if (rows.length === 0) {
      return 0;
    }
    const maxProperties = Math.max(...rows.map((row) => row.properties.length));
    if (maxProperties === 0) {
      return 0;
    }
    return Math.floor(maxWidth / maxProperties);
  }, [maxWidth, rows]);
  return (
    <TableBodyContainer>
      <TableBodyContentContainer>
        <DraftTableRow loading={loading} show={emptyState} label={label} />
        {rows.map((row, index) => (
          <TableRow
            widthPerCell={widthPerCell}
            key={index}
            {...row}
            selected={(row?.id && selectedDevices?.includes(row.id)) || false}
            onSelectRow={onSelectRow}
          />
        ))}
      </TableBodyContentContainer>
    </TableBodyContainer>
  );
};

const TableBodyContentContainer = styled.div(
  css({
    flexGrow: 1,
  }),
);

const TableBodyContainer = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
  }),
);

type TableFooterProps = {
  pagination: PaginationProps;
};

const TableFooter: VFC<TableFooterProps> = ({ pagination }) => {
  if ((pagination.count ?? 0) <= 10) {
    return null;
  }
  return (
    <Flex justifyContent="center">
      <Pagination {...pagination} />
    </Flex>
  );
};

export type TableProps = {
  header: TableHeadProperties;
  body: TableBodyProperties;
  footer?: TableFooterProps;
};

export const Table: VFC<TableProps> = ({ header, body, footer }) => {
  const ref = useRef<HTMLDivElement>(null);
  const dimensions = useDomRect(ref);
  return (
    <Box ref={ref}>
      <TableHead {...header} maxWidth={dimensions?.width ?? 0} />
      <TableBody {...body} maxWidth={dimensions?.width ?? 0} />
      {footer !== undefined && <TableFooter {...footer} />}
    </Box>
  );
};
