import { DateFormat, Delimiter, Encoding, TimeFormat, UTCOffset } from '@charphq/types';
import { create_Elsec_765_fileSettings, is_Elsec_765_file } from 'dataParsing/elsec/elsec765';
import {
  create_EasyLog_EL_USB_2_PLUS_fileSettings,
  is_EasyLog_EL_USB_2_PLUS_file,
} from 'dataParsing/lascarElectronics/easylog-el-usb-2+';

export type TimeSeries<T> = {
  time: Date;
  value: T;
};

export type ResampledTimeSeries<T> = {
  time: Date;
  value: number;
  data: TimeSeries<T>[];
};

export type TimeSeriesDataPoint = TimeSeries<number | undefined>;

export interface TimeSeriesDataSet {
  data: TimeSeriesDataPoint[];
  key: string;
  name: string;
  measurement: string;
  unit: string;
  color?: string;
  area?: string;
  areaOpacity?: number;
  strokeWidth?: number;
  dateFormat?: string;
  archived?: boolean;
}

export interface TimeSeriesDataSetWithMetaData<T> extends TimeSeriesDataSet {
  meta: T;
}

export type DomainView<T> = {
  min?: T;
  max?: T;
  measurement?: string | null;
};

export type Domain<T> = [min: T, max: T];

type DateFormatExample = { [key in DateFormat]: string };

export const dateFormatExamples: DateFormatExample = {
  [DateFormat.enum.YYYYMMDD]: '20221201',
  [DateFormat.enum.YYYYDDMM]: '20220112',
  [DateFormat.enum.DDMMYYYY]: '01122022',
  [DateFormat.enum.MMDDYYYY]: '12012022',
  [DateFormat.enum['YYYY-MM-DD']]: '2022-12-01',
  [DateFormat.enum['YYYY-DD-MM']]: '2022-01-12',
  [DateFormat.enum['DD-MM-YYYY']]: '01-12-2022',
  [DateFormat.enum['MM-DD-YYYY']]: '12-01-2022',
  [DateFormat.enum['DD-MMM-YYYY']]: '01-dec-2022',
  [DateFormat.enum['DD-MM-YY']]: '01-12-22',
  [DateFormat.enum['YYYY/MM/DD']]: '2022/12/01',
  [DateFormat.enum['YYYY/DD/MM']]: '2022/01/12',
  [DateFormat.enum['DD/MM/YYYY']]: '01/12/2022',
  [DateFormat.enum['MM/DD/YYYY']]: '12/01/2022',
  [DateFormat.enum['D/MM/YYYY']]: '1/12/2022',
};

type TimeFormatExample = { [key in TimeFormat]: string };

export const timeFormatExamples: TimeFormatExample = {
  [TimeFormat.enum['HH:MM:SS']]: '13:24:56',
  [TimeFormat.enum['HH:MM']]: '13:24',
};

export enum FileFormat {
  LASCAR_ELECTRONICS_EASYLOG_EL_USB_2_PLUS = 'lascar-electronics-easylog-el-usb-2+',
  ELSEC_765 = 'elsec-765-environmental-monitor',
  UNKOWN = 'unknown',
}

export const fileFormatAppearances: { [key in FileFormat]: string } = {
  [FileFormat.LASCAR_ELECTRONICS_EASYLOG_EL_USB_2_PLUS]: 'EasyLog EL-USB-2+',
  [FileFormat.ELSEC_765]: 'Elsec 765 Environmental Monitor',
  [FileFormat.UNKOWN]: 'Unkown file format',
};

export type FileFormatSettings = {
  fileEncoding: Encoding;
  fileDelimiter: Delimiter;
  dataRow: number;
  startDate: Date | undefined;
  sampleTime: number | undefined;
  dateFormat: DateFormat | undefined;
  timeFormat: TimeFormat | undefined;
  utcOffset: UTCOffset | undefined;
  dateColumn: number | undefined;
  timeColumn: number | undefined;
  dateDelimiter: Delimiter | undefined;
};

export type FileSettings = {
  [key: string]: {
    detect: (file: File, completeFile: string) => boolean;
    settings: (completeFile: string) => FileFormatSettings;
  };
};

export const fileFormatSettings: FileSettings = {
  [FileFormat.LASCAR_ELECTRONICS_EASYLOG_EL_USB_2_PLUS]: {
    settings: create_EasyLog_EL_USB_2_PLUS_fileSettings,
    detect: is_EasyLog_EL_USB_2_PLUS_file,
  },
  [FileFormat.ELSEC_765]: {
    settings: create_Elsec_765_fileSettings,
    detect: is_Elsec_765_file,
  },
};

type DelimiterExamples = { [key in Delimiter]: string };

export const delimiterExamples: DelimiterExamples = {
  [Delimiter.enum.T]: 'T',
  [Delimiter.enum.space]: 'Space',
  [Delimiter.enum['double-space']]: 'Double space',
  [Delimiter.enum.tab]: 'Tab',
  [Delimiter.enum.semicolon]: 'Semicolon',
  [Delimiter.enum.comma]: 'Comma',
};

export const utcOffsets: UTCOffset[] = [
  '-12:00',
  '-11:00',
  '-10:00',
  '-09:30',
  '-09:00',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:00',
  '-03:30',
  '-03:00',
  '-02:00',
  '-01:00',
  '+00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+06:30',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+12:00',
  '+12:45',
  '+13:00',
  '+14:00',
];

export const acceptedMimeTypes = ['text/plain', 'text/csv'];
