import create from 'zustand';

export type DeviceFilter = {
  name?: string;
  type?: string;
  location?: string;
};

export type SensorFilter = {
  name?: string;
  measurement?: string;
};

export type ProjectSensorFilter = SensorFilter & {
  location?: string;
};

export type ManualUploadFilter = {
  file?: string;
};

export type CumulativeLightFilter = {
  sensor?: string;
  location?: string;
};

export type LightBudgetFilter = {
  sensor?: string;
  location?: string;
};

export type MonitorFilter = {
  query?: string;
};

export type CalibrationsFilter = {
  name?: string;
};

export type LocationFilter = {
  name?: string;
  type?: string;
  room?: string;
  height?: string;
  description?: string;
};

export type RoomFilter = {
  description?: string;
  height?: string;
  level?: string;
  name?: string;
  services?: string;
  site?: string;
  surfaceArea?: string;
  type?: string;
};

type FilterStore = {
  rooms: {
    values: RoomFilter;
    filter: (values: RoomFilter) => void;
  };
  locations: {
    values: LocationFilter;
    filter: (values: LocationFilter) => void;
  };
  sensors: {
    values: SensorFilter;
    filter: (values: SensorFilter) => void;
  };
  projectSensors: {
    values: ProjectSensorFilter;
    filter: (values: ProjectSensorFilter) => void;
  };
  projectSensorsForm: {
    values: ProjectSensorFilter;
    filter: (values: ProjectSensorFilter) => void;
    clear: () => void;
  };
  devices: {
    values: DeviceFilter;
    filter: (values: DeviceFilter) => void;
  };
  devicesForm: {
    values: DeviceFilter;
    filter: (values: DeviceFilter) => void;
    clear: () => void;
  };
  deviceSensors: {
    values: SensorFilter;
    filter: (values: SensorFilter) => void;
  };
  manualUploads: {
    values: ManualUploadFilter;
    filter: (values: ManualUploadFilter) => void;
  };
  ashrae: {
    values: ProjectSensorFilter;
    filter: (values: ProjectSensorFilter) => void;
  };
  cumulativeLight: {
    values: CumulativeLightFilter;
    filter: (values: CumulativeLightFilter) => void;
  };
  lightBudget: {
    values: LightBudgetFilter;
    filter: (values: LightBudgetFilter) => void;
  };
  monitor: {
    values: MonitorFilter;
    filter: (values: MonitorFilter) => void;
  };
  calibrations: {
    values: CalibrationsFilter;
    filter: (values: CalibrationsFilter) => void;
  };
};

const filterFunc = (
  set: (partial: Partial<FilterStore> | ((state: FilterStore) => Partial<FilterStore>)) => void,
  key: keyof FilterStore,
): ((values: FilterStore[typeof key]['values']) => void) => {
  return (values) =>
    set((state) => ({
      [key]: {
        ...state[key],
        values: {
          ...state[key].values,
          ...values,
        },
      },
    }));
};

export const useFilterStore = create<FilterStore>((set) => ({
  rooms: {
    values: {},
    filter: filterFunc(set, 'rooms'),
  },
  locations: {
    values: {},
    filter: filterFunc(set, 'locations'),
  },
  sensors: {
    values: {},
    filter: filterFunc(set, 'sensors'),
  },
  projectSensors: {
    values: {},
    filter: filterFunc(set, 'projectSensors'),
  },
  projectSensorsForm: {
    values: {},
    filter: filterFunc(set, 'projectSensorsForm'),
    clear: () =>
      set((state) => ({
        projectSensorsForm: {
          ...state.projectSensorsForm,
          values: {},
        },
      })),
  },
  devices: {
    values: {},
    filter: filterFunc(set, 'devices'),
  },
  devicesForm: {
    values: {},
    filter: filterFunc(set, 'devicesForm'),
    clear: () =>
      set((state) => ({
        devicesForm: {
          ...state.devicesForm,
          values: {},
        },
      })),
  },
  deviceSensors: {
    values: {},
    filter: filterFunc(set, 'deviceSensors'),
  },
  manualUploads: {
    values: {},
    filter: filterFunc(set, 'manualUploads'),
  },
  ashrae: {
    values: {},
    filter: filterFunc(set, 'ashrae'),
  },
  cumulativeLight: {
    values: {},
    filter: filterFunc(set, 'cumulativeLight'),
  },
  lightBudget: {
    values: {},
    filter: filterFunc(set, 'lightBudget'),
  },
  monitor: {
    values: {},
    filter: filterFunc(set, 'monitor'),
  },
  calibrations: {
    values: {},
    filter: filterFunc(set, 'calibrations'),
  },
}));
