export interface Vendor {
  id: string;
  name: string;
  type: VendorType;
  updatedAt: string;
}

export enum VendorType {
  VERSASENSE = 'versasense',
  TADO = 'tado',
  HANWELL_STATIC = 'hanwell_static',
  FLUKSO = 'flukso',
  XTEL = 'xtel'
}

export const vendorToText = (vendor: VendorType): string => {
  switch (vendor) {
    case VendorType.VERSASENSE:
      return 'Versasense';
    case VendorType.XTEL:
      return 'CHARP';
    default:
      return 'CHARP';
  }
}


export const vendorImage = (vendor: VendorType): string => {
  switch (vendor) {
    case VendorType.VERSASENSE:
      return 'https://www.versasense.com/wp-content/uploads/2017/09/versasense-logo-footer.png';
    case VendorType.XTEL:
      return 'https://charphq.ams3.cdn.digitaloceanspaces.com/logo-white-bluebg-linkedin.png';
    default:
      return 'https://charphq.ams3.cdn.digitaloceanspaces.com/logo-white-bluebg-linkedin.png';
  }
}
