import { css } from '@styled-system/css';
import { useTranslation } from 'react-i18next';
import { Box } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import { useFocus } from 'hooks/useFocus';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useCallback, useState, VFC, MouseEvent, useLayoutEffect, KeyboardEvent, ChangeEvent, useRef } from 'react';
import { MdOutlineEdit, MdOutlineClose, MdCheck } from 'react-icons/md';
import styled from 'styled-components';
import { colors } from 'theme/colors';

export type EditableProperty = {
  type: 'editable';
  placeholder?: string;
  value: string | undefined | null;
  onSave: (value: string) => Promise<void>;
  hovering?: boolean;
  loading?: boolean;
  id?: string;
};

type Props = Omit<EditableProperty, 'type'>;

export const EditableProperty: VFC<Props> = ({ value, placeholder, onSave, hovering }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const editContainerRef = useRef<HTMLDivElement>(null);
  const [inputRef, focusInput, blurInput] = useFocus<HTMLInputElement>();
  const [internalValue, setInternalValue] = useState<string>(value ?? '');

  useLayoutEffect(() => {
    if (editing) {
      focusInput();
    }
  }, [editing, focusInput]);

  const onEditClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setEditing(true);
  }, []);

  const onEditContainerClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.target.value);
  }, []);

  const onInternalSave = useCallback(async () => {
    if (internalValue !== value) {
      setLoading(true);
      await onSave(internalValue);
      setLoading(false);
    }
    blurInput();
    setEditing(false);
  }, [blurInput, internalValue, onSave, value]);

  const onResetClick = useCallback(() => {
    setInternalValue(value ?? '');
    blurInput();
    setEditing(false);
  }, [blurInput, value]);

  useOnClickOutside(editContainerRef, onInternalSave);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        onInternalSave();
      } else if (e.key === 'Escape') {
        e.stopPropagation();
        onInternalSave();
      }
    },
    [onInternalSave],
  );

  return (
    <EditablePropertyContainer>
      {editing ? (
        <EditContainer ref={editContainerRef} onClick={onEditContainerClick}>
          <ContainerInput>
            <Input
              ref={inputRef}
              type="text"
              onKeyDown={handleKeyDown}
              placeholder={placeholder ?? t('Empty')}
              value={internalValue}
              onChange={onInputChange}
              disabled={loading}
            />
            {internalValue !== value && (
              <Flex gap="0.25rem" position="absolute" right="0.5rem">
                <ResetButton onClick={onResetClick}>
                  <MdOutlineClose size="1.5rem" color={colors.error[700]} />
                </ResetButton>
                <ResetButton onClick={onInternalSave}>
                  <MdCheck size="1.5rem" color={colors.brand} />
                </ResetButton>
              </Flex>
            )}
          </ContainerInput>
        </EditContainer>
      ) : (
        <ContentContainer
          style={{
            borderColor: hovering ? colors.base[400] : colors.base[100],
            backgroundColor: hovering ? colors.base[100] : 'inherit',
          }}
          onClick={onEditClick}
        >
          <ContentValue>{internalValue}</ContentValue>
          {hovering && (
            <ActionButton>
              <MdOutlineEdit size="1.5rem" color={colors.brand} />
            </ActionButton>
          )}
        </ContentContainer>
      )}
    </EditablePropertyContainer>
  );
};

const EditablePropertyContainer = styled(Box)(
  css({
    width: '100%',
  }),
);

const ContentContainer = styled(Box)(
  css({
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0.25rem 0.5rem',
    cursor: 'pointer',
  }),
);

const ContentValue = styled.span(
  css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
);

const EditContainer = styled(Box)(css({}));

const ContainerInput = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'text',
    ':focus-within': {},
  }),
);

const ResetButton = styled(Box)(
  css({
    cursor: 'pointer',
    transition: 'background 20ms ease-in 0s',
  }),
);

const Input = styled.input(
  css({
    p: '0px',
    border: '1px solid',
    borderColor: colors.brand,
    outlineColor: colors.brand,
    background: colors.base[100],
    width: '100%',
    display: 'block',
    padding: '0.25rem 0.5rem',
    resize: 'none',
    flexGrow: 1,
    outline: '1px solid',
  }),
);

const ActionButton = styled(Box)(
  css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);
