import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { LayoutProps, SpaceProps } from 'styled-system';
import { space, layout, compose } from 'styled-system';

type TextAreaProps = SpaceProps & LayoutProps;

export const TextArea = styled.textarea<TextAreaProps>`
  outline: none;
  padding-left: 0.375em;
  padding-right: 0.375em;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  resize: vertical;
  height: 30vh;
  ${css({
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'base.600',
  })}
  &:hover {
    ${css({
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'base.600',
    })}
  }
  &:active,
  :focus {
    box-shadow: inset 0 0 0 1px #0b1dd9;
    border: 1px solid #0b1dd9;
  }
  ::placeholder {
    ${css({
      color: 'base.600',
    })}
  }
  &[disabled] {
    ${css({
      borderColor: 'base.600',
      borderWidth: '1px',
      boxShadow: 'none',
      backgroundColor: 'base.300',
      cursor: 'not-allowed',
    })}
  }
  ${compose(space, layout)}
`;
