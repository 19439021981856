import { File, FileStatus } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_FILE_STATUS_KEY = 'editFileStatus';

const EDIT_FILE_STATUS = `
  mutation ($fileId: String!, $status: FileStatus!) {
    ${EDIT_FILE_STATUS_KEY}(uuid: $fileId, data: { status: $status }) {
      id
      name
      status
      mediaType
      createdAt
      owner {
        id
        email
        phone
        firstName
        lastName
        createdAt
      }
    }
  }
`;

type MutVarEditFileStatus = {
  fileId: string;
  status: FileStatus;
};

type MutResEditFileStatus = DefaultQueryResponse<
  typeof EDIT_FILE_STATUS_KEY,
  Pick<File, 'id' | 'name' | 'status' | 'mediaType' | 'createdAt' | 'owner'>
>;

export const useEditFileStatus = () => {
  return useMutation<MutResEditFileStatus, MutVarEditFileStatus>(EDIT_FILE_STATUS);
};
