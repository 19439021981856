import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { Flex } from 'components/layout/Flex';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { View } from 'graphql/generated';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDeleteOutline } from 'react-icons/md';
import { colors } from 'theme/colors';
import { isNonEmptyString } from 'utils/validation';

type Props = {
  view?: Pick<View, 'name'>;
  onSave: (viewName: View['name']) => void;
  saveLabel?: React.ReactNode;
  loading: boolean;
  onClose: () => void;
  onClickDelete?: () => void;
};

export const AlertViewForm: FC<Props> = ({ view, onSave, saveLabel = 'Save', loading, onClose, onClickDelete }) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (view !== undefined) {
      setName(view.name);
    }
  }, [view]);

  const save = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSave(name);
    },
    [name, onSave],
  );

  return (
    <Form
      onSubmit={save}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
    >
      <Body>
        <Input
          validate={isNonEmptyString}
          label={t('Name')}
          type="text"
          name="alertName"
          value={name}
          placeholder={t('placeholders.Enter the view name')}
          onChange={(e) => setName(e.target.value)}
          width="16.5rem"
          required
        />
      </Body>
      <DrawerFooter style={{ justifyContent: onClickDelete ? 'space-between' : 'flex-end' }}>
        {onClickDelete && (
          <Button smallPad iconLeft={MdDeleteOutline} variant="plain" color={colors.error[700]} onClick={onClickDelete}>
            {t('actions.Remove view')}
          </Button>
        )}
        <Flex gap="1.5rem">
          <Button smallPad onClick={onClose} variant="plain">
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit" loading={loading} disabled={!isNonEmptyString(name)}>
            {saveLabel}
          </Button>
        </Flex>
      </DrawerFooter>
    </Form>
  );
};
