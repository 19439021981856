import { FC } from 'react';
import styled from 'styled-components';
import type { LayoutProps, MarginProps } from 'styled-system';
import { compose, layout, margin, system } from 'styled-system';
import { ReactComponent as SVGLogo } from 'images/charp_logo_wit.svg';

type ColorProps = {
  color?: string;
};

type NumOrString = number | string;

type Props = {
  width?: NumOrString | NumOrString[];
  height?: NumOrString | NumOrString[];
} & ColorProps &
  LayoutProps &
  MarginProps;

const Wrapper = styled.div<Props>(
  system({
    color: {
      property: 'color',
      scale: 'colors',
    },
  }),
  compose(layout, margin),
  `
    svg {
      width: 100%;
      height: 100%;
    }
    `,
);

const CharpWordmark: FC<Props> = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <SVGLogo />
    </Wrapper>
  );
};

export default CharpWordmark;
