import { FC, useCallback } from 'react';
import { View, useQryGetTemperatureHumidityView } from 'graphql/generated';
import { Sensor } from 'types/sensor';
import { TemperatureHumidityViewForm } from './TemperatureHumidityViewForm';
import { useTranslation } from 'react-i18next';
import { TemperatureHumidityView } from 'types/view/temperatureHumidityView';
import { useSnackbar } from 'components/Snackbar';
import { Severity } from 'state/snackbarStore';
import { ViewType, viewConfigurations } from 'types';
import { useHistory } from 'react-router-dom';
import { useEditTemperatureHumidityView } from 'server/mutations/view/useEditTemperatureHumidityView';
import { SampleTime } from '@charphq/types';

type Props = {
  projectId: string;
  view: Pick<View, 'id' | 'name' | 'type'>;
  onClose: () => void;
};

export const EditTemperatureHumidityView: FC<Props> = ({ projectId, onClose, view }) => {
  const { t } = useTranslation();
  const [{ fetching: loading }, edit] = useEditTemperatureHumidityView();
  const snackbar = useSnackbar();
  const history = useHistory();

  const [{ data: temperatureHumidityView }] = useQryGetTemperatureHumidityView(
    {
      id: true,
      name: true,
      type: true,
      sampleTime: true,
      sensors: {
        id: true,
        name: true,
        unit: true,
        deviceId: true,
        location: {
          id: true,
          name: true,
        },
        sensorId: true,
        measurement: true,
        updatedAt: true,
      },
      updatedAt: true,
      autoAddNewDevices: true,
    },
    { uuid: view.id },
    {},
  );

  const editTemperatureHumidityViewToProject = useCallback(
    async (
      name: View['name'],
      projectSensorIds: Sensor['id'][],
      sampleTime?: SampleTime,
      autoAddNewDevices?: boolean,
    ) => {
      await edit({
        viewId: view.id,
        data: {
          name,
          projectSensorIds,
          autoAddNewDevices,
          viewType: view.type as ViewType.ABSOLUTE_HUMIDITY | ViewType.DEW_POINT,
          ...(sampleTime && { sampleTime }),
        },
      });
      snackbar.addAlert(
        t('{{view}} view saved', { view: t(viewConfigurations[view.type as ViewType].displayName) }),
        Severity.SUCCESS,
      );
      window.location.href = history.location.pathname;
      onClose();
    },
    [edit, history, onClose, snackbar, t, view],
  );

  return (
    <TemperatureHumidityViewForm
      projectId={projectId}
      saveLabel={t('Edit view')}
      onSave={editTemperatureHumidityViewToProject}
      loading={loading}
      onClose={onClose}
      view={{ projectId, ...temperatureHumidityView } as TemperatureHumidityView}
      viewType={view.type as ViewType.ABSOLUTE_HUMIDITY | ViewType.DEW_POINT}
    />
  );
};
