import { Room, RoomType } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const ADD_ROOM_KEY = 'addRoom';

const ADD_ROOM = `
  mutation ($data: AddRoomInput!) {
    ${ADD_ROOM_KEY}(data: $data) {
      id,
      name,
      level,
      site,
      surfaceArea,
      services,
      height,
      description,
      type {
        id,
        name,
      },
      updatedAt,
      createdAt,
    }
  }
`;

type MutVarAddRoom = {
  data: {
    name: string;
    site?: string;
    level?: string;
    services?: string;
    surfaceArea?: string;
    height?: string;
    description?: string;
    typeId?: string;
  };
};

type MutResAddRoom = DefaultQueryResponse<
  typeof ADD_ROOM_KEY,
  Pick<
    Room,
    'id' | 'name' | 'site' | 'level' | 'services' | 'surfaceArea' | 'height' | 'description' | 'updatedAt' | 'createdAt'
  > & {
    type: Pick<RoomType, 'id' | 'name'>;
  }
>;

export const useAddRoom = () => {
  return useMutation<MutResAddRoom, MutVarAddRoom>(ADD_ROOM);
};
