import { Project } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_PROJECT_ALERT_CASCADE_ORDER_KEY = 'editProjectAlertCascadeOrder';

const EDIT_PROJECT_ALERT_CASCADE_ORDER = `
  mutation ($projectId: String!, $projectUserIds: [String!]!) {
    ${EDIT_PROJECT_ALERT_CASCADE_ORDER_KEY}(uuid: $projectId, data: { items: $projectUserIds }) {
      id
      alertCascadeOrder
    }
  }
`;

type MutVarEditProjectAlertCascadeOrder = {
  projectId: string;
  projectUserIds: string[];
};

type MutResEditProjectAlertCascadeOrder = DefaultQueryResponse<
  typeof EDIT_PROJECT_ALERT_CASCADE_ORDER_KEY,
  Pick<Project, 'id' | 'alertCascadeOrder'>
>;

export const useEditProjectAlertCascadeOrder = () => {
  return useMutation<MutResEditProjectAlertCascadeOrder, MutVarEditProjectAlertCascadeOrder>(
    EDIT_PROJECT_ALERT_CASCADE_ORDER,
  );
};
