import { gql } from '@apollo/client';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';

const DELETE_PROJECT_KEY = 'deleteProject';

const DELETE_PROJECT = gql`
  mutation ($projectId: String!) {
    ${DELETE_PROJECT_KEY}(uuid: $projectId)
  }
`;

type MutVarDeleteProject = {
  projectId: string;
};

type MutResDeleteProject = string;

const update: MutationUpdateFunction<MutVarDeleteProject, MutResDeleteProject>
 = (cache, data) => {
  const result = data.data;
  if (result) {
    const deletedProjectId = result[DELETE_PROJECT_KEY];
    const projectRemoved = cache.evict({
      id: cache.identify({
        id: deletedProjectId,
        __typename: 'Project'
      })
    });
    if (projectRemoved) {
      cache.gc();
    }
  }
};

export const useDeleteProject = () => {
  return useDefaultMutation<MutResDeleteProject, MutVarDeleteProject>(DELETE_PROJECT, DELETE_PROJECT_KEY, update);
};
