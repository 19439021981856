import type { TFunction } from 'i18next';
import { Organization } from './organization';

export type LocationType = {
  id: string;
  name: string;
  color?: string;
  organization: Organization;
  /** Timestamp in ISO format */
  createdAt: string;
  /** Timestamp in ISO format */
  updatedAt: string;
};

export const defaultLocationTypes = [
  'nis/niche',
  'wall',
  'floor',
  'ceiling',
  'display case',
  'carton box',
  'packaged',
  'microclimate',
];

export type Location = {
  id: string;
  name: string;
  height?: string;
  description?: string;
  type?: LocationType;
  organization: Organization;
  /** Timestamp in ISO format */
  createdAt: string;
  /** Timestamp in ISO format */
  updatedAt: string;
};

export type LocationReference = {
  id: string;
  location: Location;
  /** Timestamp in ISO format */
  createdAt: string;
  /** Timestamp in ISO format */
  updatedAt: string;
};

export function validateName(name: string, t: TFunction): string | undefined {
  if (name.length < 1) {
    return t('A location needs a name');
  } else if (name.length > 255) {
    return t('Location name cannot exceed 255 characters');
  }
}
