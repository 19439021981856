import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, MarginProps } from 'styled-system';
import { color, compose, margin } from 'styled-system';
import { DefaultButton } from './Default';

type Props = MarginProps & ColorProps;

export const IconButton = styled(DefaultButton)<Props>`
  ${css({
    color: 'grey.400',
  })}
  padding: 0.2rem;

  &:not(:disabled) {
    &:hover {
      ${css({
        color: 'grey.900',
      })}
    }
    &:active {
      transform: translateY(1px);
    }
  }
  &[data-focus-visible-added] {
    ${css({
      color: 'grey.900',
    })}
  }

  ${compose(margin, color)}
`;
