import { Device } from 'graphql/generated';
import { urqlClient } from 'graphql/urql';
import { OperationResult } from 'urql';
import { DefaultQueryResponse } from './default';

const QUERY_DEVICE_KEY = 'device';

const QUERY_DEVICE = `
query ($deviceId: String!) {
  ${QUERY_DEVICE_KEY}(uuid: $deviceId) {
    id
    name
  }
}
`;

type QryVarDevice = {
  deviceId: string;
};

export const queryDevice = async (
  variables: QryVarDevice,
): Promise<OperationResult<DefaultQueryResponse<typeof QUERY_DEVICE_KEY, Pick<Device, 'id' | 'name'>>>> => {
  return urqlClient
    .query<DefaultQueryResponse<typeof QUERY_DEVICE_KEY, Pick<Device, 'id' | 'name'>>, QryVarDevice>(
      QUERY_DEVICE,
      variables,
    )
    .toPromise();
};
