import { css } from '@styled-system/css';
import type { LocationDescriptorObject } from 'history';
import { FC } from 'react';
import { MdWarningAmber } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import type { LayoutProps } from 'styled-system';
import { compose, layout } from 'styled-system';
import { Button } from '../buttons';
import { Box } from '../layout/Box';
import { PageTitle, Paragraph } from '../typography';

type WrapperProps = LayoutProps;

const Wrapper = styled.div<WrapperProps>(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }),
  compose(layout),
);

const Icon = styled(MdWarningAmber)(
  css({
    position: 'absolute',
    width: '75%',
    height: '75%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'brandLight',
    zIndex: -1,
    opacity: 0.1,
  }),
);

type Props = {
  title: string;
  description?: string;
  rescue?: {
    label: string;
    location: LocationDescriptorObject;
  };
} & WrapperProps;

const ErrorScreen: FC<Props> = ({ title, description, rescue, ...props }) => {
  return (
    <Wrapper height="100%" {...props}>
      <Icon />
      <PageTitle mb={4} textAlign="center">
        {title}
      </PageTitle>
      {description && <Paragraph textAlign="center">{description}</Paragraph>}
      {rescue && (
        <Box mt={8}>
          <Link to={rescue.location}>
            <Button>{rescue.label}</Button>
          </Link>
        </Box>
      )}
    </Wrapper>
  );
};

export { ErrorScreen };
