import { File } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const ADD_FILE_KEY = 'addFile';

const ADD_FILE = `
  mutation ($organizationId: String!, $name: String!, $contentType: String!) {
    ${ADD_FILE_KEY}(organizationId: $organizationId, data: { name: $name, contentType: $contentType }) {
      file {
        id
        name
        status
        mediaType
        createdAt
        owner {
          id
          email
          phone
          firstName
          lastName
          createdAt
        }
      }
      uploadLink
    }
  }
`;

type MutVarAddFile = {
  organizationId: string;
  name: string;
  contentType: string;
};

type MutFile = Pick<File, 'id' | 'name' | 'status' | 'mediaType' | 'createdAt' | 'owner'>;

type MutResAddFile = DefaultQueryResponse<
  typeof ADD_FILE_KEY,
  {
    file: MutFile;
    uploadLink: string;
  }
>;

export const useAddFile = () => {
  return useMutation<MutResAddFile, MutVarAddFile>(ADD_FILE);
};
