import { gql } from '@apollo/client';
import { Sensitivity } from 'graphql/generated';
import { LightBudgetView } from 'types/view/lightBudgetView';
import { useMutation } from 'urql';

const EDIT_LIGHT_BUDGET_VIEW_KEY = 'editLightBudgetView';

const EDIT_LIGHT_BUDGET_VIEW = gql`
  mutation ($viewId: String!, $data: AddLightBudgetViewInput!) {
    ${EDIT_LIGHT_BUDGET_VIEW_KEY}(uuid: $viewId, data: $data) {
      id
      name
      type
      projectId
      updatedAt
    }
  }
`;

type MutVarEditLightBudgetView = {
  viewId: string;
  data: {
    name: string;
    projectSensorIds?: string[];
    autoAddNewDevices?: boolean;
    period: {
      start: string;
      end?: string | null;
    };
    control: {
      targetLifetime: number;
      roleOfColor: number;
      exposureTime: number;
      uv: boolean;
      sensitivity: Sensitivity;
    };
  };
};

type MutResEditLightBudgetView = Pick<LightBudgetView, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'>;

export const useEditLightBudgetView = () => {
  return useMutation<MutResEditLightBudgetView, MutVarEditLightBudgetView>(EDIT_LIGHT_BUDGET_VIEW);
};
