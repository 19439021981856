import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { TypographyProps, ColorProps } from 'styled-system';
import { typography, color } from 'styled-system';

export const SubtleMessage = styled.span<TypographyProps & ColorProps>(
  css({
    fontSize: ['xxs', 'xs', 'sm', 'md', 'lg'],
    color: 'grey.600',
    lineHeight: 'normal',
  }),
  typography,
  color,
);
