import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import type { LayoutProps } from 'styled-system';
import { ErrorScreen } from './ErrorScreen';

export const ViewErrorScreen: FC<LayoutProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  return (
    <ErrorScreen
      {...props}
      title={t('View not found')}
      description={t('The view you are trying to access does not exist or is not accessible by you')}
      rescue={{
        label: t('Go to project views'),
        location: {
          pathname: `/projects/${projectId}`,
        },
      }}
    />
  );
};
