import { gql } from '@apollo/client';
import { LinkedPeriod } from 'types/period';
import { useDefaultMutation } from './useDefaultMutation';

const EDIT_PERIOD_KEY = 'editPeriod';

const EDIT_PERIOD = gql`
  mutation ($periodId: String!, $data: PeriodInput!) {
    ${EDIT_PERIOD_KEY}(periodId: $periodId, data: $data) {
      id
      start
      end
      updatedAt
    }
  }
`;

type MutVarEditPeriod = {
  periodId: string;
  data: {
    start: string;
    end?: string | null;
  };
};

type MutResEditPeriod = Pick<LinkedPeriod, 'id' | 'start' | 'end' | 'updatedAt'>;

export const useEditPeriod = () => {
  return useDefaultMutation<MutResEditPeriod, MutVarEditPeriod>(EDIT_PERIOD, EDIT_PERIOD_KEY);
};
