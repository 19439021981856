import { useContext, useMemo } from 'react';
import CharpWordmark from 'components/CharpWordmark';
import {
  MdOutlineChat,
  MdOutlineSensors,
  MdOutlineNotifications,
  MdMyLocation,
  MdStackedBarChart,
  MdOutlinePeopleAlt,
  MdOutlineSettings,
  MdOutlineManageAccounts,
  MdOutlineNotificationsOff,
} from 'react-icons/md';
import { LeafMenuActionType, MenuNode, MenuType } from './Menu';
import { useTranslation } from 'react-i18next';
import { ProjectContext } from 'context/ProjectContext';
import { UserContext } from 'context/UserContext';
import { useQryOrganization, useQryUserRole } from 'graphql/generated';
import { OrganizationUserRole } from 'types/organizationUser';
import { auth } from 'utils/Authentication';

export const useHomeMenu = () => {
  const { t } = useTranslation();
  const { project } = useContext(ProjectContext);
  const [user] = useContext(UserContext);
  const [{ data: org }] = useQryOrganization(
    {
      id: true,
      users: {
        id: true,
      },
    },
    {
      pause: !user?.id,
    },
  );
  const isAuthenticated = auth.isAuthenticated();
  const [{ data: organizationUserRole }] = useQryUserRole(
    true,
    {
      orgId: org?.id ?? '',
      uuid: user?.id ?? '',
    },
    { pause: !org?.id || !user?.id || !org?.users.find((u) => u.id === user?.id) },
  );

  const homeMenu = useMemo(() => {
    const menu: MenuType = {
      label: t('Home'),
      color: 'brand',
      logo: <CharpWordmark color="white" width="8rem" mt={2} marginX="0.7rem" />,
      top: [],
      middle: [],
      bottom: [],
    };

    const viewsProjectLeaf: MenuNode = {
      label: t('Views'),
      action: {
        type: LeafMenuActionType.route,
        value: `/projects/${project?.id}`,
      },
      icon: <MdStackedBarChart />,
    };

    if (project?.isShareable && !isAuthenticated) {
      menu.top.push(viewsProjectLeaf);
      return menu;
    }

    const devicesLeaf: MenuNode = {
      label: t('Devices'),
      action: {
        type: LeafMenuActionType.route,
        value: '/devices',
      },
      icon: <MdOutlineSensors />,
    };

    const accountLeaf: MenuNode = {
      label: t('Account'),
      action: {
        type: LeafMenuActionType.route,
        value: '/account',
      },
      icon: <MdOutlineManageAccounts />,
    };

    const locationsLeaf: MenuNode = {
      label: t('Locations'),
      action: {
        type: LeafMenuActionType.route,
        value: '/locations',
      },
      icon: <MdMyLocation />,
    };

    const settingsProjectLeaf: MenuNode = {
      label: t('Settings'),
      action: {
        type: LeafMenuActionType.route,
        value: `/projects/${project?.id}/settings`,
      },
      icon: <MdOutlineSettings />,
    };

    const membersLeaf: MenuNode = {
      label: t('Members'),
      action: {
        type: LeafMenuActionType.route,
        value: '/account/members',
      },
      icon: <MdOutlinePeopleAlt />,
    };

    const alertsProjectLeaf: MenuNode = {
      label: t('Alerts'),
      action: {
        type: LeafMenuActionType.route,
        value: `/projects/${project?.id}/notifications`,
      },
      icon: project?.maintenance ? <MdOutlineNotificationsOff /> : <MdOutlineNotifications />,
    };

    const notesProjectLeaf: MenuNode = {
      label: t('Notes'),
      action: {
        type: LeafMenuActionType.route,
        value: `/projects/${project?.id}/notes`,
      },
      icon: <MdOutlineChat />,
    };

    project?.id && menu.top.push(viewsProjectLeaf, alertsProjectLeaf, notesProjectLeaf, settingsProjectLeaf);

    const ownersMiddleMenu = [devicesLeaf, locationsLeaf, membersLeaf];
    const membersMiddleMenu: MenuNode[] = ownersMiddleMenu.filter(
      (node): node is MenuNode => ![t('Members'), t('Locations')].includes(node.label),
    );
    const middleMenu = organizationUserRole === OrganizationUserRole.enum.member ? membersMiddleMenu : ownersMiddleMenu;
    menu.middle.push(...middleMenu);
    menu.bottom.push(accountLeaf);
    return menu;
  }, [organizationUserRole, project, t, isAuthenticated]);

  return { homeMenu };
};
