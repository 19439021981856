import { RefObject, useCallback, useRef } from 'react';

export function useFocus<T extends HTMLElement>(): [RefObject<T>, () => void, () => void] {
  const htmlRef = useRef<T>(null);
  const focus = useCallback(() => {
    if (htmlRef.current) {
      htmlRef.current.focus();
    }
  }, []);
  const blur = useCallback(() => {
    if (htmlRef.current) {
      htmlRef.current.blur();
    }
  }, []);
  return [htmlRef, focus, blur];
}
