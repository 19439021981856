import { css } from '@styled-system/css';
import { Box } from 'components/layout/Box';
import { FC } from 'react';
import styled from 'styled-components';

export type StaticProperty = {
  type: 'static';
  value: unknown | undefined | null;
  id?: string;
};

type Props = Omit<StaticProperty, 'type'>;

export const StaticPropertyContainer = styled(Box)(() =>
  css({
    px: 2,
    py: 1,
    borderRadius: '3px',
    transition: 'background 20ms ease-in 0s',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 'max-content',
  }),
);

export const StaticProperty: FC<Props> = ({ value }) => {
  return <StaticPropertyContainer>{value ? value : 'Empty'}</StaticPropertyContainer>;
};
