import { FC } from 'react';
import styled from 'styled-components';
import type { LayoutProps, MarginProps } from 'styled-system';
import { compose, layout, margin, system } from 'styled-system';
import { ReactComponent as SVGLogo } from 'images/charp-logo-icon.svg';

type ColorProps = {
  color: string;
};

type Props = ColorProps & LayoutProps & MarginProps;

const Wrapper = styled.div<Props>(
  system({
    color: {
      property: 'color',
      scale: 'colors',
    },
  }),
  compose(layout, margin),
);

const CharpSymbol: FC<Props> = (props) => {
  return (
    <Wrapper {...props}>
      <SVGLogo fill="white" width="1.5rem" height="1.5rem" />
    </Wrapper>
  );
};

export default CharpSymbol;
