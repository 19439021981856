import { useTranslation } from 'react-i18next';
import { Div } from 'components/basics';
import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { Flex } from 'components/layout/Flex';
import { MinimizableDrawerContent } from 'components/MinimizableDrawerContent';
import { NewProperty } from 'components/properties/NewProperty';
import { Paragraph } from 'components/typography';
import { DrawerComponent, DrawerComponentProps, DrawerContext } from 'context/DrawerContext';
import { View, useQryGetNotesBy, useQryProject } from 'graphql/generated';
import { useContext, useMemo } from 'react';
import { MdList } from 'react-icons/md';
import styled from 'styled-components';
import { Organization } from 'types/organization';
import { Project } from 'types/project';
import { NoteListItem } from './NoteListItem';
import { Loader } from 'components/loader/loader';
import { isEmpty } from 'lodash';

const List = styled.div({
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr)',
  gap: '2px',
  placeContent: 'stretch',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 320px)',
});

export type NoteListDrawerArgs = {
  organizationId?: Organization['id'];
  projectId?: Project['id'];
  period?: { end: string; start: string };
  analysisId?: View['id'];
};

export const NoteListDrawer: DrawerComponent<NoteListDrawerArgs> = ({
  organizationId,
  projectId,
  period,
  analysisId,
  requestClose,
}: NoteListDrawerArgs & DrawerComponentProps) => {
  const { t } = useTranslation();
  const { requestDrawer } = useContext(DrawerContext);
  const [{ data: notes, fetching: loading }] = useQryGetNotesBy(
    {
      id: true,
      title: true,
      createdAt: true,
      updatedAt: true,
      owner: {
        email: true,
        firstName: true,
        lastName: true,
      },
    },
    { organizationId, projectId },
    {},
  );
  const [{ data: project, fetching: projectLoading }] = useQryProject(
    { name: true },
    { uuid: projectId ?? '' },
    { pause: isEmpty(projectId) },
  );

  const listArgs = useMemo(
    () => ({
      organizationId,
      projectId,
      period,
      analysisId,
    }),
    [analysisId, organizationId, period, projectId],
  );

  const hasListArgs = useMemo(() => Object.keys(listArgs).length > 0, [listArgs]);

  return (
    <MinimizableDrawerContent
      backgroundColor="note.100"
      borderColor="note.600"
      pinButtonPrimaryColor="note.600"
      pinButtonSecondaryColor="note.100"
      pinnable
    >
      {{
        main: (
          <DrawerLayout
            title={t('Notes')}
            titleProps={{ color: 'yellow.800' }}
            bg="note.100"
            onClose={requestClose}
            width="drawer.note"
          >
            {projectId !== undefined && (
              <Paragraph color="note.700" mb="2rem">
                {t('Showing notes of {{project}}', { project: !projectLoading ? project?.name : 'loading...' })}
              </Paragraph>
            )}
            {loading ? (
              <Loader />
            ) : (
              <Div>
                <List>
                  {notes?.map((note) => (
                    <NoteListItem
                      key={note['id']}
                      width="100%"
                      note={note}
                      onClick={() =>
                        requestDrawer('note', { noteId: note.id, listArgs: hasListArgs ? listArgs : undefined }, true)
                      }
                    />
                  ))}
                </List>
                <NewProperty
                  variant="note"
                  onClick={() => requestDrawer('note', { listArgs: hasListArgs ? listArgs : undefined }, true)}
                />
              </Div>
            )}
          </DrawerLayout>
        ),
        minimized: (
          <Flex pt="3.875rem" justifyContent="center" color="note.600" minWidth="48px">
            <MdList size="32px" />
          </Flex>
        ),
      }}
    </MinimizableDrawerContent>
  );
};
