import { ViewType } from '../view';
import { ProjectSensor } from 'graphql/generated';
import { View } from './view';

export enum AshraeVersion {
  V2019 = '2019',
}

export enum Ashrae2019Control {
  AA = 'AA',
  A1 = 'A1',
  A2 = 'A2',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum Ashrae2019Type {
  TEMPERATURE,
  HUMIDITY,
}

export type Ashrae2019Average = {
  temp?: number;
  hum?: number;
};

export interface Ashrae2019View extends View {
  type: ViewType.ASHRAE;
  version?: AshraeVersion;
  control?: Ashrae2019Control;
  average?: Ashrae2019Average;
  sensors?: ProjectSensor[];
  autoAddNewDevices?: boolean;
}
