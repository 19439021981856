import { User } from 'graphql/generated';
import { generateQuery, GenerateQueryProps, QueryProps, QueryResponse, useDefaultQuery } from './default';

const QRY_ME_KEY = 'me';

export const useQryMe = <T extends GenerateQueryProps<User>>(props: QueryProps<User, T>) => {
  return useDefaultQuery<QueryResponse<User, T>>({
    key: QRY_ME_KEY,
    query: generateQuery(QRY_ME_KEY, props),
  });
};
