import { css } from '@styled-system/css';
import { Avatar } from 'components/Avatar';
import { Label } from 'components/form/Label';
import { ProjectUser } from 'graphql/generated';
import { FC, useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MdClose, MdDragIndicator } from 'react-icons/md';
import styled, { ThemeContext } from 'styled-components';
import { colors } from 'theme/colors';

type DivProps = {
  draggingOver: boolean;
};

const Div = styled.div<DivProps>`
  ${css({
    alignItems: 'center',
    mb: '0.5rem',
    display: 'flex ',
    gap: '0.5rem',
  })}
  &:focus {
    outline: none;
  }
`;

type Props = {
  projectUser: ProjectUser;
  index: number;
  disabled: boolean;
};

export const UserCascadeItem: FC<Props> = ({ disabled, projectUser, index }) => {
  const theme = useContext(ThemeContext);
  return (
    <Draggable isDragDisabled={disabled} draggableId={projectUser.id} index={index}>
      {(provided, snapshot) => {
        let background;
        background = colors.base[200];
        if (snapshot.isDragging && snapshot.draggingOver) {
          background = colors.base[200];
        } else if (snapshot.isDragging && !snapshot.draggingOver) {
          background = theme?.colors.red[500];
        }
        return (
          <Div
            draggingOver={!snapshot.draggingOver && snapshot.isDragging}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <MdDragIndicator size="1.5rem" color={theme?.colors.base[600]} />
            <Avatar basic backgroundColor={background} user={projectUser} size="2.25rem" />
            <Label>
              {projectUser.firstName} {projectUser.lastName}
            </Label>
            {!snapshot.draggingOver && snapshot.isDragging && <MdClose />}
          </Div>
        );
      }}
    </Draggable>
  );
};
