import { Box } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import { ComponentPropsWithoutRef, FC } from 'react';
import { VendorType } from 'types/vendor';
import { VendorInfoCard } from './VendorInfoCard';

type Props = ComponentPropsWithoutRef<typeof Box> & {
  onSelect: (type: VendorType) => void;
};

export const VendorSelection: FC<Props> = ({
  onSelect,
  ...props
}) => {
  const vendors = [VendorType.XTEL, VendorType.VERSASENSE];
  return (
    <Box {...props}>
      <Flex flexWrap="wrap" gap="2rem">
        {vendors.map(vendor => (
          <VendorInfoCard
            maxWidth="100%"
            width="160px"
            key={vendor}
            vendor={vendor}
            onClick={() => onSelect(vendor)}
          />
        ))}
      </Flex>
    </Box>
  );
}
