import { css } from '@styled-system/css';
import styled from 'styled-components';

export const DefaultButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 0.2rem;
  outline: 2px solid transparent;

  &:disabled {
    opacity: 0.25;
    cursor: default;
    pointer-events: none;
  }

  &[data-focus-visible-added] {
    ${css({
      outlineColor: 'black',
    })}
  }

  &::-moz-focus-inner {
    border: none;
  }
`;
