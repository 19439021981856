import { useMemo, useRef } from "react";

export function useLastDefinedValue<T>(value: T): T | undefined {
  const lastDefinedValue = useRef<T>();
  
  return useMemo(() => {
    if(value !== undefined) {
      lastDefinedValue.current = value;
    }
    return lastDefinedValue.current;
  }, [value]);
}
