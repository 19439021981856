import { MutableRefObject, useEffect, useLayoutEffect, useRef, useState, RefObject } from 'react';

export function useHover<T extends EventTarget>(): [MutableRefObject<T | null>, boolean] {
  const ref = useRef<T | null>(null);
  const [value, setValue] = useState<boolean>(false);
  useLayoutEffect(() => {
    const node = ref.current;
    if (node) {
      const handleMouseOver = () => setValue(true);
      const handleMouseOut = () => setValue(false);
      node.addEventListener('mouseenter', handleMouseOver);
      node.addEventListener('mouseleave', handleMouseOut);
      return () => {
        node.removeEventListener('mouseenter', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseOut);
      };
    }
  }, [ref]);
  return [ref, value];
}

function useEventListener<T extends HTMLElement | Window>(
  eventType: keyof WindowEventMap | keyof HTMLElementEventMap,
  callback: (e: Event) => void,
  element: T | null,
) {
  const callbackRef = useRef<(e: Event) => void>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (element == null) return;
    const handler = (e: Event) => callbackRef.current(e);
    element.addEventListener(eventType, handler);

    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
}

export function useHoverWithRef<T extends HTMLElement>(ref: RefObject<T>) {
  const [hovered, setHovered] = useState(false);
  useEventListener('mouseover', () => setHovered(true), ref.current);
  useEventListener('mouseout', () => setHovered(false), ref.current);
  return hovered;
}
