import { Locale, NumberLocale } from 'context/LocaleContext';
import { bisectLeft, bisectRight, InternMap, median, rollup } from 'd3-array';
import type { Locale as DateLocale } from 'date-fns';
import {
  add,
  addMilliseconds,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  differenceInHours,
  setMinutes,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { Data, Device, DeviceData, Maybe, Sensor } from 'graphql/generated';
import _, { get } from 'lodash';
import { unparse } from 'papaparse';
import { Domain, ResampledTimeSeries, TimeSeries, TimeSeriesDataPoint } from 'types/data';
import { Order } from 'types/order';
import { max, mean, min } from 'utils/math';
import { DeviceLocationInput, getDeviceLocation } from './device';
import { formatMeasurement } from './sensor';
import { firstCharUp } from './string';
import type { TFunction } from 'i18next';

type DeviceDataInput = Pick<Device, 'name' | 'type'> &
  DeviceLocationInput & {
    sensors?: Maybe<Array<Pick<Sensor, 'id' | 'measurement' | 'unit'>>>;
  };

function mergeWith(...arrats: string[][]): string[] {
  const result = arrats.flat();
  return result;
}

export function createDeviceDataCSV(
  device: DeviceDataInput,
  deviceData: DeviceData,
  numberLocale: NumberLocale | undefined,
  t: TFunction,
): Blob {
  const deviceHeader = [t('Name'), t('Type'), t('Location')];
  const deviceLocation = getDeviceLocation(device);
  const deviceValues = [device.name, device.type, deviceLocation ?? '/'];
  const deviceSensors = device.sensors ?? [];
  const sensorData = deviceData.sensorData ?? [];
  const sensorValues = sensorData.map((sensorPoint) => {
    const result: string[][] = [];
    const sensor = deviceSensors.find((sensor) => sensor.id === sensorPoint.sensorId);
    if (!sensor) {
      return [];
    }
    result.push([
      t('Timestamp'),
      t('{{measurement}} in {{unit}}', {
        measurement: t(sensor.measurement, { ns: 'db-values' }),
        unit: t(sensor.unit, { ns: 'db-values' }),
      }),
    ]);
    for (const data of sensorPoint.data) {
      if (data.value) {
        result.push([data.time, numberLocale ? numberLocale.formatNumber(data.value) : data.value.toString()]);
      } else {
        result.push([data.time, t('None')]);
      }
    }
    return result;
  });
  const sensorCSV = _.zipWith(...sensorValues, mergeWith);
  const csv = unparse([deviceHeader, deviceValues, ...sensorCSV], { delimiter: ';' });
  return new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], { type: 'text/csv;charset=utf-8' });
}

export function createSensorDataCSV(
  sensor: Pick<Sensor, 'measurement' | 'unit'>,
  data: Data[],
  numberLocale: NumberLocale | undefined,
  t: TFunction,
): Blob {
  const sensorHeader = [t('Type'), t('Unit')];
  const sensorValues = [formatMeasurement(sensor, t), t(sensor.unit, { ns: 'db-values' })];
  const dataHeader = [t('Timestamp'), t('Value')];
  const dataValues = data.map((datapoint) => {
    if (datapoint.value !== undefined && datapoint.value !== null) {
      return [datapoint.time, numberLocale ? numberLocale.formatNumber(datapoint.value) : datapoint.value.toString()];
    } else {
      return [datapoint.time, t('None')];
    }
  });
  const csv = unparse([sensorHeader, sensorValues, dataHeader, ...dataValues], { delimiter: ';' });
  return new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], { type: 'text/csv;charset=utf-8' });
}

export const createDataCSV = (data: TimeSeriesDataPoint[]): Blob => {
  const datacsv = data.map((datapoint) => ({ datetime: datapoint.time, value: datapoint.value }));
  const csv = unparse(datacsv);
  return new Blob([csv], { type: 'text/csv;charset=utf-8' });
};

export function applyStringFilters<T>(
  array: T[],
  filters: { value: string | string[] | undefined; keys: string[] }[],
): T[] {
  return array.filter((i) => {
    for (const filter of filters) {
      const value = get(i, filter.keys);
      const filterValue = filter.value;
      if (filterValue) {
        if (
          typeof filterValue === 'string' &&
          (typeof value !== 'string' || !value.toLowerCase().includes(filterValue.toLowerCase()))
        ) {
          return false;
        } else if (Array.isArray(filterValue)) {
          const shouldFilterOut = filterValue.reduce<boolean>(
            (acc, curr) => acc && !value.toLowerCase().includes(curr.toLowerCase()),
            true,
          );
          if (shouldFilterOut) {
            return false;
          }
        }
      }
    }
    return true;
  });
}

export function applyOrdering<T>(array: T[], orders: { order: Order | undefined; keys: string[] }[]): T[] {
  const copy = [...array];
  // reverse to give the first ordering the most impact
  const activeOrders = orders.filter((o) => o !== undefined).reverse();
  for (const order of activeOrders) {
    copy.sort((a, b) => {
      const aVal = get(a, order.keys);
      const bVal = get(b, order.keys);
      if (aVal === bVal) return 0;
      if (order.order === Order.Ascending) return aVal > bVal ? 1 : -1;
      if (order.order === Order.Descending) return aVal < bVal ? 1 : -1;
      return 0;
    });
  }
  return copy;
}

/** Returns the datapoints of time series data within the specified domain. With optional offset applied on both sides. */
export function getTimeseriesInDomain<T>(data: TimeSeries<T>[], domain: Domain<Date>, offset = 0): TimeSeries<T>[] {
  const timestamps = data.map((d) => d.time.getTime());
  const left = bisectLeft(timestamps, domain[0].getTime());
  const right = bisectRight(timestamps, domain[1].getTime());
  const leftWithOffset = Math.max(0, left - offset);
  const rightWithOffset = Math.min(timestamps.length, right + offset);
  return data.slice(leftWithOffset, rightWithOffset);
}

export type GroupByDate = 'auto' | 'hour' | 'day' | 'week' | 'month' | 'year';
export type GroupByFunc = 'mean' | 'max' | 'min';

export type GroupByConfig = {
  groupByDate: GroupByDate;
  groupByDateRange?: Exclude<GroupByDate, 'auto'>[];
  groupByFunc: GroupByFunc;
};

export type GroupByDateValue = {
  type: Exclude<GroupByDate, 'auto'>;
  label: string;
  startOf: (date: Date) => Date;
};

export function getDateFormat(groupByDate: Exclude<GroupByDate, 'auto'>): string {
  const setting: {
    [key in Exclude<GroupByDate, 'auto'>]: string;
  } = {
    hour: 'PPPP p',
    day: 'PPPP',
    week: "'Week' w, PPP",
    month: 'MMMM yyyy',
    year: "'Year' yyyy",
  };
  return setting[groupByDate];
}

export function getTooltipLabel(groupByDate: GroupByDateValue, groupByFunc: GroupByFuncValue, label: string): string {
  return `${firstCharUp(groupByDate.label.toLowerCase())} ${groupByFunc.label.toLowerCase()} ${label}`;
}

export function dateDomain(groupByDateValue: GroupByDateValue, domain: Domain<Date>): Date[] {
  const dates: Date[] = [];
  const type: 'hour' | 'day' | 'week' | 'month' | 'year' = groupByDateValue.type;
  const startOf = groupByDateValue.startOf;
  const addDate = (date: Date): Date => add(date, { [`${type}s`]: 1 });
  let startDate = startOf(domain[0]);
  const endDate = startOf(domain[1]);
  dates.push(startDate);
  while (startDate.getTime() !== endDate.getTime()) {
    startDate = addDate(startDate);
    dates.push(startDate);
  }
  return dates;
}

export function getGroupByDateValue({
  groupByDate,
  groupByDateRange,
  dateDomain,
  availableWidth,
  minBarSize = 5,
  minGap = 0.2,
  locale,
  t,
}: {
  groupByDate: GroupByDate;
  groupByDateRange?: Exclude<GroupByDate, 'auto'>[];
  dateDomain: Domain<Date>;
  availableWidth: number;
  minBarSize?: number;
  minGap?: number;
  locale?: Locale;
  t: TFunction;
}): GroupByDateValue {
  const settings: {
    [key in Exclude<GroupByDate, 'auto'>]: {
      type: Exclude<GroupByDate, 'auto'>;
      label: string;
      startOf: (date: Date, options?: { locale?: Locale }) => Date;
      domainDiff: (dateLeft: Date, dateRight: Date, options?: { locale?: DateLocale }) => number;
    };
  } = {
    hour: {
      type: 'hour',
      label: t('Hourly', { ns: 'db-values' }),
      startOf: startOfHour,
      domainDiff: (dateLeft, dateRight) => differenceInHours(dateLeft, dateRight),
    },
    day: {
      type: 'day',
      label: t('Daily', { ns: 'db-values' }),
      startOf: startOfDay,
      domainDiff: differenceInCalendarDays,
    },
    week: {
      type: 'week',
      label: t('Weekly', { ns: 'db-values' }),
      startOf: (date) => startOfWeek(date, { locale: locale?.date }),
      domainDiff: differenceInCalendarWeeks,
    },
    month: {
      type: 'month',
      label: t('Monthly', { ns: 'db-values' }),
      startOf: startOfMonth,
      domainDiff: differenceInCalendarMonths,
    },
    year: {
      type: 'year',
      label: t('Yearly', { ns: 'db-values' }),
      startOf: startOfYear,
      domainDiff: differenceInCalendarYears,
    },
  };
  if (groupByDate !== 'auto') {
    const groupByDateValue = settings[groupByDate];
    return {
      type: groupByDateValue.type,
      label: groupByDateValue.label,
      startOf: groupByDateValue.startOf,
    };
  }
  let allowedValues = Object.values(settings);
  if (groupByDateRange !== undefined && groupByDateRange.length > 0) {
    allowedValues = allowedValues.filter((value) => groupByDateRange.includes(value.type));
  }
  for (const value of allowedValues) {
    const laterDate = value.startOf(dateDomain[1], { locale });
    const earlierDate = value.startOf(dateDomain[0], { locale });
    const nbOfBars = value.domainDiff(laterDate, earlierDate, { locale: locale?.date });
    const requiredWidth = Math.floor(nbOfBars * (minBarSize * (1 + minGap)));
    if (availableWidth >= requiredWidth) {
      return {
        type: value.type,
        label: value.label,
        startOf: value.startOf,
      };
    }
  }
  const fallbackValue = settings['year'];
  return {
    type: fallbackValue.type,
    label: fallbackValue.label,
    startOf: fallbackValue.startOf,
  };
}

export type GroupByFuncValue = {
  type: GroupByFunc;
  label: string;
  func: <T>(data: T[], accessor: (value: T) => number | null) => number;
};

export function getGroupByFuncValue(groupByFunc: GroupByFunc, t: TFunction): GroupByFuncValue {
  const settings: { [key in GroupByFunc]: GroupByFuncValue } = {
    mean: {
      type: 'mean',
      label: t('Average', { ns: 'db-values' }).toLowerCase(),
      func: mean,
    },
    max: {
      type: 'max',
      label: t('Maximum', { ns: 'db-values' }).toLowerCase(),
      func: max,
    },
    min: {
      type: 'min',
      label: t('Minimum', { ns: 'db-values' }).toLowerCase(),
      func: min,
    },
  };
  return settings[groupByFunc];
}

export function resampleData<T>(
  values: TimeSeries<T>[],
  groupByDate: (date: Date) => Date,
  accessor: (value: TimeSeries<T>) => number | null,
  groupByFunc: (data: TimeSeries<T>[], accessor: (value: TimeSeries<T>) => number | null) => number,
): InternMap<Date, ResampledTimeSeries<T>> {
  const mapTest = rollup<TimeSeries<T>, ResampledTimeSeries<T>, Date>(
    values,
    (data) => ({
      data,
      time: groupByDate(data[0].time),
      value: groupByFunc(data, accessor),
    }),
    (value) => groupByDate(value.time),
  );
  return mapTest;
}

export function extractFilename(filename: string): string {
  return filename.split('.')[0];
}

export const convertDataToTimeseries = (data: Data[]): TimeSeriesDataPoint[] => {
  return data.map((point) => ({
    time: new Date(point.time),
    value: point.value ?? undefined,
  }));
};

export const convertLightDataToTimeseries = (data: Data[]): TimeSeriesDataPoint[] => {
  return data.map((point) => ({
    time: new Date(point.time),
    // value can be undefined
    value: point.value ?? 0,
  }));
};

export const filterEmptyTimeseries = (p: TimeSeriesDataPoint) => p.value !== null && p.value !== undefined;

/**
 * Estimate the sampling interval of a sensor.
 * @return SampleTime in milliseconds, undefined if not enough data
 */
export function estimateSampleTime(data: TimeSeriesDataPoint[]): number | undefined {
  if (data.length < 4) {
    return undefined;
  }
  const timestamps = data.map((point) => point.time.getTime());
  const diffs = diff(timestamps);
  const medianDiff = median(diffs);
  if (medianDiff === undefined) {
    return undefined;
  }
  // We use 5 min as step value
  const stepValue = 5 * 60 * 1000;
  const sampleTime = (Math.floor(medianDiff / stepValue) + 1) * stepValue;
  return sampleTime;
}

function diff(value: number[]): number[] {
  const result: number[] = [];
  for (let i = 0; i < value.length; i++) {
    const y = i + 1;
    if (y < value.length) {
      result.push(value[y] - value[i]);
    }
  }
  return result;
}

export function resampleSensorData(values: TimeSeriesDataPoint[], sampleTime: number): TimeSeriesDataPoint[] {
  const groupByDate = (value: Date): Date => {
    const sampleInMin = sampleTime / 1000 / 60;
    const startDate = sampleInMin >= 60 ? startOfHour(value) : startOfMinute(value);
    const minutes = startDate.getMinutes();
    let start = 0;
    let end = sampleInMin;
    while (minutes >= end) {
      start = end;
      end += sampleInMin;
    }
    return setMinutes(startDate, start);
  };
  const resampledSensorDataMap = resampleData(values, groupByDate, (value) => value.value ?? null, mean);
  const resampledSensorDataArray = Array.from(resampledSensorDataMap.values());
  if (resampledSensorDataArray.length < 1) {
    return [];
  }
  const period: Domain<Date> = [
    resampledSensorDataArray[0].time,
    resampledSensorDataArray[resampledSensorDataArray.length - 1].time,
  ];
  const interval = dateInterval(period, sampleTime);
  const result: TimeSeriesDataPoint[] = [];
  for (const date of interval) {
    result.push(resampledSensorDataMap.get(date) ?? { time: date, value: undefined });
  }
  return result;
}

function dateInterval(period: Domain<Date>, millisecondsStep: number): Date[] {
  const interval: Date[] = [period[0]];
  let currentDate = period[0];
  while (currentDate.getTime() < period[1].getTime()) {
    currentDate = addMilliseconds(currentDate, millisecondsStep);
    interval.push(currentDate);
  }
  return interval;
}
