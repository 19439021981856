import { Avatar } from 'components/Avatar';
import { ContentfulButton } from 'components/buttons/ContentfulButton';
import { Table, Tbody, Td, TdOverflow, Th, Thead, Tr } from 'components/table/Table';
import { TableMoreMenu } from 'components/table/TableMoreMenu';
import { Paragraph } from 'components/typography';
import { format } from 'date-fns';
import { Note, User, useQryMe } from 'graphql/generated';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { shortenText } from 'utils/string';
import parse, { Element } from 'html-react-parser';

type MutUser = Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'createdAt'>;
export type BasicNote = Pick<Note, 'id' | 'title' | 'description' | 'period' | 'updatedAt' | 'createdAt'> & {
  views?: Array<{ name: string }> | null;
  owner: MutUser;
};

type Props = {
  notes: BasicNote[];
  selectedNoteId?: Note['id'];
  onSelectNote: (note: BasicNote) => void;
  onDeleteNote: (note: BasicNote) => void;
};

type ParsedNote = {
  title: React.ReactNode;
  description?: string | null;
  isHTML?: boolean;
};

export const NotesTable: FC<Props> = ({ notes, selectedNoteId, onSelectNote, onDeleteNote }) => {
  const [{ data: me }] = useQryMe({ id: true }, {});
  const { t } = useTranslation();

  const getNoteContent = (note: BasicNote): ParsedNote => {
    const { title, description } = note;

    if (description) {
      // Check if description contains HTML tags
      const containsHTML = /<[^>]+>/g.test(description);

      if (containsHTML) {
        // If HTML tags are present, parse the content using html-react-parser
        const matches = description.match(/<p>(.*?)<\/p>\n?<p>(.*?)<\/p>/);
        if (matches && matches.length === 3) {
          // If there are two <p> tags, consider the first one as title and the second one as description
          const parsedTitle = parse(matches[1]);
          const parsedDescription = parse(matches[2]);

          // Convert the parsed HTML to text while preserving its structure
          const convertToText = (element: Element | string | ChildNode): string => {
            if (typeof element === 'string') {
              return element; // Return plain text
            } else if (Array.isArray(element)) {
              return element.map((child) => convertToText(child)).join(''); // Recursively process each child node
            } else if ((element as Element).type === 'tag') {
              // Handle HTML tag elements
              const { name, attribs } = element as Element;
              let innerText = '';
              // Convert all HTML tags to string representation
              const attributes = attribs
                ? Object.entries(attribs)
                    .map(([key, value]) => `${key}="${value}"`)
                    .join(' ')
                : '';
              innerText = `<${name}${attributes ? ' ' + attributes : ''}>${innerText}</${name}>`;
              return innerText;
            }
            return '';
          };

          // Trim and format the parsed description
          let trimmedDescription = convertToText(parsedDescription).trim();
          if (trimmedDescription.length > 128) {
            trimmedDescription = trimmedDescription.substring(0, 128) + '...';
          }

          return { title: parsedTitle, description: trimmedDescription, isHTML: true };
        } else {
          // If there's only one <p> tag, consider it as title
          const parsedTitle = parse(description);
          return { title: parsedTitle, description: null };
        }
      }
      // If no HTML tags are present, return the original object
    }
    return { title, description };
  };

  return (
    <Table fixed>
      <Thead>
        <Tr>
          <Th width={['10rem', '20rem']} backgroundColor="note.100" accentColor="note.400">
            {t('Content')}
          </Th>
          <Th width="6rem" backgroundColor="note.100" accentColor="note.400">
            {t('View')}
          </Th>
          <Th width="6rem" backgroundColor="note.100" accentColor="note.400">
            {t('When')}
          </Th>
          <Th width="6rem" backgroundColor="note.100" accentColor="note.400">
            {t('Created')}
          </Th>
          <Th width="5rem" backgroundColor="note.100" accentColor="note.400">
            {t('Author')}
          </Th>
          <Th width="5rem" backgroundColor="note.100" accentColor="note.400" />
        </Tr>
      </Thead>
      <Tbody>
        {notes.map((note) => {
          const { title, description, isHTML } = getNoteContent(note);
          // const description = note.description?.split('\n')?.[0];
          const isSelected = note.id === selectedNoteId;
          return (
            <Tr
              key={note.id}
              bg={isSelected ? 'note.200' : undefined}
              hoverBg={isSelected ? 'note.200' : 'note.150'}
              onClick={() => note.owner.id && onSelectNote(note)}
              style={{ cursor: note.owner.id ? 'pointer' : 'default' }}
            >
              <Td>
                <ContentfulButton>
                  <Paragraph fontWeight={600} my={0}>
                    {title}
                  </Paragraph>
                  {isHTML ? description : description && <Paragraph my={0}>{shortenText(description, 128)}</Paragraph>}
                </ContentfulButton>
              </Td>
              <TdOverflow title={note.views?.[0]?.name}>{note.views?.[0]?.name || '-'}</TdOverflow>
              <TdOverflow title={note.period?.start && format(new Date(note.period?.start), 'yyyy-MM-dd HH:mm')}>
                {note.period?.start ? format(new Date(note.period?.start), 'yyyy-MM-dd HH:mm') : '-'}
              </TdOverflow>
              <TdOverflow title={format(new Date(note.createdAt), 'yyyy-MM-dd HH:mm')}>
                {format(new Date(note.createdAt), 'yyyy-MM-dd HH:mm')}
              </TdOverflow>
              <Td>
                <Avatar size="1.75rem" user={note.owner} />
              </Td>
              <Td>
                <TableMoreMenu
                  stopPropagation
                  items={[
                    {
                      text: t('actions.Edit note'),
                      onClick: () => onSelectNote(note),
                      icon: MdEdit,
                      disabled: note.owner.id !== me?.id,
                    },
                    {
                      text: t('actions.Delete note'),
                      onClick: () => onDeleteNote(note),
                      icon: MdDeleteOutline,
                      disabled: note.owner.id !== me?.id,
                    },
                  ]}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
