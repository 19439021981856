import { SampleTime } from '@charphq/types';
import { DefaultQueryResponse } from 'graphql/query/default';
import { MonitorView } from 'graphql/generated';
import { useMutation } from 'urql';

const EDIT_MONITOR_VIEW_KEY = 'editMonitorView';

const EDIT_MONITOR_VIEW = `
mutation ($viewId: String!, $data: EditMonitorViewInput!) {
  ${EDIT_MONITOR_VIEW_KEY}(uuid: $viewId, data: $data) {
    id
    sampleTime
    autoAddNewDevices
  }
}
`;

type MutVarEditMonitorView = {
  viewId: string;
  data: {
    sampleTime?: SampleTime;
    autoAddNewDevices?: boolean;
  };
};

type MutResEditMonitorView = DefaultQueryResponse<
  typeof EDIT_MONITOR_VIEW_KEY,
  Pick<MonitorView, 'id' | 'sampleTime' | 'autoAddNewDevices'>
>;

export const useEditMonitorView = () => {
  return useMutation<MutResEditMonitorView, MutVarEditMonitorView>(EDIT_MONITOR_VIEW);
};
