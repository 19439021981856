import type { ResponsiveValue, ThemeValue } from 'styled-system';
import { Theme } from './';
import { ConnectionType } from 'types/device';

export type ColorsOfTheme = ResponsiveValue<ThemeValue<'colors', Theme, string>, Theme>;
export type ColorOfTheme = ThemeValue<'colors', Theme, string>;

export function connectionToColor(connection: ConnectionType | string): ColorOfTheme {
  switch (connection) {
    case ConnectionType.CHARP:
      return 'blue.100';
    case ConnectionType.ADDON:
      return 'green.100';
    case ConnectionType.STANDALONE:
      return 'note.100';
    default:
      return 'grey.100';
  }
}

export function measurementTypeToColor(measurement: string): ColorOfTheme {
  switch (measurement) {
    case 'temperature':
      return 'red.100';
    case 'humidity':
      return 'blue.100';
    case 'pressure':
      return 'green.100';
    case 'light':
      return 'yellow.100';
    default:
      return 'grey.100';
  }
}

export function manualStatusToColor(status: string): ColorOfTheme {
  switch (status) {
    case 'process':
      return 'blue.100';
    case 'error':
      return 'red.100';
    case 'done':
      return 'green.100';
    default:
      return 'grey.100';
  }
}

export const colors = {
  // brand identity colors
  brandDark: '#283640',
  brand: '#0b1dd9',
  brandLight: '#0087d9',
  brandSubtle: '#eef3fe',
  subtle: '#c6c7c7',
  grey: {
    100: '#f2f5f7',
    200: '#c8d5df',
    300: '#a2b7c6',
    400: '#839cae',
    500: '#698395',
    600: '#536b7d',
    700: '#405564',
    800: '#2f404c',
    900: '#202b33',
  },
  /**
   * {@link https://www.notion.so/charpartcare/Brand-manual-7cd34f3170994cf091ba004642c36619?pvs=4#24181daaa18948509e9c7f0be412ca30}
   */
  brandBlue: {
    100: '#F4F4F5',
    300: '#D2D2D9',
    600: '#8E8D9F',
    700: '#1E1C3F',
    900: '#17152F', // black 25% opacity on top of colors.brandBlue[700]
  },
  /**
   * {@link https://www.notion.so/charpartcare/Brand-manual-7cd34f3170994cf091ba004642c36619?pvs=4#5c2584a15f2c42cfb582e35f60aa94af}
   */
  brandYellow: {
    100: '#FEFDF5',
    300: '#FBF8D8',
    600: '#F5ED9E',
    900: '#ECDC3E',
  },
  brandGreen: {
    500: '#0bd9b9',
  },
  positive: '#0bd9b9',
  warning: '#d9af16',
  danger: '#d94e16',
  lux: '#FBF7E7',
  switch: {
    500: '#0b1dd9',
  },
  // regular colors
  lightBlue: {
    100: '#ebf7ff',
    200: '#b2e4ff',
    300: '#7cd1ff',
    400: '#4ebfff',
    500: '#2bafff',
    600: '#119ff6',
    700: '#0087d9',
    800: '#005285',
    900: '#001f33',
  },
  note: {
    100: '#FFFBEC',
    150: '#FEF5D3',
    200: '#FCEEBD',
    400: '#EED167',
    500: '#DEB332',
    600: '#D8AF15',
    700: '#B37A1B',
  },
  red: {
    100: '#fff2ed',
    200: '#fbbfa7',
    300: '#f78f65',
    400: '#e86b39',
    500: '#d94e16',
    600: '#b0461b',
    700: '#863c1e',
    800: '#5c2f1c',
    900: '#331d14',
  },
  blue: {
    100: '#edf3ff',
    200: '#a5bbfb',
    300: '#617df7',
    400: '#2f47e8',
    500: '#0b1dd9',
    600: '#0512b0',
    700: '#040e86',
    800: '#050c5c',
    900: '#050933',
  },
  green: {
    100: '#ebfffc',
    200: '#71ecd9',
    300: '#0bd9b9',
    400: '#00bda8',
    500: '#00a295',
    600: '#008680',
    700: '#006a68',
    800: '#034f4f',
    900: '#063233',
  },
  yellow: {
    100: '#fff9e6',
    200: '#fff0b2',
    300: '#ffe680',
    400: '#ffd94d',
    500: '#ffcc1a',
    600: '#e6b300',
    700: '#b38f00',
    800: '#806c00',
    900: '#4d4900',
  },
  base: {
    100: '#FFFFFF',
    200: '#F2F5F7',
    300: '#eeeeee',
    400: '#C8D5DF',
    600: '#698395',
    900: '#202B33',
  },
  primary: {
    500: '#617DF7',
    700: '#0B1DD9',
    900: '#1E1C3F',
  },
  error: {
    700: '#BB5D37',
  },
  secondary: {
    100: '#FDFBEE',
    200: '#F9EEC7',
    500: '#EBDB41',
    700: '#D8B44D',
    900: '#7B6C2E',
  },
  black: '#283640',
  brown: '#B16555',
  white: '#FFFFFF',
  whiteBrown: '#F7F0EE',
  lightBrown: '#F0E2DF',
  lightGrey: '#e0e0e0',
  whiteGrey: '#F4F6FA',
  shadow: '#F4F6FA',
};
