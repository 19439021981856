import { DefaultQueryResponse } from 'graphql/query/default';
import { Downsample, Every, Rule, RuleOperator, Statistic, TimeWindow } from 'types/rule';
import { useMutation } from 'urql';

const ADD_RULE_KEY = 'addRule';

const ADD_RULE = `
  mutation (
    $viewId: String!
    $data: AddRuleInput!
  ) {
    ${ADD_RULE_KEY}(
      uuid: $viewId
      data: $data
    ) {
      id
      name
      every
      downsample
      timeWindow
      threshold
      statistic
      optStatistic
      optThreshold
      operator
      updatedAt
      comments
    }
  }
`;

type MutVarAddRule = {
  viewId: string;
  data: {
    name: string;
    every: Every;
    downsample?: Downsample;
    operator: RuleOperator;
    optStatistic?: Statistic;
    optThreshold?: number;
    projectSensorId: string;
    statistic: Statistic;
    threshold: number;
    timeWindow: TimeWindow;
    comments?: string;
  };
};

type MutResAddrule = DefaultQueryResponse<
  typeof ADD_RULE_KEY,
  Pick<
    Rule,
    | 'id'
    | 'name'
    | 'every'
    | 'downsample'
    | 'timeWindow'
    | 'threshold'
    | 'statistic'
    | 'optStatistic'
    | 'optThreshold'
    | 'operator'
    | 'updatedAt'
    | 'comments'
  >
>;

export const useAddRule = () => {
  return useMutation<MutResAddrule, MutVarAddRule>(ADD_RULE);
};
