import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { H2 } from 'components/typography';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { useState, VFC } from 'react';
import { Addon, AddonName, addons } from 'types/addon';
import { AddonSelection } from './AddonSelection';
import { SerisAddonCreation } from './SerisAddonCreation';
import { useTranslation } from 'react-i18next';

type Props = DrawerComponentProps & {
  addon?: Addon;
};

export const AddonDrawer: VFC<Props> = ({ addon, requestClose }) => {
  const { t } = useTranslation();
  const [addonName, setAddonName] = useState<AddonName | undefined>(addon?.name);

  useRouteChangeBlock();

  return (
    <DrawerLayout title={t('actions.Add add-on')} onClose={requestClose} width={560}>
      {!addon && <AddonSelection items={addons} onSelect={(addonName: AddonName) => setAddonName(addonName)} />}
      {addonName === AddonName.SERIS && (
        <>
          <H2>{t('Seris')}</H2>
          <SerisAddonCreation addon={addon} onSubmit={requestClose} onBack={() => setAddonName(undefined)} />
        </>
      )}
    </DrawerLayout>
  );
};
