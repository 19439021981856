import { gql } from '@apollo/client';
import { Calibration } from 'graphql/generated';
import { useDefaultMutation } from '../useDefaultMutation';

const ADD_CALIBRATION_AND_SENSORS_KEY = 'addCalibrationAndCalibrationSensors';

const ADD_CALIBRATION_AND_SENSORS = gql`
  mutation ($data: CalibrationAndCalibrationSensorsInput!) {
    addCalibrationAndCalibrationSensors(data: $data) {
      id
      name
      formula
      createdAt
      updatedAt
      calibrationSensors {
        id
        calibrationId
        sensorId
        startDate
        endDate
        createdAt
        updatedAt
      }
    }
  }
`;

type CalibrationInput = {
  name: string;
  formula: string;
  organizationId: string;
};

type CalibrationSensorInput = {
  sensorIds: string[];
  startDate?: string;
  endDate?: string;
};

type AddCalibrationAndCalibrationSensorsInput = {
  calibration: CalibrationInput;
  calibrationSensor: CalibrationSensorInput;
};

type MutVarAddCalibrationAndSensors = {
  data: AddCalibrationAndCalibrationSensorsInput;
};

type MutResAddCalibrationAndSensors = Pick<Calibration, 'id' | 'name' | 'formula' | 'createdAt' | 'updatedAt'> & {
  calibrationSensors: {
    id: string;
    sensorId: string;
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
  }[];
};

export const useAddCalibrationAndSensors = () => {
  return useDefaultMutation<MutResAddCalibrationAndSensors, MutVarAddCalibrationAndSensors>(
    ADD_CALIBRATION_AND_SENSORS,
    ADD_CALIBRATION_AND_SENSORS_KEY,
  );
};
