import { Organization, useQryOrganization } from 'graphql/generated';
import { createContext, FC, useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import { useTranslation } from 'react-i18next';
import { useLocale } from './LocaleContext';

type OrgContextType = [organization: Pick<Organization, 'id' | 'name'> | undefined, loading: boolean];

export const OrgContext = createContext<OrgContextType>([undefined, false]);

export const OrgProvider: FC = (props) => {
  const { i18n } = useTranslation();
  const [{ data: organization, fetching: loading }] = useQryOrganization({ id: true, name: true, language: true }, {});
  const [user] = useContext(UserContext);
  const { setLocale } = useLocale();

  useEffect(() => {
    if (user) {
      const localeStr = user.language || organization?.language || navigator?.languages[0] || 'en';
      i18n.changeLanguage(localeStr);
      setLocale(localeStr);
    }
  }, [user, organization, i18n, setLocale]);

  return <OrgContext.Provider value={[organization, loading]} {...props} />;
};
