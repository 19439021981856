import { Project } from 'graphql/generated';
import { urqlClient } from 'graphql/urql';
import { OperationResult } from 'urql';
import { DefaultQueryResponse } from './default';

const QUERY_PROJECT_KEY = 'project';

const QUERY_PROJECT = `
  query ($projectId: String!) {
    ${QUERY_PROJECT_KEY}(uuid: $projectId) {
      id
      name
      alert
      maintenance
      alertCascadeTime
      description
    }
  }
`;

type QryVarProject = {
  projectId: string;
};

export const queryProject = async (
  variables: QryVarProject,
): Promise<
  OperationResult<
    DefaultQueryResponse<
      typeof QUERY_PROJECT_KEY,
      Pick<Project, 'id' | 'name' | 'alert' | 'maintenance' | 'alertCascadeTime' | 'description'>
    >
  >
> => {
  return urqlClient
    .query<
      DefaultQueryResponse<
        typeof QUERY_PROJECT_KEY,
        Pick<Project, 'id' | 'name' | 'alert' | 'maintenance' | 'alertCascadeTime' | 'description'>
      >,
      QryVarProject
    >(QUERY_PROJECT, variables)
    .toPromise();
};
