import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import type { LayoutProps } from 'styled-system';
import { ErrorScreen } from './ErrorScreen';

export const SensorErrorScreen: FC<LayoutProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const pathFromData = useRouteMatch({ path: '/data/:sensorId', exact: true });
  const pathFromDevices = useRouteMatch('/data/devices/:deviceId/sensors/:sensorId');
  const { deviceId } = useParams<{ deviceId: string }>();
  const label = useMemo(() => {
    if (pathFromData) {
      return t('Go to data');
    } else if (pathFromDevices) {
      return t('Go to device');
    } else {
      return t('Go back');
    }
  }, [pathFromData, pathFromDevices, t]);

  const path = useMemo(() => {
    if (pathFromData) {
      return '/data';
    } else if (pathFromDevices) {
      return `/data/devices/${deviceId}`;
    } else {
      return '/data';
    }
  }, [deviceId, pathFromData, pathFromDevices]);

  return (
    <ErrorScreen
      {...props}
      title={t('Measurement not found')}
      description={t('The measurement you are trying to access does not exist or is not accessible by you')}
      rescue={{
        label: label,
        location: {
          pathname: path,
        },
      }}
    />
  );
};
