import { FC } from 'react';
import { createGlobalStyle } from 'styled-components';
import { generateTrueTypeFontFaceList } from 'utils/fontface';
import SourceSansProBold from '../fonts/SourceSansPro-Bold.ttf';
import SourceSansProRegular from '../fonts/SourceSansPro-Regular.ttf';
import SourceSansProSemiBold from '../fonts/SourceSansPro-SemiBold.ttf';

import ArchiaSemiBold from '../fonts/Archia-SemiBold.otf';

const FontFaceDefinitions: FC = () => {
  const Style = createGlobalStyle`
    ${generateTrueTypeFontFaceList('Source Sans Pro', [
      { weight: 400, url: SourceSansProRegular },
      { weight: 600, url: SourceSansProSemiBold },
      { weight: 700, url: SourceSansProBold },
    ])}
    ${generateTrueTypeFontFaceList('Archia', [{ weight: 600, url: ArchiaSemiBold }])}
  `;
  return <Style />;
};

export default FontFaceDefinitions;
