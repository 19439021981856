import { delimiterValues } from '@charphq/parser';
import { Delimiter } from '@charphq/types';
import { HorizontalLine, VerticalLine } from 'components/Lines';
import { Switch } from 'components/form/Switch';
import { Box, BoxProps } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import { Table, Tbody, Td, Tr } from 'components/table/Table';
import { SubtleMessage, Text } from 'components/typography';
import { VFC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheck } from 'react-icons/md';
import { generateArrayOfNumbers, getMaxColumns } from 'utils/util';

type Props = {
  fileName: string;
  fileContent: string;
  hidePreview: boolean;
  togglePreview: () => void;
  delimiter?: Delimiter;
  containerProps?: Omit<BoxProps, 'color'>;
};

export const FilePreview: VFC<Props> = ({
  fileName,
  fileContent,
  delimiter,
  hidePreview,
  togglePreview,
  containerProps,
}) => {
  const { t } = useTranslation();
  const [lines, setLines] = useState<string[]>();

  useEffect(() => {
    const lines = fileContent.split(/\r?\n/);
    setLines(lines.slice(0, 30));
  }, [fileContent]);

  const fileMatrix = useMemo(() => {
    if (lines && delimiter) {
      return lines.map((line) => line.split(delimiterValues[delimiter]));
    }
  }, [lines, delimiter]);

  return (
    <Box {...containerProps}>
      {lines !== undefined && (
        <Box>
          <Flex mt={4} mb={4} flexDirection="row" alignItems="center" justifyContent="space-between">
            <Flex flexDirection="row" alignItems="center" gap="1rem">
              <SubtleMessage color="green.400">{fileName}</SubtleMessage>
              <Box width={4} height={4} borderRadius="50%" backgroundColor="green.400" color="white">
                <MdCheck />
              </Box>
              <SubtleMessage color="green.400">{t('Uploaded')}</SubtleMessage>
            </Flex>
            <Flex flexDirection="row" alignItems="center" gap="0.5rem">
              <Text variant="small">{t('Hide preview')}</Text>
              <Switch on={hidePreview} onChange={() => togglePreview()} />
            </Flex>
          </Flex>
          <Box
            overflow="auto"
            maxHeight={60}
            borderRadius="0.3rem"
            borderColor="grey.200"
            borderStyle="solid"
            borderWidth={1}
          >
            {!hidePreview && (
              <Table style={{ whiteSpace: 'nowrap' }}>
                <Tbody>
                  {fileMatrix !== undefined ? (
                    <>
                      {
                        <Tr>
                          <Td></Td>
                          {generateArrayOfNumbers(getMaxColumns(fileMatrix)).map((column, index) => (
                            <Td key={`header-${index}`}>
                              {column}
                              <HorizontalLine mt={2} width={6} />
                            </Td>
                          ))}
                        </Tr>
                      }
                      {fileMatrix.map((row, index) => {
                        return (
                          <Tr key={`row-${index}`}>
                            <Td>
                              {index + 1}
                              <VerticalLine ml={2} height={6} />
                            </Td>
                            {row.map((column, colIndex) => {
                              return (
                                <Td title={`row ${index + 1}, column ${colIndex + 1}`} key={`column-${colIndex}`}>
                                  {column}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })}
                    </>
                  ) : (
                    lines.map((row, index) => {
                      return (
                        <Tr key={`row-${index}`}>
                          <Td>
                            {index + 1}
                            <VerticalLine ml={2} />
                          </Td>
                          <Td>{row}</Td>
                        </Tr>
                      );
                    })
                  )}
                </Tbody>
              </Table>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
