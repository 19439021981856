import { gql } from '@apollo/client';
import { ProjectAddon } from 'types/addon';
import { useDefaultMutation } from '../../useDefaultMutation';

const EDIT_PROJECT_ADDON_ACTIVE_KEY = 'editProjectAddonActive';

const EDIT_PROJECT_ADDON_ACTIVE = gql`
  mutation ($projectAddonId: String!, $active: Boolean!) {
    ${EDIT_PROJECT_ADDON_ACTIVE_KEY}(uuid: $projectAddonId, data: { item: $active }) {
      id
      active
      updatedAt
    }
  }
`;

type MutVarEditProjectAddonActive = {
  projectAddonId: string;
  active: boolean;
}

type MutResEditProjectAddonActive = Pick<ProjectAddon, 'id' | 'active' | 'updatedAt'>;

export const useEditProjectAddonActive = () => {
  return useDefaultMutation<MutResEditProjectAddonActive, MutVarEditProjectAddonActive>(EDIT_PROJECT_ADDON_ACTIVE, EDIT_PROJECT_ADDON_ACTIVE_KEY);
};
