import { animated, useSpring } from '@react-spring/web';
import { ComponentPropsWithoutRef, FC, useRef } from 'react';
import styled from 'styled-components';
import { useDomRect } from 'hooks/useDomRect';

const PushInChildrenWrapper = styled(animated.div)`
  overflow: hidden;
`;

type Props = ComponentPropsWithoutRef<typeof PushInChildrenWrapper> & {
  reveal: boolean;
};

export const VerticalReveal: FC<Props> = ({
  reveal,
  children,
  style,
  ...props
}) => {

  const ref = useRef<HTMLDivElement>(null);
  const { height = 0 } = useDomRect(ref) ?? {};

  const pushInChildrenWrapperStyle = useSpring({
    height: reveal ? height : 0,
  });

  return (
    <PushInChildrenWrapper
      {...props}
      style={{ ...style, ...pushInChildrenWrapperStyle }}
    >
      <div ref={ref}>
        {children}
      </div>
    </PushInChildrenWrapper>
  );
};
