import { Device } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { ConnectionType, DeviceModel, DeviceType } from 'types/device';
import { useMutation } from 'urql';

const ADD_DEVICE_KEY = 'addDevice';

const ADD_DEVICE = `
  mutation ($organizationId: String!, $name: String!, $connection: String!, $type: String!, $model: String, $devEUI: String, $isGateway: Boolean, $parentId: String) {
    ${ADD_DEVICE_KEY}(organizationId: $organizationId, data: { name: $name, connection: $connection, type: $type, model: $model, devEUI: $devEUI, isGateway: $isGateway, parentId: $parentId}) {
      id
      name
      connection
      type
      model
      battery
      devEUI
      updatedAt
      createdAt
    }
  }
`;

type MutVarAddDevice = {
  organizationId: string;
  name: string;
  connection: ConnectionType;
  type: DeviceType;
  model?: DeviceModel;
  isGateway?: boolean;
  parentId?: string;
  devEUI?: string;
};

type MutResAddDevice = DefaultQueryResponse<
  typeof ADD_DEVICE_KEY,
  Pick<Device, 'id' | 'name' | 'connection' | 'type' | 'model' | 'battery' | 'devEUI' | 'updatedAt' | 'createdAt'>
>;

export const useAddDevice = () => {
  return useMutation<MutResAddDevice, MutVarAddDevice>(ADD_DEVICE);
};
