import { Rule } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { Downsample, Every, RuleOperator, Statistic, TimeWindow } from 'types/rule';
import { useMutation } from 'urql';

const EDIT_RULE_KEY = 'editRule';

const EDIT_RULE = `
  mutation (
    $ruleId: String!
    $data: EditRuleInput!
  ) {
    ${EDIT_RULE_KEY}(
      uuid: $ruleId
      data: $data
    ) {
      id
      name
      every
      downsample
      timeWindow
      threshold
      statistic
      optStatistic
      optThreshold
      operator
      updatedAt
      comments
    }
  }
`;

type MutVarEditRule = {
  ruleId: string;
  data: {
    name?: string;
    projectSensorId?: string;
    timeWindow?: TimeWindow;
    every?: Every;
    downsample?: Downsample;
    statistic?: Statistic;
    optStatistic?: Statistic;
    threshold?: number;
    optThreshold?: number;
    operator?: RuleOperator;
    comments?: string;
  };
};

type MutResEditrule = DefaultQueryResponse<
  typeof EDIT_RULE_KEY,
  Pick<
    Rule,
    | 'id'
    | 'name'
    | 'every'
    | 'downsample'
    | 'timeWindow'
    | 'threshold'
    | 'statistic'
    | 'optStatistic'
    | 'optThreshold'
    | 'operator'
    | 'updatedAt'
    | 'comments'
  >
>;

export const useEditRule = () => {
  return useMutation<MutResEditrule, MutVarEditRule>(EDIT_RULE);
};
