import { View, Location, Note, User, PeriodInput } from 'graphql/generated';
import { isObject } from 'utils/types';
import { Device } from './device';
import { LocationReference } from './location';
import { isPeriod } from './period';
import { Project } from './project';
import { Sensor } from './sensor';

export enum NoteReference {
  PROJECT = 'PROJECT',
  VIEW = 'VIEW',
  SENSOR = 'SENSOR',
  DEVICE = 'DEVICE',
}

type QryProject = Pick<Project, 'id' | 'name'>;
type QryView = Pick<View, 'id' | 'name' | 'type'>;
type QrySensor = Pick<Sensor, 'id' | 'name'>;
type QryDevice = Pick<Device, 'id' | 'name'> & {
  locations: Array<Pick<LocationReference, 'id'> & { location: Pick<Location, 'id' | 'name'> }>;
};
type QryUser = Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'createdAt'>;
export type NoteDetailType = Pick<Note, 'id' | 'title' | 'description' | 'files' | 'updatedAt' | 'createdAt'> & {
  period?: PeriodInput | null;
  owner: QryUser;
  projects: QryProject[];
  views: QryView[];
  sensors: QrySensor[];
  devices: QryDevice[];
};

export type PartialNote = Partial<NoteDetailType>;

export type NoteUserInput = Pick<Note, 'title' | 'description' | 'period'>;

export function isNoteUserInput(value: unknown): value is NoteUserInput {
  if (isObject(value)) {
    const validTitle = typeof value.title === 'string';
    const validDescription = value.description == null || typeof value.description === 'string';
    const validPeriod = value.period == null || isPeriod(value.period);
    return validTitle && validDescription && validPeriod;
  }
  return false;
}

export type NoteReferencesInput = {
  projects?: string[];
  views?: string[];
  devices?: string[];
  sensors?: string[];
};
