import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { useSnackbar } from 'components/Snackbar';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useEditUserRole } from 'graphql/mutation/useEditUserRole';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { FormEvent, useCallback, useContext, useMemo, useState, VFC } from 'react';
import { Severity } from 'state/snackbarStore';
import { User } from 'types/user';
import { OrganizationUserRole, organizationUserRoles } from 'types/organizationUser';
import { dispatchErrors } from 'utils/util';
import { isNonEmptyString } from 'utils/validation';
import { upperFirst } from 'utils/visualize';
import { useQryUserRole, useQryOrganization } from 'graphql/generated';
import { useTranslation } from 'react-i18next';
import { SelectInput } from 'components/form/SelectInput';

type Props = DrawerComponentProps & {
  user: User;
  callback: () => void;
};

export const EditOrganizationUserDrawer: VFC<Props> = ({ user, requestClose, callback }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();
  const authContext = useContext(AuthContext);
  const [{ data: org }] = useQryOrganization(
    {
      id: true,
      name: true,
    },
    {},
  );
  const [{ data: organizationUserRole }] = useQryUserRole(
    true,
    {
      orgId: org?.id ?? '',
      uuid: user?.id ?? '',
    },
    { pause: !org?.id || !user?.id },
  );
  const [role, setRole] = useState<OrganizationUserRole>(organizationUserRole as OrganizationUserRole);

  const [{ fetching: loading }, editUserRole] = useEditUserRole();

  const snackbar = useSnackbar();

  const isFormValid = isNonEmptyString(role);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isFormValid) {
        const editUserRoleResult = await editUserRole({
          uuid: user.id,
          data: {
            role,
          },
        });
        if (editUserRoleResult.data) {
          snackbar.addAlert(
            t('The role of {{firstName}} {{lastName}} is updated to {{role}}', {
              firstName: user.firstName,
              lastName: user.lastName,
              role: t(role, { ns: 'db-values' }),
            }),
            Severity.SUCCESS,
          );
          callback();
          requestClose();
        }
        if (editUserRoleResult.error) {
          dispatchErrors(snackbar, editUserRoleResult.error, authContext, t);
        }
      }
    },
    [
      isFormValid,
      editUserRole,
      user.id,
      user.firstName,
      user.lastName,
      role,
      snackbar,
      callback,
      requestClose,
      authContext,
      t,
    ],
  );

  const organizationUserOptions = useMemo(
    () => organizationUserRoles.map((v) => ({ value: v, label: upperFirst(t(v, { ns: 'db-values' })) })),
    [t],
  );

  return (
    <DrawerLayout title={`${user.firstName} ${user.lastName}`} onClose={requestClose}>
      <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }} onSubmit={handleSubmit}>
        <Body>
          <SelectInput
            value={organizationUserOptions.find((v) => v.value === role)}
            options={organizationUserOptions}
            placeholder={t('Select a role')}
            label={t('Select the role of the member:')}
            onChange={(value: string) => setRole(value as OrganizationUserRole)}
            width="16.5rem"
          />
        </Body>
        <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
          <Button smallPad onClick={() => requestClose()} variant="plain">
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit" disabled={!isFormValid} loading={loading}>
            {t('actions.Save')}
          </Button>
        </DrawerFooter>
      </Form>
    </DrawerLayout>
  );
};
