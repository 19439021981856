import { css } from '@styled-system/css';
import CharpWordmark from 'components/CharpWordmark';
import { FC } from 'react';
import styled from 'styled-components';
import type { BackgroundImageProps, LayoutProps } from 'styled-system';
import { backgroundImage, layout } from 'styled-system';
import { fonts } from 'theme/typography';
import { Flex } from './Flex';

const ScrollContainer = styled.div<LayoutProps & BackgroundImageProps>`
  overflow-y: auto;
  background-position: center;
  background-size: cover;
  ${layout}
  ${backgroundImage}
`;

const Container = styled.div`
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  ${css({
    flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
    pt: ['2rem', '2rem', '12.5rem'],
    pb: '1rem',
    pl: ['1rem', '2rem', '5.875rem'],
    pr: ['1rem', '2rem', '8.5rem'],
    justifyContent: ['start', null, 'space-between'],
  })}
`;

const Content = styled.div`
  min-height: 0;
  flex-shrink: 0;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  ${css({
    width: 'full',
    minWidth: [0, '22.5rem'],
    maxWidth: '34rem',
    boxShadow: 'floating.regular',
  })}
`;

type Props = {
  imageSrc: string;
} & LayoutProps;

export const BoxWithImage: FC<Props> = (props) => {
  const { children, imageSrc, ...styleProps } = props;
  return (
    <ScrollContainer {...styleProps} backgroundImage={`url(${imageSrc})`}>
      <Container>
        <Content>{children}</Content>
        <Flex flexDirection="column" mb={['1rem', '2rem']}>
          <CharpWordmark height={['5rem', '7rem']} width={['10rem', '14.5rem']} mb={['1rem', '2rem']} />
          <Flex
            flexDirection="column"
            style={{ fontFamily: fonts.headerTitle, fontSize: '1.25rem', color: 'white', lineHeight: '1.5rem' }}
          >
            <span>MONITORING</span>
            <span>IS CARING</span>
          </Flex>
        </Flex>
      </Container>
    </ScrollContainer>
  );
};
