import { AuthContext } from 'context/AuthContext';
import { Maybe, Room, RoomType, useQryRoomTypes } from 'graphql/generated';
import { useAddRoomType } from 'graphql/mutation/useAddRoomType';
import { FC, FormEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Severity } from 'state/snackbarStore';
import { defaultRoomTypes } from 'types/room';
import { dispatchErrors } from 'utils/util';
import { Button } from './buttons';
import { Form } from './form';
import { Input } from './form/InputPure';
import { Label } from './form/Label';
import { TextArea } from './form/TextAreaPure';
import { Flex } from './layout/Flex';
import { useSnackbar } from './Snackbar';
import { useTranslation } from 'react-i18next';
import { Body, DrawerFooter } from './layout/drawer/DrawerLayout';
import { SelectInput } from './form/SelectInput';
import { OptionType } from 'types/select';

type Props = {
  room?: Pick<Room, 'name' | 'site' | 'level' | 'surfaceArea' | 'height' | 'services' | 'description'> & {
    type: Maybe<Pick<RoomType, 'id' | 'name'>> | undefined;
  };
  submitText: string;
  organizationId: string;
  submitting?: boolean;
  onSubmit: (data: {
    name: string;
    site?: string;
    level?: string;
    surfaceArea?: string;
    services?: string;
    height?: string;
    description?: string;
    typeId?: string;
  }) => void;
  onClose: () => void;
};

export const RoomForm: FC<Props> = ({ room, submitText, submitting, organizationId, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);

  const [name, setName] = useState<string>(room?.name ?? '');
  const [site, setSite] = useState<string>(room?.site ?? '');
  const [level, setLevel] = useState<string>(room?.level ?? '');
  const [surfaceArea, setSurfaceArea] = useState<string>(room?.surfaceArea ?? '');
  const [services, setServices] = useState<string>(room?.services ?? '');
  const [height, setHeight] = useState<string>(room?.height ?? '');
  const [description, setDescription] = useState<string>(room?.description ?? '');
  const [typeId, setTypeId] = useState<string | undefined>(room?.type?.id);

  const [{ data: roomTypes }] = useQryRoomTypes({ id: true, name: true }, { organizationId }, {});
  const [, addRoomType] = useAddRoomType();

  const roomTypeItems = useMemo(() => {
    const existingItems = roomTypes ?? [];
    const filterItems: OptionType[] = existingItems.map((type) => ({
      value: type.id,
      label: t(type.name, { ns: 'db-values' }),
    }));
    for (const type of defaultRoomTypes) {
      if (existingItems.find((item) => item.name === type) === undefined) {
        filterItems.push({
          value: type,
          label: t(type, { ns: 'db-values' }),
          default: true,
        });
      }
    }
    return filterItems;
  }, [roomTypes, t]);

  const onNewRoomType = useCallback(
    async (value: string) => {
      const mewRoomType = await addRoomType({
        organizationId,
        data: {
          name: value,
        },
      });
      setTypeId(mewRoomType.data?.addRoomType.id);
      if (mewRoomType.data) {
        snackbar.addAlert(
          t('Created new room type: {{type}}', { type: mewRoomType.data.addRoomType.name }),
          Severity.SUCCESS,
        );
      }
      if (mewRoomType.error) {
        dispatchErrors(snackbar, mewRoomType.error, authContext, t);
      }
    },
    [addRoomType, authContext, organizationId, snackbar, t],
  );

  const onRoomTypeChange = useCallback(async (value: string) => {
    setTypeId(value);
  }, []);

  const onFormSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (name !== '') {
        onSubmit({
          name,
          site,
          level,
          surfaceArea,
          services,
          height,
          description,
          typeId,
        });
      }
    },
    [site, level, surfaceArea, services, description, height, name, onSubmit, typeId],
  );

  return (
    <Form onSubmit={onFormSubmit} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Body>
        <Flex flexDirection="row" flexWrap="wrap" gap="1.5rem" mb="1.5rem">
          <Flex flexDirection="column" gap="0.25rem" flex={1}>
            <Label>{t('Room')}*</Label>
            <Input
              type="text"
              name="roomName"
              value={name}
              placeholder={t('The room name')}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Flex>
          <Flex flexDirection="column" gap="0.25rem" flex={1}>
            <Label>{t('Height')}</Label>
            <Input
              type="text"
              name="roomHeight"
              value={height}
              placeholder={t('The height of the room')}
              onChange={(e) => setHeight(e.target.value)}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="row" flexWrap="wrap" gap="1.5rem" mb="1.5rem">
          <Flex flexDirection="column" gap="0.25rem" flex={1}>
            <Label>{t('Surface area')}</Label>
            <Input
              type="text"
              name="roomSurfaceArea"
              value={surfaceArea}
              placeholder={t('The room surface area')}
              onChange={(e) => setSurfaceArea(e.target.value)}
            />
          </Flex>

          <Flex flexDirection="column" gap="0.25rem" flex={1}>
            <Label>{t('Level')}</Label>
            <Input
              type="text"
              name="roomLevel"
              value={level}
              placeholder={t('The level of the room')}
              onChange={(e) => setLevel(e.target.value)}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="row" flexWrap="wrap" gap="1.5rem" mb="1.5rem">
          <Flex flexDirection="column" gap="0.25rem" flex={1}>
            <Label>{t('Type')}</Label>
            <SelectInput
              options={roomTypeItems}
              value={roomTypeItems.find((item) => item.value === room?.type?.id) ?? undefined}
              onChange={onRoomTypeChange}
              onNewItem={onNewRoomType}
            />
          </Flex>
          <Flex flexDirection="column" gap="0.25rem" flex={1}>
            <Label>{t('Site')}</Label>
            <Input
              type="text"
              placeholder={t('The site of the room')}
              value={site}
              onChange={(e) => setSite(e.target.value)}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="0.25rem">
          <Flex flexDirection="column" gap="0.25rem" mb="1.5rem">
            <Label>{t('Description')}</Label>
            <TextArea
              width="100%"
              height="7.5rem"
              name="roomDescription"
              value={description}
              placeholder={t('A description of the room')}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Flex>
          <Flex flexDirection="column" gap="0.25rem">
            <Label>{t('Services')}</Label>
            <TextArea
              width="100%"
              height="7.5rem"
              name="roomSevices"
              value={services}
              placeholder={t('The services of the room')}
              onChange={(e) => setServices(e.target.value)}
            />
          </Flex>
        </Flex>
      </Body>
      <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
        <Button smallPad onClick={onClose} variant="plain">
          {t('actions.Cancel')}
        </Button>
        <Button smallPad type="submit" disabled={name === ''} loading={submitting}>
          {submitText}
        </Button>
      </DrawerFooter>
    </Form>
  );
};
