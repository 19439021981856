import { Container, Content } from 'components/layout';
import { Header } from 'components/layout/Header';
import { UserTable } from 'components/table/UserTable';
import { H4 } from 'components/typography';
import { DrawerContext } from 'context/DrawerContext';
import { useQryInvitations, useQryMe, useQryUserRole, useQryOrganization } from 'graphql/generated';
import { useContext, useMemo, VFC } from 'react';
import { OrganizationUserRole } from 'types/organizationUser';
import { UserInvitationTable } from './components/UserInvitationTable';
import { Button } from 'components/buttons';
import { useTranslation } from 'react-i18next';

const Users: VFC = () => {
  const { t } = useTranslation();
  const [{ data: me }] = useQryMe({ id: true }, {});
  const [{ data: org, fetching: loading }] = useQryOrganization(
    {
      id: true,
      name: true,
      users: {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
      },
    },
    {},
  );
  const [{ data: organizationUserRole }] = useQryUserRole(
    true,
    {
      orgId: org?.id ?? '',
      uuid: me?.id ?? '',
    },
    { pause: !org?.id || !me?.id },
  );
  const context = useMemo(() => ({ additionalTypenames: ['UserInvitation'] }), []);
  const [{ data: invitations }] = useQryInvitations(
    {
      id: true,
      role: true,
      email: true,
      status: true,
      updatedAt: true,
      expiration: true,
    },
    {
      context,
    },
  );

  const { requestDrawer } = useContext(DrawerContext);

  const controls = useMemo(() => {
    if (
      organizationUserRole === OrganizationUserRole.enum.collaborator ||
      organizationUserRole === OrganizationUserRole.enum.owner
    ) {
      return (
        <Button smallPad onClick={() => requestDrawer('inviteUser')}>
          {t('actions.Invite member')}
        </Button>
      );
    }
    return undefined;
  }, [organizationUserRole, requestDrawer, t]);

  return (
    <Container>
      <Header title={t('Members')} controls={controls} />
      <Content loading={loading}>
        <UserTable org={org} me={me} users={org?.users ?? []} />
        <H4 mt={16} mb={4}>
          {t('Invitations')}
        </H4>
        <div style={{ marginBottom: '5rem' }}>
          <UserInvitationTable invitations={invitations} />
        </div>
      </Content>
    </Container>
  );
};

export default Users;
