import { Flex } from 'components/layout/Flex';
import { FC } from 'react';
import styled from 'styled-components';
import type { LayoutProps, SpaceProps } from 'styled-system';
import { layout, space } from 'styled-system';
import { AddonInfo, AddonName } from 'types/addon';
import { AddonInfoCard } from './AddonInfoCard';

const Div = styled.div<SpaceProps & LayoutProps>`
  ${space}
  ${layout}
`;

type Props = {
  items: AddonInfo[];
  onSelect: (type: AddonName) => void;
};
const col = ['100%', '25%'];
export const AddonSelection: FC<Props> = ({ items, onSelect }) => {
  return (
    <Div>
      <Flex>
        {items.map((addon, index) => (
          <Flex flexBasis={col} key={index}>
            <AddonInfoCard addonInfo={addon} onClick={() => onSelect(addon.name)} />
          </Flex>
        ))}
      </Flex>
    </Div>
  );
};
