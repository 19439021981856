import { ApolloProvider } from '@apollo/client';
import { CSSReset } from '@chakra-ui/css-reset';
import { ChakraProvider } from '@chakra-ui/provider';
import defaultChakraTheme from '@chakra-ui/theme';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Charp } from 'App';
import { SnackbarContainer } from 'components/Snackbar';
import { AuthProvider } from 'context/AuthContext';
import { LocaleProvider } from 'context/LocaleContext';
import { UserContext } from 'context/UserContext';
import 'focus-visible';
import { createBrowserHistory } from 'history';
import { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { urqlClient } from 'graphql/urql';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme';
import FontFaceDefinitions from 'theme/FontFaceDefinition';
import GlobalStyle from 'theme/GlobalStyle';
import { Provider } from 'urql';
import environment from 'utils/environment';
import { client } from './services/apollo';
import i18n from 'i18next';
import initI18n from 'i18init';
import { useTranslation } from 'react-i18next';
import { Auth0Provider } from '@auth0/auth0-react';

const history = createBrowserHistory();

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      // createBrowserHistory type covers all properties of RouterHistory, so this type assertion is fine!
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // 1 means 100% of all traces are sent to Sentry. Sentry recommends a lower value, but our userbase isn't that big yet.
  tracesSampleRate: 1,
});

const App = () => {
  const [user] = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (user !== undefined) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
      });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [user]);

  return (
    <Sentry.ErrorBoundary fallback={<div>{t('An error has occurred')}</div>}>
      <ThemeProvider theme={theme}>
        <FontFaceDefinitions />
        <CSSReset />
        <GlobalStyle />
        <LocaleProvider>
          <SnackbarContainer>
            <ChakraProvider theme={defaultChakraTheme}>
              <Charp history={history} />
            </ChakraProvider>
          </SnackbarContainer>
        </LocaleProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

if (!i18n.isInitialized) {
  initI18n().then(() => {
    ReactDOM.render(
      <Provider value={urqlClient}>
        <ApolloProvider client={client}>
          <Auth0Provider
            domain="charp.eu.auth0.com"
            clientId="O90zrp2XXWd6pTyodEVBvM8yV5ds5ubx"
            authorizationParams={{
              redirect_uri: window.location.origin,
            }}
          >
            <AuthProvider>
              <App />
            </AuthProvider>
          </Auth0Provider>
        </ApolloProvider>
      </Provider>,
      document.getElementById('root'),
    );
  });
}
