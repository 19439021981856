import { gql } from '@apollo/client';
import { Sensitivity, LightBudgetView } from 'graphql/generated';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';
import { useTranslation } from 'react-i18next';

const ADD_LIGHT_BUDGET_VIEW_KEY = 'addLightBudgetView';

const ADD_LIGHT_BUDGET_VIEW = gql`
  mutation ($projectId: String!, $data: AddLightBudgetViewInput!) {
    ${ADD_LIGHT_BUDGET_VIEW_KEY}(uuid: $projectId, data: $data) {
      id
      name
      type
      updatedAt
    }
  }
`;

type MutVarAddLightBudgetView = {
  projectId: string;
  data: {
    name: string;
    projectSensorIds: string[];
    period: {
      start: string;
      end?: string | null;
    };
    control: {
      targetLifetime: number;
      roleOfColor: number;
      exposureTime: number;
      uv: boolean;
      sensitivity: Sensitivity;
    };
    autoAddNewDevices?: boolean;
  };
};

type MutResAddLightBudgetView = Pick<LightBudgetView, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'>;

export const useAddLightBudgetView = () => {
  const { t } = useTranslation();
  const update: MutationUpdateFunction<MutVarAddLightBudgetView, MutResAddLightBudgetView> = (
    cache,
    data,
    { variables },
  ) => {
    const result = data.data;
    if (result) {
      const newViewRef = cache.writeFragment({
        data: result[ADD_LIGHT_BUDGET_VIEW_KEY],
        fragment: gql`
          fragment NewView on View {
            id
          }
        `,
      });
      if (variables === undefined) {
        throw new Error(t('Missing mutation variables'));
      }
      cache.modify({
        id: cache.identify({
          id: variables.projectId,
          __typename: 'Project',
        }),
        fields: {
          views(views = []) {
            return [newViewRef, ...views];
          },
        },
      });
    }
  };

  return useDefaultMutation<MutResAddLightBudgetView, MutVarAddLightBudgetView>(
    ADD_LIGHT_BUDGET_VIEW,
    ADD_LIGHT_BUDGET_VIEW_KEY,
    update,
  );
};
