import { HTMLProps } from 'react';
import styled from 'styled-components';
import type {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PaddingProps,
  PositionProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';
import { border, color, compose, flexbox, layout, padding, position, space, typography } from 'styled-system';

export type StyledProps = ColorProps &
  SpaceProps &
  LayoutProps &
  BorderProps &
  PositionProps &
  TypographyProps &
  PaddingProps &
  FlexboxProps;
const styledFunctions = [color, space, layout, border, position, typography, padding, flexbox];

export type SpanProps = HTMLProps<HTMLSpanElement> & StyledProps;
export const Span = styled.span<SpanProps>(compose(...styledFunctions));
export type DivProps = HTMLProps<HTMLDivElement> & StyledProps;
export const Div = styled.div<DivProps>(compose(...styledFunctions));
