import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, LayoutProps, MarginProps, TypographyProps } from 'styled-system';
import { color, compose, layout, margin, typography } from 'styled-system';
import { commonTextStyle } from './Text';

type Props = LayoutProps & MarginProps & TypographyProps & ColorProps;

export const Paragraph = styled.p<Props>(
  css({
    ...commonTextStyle,
    marginTop: 2,
    marginBottom: 2,
    ':first-child': {
      marginTop: 0,
    },
    ':last-child': {
      marginBottom: 0,
    },
    lineHeight: 'normal',
    fontSize: ['xs', 'sm', 'md', 'lg', 'xl'],
    wordBreak: 'normal',
    overflowWrap: 'break-word',
    '@media (max-width: 600px)': {
      fontSize: ['md', 'lg', 'xl', '2xl', '3xl'],
    },
  }),
  compose(layout, margin, typography, color),
);
