import { css } from '@styled-system/css';
import { useFocus } from 'hooks/useFocus';
import { useHover } from 'hooks/useHover';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdOutlineAdd, MdOutlineRemove } from 'react-icons/md';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PropertyContainer } from '../../components/properties/DefaultProperty';
import { Loader } from 'components/loader/loader';

export type SelectProperty = {
  type: 'select';
  title: string;
  value: string | undefined | null;
  values: string[];
  onSave: (value: string | null) => Promise<void>;
  id?: string;
};

type Props = Pick<SelectProperty, 'title' | 'value' | 'values' | 'onSave'>;

export const SelectProperty: FC<Props> = ({ title, value, values, onSave }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const containerInputRef = useRef<HTMLDivElement>(null);
  const [containerRef, hoveringContainer] = useHover<HTMLDivElement>();
  const [inputRef, focusInput, blurInput] = useFocus<HTMLInputElement>();

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  useLayoutEffect(() => {
    if (editing) {
      focusInput();
    }
  }, [editing, focusInput]);

  const onContainerClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (!editing) {
        setEditing(true);
      }
    },
    [editing],
  );

  const onPlusClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (!editing) {
        setEditing(true);
      }
    },
    [editing],
  );

  const onMinClick = useCallback(
    async (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setLoading(true);
      await onSave(null);
      setLoading(false);
    },
    [onSave],
  );

  const onExit = useCallback(async () => {
    blurInput();
    setInputValue('');
    setEditing(false);
  }, [blurInput]);

  const onSelectValue = useCallback(
    async (event: MouseEvent<HTMLDivElement>, value: string) => {
      event.stopPropagation();
      setLoading(true);
      await onSave(value);
      setLoading(false);
      onExit();
    },
    [onExit, onSave],
  );

  const filteredValues: string[] = useMemo(() => {
    const trimmedInput = inputValue.trim();
    if (trimmedInput !== '') {
      const valuesBasedOnSearch = (values ?? []).filter((value) =>
        value.toLowerCase().includes(trimmedInput.toLowerCase()),
      );
      return valuesBasedOnSearch;
    } else {
      return values;
    }
  }, [inputValue, values]);

  useOnClickOutside(containerInputRef, onExit);

  const handleKeyDown = useCallback(
    async (e: KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        if (filteredValues.length > 0) {
          await onSave(filteredValues[0]);
        }
        onExit();
      } else if (e.key === 'Escape') {
        e.stopPropagation();
        onExit();
      }
    },
    [filteredValues, onExit, onSave],
  );

  return (
    <PropertyContainer
      justifyContent="space-between"
      alignItems={editing ? 'flex-start' : 'center'}
      height="32px"
      ref={containerRef}
      editing={editing}
      onClick={onContainerClick}
    >
      {editing ? (
        <ContainerEditor ref={containerInputRef}>
          <ContainerInput>
            <PropertyInput
              ref={inputRef}
              type="text"
              onKeyDown={handleKeyDown}
              placeholder={t('Select a {{data}}...', { data: title.toLowerCase() })}
              value={inputValue}
              onChange={onInputChange}
              disabled={loading}
            />
            {loading && <Loader />}
          </ContainerInput>
          <DropdownContainer>
            <DropdownSubtitle>
              {filteredValues.length > 0 ? t('Select a {{data}}', { data: title.toLowerCase() }) : t('No results')}
            </DropdownSubtitle>
            {filteredValues.map((value, index) => (
              <DropdownItem
                selected={filteredValues.length === 0}
                key={index}
                onClick={(event) => onSelectValue(event, value)}
              >
                <ObjectBadge>{value}</ObjectBadge>
              </DropdownItem>
            ))}
          </DropdownContainer>
        </ContainerEditor>
      ) : (
        <>
          {value != undefined ? (
            <>
              <ObjectBadge>{value}</ObjectBadge>
              {hoveringContainer && (
                <ActionContainer>
                  <ActionButton onClick={onPlusClick}>
                    <MdOutlineAdd />
                  </ActionButton>
                  <ActionButton onClick={onMinClick}>
                    <MdOutlineRemove />
                  </ActionButton>
                </ActionContainer>
              )}
            </>
          ) : (
            'Empty'
          )}
        </>
      )}
    </PropertyContainer>
  );
};

const ObjectBadge = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    borderRadius: '3px',
    px: '6px',
    backgroundColor: 'rgba(227, 226, 224, 0.5)',
  }),
);

const ActionContainer = styled.div(
  css({
    display: 'flex',
    borderRadius: '4px',
    p: '2px',
    gap: '1px',
    background: 'white',
    boxShadow: 'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px',
  }),
);

const ActionButton = styled.div(
  css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: '20px',
    width: '20px',
    borderRadius: '0px',
    transition: 'background 20ms ease-in 0s',
    ':hover': {
      borderRadius: '3px',
      backgroundColor: 'rgba(55, 53, 47, 0.08)',
    },
  }),
);

const ContainerEditor = styled.div(
  css({
    borderRadius: '4px',
    background: 'white',
    position: 'relative',
    maxWidth: 'calc(100vw - 24px)',
    minWidth: '180px',
    maxHeight: '70vh',
    boxShadow: 'rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px',
    overflow: 'hidden',
    width: '100%',
    minHeight: '65px',
  }),
);

const ContainerInput = styled.div(
  css({
    display: 'flex',
    position: 'relative',
    width: '100%',
    px: '10px',
    py: '6px',
    background: 'rgba(242, 241, 238, 0.6)',
    boxShadow: 'rgb(55 53 47 / 16%) 0px 1px 0px',
  }),
);

const DropdownContainer = styled.div(
  css({
    width: '100%',
    maxHeight: '300px',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden auto',
    mr: '0px',
    mb: '0px',
  }),
);

const DropdownSubtitle = styled.div(
  css({
    display: 'flex',
    pl: '12px',
    pr: '9px',
    my: '6px',
    color: 'rgba(55, 53, 47, 0.65)',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '120%',
    userSelect: 'none',
    height: '19px',
    alignItems: 'center',
  }),
);

type DropdownItemProps = {
  selected?: boolean;
};

const DropdownItem = styled.div<DropdownItemProps>((props) =>
  css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    userSelect: 'none',
    transition: 'background 20ms ease-in 0s',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: '3px',
    height: '32px',
    py: '2px',
    px: '12px',
    background: props.selected ? 'rgba(55, 53, 47, 0.08)' : 'initial',
    ':hover': {
      background: 'rgba(55, 53, 47, 0.08)',
    },
  }),
);

const PropertyInput = styled.input(
  css({
    p: '0px',
    border: 'none',
    background: 'none',
    width: '100%',
    display: 'block',
    resize: 'none',
    flexGrow: 1,
    outline: 'none',
  }),
);
