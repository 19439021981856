import create from 'zustand';

type PaginationVars = {
  limit: number;
  offset: number;
  setLimit: (value: number) => void;
  setOffset: (value: number) => void;
};

type PaginationStore = {
  deviceTable: PaginationVars;
  projectSensors: PaginationVars;
  sensorTable: PaginationVars;
  deviceSelection: PaginationVars;
  sensorSelectorTable: PaginationVars;
  addLightBudgetControl: PaginationVars;
  ashraeViewForm: PaginationVars;
  cumulativeLightViewForm: PaginationVars;
  lightBudgetForm: PaginationVars;
  monitorViewForm: PaginationVars;
  temperatureHumidityViewForm: PaginationVars;
  locationTable: PaginationVars;
  roomsTable: PaginationVars;
  calibrationTable: PaginationVars;
  ruleTable: PaginationVars;
};

function createPaginationStore(
  set: (partial: Partial<PaginationStore> | ((state: PaginationStore) => Partial<PaginationStore>)) => void,
  key: keyof PaginationStore,
  initialLimit: number,
): PaginationVars {
  return {
    limit: initialLimit,
    offset: 0,
    setLimit: (value: number) =>
      set((state) => ({
        [key]: {
          ...state[key],
          limit: value,
        },
      })),
    setOffset: (value: number) =>
      set((state) => ({
        [key]: {
          ...state[key],
          offset: value,
        },
      })),
  };
}

export const usePaginationStore = create<PaginationStore>((set) => ({
  deviceTable: createPaginationStore(set, 'deviceTable', 10),
  projectSensors: createPaginationStore(set, 'projectSensors', 10),
  sensorTable: createPaginationStore(set, 'sensorTable', 10),
  deviceSelection: createPaginationStore(set, 'deviceSelection', 8),
  sensorSelectorTable: createPaginationStore(set, 'sensorSelectorTable', 10),
  addLightBudgetControl: createPaginationStore(set, 'addLightBudgetControl', 10),
  ashraeViewForm: createPaginationStore(set, 'ashraeViewForm', 10),
  cumulativeLightViewForm: createPaginationStore(set, 'cumulativeLightViewForm', 10),
  lightBudgetForm: createPaginationStore(set, 'lightBudgetForm', 10),
  monitorViewForm: createPaginationStore(set, 'monitorViewForm', 10),
  temperatureHumidityViewForm: createPaginationStore(set, 'temperatureHumidityViewForm', 10),
  locationTable: createPaginationStore(set, 'locationTable', 10),
  roomsTable: createPaginationStore(set, 'roomsTable', 10),
  calibrationTable: createPaginationStore(set, 'calibrationTable', 10),
  ruleTable: createPaginationStore(set, 'ruleTable', 10),
}));
