import { useMutation } from 'urql';
import { MutationEditProjectUserRoleArgs, ProjectUser } from 'graphql/generated';

const EDIT_PROJECT_USER_ROLE_KEY = 'editProjectUserRole';

const EDIT_USER_ROLE = `
  mutation ($uuid: String!, $data: EditProjectUserRoleInput!) {
    ${EDIT_PROJECT_USER_ROLE_KEY}(uuid: $uuid, data: $data) {
      id
    }
  }
`;

type MutResEditProjectUserRole = Pick<
  ProjectUser,
  'id' | 'role' | 'email' | 'userId' | 'firstName' | 'lastName' | 'communicationChannel' | 'createdAt'
>;

export const useEditProjectUserRole = () => {
  return useMutation<MutResEditProjectUserRole, MutationEditProjectUserRoleArgs>(EDIT_USER_ROLE);
};
