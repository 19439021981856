import { useTranslation } from 'react-i18next';
import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { FC, FormEvent, useMemo, useState } from 'react';
import { useAddVersaSenseVendor } from 'server';
import { isNonEmptyString } from 'utils/validation';

type Props = {
  onClose: () => void;
};

export const AddVersaSense: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [topic, setTopic] = useState<string>('');
  const [addVersaSenseVendor, loading] = useAddVersaSenseVendor();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await addVersaSenseVendor({
      data: {
        name,
        metadata: {
          username,
          password,
          topic,
        },
      },
    });
    onClose();
  };

  const disabled = useMemo(() => {
    return !isNonEmptyString(name) || !isNonEmptyString(username) || !isNonEmptyString(topic);
  }, [name, topic, username]);

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Input
          validate={isNonEmptyString}
          label={t('Name')}
          type="text"
          name="dataStreamName"
          value={name}
          marginProps={{ mb: 4 }}
          placeholder={t('placeholders.Enter a name for this data stream')}
          onChange={e => setName(e.target.value)}
          required
        />
        <Input
          validate={isNonEmptyString}
          label={t('Username')}
          type="text"
          name="dataStreamUsername"
          value={username}
          marginProps={{ mb: 4 }}
          placeholder={t('placeholders.Enter the username')}
          onChange={e => setUsername(e.target.value)}
          required
        />
        <Input
          validate={isNonEmptyString}
          label={t('Password')}
          type="password"
          name="dataStreamPassword"
          value={password}
          marginProps={{ mb: 4 }}
          placeholder={t('placeholders.Enter the password')}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Input
          validate={isNonEmptyString}
          label={t('Topic')}
          type="text"
          name="dataStreamTopic"
          value={topic}
          marginProps={{ mb: 4 }}
          placeholder={t('placeholders.Enter the topic')}
          onChange={e => setTopic(e.target.value)}
          required
        />
        <Button
          type="submit"
          disabled={disabled}
          loading={loading}
          variant="positive"
        >
          {t('actions.Add data stream')}
        </Button>
      </Form>
    </div>
  );
};
