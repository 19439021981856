import { gql } from '@apollo/client';
import { Project } from 'types/project';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';

const ADD_PROJECT_KEY = 'addProject';

const ADD_PROJECT = gql`
  mutation ($name: String!, $description: String) {
    ${ADD_PROJECT_KEY}(data: { name: $name, description: $description }) {
      id
      name
      description
      updatedAt
    }
  }
`;

type MutVarAddProject = {
  name: string;
  description?: string;
};

type MutResAddProject = Pick<Project, 'id' | 'name' | 'description' | 'updatedAt' | 'maintenance'>;

const update: MutationUpdateFunction<MutVarAddProject, MutResAddProject> = (cache, data) => {
  const result = data.data;
  if (result) {
    const project = result[ADD_PROJECT_KEY];
    const newProjectRef = cache.writeFragment({
      data: project,
      fragment: gql`
        fragment NewProject on Project {
          id
        }
      `,
    });
    cache.modify({
      fields: {
        projects(projects = []) {
          return [...projects, newProjectRef];
        },
      },
    });
  }
};

export const useAddProject = () => {
  return useDefaultMutation<MutResAddProject, MutVarAddProject>(ADD_PROJECT, ADD_PROJECT_KEY, update);
};
