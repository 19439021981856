export const fonts = {
  main: "'Source Sans Pro', sans-serif",
  headerTitle: "'Archia', sans-serif",
};

export const fontSizes = {
  xxs: '0.50rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '2rem',
  '4xl': '2.5rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
};

export const lineHeights = {
  tightest: 1,
  tighter: 1.125,
  tight: 1.25,
  normal: 1.375,
  wide: 1.5,
  wider: 1.625,
  widest: 2.875,
};

export const letterSpacings = {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: '0',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
};
