import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { LayoutProps } from 'styled-system';
import { ErrorScreen } from './ErrorScreen';

export const LocationErrorScreen: FC<LayoutProps> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <ErrorScreen
      {...props}
      title={t('Location not found')}
      description={t('The location you are trying to access does not exist or is not accessible by you')}
      rescue={{
        label: t('Go to locations'),
        location: {
          pathname: `/locations/`,
        },
      }}
    />
  );
};
