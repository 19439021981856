import { css } from '@styled-system/css';
import { Div } from 'components/basics';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { colors } from 'theme/colors';

const NewContainer = styled(Div)<{ variant?: 'note' }>(
  css({
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: colors.base[400],
    cursor: 'pointer',
    py: 2,
    px: 2,
    ':hover': {
      background: colors.base[200],
    },
  }),
  variant({
    variants: {
      note: {
        color: 'yellow.700',
        ':hover': {
          backgroundColor: 'yellow.300',
        },
      },
    },
  }),
);

type Props = {
  variant?: 'note';
  onClick: () => void;
};

export const NewProperty: VFC<Props> = ({ variant, onClick }) => {
  const { t } = useTranslation();
  return (
    <NewContainer variant={variant} onClick={onClick}>
      <MdAdd /> {t('New')}
    </NewContainer>
  );
};
