// allowed values according to CSS spec
type FontWeight = number | 'normal' | 'bold' | 'lighter' | 'bolder';
type FontStyle = 'normal' | 'italic' | 'oblique';

export function generateTrueTypeFontFaceDefinition(
  family: string,
  url: string,
  weight?: FontWeight,
  style?: FontStyle,
): string {
  return `
    @font-face {
      font-family: '${family}';
      ${weight !== undefined ? `font-weight: ${weight}` : ''};
      ${style !== undefined ? `font-style: ${style}` : ''};
      src: url(${url}) format('truetype');
      font-display: fallback;
    }
  `;
  // "font-display: fallback" is a workaround for a styled-components font-handling issue
  // see: https://styled-components.com/docs/faqs#how-do-i-fix-flickering-text-after-server-side-rendering
}

export function generateTrueTypeFontFaceList(
  family: string,
  fontConfig: { url: string; weight?: FontWeight; style?: FontStyle }[],
): string {
  return fontConfig
    .map(({ url, weight, style }) => generateTrueTypeFontFaceDefinition(family, url, weight, style))
    .join('');
}
