import { css } from '@styled-system/css';
import { FC } from 'react';
import styled from 'styled-components';
import type { MarginProps } from 'styled-system';
import { margin } from 'styled-system';

type StyleProps = {
  accentColor?: string;
  onColor?: string;
  height?: string | number;
} & MarginProps;

const Input = styled.input<StyleProps>`
  ${(p) =>
    css({
      fontSize: p.height ?? '1.25rem',
      backgroundColor: p.accentColor ?? 'white',
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: '2px',
    })}
  width: 1.5em;
  height: 0.75em;
  border-radius: 0.5em;
  appearance: none;
  position: relative;
  cursor: pointer;
  &:checked {
    ${(p) =>
      css({
        backgroundColor: p.onColor ?? 'black',
      })}
    &::after {
      transform: translateX(0.75em);
      ${css({
        backgroundColor: 'white',
      })}
    }
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors.black};
  }
  &:disabled {
    opacity: 0.5;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0.075em;
    left: 0.1em;
    height: 0.4em;
    width: 0.4em;
    border-radius: 0.4em;
    pointer-events: none;
    transition: transform 96ms ease;
    ${css({
      backgroundColor: 'black',
    })}
  }
  ${margin};
`;

type Props = {
  on: boolean;
  onChange: (on: boolean) => void;
  disabled?: boolean;
} & StyleProps;

const Switch: FC<Props> = ({ on, onChange, ...props }) => {
  return <Input type="checkbox" checked={on} onChange={(e) => onChange(e.target.checked)} {...props} />;
};

export { Switch };
