import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { SelectInput } from 'components/form/SelectInput';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { useSnackbar } from 'components/Snackbar';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useInviteUser } from 'graphql/mutation/useInviteUser';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { ChangeEvent, FormEvent, useContext, useMemo, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Severity } from 'state/snackbarStore';
import { OrganizationUserRole, organizationUserRoles } from 'types/organizationUser';
import { dispatchErrors } from 'utils/util';
import { isEmailAddress, isNonEmptyString } from 'utils/validation';
import { upperFirst } from 'utils/visualize';

export const InviteUserDrawer: VFC<DrawerComponentProps> = ({ requestClose }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<OrganizationUserRole | ''>('');

  const [{ fetching: invitingUser }, inviteUser] = useInviteUser();

  const isFormValid = isEmailAddress(email) && isNonEmptyString(role);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (role !== '') {
      const invitation = await inviteUser({
        data: {
          email,
          role,
        },
      });
      if (invitation.data) {
        snackbar.addAlert(t('An invitation has been sent to {{email}}', { email: email }), Severity.SUCCESS);
        requestClose();
      }
      if (invitation.error) {
        dispatchErrors(snackbar, invitation.error, authContext, t);
      }
    }
  };

  const organizationUserOptions = useMemo(
    () => organizationUserRoles.map((v) => ({ value: v as string, label: upperFirst(t(v, { ns: 'db-values' })) })),
    [t],
  );

  return (
    <DrawerLayout title={t('actions.Invite member')} onClose={requestClose}>
      <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }} onSubmit={onSubmit}>
        <Body>
          <Input
            validate={isEmailAddress}
            label={t('Email address')}
            type="email"
            name="email"
            value={email}
            marginProps={{ mb: '1.5rem' }}
            placeholder={t('placeholders.Enter the email address')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            required
            width="16.5rem"
          />
          <SelectInput
            value={organizationUserOptions.find((v) => v.value === role)}
            options={organizationUserOptions}
            placeholder={t('Select a role')}
            label={t('Select the role of the member:')}
            onChange={(value: string) => setRole(value as OrganizationUserRole)}
            width="16.5rem"
          />
        </Body>
        <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
          <Button smallPad onClick={() => requestClose()} variant="plain">
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit" disabled={!isFormValid} loading={invitingUser}>
            {t('actions.Invite member')}
          </Button>
        </DrawerFooter>
      </Form>
    </DrawerLayout>
  );
};
