import { FC } from 'react';
import styled from 'styled-components';

// size always relative to accompanying text
const Span = styled.span`
  font-size: 0.5em;
  position: relative;
  bottom: 0.5em;
`;

export const SuperScript: FC = (props) => <Span { ...props } />;
