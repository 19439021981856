import { css } from '@styled-system/css';
import styled from 'styled-components';
import { colors } from 'theme/colors';

export const Anchor = styled.a`
  text-decoration: underline;
  ${css({
    color: colors.brand,
  })}
  &:hover,
  :active,
  :focus {
    ${css({
      color: colors.primary[500],
    })}
  }
`;
