import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, LayoutProps } from 'styled-system';
import { color, compose, layout } from 'styled-system';

export const Container = styled.div<ColorProps & LayoutProps>`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: auto;
  ${css({
    backgroundColor: 'white',
  })}
  ${compose(color, layout)}
`;
