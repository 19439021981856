import { gql } from '@apollo/client';
import { ProjectSensor } from 'graphql/generated';
import { Rule } from 'types/rule';
import { AlertView } from 'types/view/alertView';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';
import { useTranslation } from 'react-i18next';

const ADD_ALERT_VIEW_KEY = 'addAlertView';

const ADD_ALERT_VIEW = gql`
  mutation ($projectId: String!, $name: String!) {
    ${ADD_ALERT_VIEW_KEY}(uuid: $projectId, data: { item: $name }) {
      id
      name
      type
      rules {
        id
        name
        every
        downsample
        timeWindow
        threshold
        statistic
        optStatistic
        optThreshold
        operator
        sensor {
          id
          name
          unit
          location {
            id
            name
          }
          sensorId
          deviceId
          measurement
          updatedAt
        }
        updatedAt
      }
      updatedAt
    }
  }
`;

type MutVarAddAlertView = {
  projectId: string;
  name: string;
};

type MutProjectSensor = Pick<
  ProjectSensor,
  'id' | 'name' | 'unit' | 'location' | 'sensorId' | 'deviceId' | 'measurement' | 'updatedAt'
>;
type MutRule = Pick<
  Rule,
  | 'id'
  | 'name'
  | 'every'
  | 'downsample'
  | 'timeWindow'
  | 'threshold'
  | 'statistic'
  | 'optStatistic'
  | 'optThreshold'
  | 'operator'
  | 'updatedAt'
> & {
  sensor: MutProjectSensor;
};
type MutResAddAlertView = Pick<AlertView, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'> & {
  rules: MutRule[];
};

export const useAddAlertView = () => {
  const { t } = useTranslation();
  const update: MutationUpdateFunction<MutVarAddAlertView, MutResAddAlertView> = (cache, data, { variables }) => {
    const result = data.data;
    if (result) {
      const newViewRef = cache.writeFragment({
        data: result[ADD_ALERT_VIEW_KEY],
        fragment: gql`
          fragment NewView on View {
            id
          }
        `,
      });
      if (variables === undefined) {
        throw new Error(t('Missing mutation variables'));
      }
      cache.modify({
        id: cache.identify({
          id: variables.projectId,
          __typename: 'Project',
        }),
        fields: {
          views(views = []) {
            return [newViewRef, ...views];
          },
        },
      });
    }
  };
  return useDefaultMutation<MutResAddAlertView, MutVarAddAlertView>(ADD_ALERT_VIEW, ADD_ALERT_VIEW_KEY, update);
};
