import { BoxWithImage } from 'components/layout';
import { Box } from 'components/layout/Box';
import { Text } from 'components/typography';
import { FC } from 'react';
import imageSrc from '../images/public_background.webp';
import { useTranslation } from 'react-i18next';
import { PublicPageTitle } from 'components/layout/PublicPageTitle';

const Update: FC = () => {
  const { t } = useTranslation();
  return (
    <BoxWithImage imageSrc={imageSrc} height="100%">
      <Box padding={[8, 12]}>
        <PublicPageTitle>{t('Maintenance')}</PublicPageTitle>
        <Text>{t('We are updating Charp, sorry for the inconvenience')}</Text>
      </Box>
    </BoxWithImage>
  );
};

export default Update;
