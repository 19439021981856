import { Device, LocationReference, Location, Sensor } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { MeasurementGQ, UnitGQ } from 'types/sensor';
import { useMutation } from 'urql';

const ADD_SENSOR_TO_DEVICE_KEY = 'addSensorToDevice';

const ADD_SENSOR_TO_DEVICE = `
  mutation ($organizationId: String!, $deviceId: String!, $data: AddSensorInput!) {
    ${ADD_SENSOR_TO_DEVICE_KEY}(organizationId: $organizationId, deviceId: $deviceId, data: $data) {
      device {
        id
        name
        locations {
          id,
          location {
            id,
            name
          },
          createdAt
        }
        type
        battery
        sensors {
          id
          name
          unit
          measurement
          updatedAt
        }
        updatedAt
        createdAt
      }
      sensor {
        id
        name
        unit
        measurement
        deviceId
        updatedAt
        createdAt
      }
    }
  }
`;
type MutVarAddSensorToDevice = {
  organizationId: string;
  deviceId: string;
  data: {
    name: string;
    unit: UnitGQ;
    measurement: MeasurementGQ;
  };
};

type MutSensor = Pick<Sensor, 'id' | 'name' | 'unit' | 'measurement' | 'deviceId' | 'updatedAt' | 'createdAt'>;
type MutDevice = Pick<Device, 'id' | 'name' | 'type' | 'battery' | 'updatedAt' | 'createdAt'> & {
  sensors: Pick<Sensor, 'id' | 'name' | 'unit' | 'measurement' | 'updatedAt'>[];
  locations: Pick<LocationReference, 'id' | 'createdAt'> & {
    location: Pick<Location, 'id' | 'name'>;
  };
};

type MutResAddSensorToDevice = DefaultQueryResponse<
  typeof ADD_SENSOR_TO_DEVICE_KEY,
  {
    device: MutDevice;
    sensor: MutSensor;
  }
>;

export const useAddSensorToDevice = () => {
  return useMutation<MutResAddSensorToDevice, MutVarAddSensorToDevice>(ADD_SENSOR_TO_DEVICE);
};
