import { FC, useMemo } from 'react';
import type { LayoutProps } from 'styled-system';
import { Menu } from './Menu';
import { useQryOrganization } from 'graphql/generated';
import { useHomeMenu } from './useHomeMenu';

type Props = {
  setScrollPosition?: (scrollPosition: number) => void;
  scrollPosition?: number;
} & LayoutProps;

const MainMenu: FC<Props> = ({ setScrollPosition, scrollPosition, ...props }) => {
  const context = useMemo(() => ({ additionalTypenames: ['User'] }), []);
  const { homeMenu } = useHomeMenu();
  const [{ data: organization }] = useQryOrganization(
    { id: true, name: true },
    {
      context,
    },
  );

  return (
    <Menu
      organization={organization}
      {...props}
      rootMenu={homeMenu}
      setScrollPosition={setScrollPosition}
      scrollPosition={scrollPosition}
    />
  );
};

export { MainMenu };
