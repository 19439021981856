import { AuthContext } from 'context/AuthContext';
import { RoomType, useQryRooms, useQryLocationTypes, Location } from 'graphql/generated';
import { useAddLocationType } from 'graphql/mutation/useAddLocationType';
import { useAddRoom } from 'graphql/mutation/useAddRoom';
import { FC, FormEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Severity } from 'state/snackbarStore';
import { defaultLocationTypes, LocationType } from 'types/location';
import { dispatchErrors } from 'utils/util';
import { Button } from './buttons';
import { Form } from './form';
import { Input } from './form/InputPure';
import { Label } from './form/Label';
import { TextArea } from './form/TextAreaPure';
import { Flex } from './layout/Flex';
import { useSnackbar } from './Snackbar';
import { useTranslation } from 'react-i18next';
import { Body, DrawerFooter } from './layout/drawer/DrawerLayout';
import { SelectInput } from './form/SelectInput';
import { OptionType } from 'types/select';

type Props = {
  location?: Pick<Location, 'name' | 'height' | 'description'> & {
    type: Pick<LocationType, 'id' | 'name'> | undefined | null;
  } & { room: Pick<RoomType, 'id' | 'name'> | undefined | null };
  submitText: string;
  organizationId: string;
  submitting?: boolean;
  onSubmit: (data: { name: string; height?: string; description?: string; typeId?: string; roomId?: string }) => void;
  onClose: () => void;
};

export const LocationForm: FC<Props> = ({ location, submitText, submitting, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();

  const [name, setName] = useState<string>(location?.name ?? '');
  const [height, setHeight] = useState<string>(location?.height ?? '');
  const [description, setDescription] = useState<string>(location?.description ?? '');
  const [typeId, setTypeId] = useState<string | undefined>(location?.type?.id);
  const [roomId, setRoomId] = useState<string | undefined>(location?.room?.id);

  const [customType, setCustomType] = useState<{ id: string; name: string }>();

  const [{ data: locationTypes }] = useQryLocationTypes({ id: true, name: true }, {});
  const [, addLocationType] = useAddLocationType();
  const context = useMemo(() => ({ additionalTypenames: ['Room'] }), []);
  const [{ data: rooms }] = useQryRooms({ id: true, name: true }, { context });
  const [, addRoom] = useAddRoom();

  const locationTypeItems = useMemo(() => {
    const existingItems = locationTypes ?? [];
    if (customType) {
      existingItems.push(customType);
    }

    const filterItems: OptionType[] = existingItems.map((type) => ({
      label: t(type.name, { ns: 'db-values' }),
      value: type.id,
    }));

    for (const type of defaultLocationTypes) {
      if (existingItems.find((item) => item.name === type) === undefined) {
        filterItems.push({
          label: t(type, { ns: 'db-values' }),
          value: type,
          default: true,
        });
      }
    }

    return filterItems;
  }, [locationTypes, customType, t]);

  const roomItems = useMemo(() => {
    const existingItems = rooms ?? [];

    const filterItems: OptionType[] = existingItems.map((room) => ({
      value: room.id,
      label: t(room.name, { ns: 'db-values' }),
    }));
    return filterItems;
  }, [rooms, t]);

  const onNewLocationType = useCallback(
    async (value: string) => {
      const newLocationType = await addLocationType({
        data: {
          name: value,
        },
      });

      if (newLocationType.data?.addLocationType) {
        setTypeId(newLocationType.data?.addLocationType.id);
        setCustomType({ id: newLocationType.data?.addLocationType.id, name: value });
        snackbar.addAlert(
          t('Created new location type: {{type}}', { type: newLocationType.data.addLocationType.name }),
          Severity.SUCCESS,
        );
      }
      if (newLocationType.error) {
        dispatchErrors(snackbar, newLocationType.error, authContext, t);
      }
    },
    [addLocationType, authContext, snackbar, t],
  );

  const onLocationTypeChange = useCallback(async (value: string) => {
    setTypeId(value);
  }, []);

  const onNewRoom = useCallback(
    async (value: string) => {
      const newRoom = await addRoom({
        data: {
          name: value,
        },
      });

      if (newRoom.data?.addRoom) {
        setRoomId(newRoom.data?.addRoom.id);
        snackbar.addAlert(t('Created new room: {{name}}', { name: newRoom.data.addRoom.name }), Severity.SUCCESS);
      }
      if (newRoom.error) {
        dispatchErrors(snackbar, newRoom.error, authContext, t);
      }
    },
    [addRoom, authContext, snackbar, t],
  );

  const onRoomChange = useCallback(async (value: string) => {
    setRoomId(value);
  }, []);

  const onFormSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (name !== '') {
        onSubmit({
          name,
          height,
          description,
          typeId,
          roomId,
        });
      }
    },
    [description, height, name, onSubmit, typeId, roomId],
  );

  return (
    <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }} onSubmit={onFormSubmit}>
      <Body>
        <Flex flexDirection="row" flexWrap="wrap" gap="1.5rem" mb="1.5rem">
          <Flex flexDirection="column" gap="0.25em" flex={1}>
            <Label>{t('Location')}*</Label>
            <Input
              type="text"
              name="locationName"
              value={name}
              placeholder={t('The location name')}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Flex>
          <Flex flexDirection="column" gap="0.25em" flex={1}>
            <Label>{t('Height')}</Label>
            <Input
              type="text"
              name="locationHeight"
              value={height}
              placeholder={t('The height of the location')}
              onChange={(e) => setHeight(e.target.value)}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="row" flexWrap="wrap" gap="1.5rem" mb="1.5rem">
          <Flex flexDirection="column" gap="0.25em" flex={1}>
            <Label>{t('Type')}</Label>
            <SelectInput
              options={locationTypeItems}
              value={locationTypeItems.find((item) => item.value === (typeId || location?.type?.id))}
              onChange={onLocationTypeChange}
              placeholder={t('Type')}
              onNewItem={onNewLocationType}
              creatable={true}
            />
          </Flex>

          <Flex flexDirection="column" gap="0.25em" flex={1}>
            <Label>{t('Room')}</Label>
            <SelectInput
              options={roomItems}
              value={roomItems.find((item) => item.value === (roomId || location?.room?.id))}
              onChange={onRoomChange}
              placeholder={t('The room')}
              onNewItem={onNewRoom}
              creatable={true}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="0.25em">
          <Label>{t('Description')}</Label>
          <TextArea
            width="100%"
            height="7.5rem"
            name="locationDescription"
            value={description}
            placeholder={t('A description of the location')}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Flex>
      </Body>
      <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
        <Button smallPad onClick={onClose} variant="plain">
          {t('actions.Cancel')}
        </Button>
        <Button smallPad type="submit" disabled={name === ''} loading={submitting}>
          {submitText}
        </Button>
      </DrawerFooter>
    </Form>
  );
};
