import { FC, useCallback } from 'react';
import { useAddAlertView } from 'server';
import { View } from 'graphql/generated';
import { AlertViewForm } from './AlertViewForm';
import { useTranslation } from 'react-i18next';

type Props = {
  projectId: string;
  afterCreate?: (view: View) => void;
  onClose: () => void;
};

export const AddAlertViewToProject: FC<Props> = ({ projectId, afterCreate, onClose }) => {
  const { t } = useTranslation();
  const [addAlertView, loading] = useAddAlertView();

  const addAlertViewToProject = useCallback(
    async (name: View['name']) => {
      const alertView = await addAlertView({
        projectId,
        name,
      });
      if (alertView && afterCreate) {
        afterCreate(alertView);
      }
    },
    [projectId, addAlertView, afterCreate],
  );

  return (
    <AlertViewForm
      onSave={addAlertViewToProject}
      saveLabel={t('actions.Add view')}
      loading={loading}
      onClose={onClose}
    />
  );
};
