import type { TFunction } from 'i18next';
import { useCallback, useState } from 'react';

export type FileReaderResult = {
  aborted: boolean;
  hasError: boolean;
  error?: string;
  fileAsString?: string;
};

type Props = {
  onFinish: (data: FileReaderResult) => void;
};
type Return = [boolean, (file: File, encoding: string) => void];

export async function readFile(file: File, encoding: string, t: TFunction): Promise<Error | string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (typeof event.target?.result !== 'string') {
        resolve(new Error(t('File could not be read')));
        return;
      }
      const completeFile = event.target?.result;
      resolve(completeFile);
    };
    reader.onabort = () => {
      resolve(new Error(t('File reading aborted')));
    };
    reader.readAsText(file, encoding);
  });
}

/**
 * @deprecated Not used - just the exported function above
 */
export const useFileReader = ({ onFinish }: Props): Return => {
  const [loading, setLoading] = useState<boolean>(false);

  const onInternalFinish = useCallback(
    (data: FileReaderResult) => {
      setLoading(false);
      onFinish(data);
    },
    [onFinish],
  );

  const onAbort = useCallback(() => {
    onInternalFinish({
      aborted: true,
      hasError: false,
    });
  }, [onInternalFinish]);

  const onFileLoaded = useCallback(
    (event: ProgressEvent<FileReader>) => {
      if (typeof event.target?.result !== 'string') {
        onInternalFinish({
          aborted: false,
          hasError: true,
          error: 'File could not be read',
        });
        return;
      }
      const completeFile = event.target?.result;
      onInternalFinish({
        aborted: false,
        hasError: false,
        fileAsString: completeFile,
      });
    },
    [onInternalFinish],
  );

  const internalReadFile = useCallback(
    (file: File, encoding: string) => {
      const fileReader = new FileReader();
      fileReader.onloadstart = () => setLoading(true);
      fileReader.onabort = () => onAbort();
      fileReader.onload = (event) => onFileLoaded(event);
      fileReader.readAsText(file, encoding);
    },
    [onAbort, onFileLoaded],
  );

  return [loading, internalReadFile];
};
