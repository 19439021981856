import { css } from '@styled-system/css';
import { Badge } from 'components/Badge';
import { Flex } from 'components/layout/Flex';
import { ResponsiveImage } from 'components/ResponsiveImage';
import { CardTitle } from 'components/typography/CardTitle';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { LayoutProps } from 'styled-system';
import { layout } from 'styled-system';
import { vendorImage, vendorToText, VendorType } from 'types/vendor';

const Card = styled.div<LayoutProps>`
  width: 100%;
  ${css({ padding: 1 })}
  cursor: pointer;
  ${css({
    borderWidth: 0.5,
    borderColor: 'white',
    borderStyle: 'solid',
    borderRadius: 'md',
  })}

  &:hover {
    ${css({
      borderColor: 'grey.500',
    })}
  }

  ${layout}
`;

type Props = ComponentPropsWithoutRef<typeof Card> & {
  vendor: VendorType;
};

export const VendorInfoCard: FC<Props> = ({ vendor, ...props }) => {
  const { t } = useTranslation();
  return (
    <Card {...props}>
      <ResponsiveImage image={vendorImage(vendor)} />
      <Flex mt="2">
        <Badge variant="branded">{t('Data integration')}</Badge>
      </Flex>
      <Flex>
        <CardTitle>{vendorToText(vendor)}</CardTitle>
      </Flex>
    </Card>
  );
};
