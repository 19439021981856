import { Maybe, Project, ProjectUser, useQryProject, useQryProjects } from 'graphql/generated';
import { useHistory } from 'hooks/useHistoryEnhanced';
import { isEmpty } from 'lodash';
import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { auth } from 'utils/Authentication';

export type ProjectType = Pick<
  Project,
  | 'id'
  | 'name'
  | 'alert'
  | 'maintenance'
  | 'alertCascadeTime'
  | 'description'
  | 'updatedAt'
  | 'createdAt'
  | 'organizationId'
  | 'isShareable'
> & {
  users?: Maybe<
    Array<
      Pick<
        ProjectUser,
        'id' | 'firstName' | 'lastName' | 'email' | 'communicationChannel' | 'createdAt' | 'role' | 'userId'
      >
    >
  >;
} & {
  views?: { id: string; name: string; type: string; updatedAt: string; projectId: string }[];
};

type ProjectContextType = {
  project: ProjectType | undefined;
  projects: ProjectType[] | undefined;
  loading: boolean;
  setProject: (newProject: ProjectType) => void;
  setDefaultProject: () => void;
  refetchProjects: () => void;
};

export const ProjectContext = createContext<ProjectContextType>({
  project: undefined,
  projects: undefined,
  loading: false,
  setProject: () => {},
  setDefaultProject: () => {},
  refetchProjects: () => {},
});

const queryColumns = {
  id: true,
  name: true,
  alert: true,
  maintenance: true,
  alertCascadeTime: true,
  description: true,
  updatedAt: true,
  createdAt: true,
  organizationId: true,
  isShareable: true,
  users: {
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    communicationChannel: true,
    createdAt: true,
    role: true,
    userId: true,
  },
  views: {
    id: true,
    name: true,
    type: true,
    updatedAt: true,
    projectId: true,
  },
} as const;

export const ProjectProvider: FC = (props) => {
  const [project, setProject] = useState<ProjectType | undefined>(undefined);
  const [{ data: projects, fetching: loading }, refetchProjects] = useQryProjects(queryColumns, {});
  const history = useHistory();

  const match = useRouteMatch<{ projectId: string }>('/projects/:projectId');
  match?.params.projectId && localStorage.setItem('projectId', match?.params.projectId);
  const projectId = match ? match.params.projectId : localStorage.getItem('projectId');

  const isAuthenticated = auth.isAuthenticated();
  const [{ data: projectQuery }] = useQryProject(
    {
      id: true,
      name: true,
      alert: true,
      maintenance: true,
      alertCascadeTime: true,
      description: true,
      updatedAt: true,
      createdAt: true,
      organizationId: true,
      isShareable: true,
      users: {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        communicationChannel: true,
        createdAt: true,
        role: true,
        userId: true,
      },
      views: {
        id: true,
        name: true,
        type: true,
        updatedAt: true,
        projectId: true,
      },
    },
    { uuid: projectId ?? '' },
    { pause: isEmpty(projectId) },
  );

  useEffect(() => {
    if (projectQuery?.isShareable && !isAuthenticated) {
      setProject(projectQuery);
    }
  }, [isAuthenticated, projectQuery]);

  useEffect(() => {
    if (
      projectId &&
      projects &&
      !projects?.find((project) => project.id === projectId) &&
      history.location.pathname.startsWith('/projects/')
    ) {
      localStorage.setItem('projectId', '');
      history.push('/404');
    }
  }, [projectId, history, projects]);

  const projectExists = projects?.find((project) => project.id === projectId);

  const setDefaultProject = useCallback(async () => {
    await refetchProjects();
    setProject(
      projects
        ?.filter((project) => project.id !== localStorage.getItem('projectId'))
        .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))[0],
    );
  }, [projects, refetchProjects]);

  const filteredProject = useMemo(
    () =>
      projectId && projectExists ? projectExists : projects?.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))[0],
    [projectExists, projectId, projects],
  );

  return (
    <ProjectContext.Provider
      value={{ project: project ?? filteredProject, projects, loading, setProject, setDefaultProject, refetchProjects }}
      {...props}
    />
  );
};
