import { ComponentPropsWithoutRef, FC } from 'react';
import { MdClose } from 'react-icons/md';
import { IconButton } from './IconButton';

type BtnProps = {
  color?: string;
};

type Props = BtnProps &
  Pick<ComponentPropsWithoutRef<'button'>, 'onClick' | 'disabled'> &
  Omit<ComponentPropsWithoutRef<typeof MdClose>, 'onClick'>;

export const CloseButton: FC<Props> = ({ color = 'subtle', onClick, disabled, ...props }) => {
  return (
    <IconButton type="button" disabled={disabled} onClick={onClick} color={color}>
      <MdClose size={24} style={{ margin: '0.25rem' }} {...props} />
    </IconButton>
  );
};
