import { css } from '@styled-system/css';
import { PageSubtitle } from 'components/typography/PageSubtitle';
import { HeaderTitle } from 'components/typography/PageTitle';
import { FC, ReactNode } from 'react';
import { MdArrowBack } from 'react-icons/md';
import styled from 'styled-components';
import { Flex } from './Flex';
import { Text } from 'components/typography';
import { colors } from 'theme/colors';
import { useTranslation } from 'react-i18next';
import { headerHeight } from './drawer/DrawerLayout';
import useIsMobile from 'hooks/useIsMobile';

const BackButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.5em;
  ${css({
    color: 'base.600',
    borderRight: '1px solid',
    borderColor: 'base.400',
  })}
  transition: color 96ms ease;
  @media (min-width: 601px) {
    padding: 0 1.5em;
  }
  @media (max-width: 600px) {
    padding-left: 0.5rem;
  }
  cursor: pointer;
`;

const BackIcon = styled(MdArrowBack)`
  margin-right: 0.5em;
  ${css({
    color: 'base.600',
  })}
`;

type StyleProps = {
  variant?: 'regular' | 'notes';
};

export const SHeader = styled.header<StyleProps>`
  padding: 0;
  position: relative;
  z-index: 20;
`;

export const SMain = styled.div<StyleProps>`
  ${(p) =>
    css({
      display: 'flex',
      backgroundColor: p.variant === 'notes' ? 'secondary.200' : 'base.200',
      borderBottom: '1px solid',
      borderColor: p.variant === 'notes' ? 'secondary.700' : 'base.400',
      padding: 0,
      position: 'relative',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: headerHeight + 'px',
    })}
`;

export const SSub = styled.header<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  ${(p) =>
    css({
      borderBottom: '1px solid',
      borderColor: p.variant === 'notes' ? 'secondary.700' : 'base.400',
      height: headerHeight + 'px',
    })}
  @media (max-width: 600px) {
    height: auto;
    padding-right: 0;
  }
`;

export const SSubTitle = styled.header<StyleProps>`
  display: flex;
  align-items: center;
  ${css({
    padding: '0 24px',
    borderRight: '1px solid',
    borderColor: colors.base[400],
    height: '100%',
  })}
  @media (max-width: 600px) {
    border-right: none;
  }
`;

type Props = {
  title: ReactNode;
  subTitle?: string;
  subTitleNoView?: string;
  subHeader?: string | ReactNode;
  controls?: ReactNode;
  onClickBack?: () => void;
  information?: ReactNode;
  children?: ReactNode;
  variant?: 'regular' | 'notes';
  tabsHeader?: boolean;
};

export const Header: FC<Props> = ({
  title,
  subTitle,
  subTitleNoView,
  subHeader,
  controls,
  onClickBack,
  information,
  children,
  variant,
  tabsHeader = false,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  return (
    <SHeader variant={variant}>
      <SMain variant={variant}>
        <Flex
          alignItems="center"
          height="100%"
          {...(!tabsHeader && { borderRight: '1px solid' })}
          borderColor={variant === 'notes' ? colors.secondary[700] : colors.base[400]}
        >
          {onClickBack && (
            <BackButton onClick={onClickBack} type="button">
              <BackIcon />
              {!isMobile && (
                <Text variant="large" fontWeight={600}>
                  {t('Back')}
                </Text>
              )}
            </BackButton>
          )}
          {typeof title === 'string' ? <HeaderTitle>{title}</HeaderTitle> : title}
          {children}
        </Flex>
        <Flex gap="1rem" alignItems="center" paddingRight="0.375rem">
          {controls}
        </Flex>
      </SMain>

      {(information || subTitleNoView || subTitle || subHeader) && (
        <SSub style={{ marginBottom: isMobile ? 0 : '2rem' }}>
          <Flex height="100%" width="100%" flexDirection={`${isMobile ? 'column' : 'row'}`}>
            <Flex>
              {information && (
                <SSubTitle>
                  <PageSubtitle>{information}</PageSubtitle>
                </SSubTitle>
              )}
              {subTitleNoView && (
                <SSubTitle>
                  <PageSubtitle>{subTitleNoView}</PageSubtitle>
                </SSubTitle>
              )}
              {subTitle && (
                <SSubTitle>
                  <PageSubtitle>{t('{{view}} view', { view: subTitle })}</PageSubtitle>
                </SSubTitle>
              )}
            </Flex>
            {subHeader}
          </Flex>
        </SSub>
      )}
    </SHeader>
  );
};
