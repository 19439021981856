import { css } from '@styled-system/css';
import { Avatar } from 'components/Avatar';
import { ContentRelativeTime } from 'components/ContentRelativeTime';
import { Box } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import { Note, User } from 'graphql/generated';
import { ComponentPropsWithoutRef, VFC } from 'react';
import styled from 'styled-components';

const Container = styled(Box)(() =>
  css({
    bg: 'note.200',
    fontSize: 'lg',
    p: '1rem 1.25rem',
    color: 'note.700',
    '&:first-child': {
      borderRadius: '4px 4px 0 0',
    },
    '&:lasy-child': {
      borderRadius: '0 0 4px 4px',
    },
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': {
      bg: 'note.400',
    },
  }),
);

const Title = styled.h3({
  lineHeight: 1,
  display: 'block',
  width: '80%',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const Time = styled(ContentRelativeTime)(
  css({
    opacity: 0.5,
    fontSize: 'xs',
  }),
);

type Props = ComponentPropsWithoutRef<typeof Container> & {
  note: Pick<Note, 'id' | 'title' | 'createdAt' | 'updatedAt'> & {
    owner: Pick<User, 'email' | 'firstName' | 'lastName'>;
  };
};

export const NoteListItem: VFC<Props> = ({ note, ...props }) => {
  return (
    <Container {...props}>
      <Title title={note.title}>{note.title}</Title>
      <Flex justifyContent="space-between" alignItems="flex-end" gap="1rem" flexWrap="wrap">
        <Time createdDate={note.createdAt} updatedDate={note?.updatedAt} />
        <Avatar size="1.25rem" user={note.owner} />
      </Flex>
    </Container>
  );
};
