import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { TypographyProps } from 'styled-system';
import { typography } from 'styled-system';

export const ErrorMessage = styled.span<TypographyProps>(
  typography,
  css({
    fontSize: ['xxs', 'xs', 'sm'],
    color: 'red.400',
    lineHeight: 'normal',
  }),
);
