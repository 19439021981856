import { useLayoutEffect } from 'react';

export const useOnClickOutside = <T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  handler: (event: MouseEvent | TouchEvent) => void,
) => {
  useLayoutEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
