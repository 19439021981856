import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { auth } from 'utils/Authentication';
import { extractFromEnv } from 'utils/environment';

const httpLink = createHttpLink({
  uri: extractFromEnv('VITE_CHARP_API', 'https://api.charp.be/graphql'),
});

const authLink = setContext((_, { headers }) => {
  const token = auth.getAccessToken();
  const projectId = localStorage.getItem('projectId');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-project-id': projectId ?? '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        notifications: {
          keyArgs: [],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
    Note: {
      fields: {
        files: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
