import type { TFunction } from 'i18next';
import serisLogo from 'images/seris.svg';

export interface Addon {
  id: string;
  type: AddonType;
  name: AddonName;
  data: AddonData;
  createdAt: string;
}

export enum ResponseTime {
  IMMEDIATE = 'immediate',
  '5MIN' = '5m',
  '10MIN' = '10m',
  '15MIN' = '15m',
  '30MIN' = '30m',
  HOUR = '1h',
}

export const getResponseTimes = () => {
  return Object.values(ResponseTime).map((responseTime) => ({
    key: responseTime,
    value: responseTime,
  }));
};

export interface ProjectAddon {
  id: string;
  active: boolean;
  addonId: string;
  addon?: Addon;
  data: {
    responseTime: ResponseTime;
  };
  updatedAt: string;
  createdAt: string;
}

export enum AddonType {
  CONTROL_ROOM = 'Control room',
}

export enum AddonName {
  SERIS = 'seris',
}

export const addonPerType = {
  [AddonType.CONTROL_ROOM]: [AddonName.SERIS],
};

export interface AddonInfo {
  name: AddonName;
  type: AddonType;
  image: string;
  description: string;
}

export const addonInfo: { [key: string]: AddonInfo } = {
  [AddonName.SERIS]: {
    name: AddonName.SERIS,
    type: AddonType.CONTROL_ROOM,
    image: serisLogo,
    description: 'Seris',
  },
};

export const addons: AddonInfo[] = Object.values(addonInfo);

export const prettifyAddonType = (addonType: string, t: TFunction): string => {
  switch (addonType) {
    case AddonType.CONTROL_ROOM:
      return t('Control room');
    default:
      return t('Unknown addon');
  }
};

export type SerisAddon = {
  name: AddonName.SERIS;
  data: SerisAddonData;
};

export type AddonData = SerisAddonData;

export interface SerisAddonData {
  customerId: string;
}
