import { Card } from 'components/Card';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { viewConfigurations, ViewType } from 'types/view';

const Container = styled.div`
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(auto-fill, minmax(11.4rem, 1fr));
  padding: 1.5rem;
`;

type Props = {
  onSelect: (type: ViewType) => void;
  onClose?: () => void;
};

export const ViewSelection: FC<Props> = (props) => {
  const { t } = useTranslation();
  const views = useMemo(() => Object.values(viewConfigurations), []);

  return (
    <Container>
      {views
        .filter((view) => ![ViewType.ALERT].includes(view.type))
        .map((view) => (
          <Card
            key={view.type}
            title={t(view.displayName)}
            onClick={() => props.onSelect(view.type)}
            onClose={() => props?.onClose?.()}
            disabled={!view.enabled}
            description={t(view.description)}
            moved={view.moved}
            linkTo={view.linkTo}
          />
        ))}
    </Container>
  );
};
