import { sizes } from './sizes';
import { space } from './space';
import { radii } from './radii';
import { colors } from './colors';
import { breakpoints } from './breakpoints';
import { borderWidths } from './borderWidths';
import { shadows } from './shadows';
import {
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
} from './typography';

export const theme = {
  space,
  sizes,
  fonts,
  radii,
  colors,
  fontSizes,
  breakpoints,
  fontWeights,
  lineHeights,
  borderWidths,
  letterSpacings,
  shadows,
};

export type Theme = typeof theme;
