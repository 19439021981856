import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { BackgroundColorProps, ColorProps, MarginProps } from 'styled-system';
import { background, color, compose, margin, variant } from 'styled-system';

type VariantProps = {
  variant: 'positive' | 'danger' | 'branded' | 'subtle';
};

export const Badge = styled.span<BackgroundColorProps & ColorProps & MarginProps & VariantProps>(
  compose(background, color, margin),
  css({
    borderRadius: '0.25em',
    padding: '0.25em',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 'sm',
    lineHeight: 1,
    letterSpacing: -0.125,
  }),
  variant({
    variants: {
      positive: {
        color: 'white',
        bg: 'positie',
      },
      danger: {
        color: 'white',
        bg: 'danger',
      },
      branded: {
        color: 'white',
        bg: 'brandLight',
      },
      subtle: {
        color: 'white',
        bg: 'subtle',
      },
    },
  }),
);
