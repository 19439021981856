import { MutationAddRefToNoteArgs } from 'graphql/generated';
import { useMutation } from 'urql';

const ADD_REF_TO_NOTE_KEY = 'addRefToNote';

const ADD_REF_TO_NOTE = `
  mutation ($data: NoteRefInput!) {
    ${ADD_REF_TO_NOTE_KEY}(data: $data) {
      id
    }
  }
`;

export const useAddRefToNote = () => {
  return useMutation<MutationAddRefToNoteArgs>(ADD_REF_TO_NOTE);
};
