import React, { useState } from 'react';
import type { MarginProps } from 'styled-system';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { colors } from 'theme/colors';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

import type {
  DropdownIndicatorProps,
  OptionProps,
  StylesConfig,
  OptionsOrGroups,
  GroupBase,
  ActionMeta,
} from 'react-select';
import { MdOutlineSearch } from 'react-icons/md';
import { Sensor, ProjectSensor, ProjectSensorWithRule } from 'graphql/generated';
import { Location } from 'types/location';

export type PrevOptions = OptionsOrGroups<unknown, GroupBase<unknown>>;

export type SharedSelectProps<T> = {
  onChange: (values: T | T[]) => void;
  loadOptions: (
    search: string,
    prevOptions: PrevOptions,
  ) => Promise<{
    options: T[];
    hasMore?: boolean;
    additional?: unknown;
  }>;
  loading?: boolean;
  placeholder?: string;
  selected?: T[];
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onInputChange?: (inputValue: string) => void;
  onMenuClose?: () => void;
  isMulti?: boolean;
  inputValue?: string;
  Option: (props: OptionProps) => JSX.Element;
} & MarginProps;

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SMdOutlineSearch />
      </components.DropdownIndicator>
    )
  );
};

export const SMdOutlineSearch = styled(MdOutlineSearch)`
  position: absolute;
  font-size: 1.5rem;
  right: 1.125rem;
  bottom: 0.5rem;
  pointer-events: none;
  ${css({
    color: colors.base[600],
  })}
`;

export const customAsyncPaginateStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    fontSize: '1.125rem',
    fontWeight: 400,
    background: 'transparent',
    border: 'none',
    color: colors.base[900],
    height: '100%',
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    color: colors.base[900],
    padding: '0.5rem 0 0.5rem 0.5rem',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
  }),
  container: (provided) => ({
    ...provided,
    border: '1px solid',
    borderColor: colors.base[600],
    height: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: 0,
    padding: 0,
    marginTop: '1px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.base[400] : '',
    color: colors.base[900],
    borderBottom: '1px solid',
    borderColor: colors.base[400],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.base[200],
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

export const SharedSelect = <T extends ProjectSensor | Sensor | ProjectSensorWithRule | Location>({
  onChange,
  placeholder,
  loadOptions,
  selected = [],
  onInputChange,
  loading,
  isMulti = true,
  onMenuClose,
  inputValue,
  Option,
  ...props
}: SharedSelectProps<T>) => {
  const [cacheTrigger, setCacheTrigger] = useState(0);

  return (
    <AsyncPaginate
      debounceTimeout={300}
      isMulti={isMulti}
      inputValue={inputValue}
      components={{ DropdownIndicator, Option, MultiValue: () => null }}
      styles={customAsyncPaginateStyles}
      placeholder={placeholder || ''}
      closeMenuOnSelect={!isMulti}
      onChange={onChange as (newValue: unknown, actionMeta: ActionMeta<unknown>) => void}
      loadOptions={loadOptions}
      onInputChange={onInputChange}
      isClearable={false}
      cacheUniqs={[cacheTrigger]}
      isLoading={loading}
      onMenuClose={() => {
        setCacheTrigger((prev) => prev + 1);
        if (onMenuClose) onMenuClose();
      }}
      getOptionValue={(option) => (option as T).id}
      value={selected}
      {...props}
    />
  );
};
