import { useTranslation } from 'react-i18next';
import { Button } from 'components/buttons';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useQryUsers } from 'graphql/generated';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { FC, useCallback, useMemo, useState } from 'react';
import { useAddProjectUsers } from 'server';
import { ProjectUser } from 'types/projectUser';
import { ProjectUSerModalTable, SelectedUser } from './ProjectUserModalTable';
import { Loader } from 'components/loader/loader';

type ProjectUserDrawerProps = {
  projectId: string;
  projectUsers?: ProjectUser[];
  callback?: () => void;
};

type Props = DrawerComponentProps & ProjectUserDrawerProps;

export const ProjectUserDrawer: FC<Props> = ({ requestClose, projectId, projectUsers, callback }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();

  const [selected, setSelectedUsers] = useState<SelectedUser[]>([]);
  const [{ data: users, fetching: loadingUsers }] = useQryUsers(
    {
      id: true,
      firstName: true,
      lastName: true,
    },
    {},
  );
  const [addProjectUsers, loading] = useAddProjectUsers();

  const filteredUsers = useMemo(() => {
    const userIds = projectUsers?.map((user) => user.userId) ?? [];
    const filteredUsers = users?.filter((user) => !userIds.includes(user.id));
    return filteredUsers ?? [];
  }, [projectUsers, users]);

  const onSubmit = useCallback(async () => {
    if (selected.length > 0) {
      const data = selected.map((selectedUser) => ({
        id: selectedUser.user.id,
        role: selectedUser.role,
      }));
      await addProjectUsers({
        projectId,
        metadata: data,
      });
      requestClose();
      if (callback) {
        callback();
      }
    }
  }, [addProjectUsers, callback, projectId, requestClose, selected]);

  return (
    <DrawerLayout title={t('Add member')} onClose={requestClose}>
      <Body>
        {loadingUsers ? (
          <Loader />
        ) : (
          <ProjectUSerModalTable users={filteredUsers} selectedUsers={selected} setSelectedUsers={setSelectedUsers} />
        )}
      </Body>
      <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
        <Button smallPad onClick={() => requestClose()} variant="plain">
          {t('actions.Cancel')}
        </Button>
        <Button smallPad loading={loading} onClick={onSubmit} disabled={selected.length === 0}>
          {t('actions.Add member', { count: selected.length })}
        </Button>
      </DrawerFooter>
    </DrawerLayout>
  );
};
