import { FC, useCallback } from 'react';
import { useAddLightBudgetView } from 'server';
import { View } from 'graphql/generated';
import { Project } from 'types/project';
import { LightBudgetForm, LightBudgetViewData } from './LightBudgetForm';
import { useTranslation } from 'react-i18next';

type Props = {
  projectId: Project['id'];
  afterCreate: (view: View) => void;
  onClose: () => void;
};

export const AddLightBudgetView: FC<Props> = ({ projectId, afterCreate, onClose }) => {
  const { t } = useTranslation();
  const [addLightBudgetView, loading] = useAddLightBudgetView();

  const onAdd = useCallback(
    async ({
      name,
      sensorIds,
      targetLifetime,
      roleOfColor,
      sensitivity,
      exposureTime,
      uv,
      period,
      autoAddNewDevices,
    }: LightBudgetViewData) => {
      const lightBudgetView = await addLightBudgetView({
        projectId,
        data: {
          name,
          projectSensorIds: sensorIds,
          period,
          control: {
            targetLifetime,
            roleOfColor,
            exposureTime,
            uv,
            sensitivity,
          },
          autoAddNewDevices,
        },
      });
      afterCreate(lightBudgetView);
    },
    [addLightBudgetView, afterCreate, projectId],
  );

  return (
    <LightBudgetForm
      projectId={projectId}
      onSave={onAdd}
      onClose={onClose}
      saveLabel={t('actions.Add view')}
      loading={loading}
    />
  );
};
