import { useSnackbar } from 'components/Snackbar';
import { View, useQryView } from 'graphql/generated';
import { FC, useCallback } from 'react';
import { useEditViewName } from 'server';
import { useEditCumulativeLightViewSensors } from 'server/mutations/view/useEditCumulativeLightViewSensors';
import { Severity } from 'state/snackbarStore';
import { viewConfigurations, ViewType } from 'types/view';
import { Project } from 'types/project';
import { Sensor } from 'types/sensor';
import { CumulativeLightViewForm, LightView } from './CumulativeLightViewForm';
import { useTranslation } from 'react-i18next';

type Props = {
  projectId: Project['id'];
  view: Pick<View, 'name' | 'id' | 'type' | 'autoAddNewDevices'>;
  afterEdit: () => void;
  onClickDelete?: () => void;
};

export const EditCumulativeLightView: FC<Props> = ({ projectId, view, afterEdit, onClickDelete }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [editViewName, updatingName] = useEditViewName();
  const [{ data: viewWithSensors }] = useQryView(
    {
      id: true,
      name: true,
      type: true,
      sensors: {
        id: true,
        name: true,
        unit: true,
        location: {
          id: true,
          name: true,
        },
        sensorId: true,
        measurement: true,
        updatedAt: true,
      },
      autoAddNewDevices: true,
      updatedAt: true,
    },
    { uuid: view.id },
    {},
  );
  const [editCumulativeLightViewSensors, updating] = useEditCumulativeLightViewSensors();

  const loading = updatingName || updating;

  const save = useCallback(
    async (name: View['name'], sensorIds: Sensor['id'][], autoAddNewDevices?: boolean) => {
      try {
        let updated = false;
        if (name !== view.name) {
          await editViewName({
            name,
            viewId: view.id,
          });
          updated = true;
        }
        const currentSensor = viewWithSensors?.sensors?.map((sensor) => sensor.id);
        const changedSensors = JSON.stringify(currentSensor) !== JSON.stringify(sensorIds);
        if (changedSensors || autoAddNewDevices !== view.autoAddNewDevices) {
          await editCumulativeLightViewSensors({
            viewId: view.id,
            data: {
              projectSensorIds: sensorIds,
              autoAddNewDevices,
            },
          });
          updated = true;
        }
        if (updated) {
          snackbar.addAlert(
            t('{{view}} view saved', { view: t(viewConfigurations[view.type as ViewType].displayName) }),
            Severity.SUCCESS,
          );
        }
        if (afterEdit) {
          afterEdit();
        }
      } catch (e) {
        snackbar.addAlert(
          t('Failed to save changes to {{view}} view', {
            view: t(viewConfigurations[view.type as ViewType].displayName),
          }),
          Severity.ERROR,
        );
      }
    },
    [
      view.name,
      view.id,
      view.type,
      viewWithSensors?.sensors,
      afterEdit,
      editViewName,
      editCumulativeLightViewSensors,
      snackbar,
      t,
    ],
  );

  return (
    <CumulativeLightViewForm
      projectId={projectId}
      view={viewWithSensors as LightView}
      saveLabel={t('actions.Save')}
      onSave={save}
      onClose={afterEdit}
      loading={loading}
      onClickDelete={onClickDelete}
    />
  );
};
