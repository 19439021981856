import { Flex } from 'components/layout/Flex';
import { ChangeEvent, FC, TextareaHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { Theme } from 'theme';
import { Label } from './Label';
import { css } from '@styled-system/css';

type InputProps = {
  valid?: boolean;
} & { theme: Theme };

const Stextarea = styled.textarea<InputProps>`
  width: 100%;
  outline: none;
  box-sizing: border-box;
  resize: vertical;
  height: 30vh;
  font-size: medium;
  padding-left: 0.375em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: 1px solid
    ${(p) =>
      (p.valid === true && p.theme.colors.green[500]) ||
      (p.valid === false && p.theme.colors.red[500]) ||
      p.theme.colors.grey[500]};
  font-family: ${(p) => p.theme.fontFamily};
  ::placeholder {
    ${css({
      color: 'grey.500',
    })}
  }
  &:active,
  :focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px
      ${(p) =>
        (p.valid === true && p.theme.colors.green[500]) ||
        (p.valid === false && p.theme.colors.red[500]) ||
        p.theme.colors.black};
  }
`;

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  validate: (value: string) => boolean;
};

export const TextArea: FC<Props> = (props) => {
  const [valid, setValid] = useState<boolean | undefined>(undefined);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (newValue === '' && !props.required) {
      setValid(undefined);
    } else if (newValue !== '' && props.validate(newValue)) {
      setValid(true);
    } else {
      setValid(false);
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <Flex flexDirection="column" mb={4}>
      <Flex mb={1}>
        <Label>
          {props.required && '*'} {props.label}
        </Label>
      </Flex>
      <Stextarea
        valid={valid}
        value={props.value}
        placeholder={props.placeholder}
        onChange={onChange}
        required={props.required}
      >
        {props.children}
      </Stextarea>
    </Flex>
  );
};
