import { DefaultQueryResponse } from 'graphql/query/default';
import { Project } from 'types/project';
import { useMutation } from 'urql';

const EDIT_PROJECT_MAINTENANCE_KEY = 'editProjectMaintenance';

const EDIT_PROJECT_MAINTENANCE = `
  mutation ($projectId: String!, $maintenance: Boolean!, $maintenanceEndDate: DateTimeISO) {
    ${EDIT_PROJECT_MAINTENANCE_KEY}(uuid: $projectId, data: { item: $maintenance, endDate: $maintenanceEndDate}) {
      id
      maintenance
      maintenanceEndDate
    }
  }
`;

type MutVarEditProjectMaintenance = {
  projectId: string;
  maintenance: boolean;
  maintenanceEndDate?: Date;
};

type MutResEditProjectMaintenance = DefaultQueryResponse<
  typeof EDIT_PROJECT_MAINTENANCE_KEY,
  Pick<Project, 'id' | 'maintenance' | 'maintenanceEndDate'>
>;

export const useEditProjectMaintenance = () => {
  return useMutation<MutResEditProjectMaintenance, MutVarEditProjectMaintenance>(EDIT_PROJECT_MAINTENANCE);
};
