export const downloadFile = (blob: Blob, name: string) => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const printPage = () => {
  const css = '@page { size: landscape; }';
  const head = document.head || document.getElementsByTagName('head')[0];
  const newStyle = document.createElement('style');
  newStyle.type = 'text/css';
  newStyle.media = 'print';
  newStyle.id = 'charp-print-css';
  newStyle.appendChild(document.createTextNode(css));
  head.appendChild(newStyle);
  window.onafterprint = removePrintCss;
  window.print();
}

export const removePrintCss = () => {
  const printStyle = document.getElementById('charp-print-css');
  printStyle?.remove();
}
