import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, LayoutProps } from 'styled-system';
import { color, compose, layout } from 'styled-system';
import { fonts } from 'theme/typography';

export const PublicPageTitle = styled.h2<ColorProps & LayoutProps>`
  font-weight: 600;
  margin-bottom: 2rem;
  ${css({
    fontFamily: fonts.headerTitle,
    fontSize: ['2rem', '2.5rem'],
  })}
  ${compose(color, layout)};
`;
