import { DefaultQueryResponse } from 'graphql/query/default';
import { LocationType } from 'types/location';
import { useMutation } from 'urql';

const ADD_LOCATION_TYPE_KEY = 'addLocationType';

const ADD_LOCATION_TYPE = `
  mutation ($data: LocationTypeInput!) {
    ${ADD_LOCATION_TYPE_KEY}(data: $data) {
      id,
      name,
      color,
      updatedAt,
      createdAt,
    }
  }
`;

type MutVarAddLocationType = {
  data: {
    name: string;
  };
};

export const useAddLocationType = () => {
  return useMutation<
    DefaultQueryResponse<
      typeof ADD_LOCATION_TYPE_KEY,
      Pick<LocationType, 'id' | 'name' | 'color' | 'updatedAt' | 'createdAt'>
    >,
    MutVarAddLocationType
  >(ADD_LOCATION_TYPE);
};
