import { SampleTime, UTCOffset } from '@charphq/types';
import { format, min, max, startOfDay, sub, add, startOfHour } from 'date-fns';
import type { TFunction } from 'i18next';
import { Domain } from 'types/data';

export function prettyPrintDate(date: Date, locale: Locale | undefined): string {
  // May 15, 2023 at 19:20
  const dateFormat = "MMM d, yyyy '@' H:mm";
  return format(date, dateFormat, { locale });
}

export function prettyPrintDateDomain(domain: Domain<Date>, t: TFunction): string {
  // January 1, 2017
  const dateFormat = 'MMMM d, yyyy';
  return t('from {{from}} to {{to}}', { from: format(domain[0], dateFormat), to: format(domain[1], dateFormat) });
}

export function biggestDate(a: Date, b: Date): Date {
  return max([a, b]);
}

export function smallestDate(a: Date, b: Date): Date {
  return min([a, b]);
}

export type TimeIncrement = {
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  weeks?: number;
  months?: number;
};

export function sampleTimeToIncrement(sampleTime: SampleTime): TimeIncrement {
  switch (sampleTime) {
    case SampleTime.FIVE:
      return { minutes: 5 };
    case SampleTime.TEN:
      return { minutes: 10 };
    case SampleTime.FIFTEEN:
      return { minutes: 15 };
    case SampleTime.THIRTY:
      return { minutes: 30 };
    case SampleTime.HOUR:
      return { hours: 1 };
    case SampleTime.THREE_HOURS:
      return { hours: 3 };
    case SampleTime.SIX_HOURS:
      return { hours: 6 };
    case SampleTime.TWELVE_HOURS:
      return { hours: 12 };
    case SampleTime.DAY:
      return { days: 1 };
    case SampleTime.WEEK:
      return { weeks: 1 };
    case SampleTime.MONTH:
      return { months: 1 };
  }
}

export function datesBetweenIncl(startDate: Date, endDate: Date, increment: TimeIncrement, t: TFunction): Date[] {
  const incrementHasValue = (Object.keys(increment) as Array<keyof TimeIncrement>).reduce(
    (acc, key) => acc || (increment[key] ?? 0) > 0,
    false,
  );
  if (!incrementHasValue) {
    throw new Error(t('Increment must have at least one value greater than 0'));
  }
  const dates: Date[] = [];
  let currentDate = new Date(startOfHour(startDate));

  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = add(currentDate, increment);
  }

  return dates;
}

export type PeriodSelection = {
  key: string;
  label: string;
  period: Domain<Date>;
};

export function getDefaultPeriods(relativeEndDate: Date): PeriodSelection[] {
  const pastDayPeriod: Domain<Date> = [startOfDay(sub(relativeEndDate, { days: 1 })), relativeEndDate];
  const pastWeekPeriod: Domain<Date> = [startOfDay(sub(relativeEndDate, { weeks: 1 })), relativeEndDate];
  const pastTwoWeeksPeriod: Domain<Date> = [startOfDay(sub(relativeEndDate, { weeks: 2 })), relativeEndDate];
  const pastMonthPeriod: Domain<Date> = [startOfDay(sub(relativeEndDate, { months: 1 })), relativeEndDate];
  const pastThreeMonthsPeriod: Domain<Date> = [startOfDay(sub(relativeEndDate, { months: 3 })), relativeEndDate];
  const pastYearPeriod: Domain<Date> = [startOfDay(sub(relativeEndDate, { years: 1 })), relativeEndDate];
  return [
    {
      key: genPeriodKey(pastDayPeriod),
      label: 'Past day',
      period: pastDayPeriod,
    },
    {
      key: genPeriodKey(pastWeekPeriod),
      label: 'Past week',
      period: pastWeekPeriod,
    },
    {
      key: genPeriodKey(pastTwoWeeksPeriod),
      label: 'Past 2 weeks',
      period: pastTwoWeeksPeriod,
    },
    {
      key: genPeriodKey(pastMonthPeriod),
      label: 'Past month',
      period: pastMonthPeriod,
    },
    {
      key: genPeriodKey(pastThreeMonthsPeriod),
      label: 'Past 3 months',
      period: pastThreeMonthsPeriod,
    },
    {
      key: genPeriodKey(pastYearPeriod),
      label: 'Past year',
      period: pastYearPeriod,
    },
  ];
}

export type PeriodKey = string;

export function genPeriodKey(period: Domain<Date>): PeriodKey {
  return `${period[0].toUTCString()}-${period[1].toUTCString()}`;
}

export function searchPeriod(key: PeriodKey, periodSelections: PeriodSelection[]): PeriodSelection | undefined {
  for (const periodSelection of periodSelections) {
    const currentPeriodKey = genPeriodKey(periodSelection.period);
    if (currentPeriodKey === key) {
      return periodSelection;
    }
  }
  return undefined;
}

export function isValidDate(value: unknown): value is Date {
  // Does not take into account dates from other JS contexts
  return value instanceof Date && !isNaN(value.getTime());
}

export function isValidStringDate(value: string): boolean {
  return isValidDate(new Date(value));
}

export function formatStringForDateInput(value: string): string {
  const date = new Date(value);
  return isValidDate(date) ? format(date, 'yyyy-MM-dd') : value;
}

export function formatDateForInput(date: Date): string {
  return isValidDate(date) ? format(date, 'yyyy-MM-dd') : '';
}

export function formatDateRange(from: Date | undefined, to: Date | undefined): string {
  const pattern = 'dd/MM/yyyy';
  return isValidDate(from) && isValidDate(to) ? `${format(from, pattern)} - ${format(to, pattern)}` : '';
}

export function formatDateTimeForInput(date: Date): string {
  return isValidDate(date) ? format(date, "yyyy-MM-dd'T'HH:mm") : '';
}

export function calculateUTCOffset(): UTCOffset | undefined {
  const date: Date = new Date();
  const offset: number = date.getTimezoneOffset();
  const offsetSign = offset < 0 ? '+' : '-';
  const offsetHours = Math.floor(Math.abs(offset) / 60);
  const offsetMinutes = Math.abs(offset) % 60;
  const utcOffset = `${offsetSign}${offsetHours < 10 ? `0${offsetHours}` : offsetHours}:${
    offsetMinutes < 10 ? `0${offsetMinutes}` : offsetMinutes
  }`;
  const parseResult = UTCOffset.safeParse(utcOffset);
  if (parseResult.success) {
    return parseResult.data;
  }
}
