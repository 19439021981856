import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { LayoutProps, SpaceProps } from 'styled-system';
import { space, layout } from 'styled-system';

export const VerticalLine = styled.span<SpaceProps & LayoutProps>(
  css({
    borderLeft: '1px solid',
    borderLeftColor: 'black.100',
  }),
  space,
  layout,
);

export const HorizontalLine = styled.hr<SpaceProps & LayoutProps>(
  css({
    borderTop: '1px solid',
    borderTopColor: 'black.100',
  }),
  space,
  layout,
);
