import { gql } from '@apollo/client';
import { Order } from 'types/order';
import { Project } from 'types/project';
import { useDefaultQuery } from './useDefaultQuery';

const GET_PROJECT_SENSOR_PAGES = gql`
  query project(
    $uuid: String!
    $filter: ProjectSensorFilterArgs
    $order: ProjectSensorOrderArgs
    $page: PaginationArgs
  ) {
    project(uuid: $uuid) {
      id
      name
      sensorPages(filter: $filter, order: $order, page: $page) {
        items {
          id
          name
          unit
          value {
            value
            time
          }
          sensor {
            device {
              id
              name
            }
          }
          battery
          location {
            id
            name
          }
          sensorId
          measurement
          updatedAt
        }
        count
      }
    }
  }
`;

type Props = {
  uuid?: string;
  filter?: {
    name?: string;
    location?: string;
    measurement?: string;
  };
  order?: {
    name?: Order;
    location?: Order;
    measurement?: Order;
    updatedAt?: Order;
  };
  page?: {
    limit: number;
    offset: number;
  };
};

export const useGetProjectSensorPages = (vars: Props) => {
  const skip = vars.uuid === undefined;
  return useDefaultQuery<Project, Props>(GET_PROJECT_SENSOR_PAGES, 'project', vars, undefined, skip);
};
