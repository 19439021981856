import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';
import { File, FileItem } from 'graphql/generated';

const ADD_FILE_TO_ITEM_KEY = 'addFileToItem';

const ADD_FILE_TO_ITEM = `
  mutation ($fileId: String!, $itemId: String!, $item: FileItem!) {
    ${ADD_FILE_TO_ITEM_KEY}(data: { fileId: $fileId, itemId: $itemId, item: $item }) {
      id
      name
      status
      mediaType
      createdAt
      owner {
        id
        email
        phone
        firstName
        lastName
        createdAt
      }
    }
  }
`;

type MutVarAddFileToItem = {
  fileId: string;
  itemId: string;
  item: FileItem;
};

type MutResAddFileToItem = DefaultQueryResponse<
  typeof ADD_FILE_TO_ITEM_KEY,
  Pick<File, 'id' | 'name' | 'status' | 'mediaType' | 'createdAt' | 'owner'>
>;

export const useAddFileToItem = () => {
  return useMutation<MutResAddFileToItem, MutVarAddFileToItem>(ADD_FILE_TO_ITEM);
};
