import { isAfter, isBefore } from 'date-fns';

interface ObjectUpdatedAt {
  updatedAt: string;
}

export const sortByUpdatedAt = (v1: ObjectUpdatedAt, v2: ObjectUpdatedAt) => {
  const date1 = new Date(v1.updatedAt);
  const date2 = new Date(v2.updatedAt);
  if (isBefore(date1, date2)) {
    return 1;
  } else if (isAfter(date1, date2)) {
    return -1;
  } else {
    return 0;
  }
};

interface ObjectCreatedAt {
  createdAt: string;
}

export const sortByCreatedAt = (v1: ObjectCreatedAt, v2: ObjectCreatedAt) => {
  const date1 = new Date(v1.createdAt);
  const date2 = new Date(v2.createdAt);
  if (isBefore(date1, date2)) {
    return 1;
  } else if (isAfter(date1, date2)) {
    return -1;
  } else {
    return 0;
  }
};

type ObjectValueString = {
  value: string;
}

export const sortByValueString = (v1: ObjectValueString, v2: ObjectValueString) => v1.value.localeCompare(v2.value);
