import { useSnackbar } from 'components/Snackbar';
import { View } from 'graphql/generated';
import { FC, useCallback } from 'react';
import { useEditViewName } from 'server';
import { Severity } from 'state/snackbarStore';
import { viewConfigurations, ViewType } from 'types/view';
import { AlertViewForm } from './AlertViewForm';
import { useTranslation } from 'react-i18next';

type Props = {
  view: Pick<View, 'name' | 'id' | 'type'>;
  afterEdit: () => void;
  onClickDelete?: () => void;
};

export const EditAlertView: FC<Props> = ({ view, afterEdit, onClickDelete }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [editViewName, updatingName] = useEditViewName();

  const save = useCallback(
    async (name: View['name']) => {
      try {
        if (name !== view.name) {
          await editViewName({
            name,
            viewId: view.id,
          });
          snackbar.addAlert(
            t('{{view}} view saved', { view: t(viewConfigurations[view.type as ViewType].displayName) }),
            Severity.SUCCESS,
          );
        }
        if (afterEdit) {
          afterEdit();
        }
      } catch (e) {
        snackbar.addAlert(
          t('Failed to save changes to {{view}} view', {
            view: t(viewConfigurations[view.type as ViewType].displayName),
          }),
          Severity.ERROR,
        );
      }
    },
    [editViewName, view, afterEdit, snackbar, t],
  );

  return (
    <AlertViewForm
      view={view}
      onSave={save}
      saveLabel={t('actions.Save')}
      loading={updatingName}
      onClose={afterEdit}
      onClickDelete={onClickDelete}
    />
  );
};
