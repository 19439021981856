import { IconButton } from 'components/buttons/IconButton';
import { Flex } from 'components/layout/Flex';
import { Tr } from 'components/table/Table';
import { ComponentPropsWithRef, FC, MouseEvent as RMouseEvent, useCallback } from 'react';
import styled from 'styled-components';
import { DropDownContainer } from '../dropdowns/components/DropdownContainer';
import { colors } from 'theme/colors';
import type { IconType } from 'react-icons';
import { Box } from 'components/layout/Box';
import { Tooltip } from 'react-tooltip';

const TableDropdownContainer = styled(Box)`
  display: flex;
  @media (min-width: 601px) {
    ${Tr} & {
      opacity: 0;
      display: flex;
    }
    ${Tr}:hover & {
      opacity: 1;
      display: flex;
    }
  }
`;

export type MoreMenuItem = {
  text: string;
  to?: string;
  onClick?: () => void;
  icon: IconType;
  disabled?: boolean;
};

type Props = ComponentPropsWithRef<typeof DropDownContainer> & {
  items: MoreMenuItem[];
  stopPropagation?: boolean;
};

export const TableMoreMenu: FC<Props> = ({ items, stopPropagation = false, ...props }) => {
  const handleItemClick = useCallback(
    (e: RMouseEvent<HTMLButtonElement>, item: MoreMenuItem) => {
      if (stopPropagation) {
        e.stopPropagation();
      }

      if (item.onClick) {
        item.onClick();
      }
    },
    [stopPropagation],
  );

  return items.length > 0 ? (
    <Flex justifyContent="end">
      <TableDropdownContainer {...props}>
        {items.map((item, i) => {
          const Icon = item.icon;
          return (
            <IconButton key={i} onClick={(event) => handleItemClick(event, item)} disabled={item.disabled}>
              <Icon
                data-tooltip-id={`action_${i}`}
                data-tooltip-content={item.text}
                size="1.375rem"
                color={colors.brand}
              />
              <Tooltip
                id={`action_${i}`}
                place="bottom"
                style={{
                  borderRadius: 0,
                  backgroundColor: colors.primary[900],
                  padding: '0.125rem 0.75rem',
                }}
              />
            </IconButton>
          );
        })}
      </TableDropdownContainer>
    </Flex>
  ) : null;
};
