/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Measurement, measurements, measurementUnits, Unit, units } from '@charphq/types';
import { AuthContext } from 'context/AuthContext';
import { Device, Sensor } from 'graphql/generated';
import { useAddSensorToDevice } from 'graphql/mutation/useAddSensorToDevice';
import { useCallback, useContext, useMemo, useState, VFC } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';
import { Severity } from 'state/snackbarStore';
import { Organization } from 'types/organization';
import { measurementToGQ, unitToGQ } from 'types/sensor';
import { dispatchErrors, formatNumber } from 'utils/util';
import { IconButton } from './buttons/IconButton';
import { Input } from './form/InputPure';
import { Select } from './form/Select';
import { Flex } from './layout/Flex';
import { useSnackbar } from './Snackbar';
import Spinner from './Spinner';
import { Td, Tr } from './table/Table';
import { useTranslation } from 'react-i18next';

type Props = {
  org: Pick<Organization, 'id'>;
  device: Pick<Device, 'id'>;
  onBack: () => void;
  onAddSensor: (sensor: Sensor, column: number) => void;
};

export const AddSensorTr: VFC<Props> = ({ org, device, onBack, onAddSensor: afterAddSensor }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();
  const [unit, setUnit] = useState<Unit>();
  const [column, setColumn] = useState<number>();
  const [measurement, setMeasurement] = useState<Measurement>();

  const [{ fetching: loadingAddSensor }, addSensor] = useAddSensorToDevice();

  const formValid = useMemo(
    () => column !== undefined && measurement !== undefined && unit !== undefined,
    [column, measurement, unit],
  );

  const onAddSensor = useCallback(async () => {
    if (formValid) {
      const deviceAndSensor = await addSensor({
        organizationId: org.id,
        deviceId: device.id,
        data: {
          name: 'name',
          // Checked in formValid
          measurement: measurementToGQ[measurement!],
          unit: unitToGQ[unit!],
        },
      });
      if (deviceAndSensor.data) {
        snackbar.addAlert(
          t('Created new measurement: {{measurement}}', {
            measurement: t(deviceAndSensor.data.addSensorToDevice.sensor.measurement, { ns: 'db-values' }),
          }),
          Severity.SUCCESS,
        );
        afterAddSensor(deviceAndSensor.data.addSensorToDevice.sensor, column!);
      }
      if (deviceAndSensor.error) {
        dispatchErrors(snackbar, deviceAndSensor.error, authContext, t);
      }
    }
  }, [formValid, addSensor, org.id, device.id, measurement, unit, snackbar, afterAddSensor, column, authContext, t]);

  return (
    <Tr hoverBg="transparent">
      <Td>
        <Select
          value={measurement ?? ''}
          onChange={(event) => setMeasurement(event.target.value as Measurement)}
          width={36}
          selectProps={{
            width: 36,
            height: 8,
            pt: '0.175em',
          }}
        >
          <option disabled value={''}>
            {t('Measurement')}
          </option>
          {Object.keys(measurements).map((key, i) => (
            <option key={i} value={key}>
              {t(measurements[key as Measurement])}
            </option>
          ))}
        </Select>
      </Td>
      <Td>
        <Select
          disabled={measurement === undefined}
          value={unit ?? ''}
          onChange={(event) => setUnit(event.target.value as Unit)}
          width={24}
          selectProps={{
            width: 24,
            height: 8,
            pt: '0.175em',
          }}
        >
          <option disabled value={''}>
            {t('Unit')}
          </option>
          {measurement &&
            measurementUnits[measurement].map((key, i) => (
              <option key={i} value={key}>
                {t(units[key as Unit])}
              </option>
            ))}
        </Select>
      </Td>
      <Td>
        <Input
          required
          type="number"
          height={8}
          value={column ?? ''}
          min={1}
          width={24}
          placeholder={t('Column')}
          onChange={(e) => setColumn(formatNumber(e))}
        />
      </Td>
      <Td>
        <Flex>
          <IconButton disabled={loadingAddSensor} onClick={() => onBack()} color="red.400">
            <MdClose />
          </IconButton>
          <IconButton disabled={!formValid || loadingAddSensor} onClick={() => onAddSensor()} color="grey.900">
            {loadingAddSensor ? <Spinner /> : <MdCheck />}
          </IconButton>
        </Flex>
      </Td>
    </Tr>
  );
};
