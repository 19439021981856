import { ProjectUser } from 'graphql/generated';
import { CommunicationChannel } from 'types/notification';
import { useMutation } from 'urql';

const EDIT_PROJECT_USER_COMMUNICATION_CHANNELS_KEY = 'editProjectUserCommunicationChannels';

const EDIT_PROJECT_USER_COMMUNICATION_CHANNELS = `
  mutation ($projectUserId: String!, $channels: [String!]!) {
    ${EDIT_PROJECT_USER_COMMUNICATION_CHANNELS_KEY}(uuid: $projectUserId, channels: $channels) {
      id,
      role,
      email,
      userId,
      firstName,
      lastName,
      createdAt,
      communicationChannel
    }
  }
`;

type MutVarEditProjectUserCommunicationChannels = {
  projectUserId: string;
  channels: CommunicationChannel[];
};

type MutResEditProjectUserCommunicationChannels = Pick<
  ProjectUser,
  'id' | 'role' | 'email' | 'userId' | 'firstName' | 'lastName' | 'communicationChannel' | 'createdAt'
>;

export const useEditProjectUserCommunicationChannels = () => {
  return useMutation<MutResEditProjectUserCommunicationChannels, MutVarEditProjectUserCommunicationChannels>(
    EDIT_PROJECT_USER_COMMUNICATION_CHANNELS,
  );
};
