import type {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  PureQueryOptions,
  RefetchQueriesFunction,
} from '@apollo/client';
import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultResponse } from 'types/defaultResponse';
import { omitTypenameDeep } from 'utils/apolloUtils';

export type MutationUpdateFunction<MutationVariables, MutationResult> = MutationUpdaterFunction<
  DefaultResponse<MutationResult>,
  MutationVariables,
  DefaultContext,
  ApolloCache<DefaultResponse<MutationResult>>
>;

export const useDefaultMutation = <Tvalue, Tprops extends Record<string, unknown>>(
  mutation: DocumentNode,
  key: string,
  update?: MutationUpdaterFunction<
    DefaultResponse<Tvalue>,
    Tprops,
    DefaultContext,
    ApolloCache<DefaultResponse<Tvalue>>
  >,
  refetchQueries?: (PureQueryOptions | string)[] | RefetchQueriesFunction,
): [(variables: Tprops) => Promise<Tvalue>, boolean] => {
  const { t } = useTranslation();
  const [exec, { loading }] = useMutation<DefaultResponse<Tvalue>, Tprops>(mutation, {
    errorPolicy: 'all',
    refetchQueries,
  });

  const execMutation = useCallback(
    async (variables: Tprops): Promise<Tvalue> => {
      const result = await exec({
        variables: omitTypenameDeep(variables),
        update,
      });
      if (!result.data) {
        throw new Error(t('Failed to execute mutation: {{data}}', { data: key }));
      }

      return result.data[key];
    },
    [exec, key, update, t],
  );

  return [execMutation, loading];
};
