import { gql } from '@apollo/client';
import { CumulativeSumLightHoursView } from 'types/view/cumulativeSumLightHoursView';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';
import { useTranslation } from 'react-i18next';

const ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_KEY = 'addCumulativeSumLightHoursView';

const ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW = gql`
  mutation ($projectId: String!, $data: AddViewWithSensorsInput!) {
    ${ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_KEY}(uuid: $projectId, data: $data) {
      id
      name
      type
      projectId
      updatedAt
    }
  }
`;

type MutVarAddCumulativeSumLightHoursView = {
  projectId: string;
  data: {
    name: string;
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
  };
};

type MutResAddCumulativeSumLightHoursView = Pick<
  CumulativeSumLightHoursView,
  'id' | 'name' | 'type' | 'updatedAt' | 'projectId'
>;

export const useAddCumulativeSumLightHoursView = () => {
  const { t } = useTranslation();
  const update: MutationUpdateFunction<MutVarAddCumulativeSumLightHoursView, MutResAddCumulativeSumLightHoursView> = (
    cache,
    data,
    { variables },
  ) => {
    const result = data.data;
    if (result) {
      const newViewRef = cache.writeFragment({
        data: result[ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_KEY],
        fragment: gql`
          fragment NewView on View {
            id
          }
        `,
      });
      if (variables === undefined) {
        throw new Error(t('Missing mutation variables'));
      }
      cache.modify({
        id: cache.identify({
          id: variables.projectId,
          __typename: 'Project',
        }),
        fields: {
          views(views = []) {
            return [newViewRef, ...views];
          },
        },
      });
    }
  };

  return useDefaultMutation<MutResAddCumulativeSumLightHoursView, MutVarAddCumulativeSumLightHoursView>(
    ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW,
    ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_KEY,
    update,
  );
};
