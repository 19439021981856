import Spinner from 'components/Spinner';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';
import type { ColorProps, LayoutProps, SpaceProps, TypographyProps } from 'styled-system';
import { color, compose, layout, space, typography, variant } from 'styled-system';
import { DefaultButton } from './Default';
import type { IconType } from 'react-icons';

// filled in design = neutral
// danger = error
type Variant =
  | 'action'
  | 'positive'
  | 'danger'
  | 'neutral'
  | 'subtle'
  | 'note'
  | 'outline'
  | 'note'
  | 'note-secondary'
  | 'plain';

type ButtonProps = SpaceProps &
  LayoutProps &
  TypographyProps &
  ColorProps & {
    $loading?: boolean;
    variant?: Variant;
    iconLeft?: IconType;
    iconRight?: IconType;
    smallPad?: boolean;
  };

const Inner = styled.span<{ $loading?: boolean }>`
  opacity: ${(p) => (p.$loading ? 0.25 : 1)};
`;

const NormalButton = styled(DefaultButton)<ButtonProps>`
  box-sizing: border-box;
  border-radius: 0;
  position: relative;
  line-height: 1.75rem;
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.smallPad ? '0.250rem 1.75rem' : '0.5rem 1.75rem')};

  &:hover:enabled {
    filter: saturate(150%);
  }

  transition-property: filter;
  transition-duration: 96ms;

  &:active:enabled {
    filter: saturate(150%) brightness(150%);
    transition-duration: 24ms;
  }

  &:disabled {
    ${(p) => (!p.$loading ? 'opacity: 0.25;' : '')}
    cursor: default;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors.black}, inset 0 0 0 2px ${(p) => p.theme.colors.white};
  }

  ${variant({
    variants: {
      action: {
        color: 'white',
        backgroundColor: 'brand',
      },
      positive: {
        color: 'brandDark',
        backgroundColor: 'positive',
      },
      danger: {
        color: 'white',
        backgroundColor: 'danger',
      },
      neutral: {
        color: 'white',
        backgroundColor: 'primary.700',
        border: '2px solid',
        borderColor: 'primary.700',
      },
      subtle: {
        color: 'brand',
        backgroundColor: 'brandSubtle',
      },
      outline: {
        color: 'primary.700',
        backgroundColor: 'transparent',
        border: '2px solid',
        borderColor: 'primary.700',
      },
      note: {
        color: 'primary.900',
        backgroundColor: 'secondary.500',
      },
      'note-secondary': {
        color: 'secondary.100',
        backgroundColor: 'secondary.900',
      },
      plain: {
        color: 'primary.700',
        backgroundColor: 'transparent',
        padding: '0.5rem 0',
      },
    },
  })}

  ${compose(space, layout, typography, color)}
`;

const SSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(175%);
`;

type Props = ButtonHTMLAttributes<HTMLButtonElement> & Omit<ButtonProps, '$loading'> & { loading?: boolean };

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ loading, variant = 'neutral', children, iconLeft, iconRight, type = 'button', ...props }, ref) => {
    const LeftIcon = iconLeft || null;
    const RightIcon = iconRight || null;

    return (
      <NormalButton ref={ref} $loading={loading} variant={variant} type={type} {...props}>
        {LeftIcon && <LeftIcon size="1.5rem" style={{ marginRight: '0.5rem' }} />}
        {loading && <SSpinner />}
        <Inner $loading={loading}>{children}</Inner>
        {RightIcon && <RightIcon size="1.5rem" style={{ marginLeft: '0.5rem' }} />}
      </NormalButton>
    );
  },
);
Button.displayName = 'Button';
