import { DefaultQueryResponse } from 'graphql/query/default';
import { LocationType, Location } from 'types/location';
import { useMutation } from 'urql';

const EDIT_LOCATION_KEY = 'editLocation';

const EDIT_LOCATION = `
  mutation ($locationId: String!, $data: EditLocationInput!) {
    ${EDIT_LOCATION_KEY}(uuid: $locationId, data: $data) {
      id,
      name,
      height,
      description,
      type {
        id,
        name,
      },
      updatedAt,
      createdAt,
    }
  }
`;

type MutVarEditLocation = {
  locationId: string;
  data: {
    name?: string;
    height?: string;
    description?: string;
    typeId?: string | null;
    roomId?: string | null;
  };
};

type MutResEditLocation = DefaultQueryResponse<
  typeof EDIT_LOCATION_KEY,
  Pick<Location, 'id' | 'name' | 'height' | 'description' | 'updatedAt' | 'createdAt'> & {
    type: Pick<LocationType, 'id' | 'name'>;
  }
>;

export const useEditLocation = () => {
  return useMutation<MutResEditLocation, MutVarEditLocation>(EDIT_LOCATION);
};
