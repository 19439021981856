import { Button } from 'components/buttons';
import { ContentRelativeTime } from 'components/ContentRelativeTime';
import { Box } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { LayoutProps, MarginProps } from 'styled-system';
import { layout, margin } from 'styled-system';
import { File } from 'graphql/generated';
import { NoteDetailType, PartialNote } from 'types/note';
import { User } from 'types/user';
import { isNonEmptyString, isStringWithMaxLength } from 'utils/validation';
import {
  MdAddCircleOutline,
  MdClose,
  MdEditCalendar,
  MdFormatBold,
  MdFormatItalic,
  MdFormatStrikethrough,
  MdFormatUnderlined,
  MdInsertLink,
  MdKeyboardArrowDown,
} from 'react-icons/md';
import { DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { useTranslation } from 'react-i18next';

import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';

import { colors } from 'theme/colors';
import ReactDOMServer from 'react-dom/server';
import { Label } from 'components/form/Label';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';

type ContainerProps = LayoutProps & MarginProps;

const Container = styled.div<ContainerProps>(layout, margin);

const svgBold = ReactDOMServer.renderToStaticMarkup(<MdFormatBold size="1.5rem" />);
const svgItalic = ReactDOMServer.renderToStaticMarkup(<MdFormatItalic size="1.5rem" />);
const svgUnderlined = ReactDOMServer.renderToStaticMarkup(<MdFormatUnderlined size="1.5rem" />);
const svgStrikethrough = ReactDOMServer.renderToStaticMarkup(<MdFormatStrikethrough size="1.5rem" />);
const svgLink = ReactDOMServer.renderToStaticMarkup(<MdInsertLink size="1.5rem" />);

type Props = {
  currentUser?: Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>;
  isNewNote?: boolean;
  note?: NoteDetailType;
  editedNote: PartialNote;
  setEditedNote: Dispatch<SetStateAction<PartialNote>>;
  filesToAdd: File[];
  onAddFile: (file: File) => void;
  filesToRemove: File[];
  onRemoveFile: (file: File) => void;
  saving: boolean;
  loading: boolean;
  onSubmit: () => void;
  onClickClose: (useGuard?: boolean) => void;
} & ContainerProps;

const setupEditor = (editor: TinyMCEEditor) => {
  editor.ui.registry.addIcon('bold', svgBold);
  editor.ui.registry.addIcon('italic', svgItalic);
  editor.ui.registry.addIcon('underline', svgUnderlined);
  editor.ui.registry.addIcon('strikethrough', svgStrikethrough);
  editor.ui.registry.addIcon('link', svgLink);

  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = `
    .tox-tinymce {
      border: none !important;
      border-radius: 0 !important;
      height: 100% !important;
    }
    .tox .tox-tbtn svg {
      fill: ${colors.secondary[900]} !important;
    }
    .tox .tox-editor-header {
      padding: 0 !important;
      box-shadow: none !important;
      height: 3rem !important;
      background-color: ${colors.secondary[200]} !important;
      border-bottom: 1px solid ${colors.secondary[700]} !important;
    }
    .tox-tbtn {
      margin: 0 !important;
      cursor: pointer !important;
      border-radius: 0 !important;
      height: 3rem !important;
      width: 3.5rem !important;
      background: ${colors.secondary[200]} !important;
      &:hover {
        background: ${colors.secondary[500]} !important;
      }
    }
    .tox-tbtn--enabled {
      background: ${colors.secondary[500]} !important;
    }
    .tox-toolbar__group {
      padding: 0 !important;
    }
    .tox-toolbar__primary {
      background: ${colors.secondary[200]} !important;
      border-bottom: 1px solid !important;
      border-color: ${colors.secondary[700]} !important;
    }
    .tox .tox-editor-header>.tox-toolbar--scrolling {
      background: ${colors.secondary[200]} !important;
    }
  `;
  document.head.appendChild(style);

  editor.on('KeyDown', (e) => {
    const keyCode = e.keyCode || e.which;
    const content = editor.getContent({ format: 'text' }).trim();
    // Check if the Enter key is pressed and if the current content is the first line (title)
    if (keyCode === 13 && content.split('\n').length === 1) {
      setTimeout(() => {
        // Move the cursor to the next line and apply normal text styling
        editor.execCommand('mceInsertContent', false, '<p><br></p>');
        editor.formatter.apply('p');
      }, 0);
    }
  });
};

export const NoteDetail: FC<Props> = ({
  currentUser,
  isNewNote,
  note,
  editedNote,
  setEditedNote,
  filesToAdd,
  filesToRemove,
  saving,
  loading,
  onSubmit,
  onClickClose,
  ...props
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const owner = useMemo(() => (isNewNote ? currentUser : editedNote.owner), [isNewNote, currentUser, editedNote]);

  const userIsOwner = useMemo(
    () => currentUser?.id !== undefined && currentUser.id === owner?.id,
    [owner, currentUser],
  );

  const editingAllowed = userIsOwner;

  const formatDateToYYYYMMDD = (date: string) => format(new Date(date), 'yyyy-MM-dd');
  const dirty = useMemo(
    () => ({
      title: note?.title !== editedNote?.title,
      description: note?.description !== editedNote?.description,
      start:
        editedNote?.period?.start &&
        note?.period?.start &&
        formatDateToYYYYMMDD(note?.period?.start) !== formatDateToYYYYMMDD(editedNote?.period?.start),
      end:
        editedNote?.period?.end &&
        note?.period?.end &&
        formatDateToYYYYMMDD(note?.period?.end) !== formatDateToYYYYMMDD(editedNote?.period?.end),
      files: filesToAdd.length > 0 || filesToRemove.length > 0,
    }),
    [
      note?.title,
      note?.description,
      note?.period,
      editedNote?.title,
      editedNote?.description,
      editedNote?.period,
      filesToAdd.length,
      filesToRemove.length,
    ],
  );

  const formIsDirty = useMemo(() => Object.values(dirty).includes(true), [dirty]);

  const valid = useMemo(
    () => ({
      title:
        editedNote.title !== undefined &&
        isNonEmptyString(editedNote.title) &&
        isStringWithMaxLength(editedNote.title, 255),
    }),
    [editedNote],
  );

  const formIsValid = useMemo(() => !Object.values(valid).includes(false), [valid]);

  const handleSubmit = useCallback(() => {
    if (formIsValid) {
      onSubmit();
    }
  }, [onSubmit, formIsValid]);

  const initialValue = useMemo(() => editedNote.description ?? '<p></p>', [editedNote]);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [formattedDate, setFormattedDate] = useState<string>();
  useEffect(() => {
    const startDateAfterDrag = localStorage.getItem('noteStartDate');
    const startDate = note?.period?.start || editedNote.period?.start;
    if (startDate && !formattedDate) {
      setFormattedDate(new Date(startDate).toDateString());
    }
    if (startDateAfterDrag) {
      const { date, id } = JSON.parse(startDateAfterDrag);
      if (id === note?.id) setFormattedDate(new Date(date).toDateString());
    }
  }, [editedNote.period?.start, formattedDate, note?.id, note?.period?.start]);

  const handleDayClick = (day: Date) => {
    const date = new Date(day);
    setEditedNote((prevNote) => ({
      ...prevNote,
      period: { end: date.toDateString(), start: date.toDateString() },
    }));
    setFormattedDate(date.toDateString());
    const noteStartDate = {
      date: date.toDateString(),
      id: editedNote.id,
    };
    localStorage.setItem('noteStartDate', JSON.stringify(noteStartDate));
    setIsDatePickerVisible(false);
  };

  const currentYear = new Date().getFullYear();

  const handleOnClose = useCallback(() => {
    onClickClose();
    setFormattedDate(undefined);
    localStorage.removeItem('noteStartDate');
  }, [onClickClose]);

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', flex: 1 }} {...props}>
      <Flex flexDirection="column" height="100%">
        <Flex
          alignItems="center"
          justifyContent={'flex-end'}
          padding="0.25rem 1.5rem"
          backgroundColor={colors.secondary[200]}
          borderBottom="1px solid"
          height="49px"
          borderColor={colors.secondary[700]}
        >
          {formattedDate && (
            <Box mr="auto" borderRight={`1px solid ${colors.secondary[700]}`} padding="0.6rem" paddingRight="2rem">
              <Label
                onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              >
                <MdEditCalendar size="1rem" style={{ marginRight: '1.5rem' }} />
                {formattedDate}
                <MdKeyboardArrowDown
                  size="1.5rem"
                  style={{
                    marginLeft: '0.5rem',
                    transform: isDatePickerVisible ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </Label>
            </Box>
          )}
          <Button smallPad iconLeft={MdClose} variant="plain" onClick={handleOnClose} color={colors.secondary[900]}>
            {t('Close')}
          </Button>
        </Flex>
        {isDatePickerVisible && formattedDate && (
          <Box alignSelf="center">
            <DayPicker
              mode="single"
              fromMonth={new Date(currentYear - 10, 0)}
              toMonth={new Date(currentYear + 10, 11)}
              captionLayout="dropdown"
              selected={new Date(formattedDate)}
              onDayClick={handleDayClick}
              month={formattedDate ? new Date(formattedDate) : new Date()}
            />
          </Box>
        )}
        <Editor
          value={initialValue}
          apiKey="jmvepfbmvsvepawiqwahskg0l7dsbvvgg5xfde5rxq7hvc1g"
          disabled={!editingAllowed}
          onEditorChange={(v) => setEditedNote((n) => ({ ...n, title: 'old title', description: v }))}
          init={{
            placeholder: t('Type your note here'),
            menubar: false,
            statusbar: false,
            language,
            height: '100%',
            plugins: 'link',
            toolbar: 'bold italic underline strikethrough link',
            content_style: `
              @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
              body { font-family: 'Source Sans Pro', sans-serif; font-size:20px; background-color: ${colors.secondary[100]};}
              p { font-size:20px; margin: 16px 0;}
              p:first-of-type { font-size:24px; font-weight:bold; }
              /* Placeholder styles */
              p[data-placeholder]:empty:before {
                content: attr(data-placeholder);
                display: block;
                color: #ccc;
              }
              p.custom-placeholder { color: #ccc; font-size:20px; }
              p.custom-placeholder:first-child { font-size:24px; font-weight:bold; }
            `,
            setup: setupEditor,
          }}
        />
        {editedNote?.createdAt && (
          <Box fontSize="md" color="note.400">
            <ContentRelativeTime createdDate={editedNote.createdAt} updatedDate={editedNote?.updatedAt} />
          </Box>
        )}
      </Flex>
      {editingAllowed && (
        <DrawerFooter
          gap="1.5rem"
          style={{
            justifyContent: 'flex-end',
            backgroundColor: colors.secondary[200],
            borderColor: colors.secondary[700],
          }}
        >
          <Button
            iconLeft={MdClose}
            smallPad
            color={colors.secondary[900]}
            type="button"
            onClick={() => onClickClose(true)}
            disabled={loading}
            variant="plain"
          >
            {t('actions.Cancel')}
          </Button>
          <Button
            variant="note"
            iconLeft={MdAddCircleOutline}
            smallPad
            bg={colors.secondary[900]}
            color={colors.base[100]}
            onClick={() => handleSubmit()}
            disabled={!formIsDirty || !formIsValid || loading}
            loading={saving}
          >
            {isNewNote ? t('actions.Add') : t('actions.Update note')}
          </Button>
        </DrawerFooter>
      )}
    </Container>
  );
};
