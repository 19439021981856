import { useTranslation } from 'react-i18next';
import { Button } from 'components/buttons';
import { Flex } from 'components/layout/Flex';
import { useSnackbar } from 'components/Snackbar';
import { useAuthContext } from 'context/AuthContext';
import { Project } from 'graphql/generated';
import { useEditProjectAlertCascadeOrder } from 'graphql/mutation/useEditProjectAlertCascadeOrder';
import { FC, useCallback, useMemo, useState } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { UserCascadeList } from './userCascadeList';
import { Label, LabelSmall } from 'components/form/Label';
import { SelectInput } from 'components/form/SelectInput';

type Props = {
  isOwner: boolean;
  project: Pick<Project, 'users' | 'alertCascadeOrder' | 'id'>;
};

export const ControlOrderConfiguration: FC<Props> = ({ isOwner, project }) => {
  const { t } = useTranslation();
  const { addAlert } = useSnackbar();
  const authContext = useAuthContext();
  const [, editProjectAlertCascadeOrder] = useEditProjectAlertCascadeOrder();
  const [addUser, setAddUser] = useState<boolean>(false);

  const projectUsers = useMemo(() => project.users ?? [], [project.users]);
  const alertCascadeOrder = useMemo(() => project.alertCascadeOrder ?? [], [project]);
  const availableUsers = useMemo(
    () => projectUsers.filter((projectUser) => !alertCascadeOrder.includes(projectUser.id)),
    [alertCascadeOrder, projectUsers],
  );
  const hasAvailableUsers = useMemo(() => availableUsers.length > 0, [availableUsers.length]);
  const hasActiveCascade = alertCascadeOrder.length > 0;

  const onSelect = useCallback(
    async (id: string) => {
      if (id !== '') {
        const response = await editProjectAlertCascadeOrder({
          projectId: project.id,
          projectUserIds: [...alertCascadeOrder, id],
        });
        if (response.data) {
          addAlert(t('User added to cascade'), Severity.SUCCESS);
        } else if (response.error) {
          dispatchErrors({ addAlert }, response.error, authContext, t);
        }
        setAddUser(false);
      }
    },
    [editProjectAlertCascadeOrder, project.id, alertCascadeOrder, addAlert, authContext, t],
  );

  const usersOptions = useMemo(
    () =>
      availableUsers.map((projectUser) => ({
        label: `${projectUser.firstName} ${projectUser.lastName}`,
        value: projectUser.id,
      })),
    [availableUsers],
  );

  return (
    <Flex flexDirection="column" mb="1.5rem">
      <Label mb="0.5rem">{t('Order')}</Label>
      {alertCascadeOrder.length === 0 && (
        <LabelSmall>{t('All members will be notified unless a member is set')}</LabelSmall>
      )}
      {hasActiveCascade && (
        <Flex flexBasis="100%">
          <UserCascadeList
            isOwner={isOwner}
            projectId={project.id}
            projectUsers={projectUsers}
            order={alertCascadeOrder}
          />
        </Flex>
      )}
      <Flex>
        {addUser ? (
          <SelectInput
            options={usersOptions}
            onChange={onSelect}
            placeholder={t('placeholders.Select a member')}
            disabled={!isOwner}
            width="12.5rem"
          />
        ) : (
          <Flex>
            <Button
              disabled={!hasAvailableUsers || !isOwner}
              onClick={() => setAddUser(true)}
              iconLeft={MdAddCircleOutline}
              variant="plain"
            >
              {t('actions.Add member')}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
