import { useTranslation } from 'react-i18next';
import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { DrawerComponentProps } from 'context/DrawerContext';
import { DocumentationKeys, documentationMap } from 'docs/config';
import { FC, LazyExoticComponent, Suspense, useEffect, useState } from 'react';
import { H1, H2, H3, H4, H5, Text } from './typography';
import { Loader } from './loader/loader';

type Props = DrawerComponentProps & {
  docId: DocumentationKeys;
};

export const DocumentationDrawer: FC<Props> = ({ docId, requestClose }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [DocComponent, setDocComponent] = useState<LazyExoticComponent<any> | string>();

  useEffect(() => {
    const loadComponent = async () => {
      try {
        const docComponent = documentationMap[docId];
        setDocComponent(docComponent);
      } catch (error) {
        setDocComponent(t('We could not retrieve the following documentation: {{docId}}', { docId }));
        if (import.meta.env.DEV) {
          throw error;
        }
      }
    };
    loadComponent();
  }, [docId, t]);

  if (!DocComponent) {
    return (
      <DrawerLayout title={t('Loading documentation')} onClose={requestClose}>
        <Loader />
      </DrawerLayout>
    );
  }

  return (
    <DrawerLayout title={'Documentation'} backgroundColor={'grey.100'} onClose={requestClose}>
      <Suspense fallback={<Loader />}>
        <DocComponent
          components={{
            h1: H1,
            h2: H2,
            h3: H3,
            h4: H4,
            h5: H5,
            p: Text,
          }}
        />
      </Suspense>
    </DrawerLayout>
  );
};
