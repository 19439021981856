import { useTranslation } from 'react-i18next';
import { Flex } from 'components/layout/Flex';
import { FC, useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { UserCascadeItem } from './userCascadeItem';
import { ProjectUser } from 'graphql/generated';
import { useEditProjectAlertCascadeOrder } from 'graphql/mutation/useEditProjectAlertCascadeOrder';
import { useSnackbar } from 'components/Snackbar';
import { useAuthContext } from 'context/AuthContext';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { Loader } from 'components/loader/loader';

type Props = {
  isOwner: boolean;
  projectId: string;
  projectUsers: ProjectUser[];
  order: string[]; // The order of project user based on their uuid
};

export const UserCascadeList: FC<Props> = ({ projectId, projectUsers, order, isOwner }) => {
  const { t } = useTranslation();
  const { addAlert } = useSnackbar();
  const authContext = useAuthContext();
  const [cascadeOrder, setCascadeOrder] = useState<string[]>(order);
  const [{ fetching: loading }, editProjectAlertCascadeOrder] = useEditProjectAlertCascadeOrder();

  useEffect(() => {
    setCascadeOrder(order);
  }, [order]);

  const onDragEnd = async ({ destination, source, draggableId }: DropResult) => {
    if (!destination) {
      const newOrder = Array.from(cascadeOrder);
      newOrder.splice(source.index, 1);
      setCascadeOrder(newOrder);
      const response = await editProjectAlertCascadeOrder({
        projectId,
        projectUserIds: newOrder,
      });
      if (response.data) {
        addAlert(t('User removed from cascade order'), Severity.SUCCESS);
      } else if (response.error) {
        dispatchErrors({ addAlert }, response.error, authContext, t);
      }
      return; //remove user
    }

    if (destination.index === source.index) {
      return;
    }
    const newOrder = Array.from(cascadeOrder);
    newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, draggableId);
    setCascadeOrder(newOrder);
    await editProjectAlertCascadeOrder({
      projectId,
      projectUserIds: newOrder,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable isDropDisabled={loading || !isOwner} droppableId="userCascadeList" direction="vertical">
        {(provided, snapshot) => (
          <Flex
            border="1px solid"
            borderColor={snapshot.isDraggingOver ? 'brand' : 'transparent'}
            width="100% !important"
            ref={provided.innerRef}
            {...provided.droppableProps}
            flexDirection="column"
          >
            {cascadeOrder.map((projectUserId, index) => {
              const projectUser = projectUsers.filter((projectUser) => projectUser.id === projectUserId)[0];

              return (
                projectUser && (
                  <UserCascadeItem
                    disabled={loading || !isOwner}
                    key={projectUser.id}
                    projectUser={projectUser}
                    index={index}
                  />
                )
              );
            })}
            {provided.placeholder}
            {loading && <Loader />}
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  );
};
