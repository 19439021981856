import { css } from '@styled-system/css';
import { Flex } from 'components/layout/Flex';
import { FC } from 'react';
import styled from 'styled-components';
import { CardTitle } from './typography/CardTitle';
import { Description } from './typography/Description';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme/colors';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

type CardProps = {
  disabled?: boolean;
};

const Container = styled.div<CardProps>`
  padding: 1rem;
  text-align: left;
  position: relative;
  border: 1px solid;
  margin: -1px;
  ${(p) => ({
    opacity: p.disabled ? 0.5 : 1,
    pointerEvents: p.disabled ? 'none' : 'all',
    borderColor: colors.base[400],
  })}
  &:hover {
    ${css({
      backgroundColor: colors.base[200],
    })}
  }
  transition: background-color 96ms ease;
`;

const Overlay = styled.div`
  opacity: 0.25;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  font-weight: 700;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  ${css({
    fontSize: [null, '4xl', '5xl'],
  })}
`;
const SmallOverlay = styled(Overlay)`
  font-size: 1.7rem !important;
`;

const StyledLink = styled(Link)`
  display: flex;
  width: min-content;
  margin-left: auto;
  margin-top: 1rem;
  color: ${colors.brand};
`;

type Props = {
  title: string;
  disabled?: boolean;
  description: string;
  onClick: () => void;
  moved?: boolean;
  linkTo?: string;
  onClose?: () => void;
};

export const Card: FC<Props> = ({ title, disabled, moved, description, linkTo, onClick, onClose, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container
      role="button"
      disabled={disabled}
      {...(!moved ? { onClick } : { style: { cursor: 'auto', color: `${colors.grey[400]}` } })}
      {...props}
    >
      <Flex mb="0.5rem" justifyContent="space-between" alignItems="center">
        <CardTitle color={disabled ? 'black' : moved ? colors.grey[400] : colors.brand}>{title}</CardTitle>
        {disabled && <Overlay>{t('Coming soon')}</Overlay>}
        {moved && <SmallOverlay>{t('Moved')}</SmallOverlay>}
      </Flex>
      <Description>{description}</Description>
      {moved && linkTo && (
        <StyledLink to={`${history.location.pathname}/${linkTo}`} onClick={() => onClose?.()}>
          {t('Alerts')}
        </StyledLink>
      )}
    </Container>
  );
};
