import type { LocationDescriptor } from 'history';
import { useCallback, useMemo } from 'react';
import { useHistory as useHistoryRR } from 'react-router-dom';
import { normalizeLocationDescriptor } from 'utils/location-descriptor';

/**
 * Wrapped react-router history which preserves query parameters and hash when pushing new routes.
 * It is advised to use this wrapper instead of react-router's useHistory everywhere in the app,
 * to prevent unexpected unsetting of query parameters.
 * Exposes only the functions needed for this app.
 */
export function useHistory<T>() {
  const history = useHistoryRR<T>();

  /** Push a new pathname, without modifying existing query parameters. */
  const push = useCallback(
    (to: LocationDescriptor<T>) => {
      const normalizedTo = normalizeLocationDescriptor(to);
      history.push({
        ...history.location,
        ...normalizedTo,
      });
    },
    [history],
  );

  /** Set, update or unset a query parameter for a given key. Pass an undefined value for unsetting. */
  const setQueryParameter = useCallback(
    (key: string, value: string | undefined) => {
      const search = new URLSearchParams(history.location.search);
      if (value !== undefined) {
        search.set(key, value);
      } else {
        search.delete(key);
      }
      history.push({
        ...history.location,
        search: search.toString(),
      });
    },
    [history],
  );

  // React-router mutates its' history object's location property while keeping a stale reference to history itself,
  // so we have to explicitly check for changes to the location for dependency checking to work.
  const location = useMemo(() => history.location, [history.location]);

  return useMemo(
    () => ({
      ...history,
      location,
      push,
      setQueryParameter,
    }),
    [history, location, push, setQueryParameter],
  );
}
