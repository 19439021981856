import { css } from '@styled-system/css';
import styled from 'styled-components';
import { DefaultButton } from './Default';

export const RoundButton = styled(DefaultButton)`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  ${css({
    color: 'white',
    backgroundColor: 'black',
  })}
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    ${css({
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'white',
    })}
  }

  &:hover,
  :focus {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  }
`;
