import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, LayoutProps, MarginProps } from 'styled-system';
import { color, compose, layout, margin } from 'styled-system';
import { H5 } from './Title';

type Props = LayoutProps & MarginProps & ColorProps;

export const CardTitle = styled(H5)<Props>(
  css({
    lineHeight: 'tighter',
    fontWeight: '600',
  }),
  compose(layout, margin, color),
);
