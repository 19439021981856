import type { Reference } from '@apollo/client';
import { gql } from '@apollo/client';
import { ProjectUser } from 'types/projectUser';
import { MutationUpdateFunction, useDefaultMutation } from '../../useDefaultMutation';

const ADD_PROJECT_USERS_KEY = 'addProjectUsers';

const ADD_PROJECT_USERS = gql`
  mutation ($projectId: String!, $metadata: [AddProjectUserInput!]!) {
    ${ADD_PROJECT_USERS_KEY}(uuid: $projectId, data: { values: $metadata }) {
      id
      role
      email
      userId
      communicationChannel
      firstName
      lastName
      createdAt
    }
  }
`;

type AddUserProjectInput = {
  id: string;
  role: string;
};

type MutVarAddProjectUsers = {
  projectId: string;
  metadata: AddUserProjectInput[];
};

type MutProjectUser = Pick<
  ProjectUser,
  'id' | 'role' | 'email' | 'userId' | 'communicationChannel' | 'firstName' | 'lastName' | 'createdAt'
>;

type MutResAddProjectUsers = MutProjectUser[];

const update: MutationUpdateFunction<MutVarAddProjectUsers, MutResAddProjectUsers> = (cache, data, { variables }) => {
  const result = data.data;
  if (result) {
    const projectUsers = result[ADD_PROJECT_USERS_KEY];
    const projectUserRefs: Reference[] = [];
    for (const projectUser of projectUsers) {
      const projectUserRef = cache.writeFragment({
        data: projectUser,
        fragment: gql`
          fragment NewProjectUser on ProjectUser {
            id
          }
        `,
      });
      if (projectUserRef) {
        projectUserRefs.push(projectUserRef);
      }
    }
    cache.modify({
      id: cache.identify({
        id: variables?.projectId,
        __typename: 'Project',
      }),
      fields: {
        users(users = []) {
          return [...users, ...projectUserRefs];
        },
      },
    });
  }
};

export const useAddProjectUsers = () => {
  return useDefaultMutation<MutResAddProjectUsers, MutVarAddProjectUsers>(
    ADD_PROJECT_USERS,
    ADD_PROJECT_USERS_KEY,
    update,
  );
};
