import { ProjectSensor } from 'graphql/generated';
import { Rule } from './rule';

export enum ViewType {
  MONITOR = 'monitor',
  ALERT = 'alert',
  CUMULATIVE_SUM_LIGHT_HOURS = 'cumulative_sum_light_hours',
  ASHRAE = 'ashrae',
  DEW_POINT = 'dew_point',
  LIGHT_BUDGET = 'light_budget',
  ABSOLUTE_HUMIDITY = 'absolute_humidity',
}

export type ViewConfiguration = {
  type: ViewType;
  displayName: string;
  description: string;
  enabled: boolean;
  deletable: boolean;
  editable: boolean;
  moved?: boolean;
  linkTo?: string;
};

export const viewConfigurations: { [key in ViewType]: ViewConfiguration } = {
  [ViewType.MONITOR]: {
    type: ViewType.MONITOR,
    enabled: true,
    editable: true,
    deletable: true,
    displayName: 'monitor',
    description: 'Visualise measurement data and calculate summary statistics',
  },
  [ViewType.CUMULATIVE_SUM_LIGHT_HOURS]: {
    type: ViewType.CUMULATIVE_SUM_LIGHT_HOURS,
    displayName: 'light exposure',
    description: 'Keep track of the cumulative light exposure',
    enabled: true,
    editable: true,
    deletable: true,
  },
  [ViewType.ASHRAE]: {
    type: ViewType.ASHRAE,
    displayName: 'ASHRAE 2019',
    description: 'Assess temperature and relative humidity against ASHRAE 2019 Chapter 24',
    enabled: true,
    editable: false,
    deletable: true,
  },
  [ViewType.LIGHT_BUDGET]: {
    type: ViewType.LIGHT_BUDGET,
    displayName: 'light budget',
    description: 'Assess indoor light levels against Blue Wool Standard',
    enabled: true,
    editable: false,
    deletable: true,
  },
  [ViewType.DEW_POINT]: {
    type: ViewType.DEW_POINT,
    displayName: 'Dew point',
    description: 'Calculate the dew point temperature based on temperature and relative humidity',
    enabled: true,
    editable: false,
    deletable: true,
  },
  [ViewType.ABSOLUTE_HUMIDITY]: {
    type: ViewType.ABSOLUTE_HUMIDITY,
    displayName: 'Absolute humidity',
    description: 'Calculate the absolute humidity based on temperature and relative humidity',
    enabled: true,
    editable: false,
    deletable: true,
  },
  [ViewType.ALERT]: {
    type: ViewType.ALERT,
    displayName: 'alert',
    enabled: true,
    editable: true,
    deletable: true,
    moved: true,
    description: 'Moved. Alert feature has been moved to the main menu',
    linkTo: 'notifications',
  },
};

export interface View {
  id: string;
  name: string;
  type: ViewType;
  endTime?: Date;
  startTime?: Date;
  description?: string;
  rules?: Rule[];
  sensors?: ProjectSensor[];
  updatedAt: string;
}
