import { View } from 'graphql/generated';
import { urqlClient } from 'graphql/urql';
import { OperationResult } from 'urql';
import { DefaultQueryResponse } from './default';

const QUERY_VIEW_KEY = 'view';

const QUERY_VIEW = `
query ($viewId: String!) {
  ${QUERY_VIEW_KEY}(uuid: $viewId) {
    id
    name
    type
    updatedAt
  }
}
`;

type QryVarView = {
  viewId: string;
};

export const queryView = async (
  variables: QryVarView,
): Promise<
  OperationResult<DefaultQueryResponse<typeof QUERY_VIEW_KEY, Pick<View, 'id' | 'name' | 'type' | 'updatedAt'>>>
> => {
  return urqlClient
    .query<DefaultQueryResponse<typeof QUERY_VIEW_KEY, Pick<View, 'id' | 'name' | 'type' | 'updatedAt'>>, QryVarView>(
      QUERY_VIEW,
      variables,
    )
    .toPromise();
};
