import { css } from '@styled-system/css';

import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { CSSProperties, FC, MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import styled from 'styled-components';
import { PropertyContainer } from './DefaultProperty';
import { Span } from 'components/basics/span';
import { colors } from 'theme/colors';
import { Box } from 'components/layout/Box';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import type { StylesConfig } from 'react-select';
import { OptionType } from 'types/select';
import { isNumber } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'hooks/useIsMobile';

export type ObjectPropertyValue =
  | {
      value: string;
      label: string;
      id?: string;
    }
  | undefined
  | null;

export type ObjectProperty = {
  title?: string;
  values: NonNullable<ObjectPropertyValue>[];
  value: ObjectPropertyValue;
  onCreateObject?: (objectName: string) => Promise<ObjectPropertyValue>;
  onSave: (objectId: string | null) => Promise<void>;
  hovering?: boolean;
  showBadge?: boolean;
  deletable?: boolean;
  creatable?: boolean;
  inputType?: 'string' | 'numeric';
  formatCreateLabel?: (label: string) => string;
  min?: number;
  max?: number;
  loading?: boolean;
  type?: 'object';
  id?: string;
  style?: CSSProperties;
  disabled?: boolean;
};

export const ObjectProperty: FC<ObjectProperty> = ({
  title,
  value,
  values,
  onCreateObject,
  onSave,
  hovering,
  showBadge = true,
  deletable = true,
  creatable = true,
  inputType = 'string',
  formatCreateLabel = (inputText: string) => `Create "${inputText}"`,
  min,
  max,
  loading = false,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const containerInputRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const [inputValue, setInputValue] = useState('');

  const onInputChange = (input: string) => {
    if (inputType === 'numeric') {
      let correctedValue = '';
      if (isNumber(input)) {
        correctedValue = input;
        if (min && max) {
          correctedValue = Math.max(min, Math.min(parseInt(input), max)).toString();
        }
      }
      setInputValue(correctedValue);
      return;
    }
    setInputValue(input);
  };

  const onContainerClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) return;
      event.stopPropagation();
      if (!editing) {
        setEditing(true);
      }
    },
    [disabled, editing],
  );

  const onEditClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) return;
      event.stopPropagation();
      if (!editing) {
        setEditing(true);
      }
    },
    [disabled, editing],
  );

  const onRemoveClick = useCallback(
    async (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      await onSave(null);
    },
    [onSave],
  );

  const onExit = useCallback(async () => {
    setEditing(false);
    setInputValue('');
  }, []);

  const onSelectOption = useCallback(
    async (option: unknown) => {
      if (option) {
        const typedOption = option as OptionType;
        await onSave(typedOption.value);
        onExit();
      }
    },
    [onExit, onSave],
  );

  useOnClickOutside(containerInputRef, onExit);

  const onCreateOption = useCallback(
    async (inputValue: string) => {
      if (inputType === 'numeric' || (creatable && !onCreateObject)) {
        await onSave(inputValue);
      } else if (onCreateObject) {
        const newObject = await onCreateObject(inputValue);
        await onSave(newObject?.id ?? null);
      }
      onExit();
    },
    [onCreateObject, onSave, onExit, inputType, creatable],
  );

  const commonSelectProps = useMemo(
    () => ({
      components: { DropdownIndicator: () => null, IndicatorSeparator: () => null },
      styles: customStyles,
      value: value,
      onChange: onSelectOption,
      options: values,
      isDisabled: loading,
      autoFocus: editing,
      menuIsOpen: editing,
    }),
    [value, values, loading, editing, onSelectOption],
  );

  return (
    <PropertyContainer
      border={`1px solid ${colors.base[400]}`}
      justifyContent="space-between"
      alignItems={editing ? 'flex-start' : 'center'}
      editing={editing}
      onClick={onContainerClick}
      backgroundColor={disabled ? colors.base[300] : 'white'}
      width="100%"
      {...props}
    >
      {editing ? (
        <div style={{ width: '100%' }} ref={containerInputRef}>
          {creatable ? (
            <CreatableSelect
              {...commonSelectProps}
              onCreateOption={onCreateOption}
              onInputChange={onInputChange}
              inputValue={inputValue}
              placeholder={null}
              formatCreateLabel={formatCreateLabel}
              noOptionsMessage={() => t('No options')}
            />
          ) : (
            <Select {...commonSelectProps} placeholder={title?.toLowerCase()} />
          )}
        </div>
      ) : (
        <ContentContainer
          style={{
            borderColor: disabled ? 'transparent' : hovering ? colors.base[400] : colors.base[100],
            backgroundColor: disabled ? 'transparent' : hovering ? colors.base[100] : 'white',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
          onClick={onEditClick}
        >
          {!value ? (
            <ValueContainer color={colors.base[600]}>
              {t('Type to create, or select existing {{title}}', { title: isMobile ? '' : title?.toLowerCase() })}
            </ValueContainer>
          ) : showBadge ? (
            <ObjectBadge>{value?.label}</ObjectBadge>
          ) : (
            <ValueContainer>{value?.label}</ValueContainer>
          )}

          <ActionContainer>
            {value != undefined && deletable && (
              <ActionButton onClick={onRemoveClick} style={{ width: '1.5rem' }}>
                <MdDeleteOutline style={{ opacity: hovering ? 1 : 0 }} size="1.5rem" color={colors.brand} />
              </ActionButton>
            )}
          </ActionContainer>
        </ContentContainer>
      )}
    </PropertyContainer>
  );
};

const ValueContainer = styled(Span)(
  css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '0.25rem',
    width: '100%',
  }),
);

const ContentContainer = styled(Box)(
  css({
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0.25rem 0.5rem',
    width: '100%',
  }),
);

export const ObjectBadge: FC = (props) => {
  return (
    <ObjectBadgeContainer>
      <ObjectBadgeContent>{props.children}</ObjectBadgeContent>
    </ObjectBadgeContainer>
  );
};

const ObjectBadgeContainer = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    height: '1.25rem',
    borderRadius: '3px',
    px: '0.375rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: colors.base[200],
    minWidth: 0,
  }),
);

const ObjectBadgeContent = styled.span(
  css({
    minWidth: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
);

const ActionContainer = styled.div(
  css({
    display: 'flex',
  }),
);

const ActionButton = styled.div(
  css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
);

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    fontSize: '1.125rem',
    fontWeight: 400,
    background: 'transparent',
    color: colors.base[900],
    cursor: 'pointer',
    border: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 0 0 7px',
    margin: '-1px 0',
  }),
  valueContainer: (provided) => ({
    ...provided,
    color: colors.base[900],
    padding: '0',
    height: '100%',
    alignItems: 'center',
    fontSize: '1.125rem',
    maxWidth: '100%',
    display: 'flex',
    'flex-wrap': 'nowrap',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  }),
  container: (provided) => ({
    ...provided,
    padding: '0',
    border: '0',
    borderColor: colors.brand,
    outline: '2px solid',
    width: '100%',
    outlineColor: colors.brand,
    '&:hover': {
      borderColor: colors.brand,
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    padding: 0,
    margin: '2px 0 0 0',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    fontSize: '1.125rem',
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.base[400] : '',
    color: colors.base[900],
    borderBottom: '1px solid',
    borderColor: colors.base[400],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.base[200],
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
};
