import { gql } from '@apollo/client';
import { MutationUpdateFunction, useDefaultMutation } from '../../useDefaultMutation';

const DELETE_PROJECT_ADDON_KEY = 'deleteProjectAddon';

const DELETE_PROJECT_ADDON = gql`
  mutation ($projectAddonId: String!) {
    ${DELETE_PROJECT_ADDON_KEY}(uuid: $projectAddonId)
  }
`;

type MutVarDeleteProjectAddon = {
  projectAddonId: string;
}

type MutResDeleteProjectAddon = string;

const update: MutationUpdateFunction<MutVarDeleteProjectAddon, MutResDeleteProjectAddon>
  = (cache, data) => {
  const result = data.data;
  if (result) {
    const projectAddonId = result[DELETE_PROJECT_ADDON_KEY];
    cache.evict({
      id: cache.identify({
        id: projectAddonId,
        __typename: 'ProjectAddon'
      })
    });
    cache.gc();
  }
};

export const useDeleteProjectAddon = () => {
  return useDefaultMutation<MutResDeleteProjectAddon, MutVarDeleteProjectAddon>(DELETE_PROJECT_ADDON, DELETE_PROJECT_ADDON_KEY, update);
};
