import { gql } from '@apollo/client';
import { MutationUpdateFunction, useDefaultMutation } from './useDefaultMutation';

const DELETE_NOTE_KEY = 'deleteNote';

const DELETE_NOTE = gql`
  mutation ($noteId: String!) {
    ${DELETE_NOTE_KEY}(uuid: $noteId)
  }
`;

type MutVarDeleteNote = {
  noteId: string;
}

type MutResDeleteNote = string;

const update: MutationUpdateFunction<MutVarDeleteNote, MutResDeleteNote>
 = (cache, data) => {
  const result = data.data;
  if (result) {
    const noteId = result[DELETE_NOTE_KEY];
    const isNoteDeletedFromCache = cache.evict({
      id: cache.identify({
        id: noteId,
        __typename: 'Note',
      }),
    });
    if (isNoteDeletedFromCache) {
      cache.gc();
    }
  }
};

export const useDeleteNote = () => {
  return useDefaultMutation<MutResDeleteNote, MutVarDeleteNote>(DELETE_NOTE, DELETE_NOTE_KEY, update);
};
