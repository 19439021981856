import { gql } from '@apollo/client';
import { CumulativeSumLightHoursView } from 'types/view/cumulativeSumLightHoursView';
import { ProjectSensor } from 'graphql/generated';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';

const EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS_KEY = 'editCumulativeSumLightHoursSensors';

const EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS = gql`
  mutation ($viewId: String!, $data: EditViewWithSensorsInput!) {
    ${EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS_KEY}(uuid: $viewId, data: $data) {
      id
      name
      type
      sensors {
        id
        name
        unit
        location {
          id
          name
        }
        sensorId
        measurement
        updatedAt
      }
      updatedAt
    }
  }
`;

type MutVarEditCumulativeSumLightHoursViewSensors = {
  viewId: string;
  data: {
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
  };
};

type MutProjectSensor = Pick<
  ProjectSensor,
  'id' | 'name' | 'unit' | 'location' | 'sensorId' | 'measurement' | 'updatedAt'
>;

type MutResEditCumulativeSumLightHoursViewSensors = Pick<
  CumulativeSumLightHoursView,
  'id' | 'name' | 'type' | 'updatedAt'
> & {
  sensors: MutProjectSensor[];
};

const update: MutationUpdateFunction<
  MutVarEditCumulativeSumLightHoursViewSensors,
  MutResEditCumulativeSumLightHoursViewSensors
> = (cache, data) => {
  const result = data.data;
  if (result) {
    const view = result[EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS_KEY];
    cache.writeFragment({
      id: cache.identify(view),
      fragment: gql`
        fragment UpdateView on View {
          sensors
        }
      `,
      data: {
        sensors: view.sensors,
      },
    });
  }
};

export const useEditCumulativeLightViewSensors = () => {
  return useDefaultMutation<CumulativeSumLightHoursView, MutVarEditCumulativeSumLightHoursViewSensors>(
    EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS,
    EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS_KEY,
    update,
  );
};
