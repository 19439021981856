import { Sensor } from 'graphql/generated';
import { urqlClient } from 'graphql/urql';
import { OperationResult } from 'urql';
import { DefaultQueryResponse } from './default';

const QUERY_SENSOR_KEY = 'sensor';

const QUERY_SENSOR = `
  query ($sensorId: String!) {
    ${QUERY_SENSOR_KEY}(uuid: $sensorId) {
      id
      name
      unit
      measurement
      updatedAt
    }
  }
`;

type QryVarSensor = {
  sensorId: string;
};

export const querySensor = async (
  variables: QryVarSensor,
): Promise<
  OperationResult<
    DefaultQueryResponse<typeof QUERY_SENSOR_KEY, Pick<Sensor, 'id' | 'name' | 'unit' | 'measurement' | 'updatedAt'>>
  >
> => {
  return urqlClient
    .query<
      DefaultQueryResponse<typeof QUERY_SENSOR_KEY, Pick<Sensor, 'id' | 'name' | 'unit' | 'measurement' | 'updatedAt'>>,
      QryVarSensor
    >(QUERY_SENSOR, variables)
    .toPromise();
};
