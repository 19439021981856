import { ComponentProps, FC, forwardRef, InputHTMLAttributes } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import styled from 'styled-components';
import type { LayoutProps, SpaceProps } from 'styled-system';
import { compose, layout, space } from 'styled-system';
import { Box } from '../layout/Box';

const SMdKeyboardArrowDown = styled(MdKeyboardArrowDown)`
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

type SelectProps = SpaceProps &
  Omit<LayoutProps, 'size'> & {
    isValid?: boolean;
  };

const SSelect = styled.select<SelectProps>`
  appearance: none;
  width: 100%;
  min-height: 100%;
  outline: none;
  box-sizing: border-box;
  font-size: medium;
  padding: 0.375rem 1.75rem 0.375rem 0.375rem;
  border: 1px solid
    ${(p) =>
      (p.isValid === true && p.theme.colors[500]) ||
      (p.isValid === false && p.theme.colors.red[500]) ||
      p.theme.colors.grey[500]};
  font-family: ${(p) => p.theme.fontFamily};
  &:hover,
  :active,
  :focus {
    border-color: ${(p) => p.theme.colors.brand};
  }
  ${compose(space, layout)}
`;

type Props = {
  placeholder?: string;
  isValid?: boolean;
  selectName?: string;
  selectId?: string;
  selectProps?: SelectProps;
} & Omit<ComponentProps<typeof Box>, 'position'> &
  Pick<InputHTMLAttributes<HTMLSelectElement>, 'value' | 'onChange' | 'required' | 'onClick'>;

export const Select: FC<Props> = forwardRef<HTMLSelectElement, Props>(
  (
    {
      placeholder,
      isValid,
      children,
      selectName,
      selectId,
      value,
      onChange,
      onClick,
      required,
      disabled,
      selectProps,
      ...props
    },
    ref,
  ) => {
    return (
      <Box position="relative" {...props}>
        <SSelect
          isValid={isValid}
          value={value}
          onChange={onChange}
          onClick={onClick}
          id={selectId}
          name={selectName}
          required={required}
          disabled={disabled}
          ref={ref}
          defaultValue={placeholder ?? undefined}
          {...selectProps}
        >
          {placeholder && (
            <option disabled hidden value={placeholder}>
              {placeholder}
            </option>
          )}
          {children}
        </SSelect>
        <SMdKeyboardArrowDown />
      </Box>
    );
  },
);

Select.displayName = 'Select';
