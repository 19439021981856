import { VFC } from 'react';
import { Flex, FlexProps } from './layout/Flex';
import Spinner from './Spinner';
import { Text } from './typography';

type Props = Omit<FlexProps, 'color'> & {
  loading: boolean;
  message: string;
};

export const Loading: VFC<Props> = ({ loading, message, ...props }) => {
  return loading ? (
    <Flex {...props} alignItems="center" gap="1rem">
      <Spinner /> <Text variant="small">{message}...</Text>
    </Flex>
  ) : null;
};
