import { Project } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { CascadeTime } from 'types/project';
import { useMutation } from 'urql';

const EDIT_PROJECT_ALERT_CASCADE_TIME_KEY = 'editProjectAlertCascadeTime';

const EDIT_PROJECT_ALERT_CASCADE_TIME = `
  mutation ($projectId: String!, $alertCascadeTime: String!) {
    ${EDIT_PROJECT_ALERT_CASCADE_TIME_KEY}(uuid: $projectId, data: { item: $alertCascadeTime }) {
      id
      alertCascadeTime
    }
  }
`;

type MutVarEditProjectAlertCascadeTime = {
  projectId: string;
  alertCascadeTime: CascadeTime;
};

type MutResEditProjectAlertCascadeTime = DefaultQueryResponse<
  typeof EDIT_PROJECT_ALERT_CASCADE_TIME_KEY,
  Pick<Project, 'id' | 'alertCascadeTime'>
>;

export const useEditProjectAlertCascadetime = () => {
  return useMutation<MutResEditProjectAlertCascadeTime, MutVarEditProjectAlertCascadeTime>(
    EDIT_PROJECT_ALERT_CASCADE_TIME,
  );
};
