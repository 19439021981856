import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { File as ApiFile, FileStatus } from 'graphql/generated';
import { Organization } from 'types/organization';
import { useUploadFile } from './useUploadFile';
import { useAddFile } from 'graphql/mutation/useAddFile';
import { useEditFileStatus } from 'graphql/mutation/useEditFileStatus';

export function useUploadFileApi(): [
  upload: (file: File, organizationId: Organization['id']) => Promise<ApiFile>,
  uploading: boolean,
  progress: number,
  error: string | undefined,
] {
  const { t } = useTranslation();
  const [{ fetching: adding }, addFile] = useAddFile();
  const [uploadFile, uploading, progress, uploadTimedout, uploadAborted, uploadErrored] = useUploadFile();
  const [{ fetching: editingStatus }, editStatus] = useEditFileStatus();

  const upload = useCallback(
    async (file: File, organizationId: Organization['id']) => {
      let fileObj: ApiFile | undefined;
      try {
        const fileObjUpload = await addFile({
          name: file.name,
          contentType: file.type,
          organizationId,
        });
        if (fileObjUpload.data) {
          fileObj = fileObjUpload.data.addFile.file;
          await uploadFile(fileObjUpload.data.addFile.uploadLink, file);
          const updatedFileResponse = await editStatus({
            fileId: fileObj.id,
            status: FileStatus.Ready,
          });
          if (updatedFileResponse.data) {
            return updatedFileResponse.data?.editFileStatus;
          }
        }
        throw new Error(t('Unable to upload file'));
      } catch (e) {
        if (fileObj) {
          editStatus({
            fileId: fileObj.id,
            status: FileStatus.Error,
          });
        }
        throw e;
      }
    },
    [addFile, editStatus, uploadFile, t],
  );

  const error = useMemo(() => {
    if (uploadTimedout) {
      return t('Uploading timed out');
    }
    if (uploadAborted) {
      return t('Upload aborted');
    }
    if (uploadErrored) {
      return t('Upload errored');
    }
  }, [uploadAborted, uploadErrored, uploadTimedout, t]);

  return useMemo(
    () => [upload, adding || uploading || editingStatus, progress, error],
    [adding, editingStatus, error, progress, upload, uploading],
  );
}
