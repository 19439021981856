import type { PeriodArray } from 'components/form/RangeDatePicker';
import { isObject } from 'utils/types';

export type LinkedPeriod = {
  id: string;
  // ISO string
  start: string;
  // ISO string
  end?: string | null;
  // ISO string
  createdAt: string;
  // ISO string
  updatedAt: string;
};

export type Period = {
  start: string;
  end?: string;
};

export function isPeriod(value: unknown): value is Period {
  if (isObject(value)) {
    const validStart = typeof value.start === 'string' && !isNaN(new Date(value.start).getMonth());
    const validEnd = value.end == null || (typeof value.end === 'string' && !isNaN(new Date(value.end).getMonth()));
    return validStart && validEnd;
  }
  return false;
}

export function isSamePeriod(linkedPeriod: LinkedPeriod, period: PeriodArray): boolean {
  if (linkedPeriod.start === period[0]?.toISOString()) {
    if (linkedPeriod.end === undefined && period[1] === undefined) {
      return true;
    } else if (linkedPeriod.end && period[1]) {
      if (linkedPeriod.end === period[1].toISOString()) {
        return true;
      }
    }
  }
  return false;
}
