import { css } from '@styled-system/css';
import { Badge } from 'components/Badge';
import { Flex } from 'components/layout/Flex';
import { ResponsiveImage } from 'components/ResponsiveImage';
import { CardTitle } from 'components/typography/CardTitle';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AddonInfo, prettifyAddonType } from 'types/addon';

const Card = styled.div`
  width: 100%;
  ${css({ padding: 1 })}
  cursor: pointer;
  ${css({
    borderWidth: 0.5,
    borderColor: 'white',
    borderStyle: 'solid',
    borderRadius: 'md',
  })}

  &:hover {
    ${css({
      borderColor: 'grey.500',
    })}
  }
`;

type Props = {
  addonInfo: AddonInfo;
  onClick: () => void;
};

export const AddonInfoCard: FC<Props> = ({ addonInfo, onClick }) => {
  const { t } = useTranslation();
  return (
    <Card onClick={onClick}>
      <Flex>
        <ResponsiveImage image={addonInfo.image} />
      </Flex>
      <Flex mt="2">
        <Badge variant="branded">{prettifyAddonType(addonInfo.type, t)}</Badge>
      </Flex>
      <Flex>
        <CardTitle>{addonInfo.name}</CardTitle>
      </Flex>
    </Card>
  );
};
