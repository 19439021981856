import { animated, useSpring } from '@react-spring/web';
import { css } from '@styled-system/css';
import { useDomRect } from 'hooks/useDomRect';
import { useLastDefinedValue } from 'hooks/useLastDefinedValue';
import { createContext, FC, ReactNode, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import type { LayoutProps } from 'styled-system';
import { color, layout } from 'styled-system';

export type DrawerSide = 'left' | 'right';

type ContainerProps = LayoutProps;

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  ${layout}
`;

const Background = styled.div`
  z-index: 1000;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.7;
`;

const Drawer = styled(animated.div)<{ $side: DrawerSide }>((p) => [
  css({
    opacity: 1,
    pointerEvents: 'all',
    zIndex: 9999,
    overflow: 'visible',
    height: '100%',
    maxWidth: '100%',
    position: 'absolute',
    left: p.$side === 'right' ? '100%' : 'auto',
    right: p.$side === 'left' ? '100%' : 'auto',
    top: 0,
    '> *': {
      maxWidth: '100%',
      minHeight: '100%',
    },
  }),
  color,
]);

const Content = styled.div`
  position: relative;
  flex-grow: 1;
`;

const xOffsetMultiplier = {
  left: 1,
  right: -1,
};
const pusherOrder = {
  left: 0,
  right: 2,
};

export const WithDrawerContext = createContext<{
  side: DrawerSide;
  closing: boolean;
  reserveSpace: number;
  setReserveSpace: (space: number) => void;
}>({
  side: 'left',
  closing: true,
  reserveSpace: 0,
  setReserveSpace: () => {
    throw new Error('Not in a WithDrawerContext provider');
  },
});

type Props = ContainerProps & {
  drawerChildren?: ReactNode;
  /** Whenever this value updates, reserved space gets reset. */
  drawerChildrenKey?: string | number;
  side: DrawerSide;
};

export const WithDrawer: FC<Props> = ({ children, drawerChildren, drawerChildrenKey, side, ...props }) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const domRect = useDomRect(drawerRef);
  const drawerWidth = domRect?.width ?? 0;

  const [afterLoaded, setAfterLoaded] = useState(false);
  useLayoutEffect(() => {
    const loaded = domRect !== undefined;
    // Timeout to prevent react batching this update together with a change in width.
    // The useSpring immediate property should only become false until after useSpring has received the new width,
    // otherwise it will animate the transition!
    // This is an unavoidable pattern because we measure the width of the content.
    // It would be nice if react-spring would have a "trailing edge" immediate flag.
    setTimeout(() => {
      if (loaded) {
        setAfterLoaded(loaded);
      }
    }, 0);
  }, [domRect]);

  const [reserveSpace, setReserveSpace] = useState(0);

  useEffect(() => {
    setReserveSpace(0);
  }, [drawerChildrenKey]);

  const opened = useMemo(() => drawerChildren !== undefined, [drawerChildren]);

  // Will sync up with opened, when animation has ended.
  // Is true when open animation has finished.
  // Is false when close animation has finished.
  const [restingOpened, setRestingOpened] = useState<boolean>();
  const definitelyClosed = !opened && !restingOpened;
  const asideXOffset = opened ? drawerWidth * xOffsetMultiplier[side] : 0;
  const pushContentBy = Math.min(drawerWidth, reserveSpace);

  const pusherStyle = useSpring({
    width: opened ? pushContentBy : 0,
    order: pusherOrder[side],
    immediate: !afterLoaded,
  });

  const drawerStyle = useSpring({
    transform: `translateX(${asideXOffset}px)`,
    immediate: !afterLoaded,
    onRest: () => {
      setRestingOpened(opened);
    },
  });

  // We need to keep a renderable version of drawer contents around, even after it becomes undefined
  // because we need to render something when the close transition is running inside WithDrawer!
  const lastDrawerChildren = useLastDefinedValue(drawerChildren);

  const providerValue = useMemo(
    () => ({
      side,
      closing: !opened,
      reserveSpace,
      setReserveSpace,
    }),
    [opened, reserveSpace, side],
  );
  const searchParams = new URLSearchParams(window.location.search);

  return (
    <Container {...props}>
      <animated.div style={pusherStyle} />
      <Drawer ref={drawerRef} style={drawerStyle} $side={side}>
        {!definitelyClosed && (
          <WithDrawerContext.Provider value={providerValue}>{lastDrawerChildren}</WithDrawerContext.Provider>
        )}
      </Drawer>
      {searchParams.get('drawer') !== 'note' && opened && side === 'right' && <Background />}
      <Content>{children}</Content>
    </Container>
  );
};
