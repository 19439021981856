import { FC, useContext } from 'react';
import { createGlobalStyle, ThemeContext } from 'styled-components';
import { Theme } from 'theme';

const GlobalStyle: FC = () => {
  const theme = useContext<Theme>(ThemeContext);
  // --chakra-fonts-body variable because chakra's provider messes with out body styling!
  // we must apply the styling to the body for proper inheritance, * selector is not an option
  const Style = createGlobalStyle`
    html, body {
      height: 100%;
      margin: 0;
    }
    #root {
      height: 100%;
    }
    body {
      --chakra-fonts-body: ${theme.fonts.main ?? 'initial'};
      font-size: ${theme.fontSizes.md ?? '1rem'};
      font-family: ${theme.fonts.main ?? 'initial'};
      overflow: hidden;
    }
  `;
  return <Style />;
};

export default GlobalStyle;
