import type { SystemStyleObject } from '@styled-system/css';
import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, LayoutProps, SpaceProps, TypographyProps } from 'styled-system';
import { color, compose, layout, space, typography } from 'styled-system';

type StyleProps = SpaceProps & LayoutProps & TypographyProps & ColorProps;

const styleFns = [space, layout, typography, color];

const commonHStyle: SystemStyleObject = {
  marginTop: 0,
  marginBottom: 0,
  fontFamily: 'main',
  fontWeight: 'bold',
  wordBreak: 'normal',
  overflowWrap: 'break-word',
  textTransform: 'lowercase',
  '&:first-letter': {
    textTransform: 'uppercase',
  },
  lineHeight: 'normal',
};

export const H1 = styled.h1<StyleProps>(
  css({
    ...commonHStyle,
    fontSize: ['xl', '2xl', '3xl', '4xl', '5xl'],
  }),
  compose(...styleFns),
);

export const H2 = styled.h2<StyleProps>(
  css({
    ...commonHStyle,
    fontSize: ['lg', 'xl', '2xl', '3xl', '4xl'],
  }),
  compose(...styleFns),
);

const H3 = styled.h3<StyleProps>(
  css({
    ...commonHStyle,
    fontSize: ['md', 'lg', 'xl', '2xl', '3xl'],
    fontWeight: 'semibold',
  }),
  compose(...styleFns),
);

H3.defaultProps = {
  mb: 2,
};

const H4 = styled.h4<StyleProps>(
  css({
    ...commonHStyle,
    fontSize: ['sm', 'md', 'lg', 'xl', '2xl'],
    fontWeight: 'semibold',
    '@media (max-width: 600px)': {
      fontSize: ['md', 'lg', 'xl', '2xl', '3xl'],
    },
  }),
  compose(...styleFns),
);

H4.defaultProps = {
  mt: 2,
  mb: 2,
};

export { H3, H4 };

export const H5 = styled.h5<StyleProps>(
  css({
    ...commonHStyle,
    fontSize: ['xs', 'sm', 'md', 'lg', 'xl'],
    fontWeight: 'semibold',
  }),
  compose(...styleFns),
);
