import { useSnackbar } from 'components/Snackbar';
import { DraftTableRow } from 'components/table/DraftTableRow';
import { Table, Tbody, Td, TdOverflow, Th, Thead, Tr } from 'components/table/Table';
import { TableMoreMenu } from 'components/table/TableMoreMenu';
import { Paragraph } from 'components/typography';
import { AuthContext } from 'context/AuthContext';
import { formatDistanceToNow } from 'date-fns';
import { UserInvitation } from 'graphql/generated';
import { useDeleteUserInvitation } from 'graphql/mutation/useDeleteUserInvitation';
import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDeleteOutline } from 'react-icons/md';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { upperFirst } from 'utils/visualize';

type Props = {
  invitations?: Omit<UserInvitation, 'user' | 'organization'>[];
};

export const UserInvitationTable: FC<Props> = ({ invitations }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);
  const [{ fetching: deletingInvitation }, deleteInvitation] = useDeleteUserInvitation();

  const onDelete = async (invitationId: string) => {
    const deleteInvitationResult = await deleteInvitation(
      { invitationId },
      { additionalTypenames: ['UserInvitation'] },
    );
    if (deleteInvitationResult.data) {
      snackbar.addAlert(t('The invitation has been deleted'), Severity.SUCCESS);
    }
    if (deleteInvitationResult.error) {
      dispatchErrors(snackbar, deleteInvitationResult.error, authContext, t);
    }
  };

  const createInvitationStatus = useCallback(
    (invitation: Omit<UserInvitation, 'user' | 'organization'>): string => {
      const now = new Date();
      const invitationExpirationDate = new Date(invitation.expiration);
      if (now.getTime() > invitationExpirationDate.getTime()) {
        return t('Expired');
      } else {
        return t('{{status}}, expires {{when}}', {
          status: upperFirst(t(invitation.status, { ns: 'db-values' })),
          when: formatDistanceToNow(new Date(invitation.expiration), {
            addSuffix: true,
          }),
        });
      }
    },
    [t],
  );

  return invitations ? (
    <>
      <Table fixed>
        <Thead>
          <Tr>
            <Th width="8rem">{t('Email')}</Th>
            <Th width="5rem">{t('Role')}</Th>
            <Th width="6rem">{t('Status')}</Th>
            <Th width="5rem" />
          </Tr>
        </Thead>
        <Tbody>
          {invitations.map((invitation) => {
            const status = createInvitationStatus(invitation);
            return (
              <Tr key={invitation.id}>
                <TdOverflow title={invitation.email}>{invitation.email}</TdOverflow>
                <TdOverflow title={t(invitation.role, { ns: 'db-values' })}>
                  {t(invitation.role, { ns: 'db-values' })}
                </TdOverflow>
                <TdOverflow title={status}>{status}</TdOverflow>
                <Td>
                  <TableMoreMenu
                    items={[
                      {
                        text: t('actions.Delete invitation'),
                        onClick: () => onDelete(invitation.id),
                        icon: MdDeleteOutline,
                      },
                    ]}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <DraftTableRow loading={deletingInvitation} show={invitations.length === 0} label={t('No pending invitations')} />
    </>
  ) : (
    <Paragraph>{t('No pending invitations')}</Paragraph>
  );
};
