import { FC } from 'react';
import { Box } from 'components/layout/Box';
import { css } from '@styled-system/css';
import styled from 'styled-components';
import { colors } from 'theme/colors';

export type BadgeProperty = {
  type: 'badge';
  color?: string;
  value: string | undefined | null;
  id?: string;
};

type Props = Omit<BadgeProperty, 'type'>;

export const BadgeProperty: FC<Props> = ({ value, color }) => {
  return (
    <BadgePropertyContainer>
      <BadgeContentContainer color={color}>
        <BadgeContent>{value ? value : 'Empty'}</BadgeContent>
      </BadgeContentContainer>
    </BadgePropertyContainer>
  );
};

const BadgePropertyContainer = styled(Box)(
  css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3px',
  }),
);

const BadgeContentContainer = styled.div<{ color?: string }>(({ color }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    borderRadius: '3px',
    px: '6px',
    backgroundColor: color ?? colors.base[200],
    minWidth: 0,
  }),
);

const BadgeContent = styled.span(
  css({
    minWidth: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
);
