import { Button } from 'components/buttons';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAddProjectSensors } from 'server/mutations/useAddProjectSensors';
import { useFilterStore } from 'state/filterStore';
import { Project } from 'types/project';
import { useTranslation } from 'react-i18next';
import { MeasurementSelect } from 'components/form/MeasurementSelect';
import { Sensor, useQrySensorPages } from 'graphql/generated';
import { useGetProjectSensorPages } from 'server/queries/useGetProjectSensorPages';
import { usePaginationStore } from 'state/paginationStore';
import shallow from 'zustand/shallow';

type CreateProjectSensorDrawerProps = {
  project: Project;
};

type Props = DrawerComponentProps & CreateProjectSensorDrawerProps;

export const CreateProjectSensorDrawer: FC<Props> = ({ project, requestClose }) => {
  const { t } = useTranslation();

  useRouteChangeBlock();

  const [selectedSensors, setSelectedSensors] = useState<Sensor[]>([]);
  const [addSensorToProject, adding] = useAddProjectSensors();
  const clearFilters = useFilterStore((state) => state.projectSensorsForm.clear);

  const onSubmit = useCallback(async () => {
    clearFilters();

    if (selectedSensors.length > 0) {
      const sensorIds = selectedSensors.map((sensor) => sensor.id);
      await addSensorToProject({
        projectId: project.id,
        sensorIds,
      });
      requestClose();
    }
  }, [clearFilters, selectedSensors, addSensorToProject, project.id, requestClose]);

  const [projectWithSensors] = useGetProjectSensorPages({
    uuid: project?.id,
  });

  const sensorIdsAlreadyAssignedToProject = useMemo(() => {
    return projectWithSensors?.sensorPages?.items.map((s) => s.sensorId);
  }, [projectWithSensors?.sensorPages?.items]);

  const [limit, offset, setOffset] = usePaginationStore(
    (state) => [state.sensorSelectorTable.limit, state.sensorSelectorTable.offset, state.sensorSelectorTable.setOffset],
    shallow,
  );

  const [filter, setFilter] = useFilterStore(
    (state) => [state.projectSensorsForm.values, state.projectSensorsForm.filter],
    shallow,
  );

  const [{ data: sensorPages, fetching: loadingSensors }] = useQrySensorPages(
    {
      items: {
        id: true,
        name: true,
        measurement: true,
        unit: true,
        device: {
          id: true,
          name: true,
          currentLocation: {
            id: true,
            location: {
              name: true,
            },
          },
        },
        createdAt: true,
        deviceId: true,
        updatedAt: true,
      },
      count: true,
    },
    {
      page: {
        limit,
        offset,
      },
      filter,
    },
    {},
  );

  useEffect(() => {
    setOffset(0);
  }, [filter.name, setOffset]);

  const loadOptions = useCallback(async () => {
    const pageCount = sensorPages?.count ?? 0;
    const totalPageCount = Math.ceil(pageCount / limit);
    const currentPage = Math.floor(offset / limit) + 1;

    const hasMore = currentPage < totalPageCount;

    if (hasMore) {
      setOffset(currentPage * limit);
    }

    const filteredSensors =
      sensorPages?.items
        .filter((sensor) => !sensorIdsAlreadyAssignedToProject?.includes(sensor.id))
        .map((sensor) => ({
          ...sensor,
          location: sensor.device?.currentLocation?.location,
        })) || [];

    return {
      options: filteredSensors as Sensor[],
      hasMore,
    };
  }, [offset, sensorPages, limit, setOffset, sensorIdsAlreadyAssignedToProject]);

  const removeSensor = useCallback(
    (sensor: Sensor) => {
      const filteredSensors = selectedSensors.filter((selectedSensor) => selectedSensor.id !== sensor.id);
      setSelectedSensors(filteredSensors);
    },
    [selectedSensors, setSelectedSensors],
  );

  const handleSearch = (search: string) => {
    setFilter({ name: search });
  };

  return (
    <DrawerLayout title={t('actions.Add measurement')} onClose={requestClose}>
      <Body>
        <MeasurementSelect<Sensor>
          label={`${t('Select measurements to add to this project')}*`}
          selected={selectedSensors}
          loading={loadingSensors}
          onChange={(v) => setSelectedSensors(v as Sensor[])}
          loadOptions={loadOptions}
          inputValue={filter.name}
          onRemoveItem={removeSensor}
          onInputChange={handleSearch}
          onMenuClose={() => {
            setOffset(0);
          }}
        />
      </Body>
      <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
        <Button smallPad onClick={() => requestClose()} variant="plain">
          {t('actions.Cancel')}
        </Button>
        <Button smallPad loading={adding} onClick={onSubmit} disabled={selectedSensors.length === 0}>
          {t('actions.Add measurement', { count: selectedSensors.length })}
        </Button>
      </DrawerFooter>
    </DrawerLayout>
  );
};
