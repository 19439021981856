import { Portal } from '@chakra-ui/portal';
import { css } from '@styled-system/css';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import type { MaxWidthProps } from 'styled-system';
import { maxWidth } from 'styled-system';
import { Button } from './buttons';
import { CloseButton } from './buttons/CloseButton';
import { Input } from './form';
import { Box } from './layout/Box';
import { Flex } from './layout/Flex';
import { H3, Text } from './typography';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: contrast(90%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

const Content = styled.div<MaxWidthProps>`
  position: relative;
  overflow: auto;
  border-radius: 0.4rem;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.2);
  ${css({
    px: ['1rem', '2rem', '3rem', '4rem'],
    py: ['1rem', null, '2rem', '3rem'],
    backgroundColor: 'white',
  })}
  ${maxWidth}
`;

const StickToTop = styled.div`
  position: sticky;
  backdrop-filter: blur(4px);
  ${css({
    top: ['-1rem', null, '-2rem', '-3rem'],
  })}
`;

const Form = styled.form`
  margin-top: 2rem;
`;

type Props = {
  title: ReactNode;
  loading?: boolean;
  description: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  safeWord?: {
    hint: string;
    value: string;
  };
} & MaxWidthProps;

export const Dialog: FC<Props> = (props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [safeWordValue, setSafeWordValue] = useState('');

  const { title, loading, description, onCancel, onConfirm, safeWord, maxWidth } = props;

  const canConfirm = useMemo(
    () => safeWord === undefined || safeWord.value === safeWordValue,
    [safeWord, safeWordValue],
  );

  const handleConfirm = useCallback(
    (e) => {
      e.preventDefault();
      if (canConfirm) {
        onConfirm();
      }
    },
    [canConfirm, onConfirm],
  );

  useOnClickOutside(ref, onCancel);

  return (
    <Portal>
      <Container>
        <Content ref={ref} maxWidth={maxWidth}>
          <StickToTop>
            <Flex alignItems="center" justifyContent="space-between">
              <div>
                <H3>{title}</H3>
              </div>
              <div>
                <CloseButton color="grey.500" onClick={onCancel} />
              </div>
            </Flex>
          </StickToTop>
          <Form onSubmit={handleConfirm}>
            <Box marginBottom="3rem">{description}</Box>
            {safeWord && <Text mb={4}>{safeWord.hint}</Text>}
            <Flex justifyContent="flex-end" alignItems="stretch">
              {safeWord && (
                <Input
                  flexProps={{ flexGrow: 1 }}
                  placeholder={safeWord.value}
                  value={safeWordValue}
                  onChange={(e) => setSafeWordValue(e.target.value)}
                  validate={(v) => v === safeWord?.value}
                  marginProps={{
                    mr: 2,
                  }}
                />
              )}
              <Button backgroundColor="red.100" color="red.500" type="button" mr={2} onClick={onCancel}>
                {t('actions.Cancel')}
              </Button>
              <Button type="submit" variant="danger" disabled={!canConfirm} loading={loading}>
                {t('actions.Confirm')}
              </Button>
            </Flex>
          </Form>
        </Content>
      </Container>
    </Portal>
  );
};
