import { gql } from '@apollo/client';
import { MonitorView, ProjectSensor } from 'graphql/generated';
import { useDefaultMutation } from '../useDefaultMutation';

const EDIT_MONITOR_VIEW_SENSORS_KEY = 'editMonitorViewSensors';

const EDIT_MONITOR_VIEW_SENSORS = gql`
  mutation ($viewId: String!, $projectSensorIds: [String!]!) {
    ${EDIT_MONITOR_VIEW_SENSORS_KEY}(uuid: $viewId, query: { items: $projectSensorIds }) {
      id
      name
      type
      sensors {
        id
        name
        unit
        location {
          id
          name
        }
        sensorId
        measurement
        updatedAt
      }
      updatedAt
    }
  }
`;

type MutVarEditMonitorViewSensors = {
  viewId: string;
  projectSensorIds: string[];
};

type MutProjectSensor = Pick<
  ProjectSensor,
  'id' | 'name' | 'unit' | 'location' | 'sensorId' | 'measurement' | 'updatedAt'
>;

type MutResEditMonitorViewSensors = Pick<MonitorView, 'id' | 'name' | 'type' | 'updatedAt'> & {
  sensors: MutProjectSensor[];
};

export const useEditMonitorViewSensors = () => {
  return useDefaultMutation<MutResEditMonitorViewSensors, MutVarEditMonitorViewSensors>(
    EDIT_MONITOR_VIEW_SENSORS,
    EDIT_MONITOR_VIEW_SENSORS_KEY,
  );
};
