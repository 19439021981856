import { User } from './user';

export enum NotificationType {
  ALERT = 'alert',
  MISSING_VALUE = 'missing_value',
}

export enum AlertNotificationStatus {
  CREATED = 'created',
  NOTIFIED = 'notified',
  CONFIRMED = 'confirmed',
  CONTROL = 'control',
  IGNORED = 'ignored',
  HANDLED = 'handled',
}

export enum AlertIgnoredCause {
  PROJECT_MAINTENANCE = 'project_maintenance',
  OTHER_ALERT = 'other_alert',
}

export enum CommunicationChannel {
  MAIL = 'Mail',
  SMS = 'SMS',
}

export interface Notification {
  id: string;
  type: NotificationType;
  status: AlertNotificationStatus;
  message: string;
  cause?: AlertIgnoredCause;
  reference?: string;
  channel?: CommunicationChannel;
  user?: User;
  /** Timestamp in ISO format */
  createdAt: string;
}
