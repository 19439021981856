import { gql } from '@apollo/client';
import { Addon, AddonData, AddonName, AddonType } from 'types/addon';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';

const ADD_ADDON_KEY = 'addAddon';

const ADD_ADDON = gql`
  mutation ($name: String!, $type: String!, $data: AddonDataInput!) {
    ${ADD_ADDON_KEY}(data: { name: $name, type: $type, data: $data }) {
      id
      name
      type
      data {
        customerId
      }
      createdAt
    }
  }
`;

type MutVarAddAddon = {
  name: AddonName;
  type: AddonType;
  data: {
    customerId: string;
  };
}

type MutAddonData = Pick<AddonData, 'customerId'>;

type MutResAddAddon = Pick<Addon, 'id' | 'name' | 'type' | 'createdAt'> & {
  data: MutAddonData;
};

const update: MutationUpdateFunction<MutVarAddAddon, MutResAddAddon>
  = (cache, data) => {
  const result = data.data;
  if (result) {
    const newAddonRef = cache.writeFragment({
      data: result[ADD_ADDON_KEY],
      fragment: gql`
        fragment NewAddon on Addon {
          id
        }
      `,
    });
    cache.modify({
      fields: {
        addons(addons = []) {
          return [...addons, newAddonRef];
        },
      },
    });
  }
};


export const useAddAddon = () => {
  return useDefaultMutation<MutResAddAddon, MutVarAddAddon>(ADD_ADDON, ADD_ADDON_KEY, update);
};


