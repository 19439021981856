import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const ADD_FILE_PARSER_SETTINGS_KEY = 'addFileParserSettings';

const ADD_FILE_PARSER_SETTINGS = `
  mutation ($data: AddFileParserSettingsInput!, $organizationId: String!) {
    ${ADD_FILE_PARSER_SETTINGS_KEY}(data: $data, organizationId: $organizationId) {
      id
    }
  }
`;

type MutVarAddFileParserSettings = {
  data: {
    name: string;
    fileEncoding: string;
    fileDelimiter: string;
    startDataRow: number;
    endDataRow?: number;
    dateFormat?: string;
    dateDelimiter?: string;
    dateColumn?: number;
    timeFormat?: string;
    utcOffset?: string;
    timeColumn?: number;
    sampleTime?: number;
    startDateOfSampleTime?: Date;
  };
  organizationId: string;
};

type MutResAddFileParserSettings = DefaultQueryResponse<typeof ADD_FILE_PARSER_SETTINGS_KEY, { id: string }>;

export const useAddFileParserSettings = () => {
  return useMutation<MutResAddFileParserSettings, MutVarAddFileParserSettings>(ADD_FILE_PARSER_SETTINGS);
};
