import { gql } from '@apollo/client';
import { TemperatureHumidityView } from 'types/view/temperatureHumidityView';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';
import { useTranslation } from 'react-i18next';
import { ViewType } from 'types';
import { SampleTime } from '@charphq/types';

const ADD_TEMPERATURE_HUMIDITY_VIEW_KEY = 'addTemperatureHumidityView';

const ADD_TEMPERATURE_HUMIDITY_VIEW = gql`
  mutation ($projectId: String!, $data: AddTemperatureHumidityViewInput!) {
    ${ADD_TEMPERATURE_HUMIDITY_VIEW_KEY}(uuid: $projectId, data: $data) {
      id
      name
      type
      updatedAt
    }
  }
`;

type MutVarAddTemperatureHumidityView = {
  projectId: string;
  data: {
    name: string;
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
    viewType: ViewType.DEW_POINT | ViewType.ABSOLUTE_HUMIDITY;
    sampleTime: SampleTime;
  };
};

type MutResAddTemperatureHumidityView = Pick<
  TemperatureHumidityView,
  'id' | 'name' | 'type' | 'updatedAt' | 'projectId'
>;

export const useAddTemperatureHumidityView = () => {
  const { t } = useTranslation();
  const update: MutationUpdateFunction<MutVarAddTemperatureHumidityView, MutResAddTemperatureHumidityView> = (
    cache,
    data,
    { variables },
  ) => {
    const result = data.data;
    if (result) {
      const newViewRef = cache.writeFragment({
        data: result[ADD_TEMPERATURE_HUMIDITY_VIEW_KEY],
        fragment: gql`
          fragment NewView on View {
            id
          }
        `,
      });
      if (variables === undefined) {
        throw new Error(t('Missing mutation variables'));
      }
      cache.modify({
        id: cache.identify({
          id: variables.projectId,
          __typename: 'Project',
        }),
        fields: {
          views(views = []) {
            return [newViewRef, ...views];
          },
        },
      });
    }
  };

  return useDefaultMutation<MutResAddTemperatureHumidityView, MutVarAddTemperatureHumidityView>(
    ADD_TEMPERATURE_HUMIDITY_VIEW,
    ADD_TEMPERATURE_HUMIDITY_VIEW_KEY,
    update,
  );
};
