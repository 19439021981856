import { FC } from 'react';
import type { FlexBasisProps, FlexGrowProps, FlexShrinkProps, LayoutProps, MarginProps } from 'styled-system';
import { Flex } from './layout/Flex';
import { Paragraph } from './typography';

type Props = {
  message: string;
} & LayoutProps &
  MarginProps &
  FlexBasisProps &
  FlexGrowProps &
  FlexShrinkProps;

const TutorialCta: FC<Props> = ({ message, children, ...props }) => {
  return (
    <Flex {...props} flexDirection="column" justifyContent="center" alignItems="center">
      <Paragraph textAlign="center" mb={4}>
        {message}
      </Paragraph>
      {children}
    </Flex>
  );
};

export { TutorialCta };
