import { TutorialCta } from 'components/TutorialCta';
import { Button } from 'components/buttons';
import { Project } from 'types/project';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  projectId: Project['id'];
  onClick: () => void;
  message?: string;
};

export const NoSensors: FC<Props> = ({
  projectId,
  onClick,
  message = 'This project has no measurements yet. Add some first',
}) => {
  const { t } = useTranslation();

  return (
    <TutorialCta flexGrow={1} message={t(message)}>
      <Link to={`/projects/${projectId}/settings/measurements`}>
        <Button onClick={onClick}>{t('Add measurements')}</Button>
      </Link>
    </TutorialCta>
  );
};
