import { gql } from '@apollo/client';
import { Vendor } from 'types/vendor';
import { MutationUpdateFunction, useDefaultMutation } from '../useDefaultMutation';

const ADD_VERSASENSE_VENDOR_KEY = 'addVersaSenseVendor';

const ADD_VERSASENSE_VENDOR = gql`
  mutation ($data: AddVersaSenseVendorInput!) {
    ${ADD_VERSASENSE_VENDOR_KEY}(data: $data) {
      id
      name
      type
      updatedAt
    }
  }
`;

type MutVarAddVersasenseVendor = {
  data: {
    name: string;
    metadata: {
      topic: string;
      username: string;
      password: string;
    }
  }
}

type MutResAddVersasenseVendor = Pick<Vendor, 'id' | 'name' | 'type' | 'updatedAt'>;

const update: MutationUpdateFunction<MutVarAddVersasenseVendor, MutResAddVersasenseVendor>
 = (cache, data) => {
  const result = data.data;
  if (result) {
    const vendor = result[ADD_VERSASENSE_VENDOR_KEY];
    const newVendorRef = cache.writeFragment({
      data: vendor,
      fragment: gql`
        fragment NewVendor on Vendor {
          id
        }
      `,
    });
    cache.modify({
      fields: {
        vendors(vendors = []) {
          return [...vendors, newVendorRef];
        },
      },
    });
  }
};

export const useAddVersaSenseVendor = () => {
  return useDefaultMutation<MutResAddVersasenseVendor, MutVarAddVersasenseVendor>(ADD_VERSASENSE_VENDOR, ADD_VERSASENSE_VENDOR_KEY, update);
};
