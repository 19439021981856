import { FC } from 'react';
import styled from 'styled-components';

type Props = {
  height: number;
};


export const ScrollDiv = styled.div`
  min-height: 100%;
  max-height: ${(props: Props) => props.height}rem;
  overflow-y: auto;
`;

export const Scrollable: FC<Props> = props => {
  return <ScrollDiv height={props.height}>{props.children}</ScrollDiv>;
};
