import { Button } from 'components/buttons';
import { GuardBox } from 'components/layout/drawer/GuardBox';
import { Flex } from 'components/layout/Flex';
import { WithPushIn } from 'components/layout/WithPushIn';
import { MinimizableDrawerContent } from 'components/MinimizableDrawerContent';
import { DrawerComponent, DrawerComponentProps, DrawerContext } from 'context/DrawerContext';
import { ProjectContext } from 'context/ProjectContext';
import { File, FileItem, Note, useQryNote, useQryOrganization } from 'graphql/generated';
import { useAddFileToItem } from 'graphql/mutation/useAddFileToItem';
import { useAddNoteToProject } from 'graphql/mutation/useAddNoteToProject';
import { useRemoveFileFromItem } from 'graphql/mutation/useRemoveFileFromItem';
import { useUpdateNote } from 'graphql/mutation/useUpdateNote';
import { useQryMe } from 'graphql/query/useQryMe';
import { omit } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import { isNoteUserInput, NoteReference, PartialNote } from 'types/note';
import { NoteDetail } from './NoteDetail';
import { NoteListDrawerArgs } from './NoteListDrawer';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme/colors';
import { Loader } from 'components/loader/loader';
import useIsMobile from 'hooks/useIsMobile';
import { useAddRefToNote } from 'graphql/mutation/useAddRefToNote';
import { format } from 'date-fns';

export type NoteDrawerArgs = {
  noteId?: Note['id'];
  listArgs?: NoteListDrawerArgs;
  onClose?: () => void;
  afterSave?: () => void;
};

type UpdatedNote = Pick<Note, 'id' | 'title' | 'period' | 'description' | 'updatedAt'>;

const NoteDrawer: DrawerComponent<NoteDrawerArgs> = ({
  noteId,
  listArgs,
  requestClose,
  setGuarded,
  closePending,
  acceptClose,
  denyClose,
  onClose,
  afterSave,
}: NoteDrawerArgs & DrawerComponentProps) => {
  const { t } = useTranslation();
  const { requestDrawer } = useContext(DrawerContext);

  // Having no selectNoteId means creating a new note
  const isNewNote = noteId === undefined;

  const [{ data, fetching: loadingUser }] = useQryMe({
    id: true,
    firstName: true,
    lastName: true,
    email: true,
  });
  const [{ data: org, fetching: loadOrg }] = useQryOrganization({ id: true }, {});

  const { project, loading: loadProject } = useContext(ProjectContext);
  const [{ fetching: addingToProject }, addNoteToProject] = useAddNoteToProject();
  const [{ fetching: addingRefToNote }, addRefToNote] = useAddRefToNote();
  const [{ fetching: updating }, updateNote] = useUpdateNote();
  const [{ fetching: addingFile }, addFile] = useAddFileToItem();
  const [{ fetching: removingFile }, removeFile] = useRemoveFileFromItem();

  const [{ data: note, fetching: loadingNote }, refetchNotes] = useQryNote(
    {
      id: true,
      title: true,
      description: true,
      period: {
        start: true,
        end: true,
      },
      owner: {
        id: true,
        email: true,
        firstName: true,
        lastName: true,
        createdAt: true,
      },
      files: {
        id: true,
        name: true,
        status: true,
        owner: {
          id: true,
          firstName: true,
          lastName: true,
          email: true,
          createdAt: true,
        },
        mediaType: true,
        createdAt: true,
      },
      projects: {
        id: true,
        name: true,
      },
      views: {
        id: true,
        name: true,
        type: true,
      },
      devices: {
        id: true,
        name: true,
        locations: {
          id: true,
          location: {
            id: true,
            name: true,
          },
          updatedAt: true,
          createdAt: true,
        },
      },
      sensors: {
        id: true,
        name: true,
      },
      updatedAt: true,
      createdAt: true,
    },
    { noteId: noteId ?? '' },
    {
      pause: noteId === undefined || org?.id === undefined,
    },
  );

  // all state about a note while the user is editing it
  const [editingNote, setEditingNote] = useState<PartialNote>({});
  // Since files are not stored on the note directly, it requires a special flow to keep the same editing UX.
  // This UX being: files can be uploaded+added/removed before the note is saved,
  // but these changes are only persisted when the user clicks the save button.
  // So we _have_ to keep a list of files to add and remove on save.
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  const [filesToRemove, setFilesToRemove] = useState<File[]>([]);

  const handleAddFile = useCallback((file: File) => {
    setFilesToAdd((files) => [...files, file]);
  }, []);

  const handleRemoveFile = useCallback(
    (file: File) => {
      const fileAddIndex = filesToAdd.findIndex((f) => f.id === file.id);
      if (fileAddIndex > -1) {
        setFilesToAdd((files) => files.filter((f) => f.id !== file.id));
      } else {
        setFilesToRemove((files) => [...files, file]);
      }
    },
    [filesToAdd],
  );

  const saveNote = useCallback(async () => {
    if (isNoteUserInput(editingNote) && org !== undefined) {
      let savedNote: UpdatedNote | undefined = undefined;
      if (isNewNote) {
        if (!project) return;
        const newNoteResponse = await addNoteToProject({
          projectId: project.id,
          title: editingNote.title,
          period: editingNote.period,
          description: editingNote.description,
        });
        if (listArgs?.analysisId) {
          addRefToNote({
            data: {
              noteId: newNoteResponse.data?.addNoteToProject?.id,
              refId: listArgs.analysisId,
              ref: NoteReference.VIEW,
            },
          });
        }
        savedNote = newNoteResponse.data?.addNoteToProject;
      } else if (note !== undefined) {
        const updatedNoteResponse = await updateNote({
          uuid: note.id,
          title: editingNote.title,
          period: editingNote.period ? omit(editingNote.period, '__typename') : undefined,
          description: editingNote.description ?? undefined,
        });
        savedNote = updatedNoteResponse.data?.updateNote;
      }
      if (savedNote !== undefined) {
        const itemId = savedNote.id;
        const fileAddRequests = filesToAdd.map((file) =>
          addFile({
            fileId: file.id,
            item: FileItem.Note,
            itemId,
          }),
        );
        const fileRemoveRequests = filesToRemove.map((file) =>
          removeFile({
            fileId: file.id,
            item: FileItem.Note,
            itemId,
            organizationId: org.id,
          }),
        );
        await Promise.all([...fileAddRequests, ...fileRemoveRequests]);
        setFilesToAdd([]);
        setFilesToRemove([]);
        refetchNotes();
        afterSave?.();
        requestDrawer('note', { noteId: savedNote.id }, true, true);
      }
    }
  }, [
    editingNote,
    org,
    isNewNote,
    note,
    project,
    addNoteToProject,
    listArgs,
    addRefToNote,
    updateNote,
    filesToAdd,
    filesToRemove,
    refetchNotes,
    afterSave,
    requestDrawer,
    addFile,
    removeFile,
  ]);

  const formatDateToYYYYMMDD = (date: string) => format(new Date(date), 'yyyy-MM-dd');
  const hasChanges = useMemo(
    () => ({
      title: note?.title !== editingNote?.title,
      description: note?.description !== editingNote?.description,
      start:
        editingNote?.period?.start &&
        note?.period?.start &&
        formatDateToYYYYMMDD(note?.period?.start) !== formatDateToYYYYMMDD(editingNote?.period?.start),
      end:
        editingNote?.period?.end &&
        note?.period?.end &&
        formatDateToYYYYMMDD(note?.period?.end) !== formatDateToYYYYMMDD(editingNote?.period?.end),
      files: filesToAdd.length > 0 || filesToRemove.length > 0,
    }),
    [
      note?.title,
      note?.description,
      note?.period?.start,
      note?.period?.end,
      editingNote?.title,
      editingNote?.description,
      editingNote?.period?.start,
      editingNote?.period?.end,
      filesToAdd.length,
      filesToRemove.length,
    ],
  );

  const isDirty = isNewNote || Object.values(hasChanges).some(Boolean);

  const loading = loadingUser || loadingNote || loadOrg || loadProject;
  const saving = addingToProject || addingRefToNote || updating || addingFile || removingFile;
  useEffect(() => {
    setEditingNote({ ...note, period: listArgs?.period || note?.period });
  }, [listArgs?.period, note, setEditingNote]);

  useEffect(() => {
    setFilesToAdd([]);
    setFilesToRemove([]);
  }, [note]);

  useEffect(() => {
    setGuarded(isDirty);
  }, [isDirty, setGuarded]);

  useEffect(() => {
    const startDateAfterDrag = localStorage.getItem('noteStartDate');
    if (startDateAfterDrag) {
      const { date, id } = JSON.parse(startDateAfterDrag);
      if (id === noteId) {
        const localDate = new Date(date);
        const offset = localDate.getTimezoneOffset();
        const localISOString = new Date(localDate.getTime() - offset * 60 * 1000).toISOString().slice(0, -1);
        setEditingNote({
          ...editingNote,
          period: {
            end: localISOString,
            start: localISOString,
          },
        });
      }
    }
  }, [editingNote, noteId]);

  const isMobile = useIsMobile();

  const handleOnClose = useCallback(
    (useGuard = false) => {
      onClose?.();
      requestClose(useGuard ?? !isDirty);
    },
    [onClose, requestClose, isDirty],
  );

  const Main = (
    <WithPushIn
      width="drawer.note"
      pushInChildren={
        <GuardBox bg={colors.secondary[200]}>
          <Flex alignItems="center" justifyContent="space-between" gap="1rem">
            <span>{t('Discard changes?')}</span>
            <Flex alignItems="center" gap="0.5rem">
              <Button onClick={() => acceptClose()} bg="note.200" color="note.600">
                {t('Yes')}
              </Button>
              <Button onClick={() => denyClose()} bg="note.600" color="black">
                {t('No')}
              </Button>
            </Flex>
          </Flex>
        </GuardBox>
      }
      showPushInChildren={closePending}
    >
      {loading ? (
        <Loader />
      ) : (
        <NoteDetail
          currentUser={data}
          note={note}
          editedNote={editingNote}
          setEditedNote={setEditingNote}
          filesToAdd={filesToAdd}
          onAddFile={handleAddFile}
          filesToRemove={filesToRemove}
          onRemoveFile={handleRemoveFile}
          saving={saving}
          loading={loading}
          onSubmit={saveNote}
          onClickClose={handleOnClose}
          isNewNote={isNewNote}
        />
      )}
    </WithPushIn>
  );

  return isMobile ? (
    Main
  ) : (
    <MinimizableDrawerContent
      backgroundColor="note.100"
      borderColor="note.600"
      pinButtonPrimaryColor="note.600"
      pinButtonSecondaryColor="note.100"
      pinnable
    >
      {{
        main: Main,
        minimized: (
          <Flex pt="3.875rem" justifyContent="center" color="note.600" minWidth="48px">
            <MdOutlineStickyNote2 size="32px" />
          </Flex>
        ),
      }}
    </MinimizableDrawerContent>
  );
};

NoteDrawer.isShareable = true;
NoteDrawer.shareableArgs = ['noteId'];

export { NoteDrawer };
