import { useTranslation } from 'react-i18next';
import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useQryLocation, useQryOrganization } from 'graphql/generated';
import { useEditLocation } from 'graphql/mutation/useEditLocation';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { FC, useCallback, useContext } from 'react';
import { Severity } from 'state/snackbarStore';
import { Location } from 'types/location';
import { dispatchErrors } from 'utils/util';
import { LocationForm } from './LocationFrom';
import { useSnackbar } from './Snackbar';

type Props = DrawerComponentProps & {
  locationId: Location['id'];
};

export const EditLocationDrawer: FC<Props> = ({ locationId, requestClose }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();

  const [{ data: organization }] = useQryOrganization({ id: true }, {});
  const [{ data: location }] = useQryLocation(
    {
      id: true,
      name: true,
      height: true,
      description: true,
      type: {
        id: true,
        name: true,
      },
      room: {
        id: true,
        name: true,
      },
    },
    {
      locationId,
    },
    {},
  );
  const [{ fetching: editingLocation }, editLocation] = useEditLocation();

  const onSubmit = useCallback(
    async ({
      name,
      height,
      description,
      typeId,
      roomId,
    }: {
      name: string;
      height?: string;
      description?: string;
      typeId?: string;
      roomId?: string;
    }) => {
      if (organization) {
        const editedLocation = await editLocation({
          locationId,
          data: {
            name,
            typeId,
            roomId,
            height: height === '' ? undefined : height,
            description: description === '' ? undefined : description,
          },
        });
        if (editedLocation.data) {
          snackbar.addAlert(t('Edited location: {{data}}', { data: editedLocation.data.editLocation.name }), Severity.SUCCESS);
          requestClose();
        }
        if (editedLocation.error) {
          dispatchErrors(snackbar, editedLocation.error, authContext, t);
        }
      }
    },
    [authContext, editLocation, locationId, organization, requestClose, snackbar, t],
  );

  return (
    <DrawerLayout title={t('Edit Location')} onClose={requestClose} width={800}>
      {organization && location && (
        <LocationForm
          location={location}
          submitText={t('Edit Location')}
          organizationId={organization.id}
          onSubmit={onSubmit}
          onClose={requestClose}
          submitting={editingLocation}
        />
      )}
    </DrawerLayout>
  );
};
