import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useQryView, View } from 'graphql/generated';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { VFC } from 'react';
import { ViewType } from 'types/view';
import { Project } from 'types/project';
import { EditAlertView } from './EditAlertView';
import { EditCumulativeLightView } from './EditCumulativeLightView';
import { EditMonitorView } from './EditMonitorView';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/loader/loader';
import { EditAshraeView } from './EditAshraeView';
import { EditTemperatureHumidityView } from './EditTemperatureHumidityView';
import { EditLightBudgetView } from './EditLightBudgetView';

type Props = DrawerComponentProps & {
  projectId: Project['id'];
  viewId: View['id'];
  onClickDelete: () => void;
};

export const ViewEditDrawer: VFC<Props> = ({ projectId, viewId, requestClose, onClickDelete }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();

  const [{ data: view, fetching: loading }] = useQryView(
    {
      id: true,
      type: true,
      name: true,
    },
    { uuid: viewId },
    {},
  );

  const onDelete = () => {
    requestClose();
    onClickDelete();
  };

  return (
    <DrawerLayout title={t('Edit view')} onClose={requestClose}>
      {loading && <Loader />}
      {view?.type === ViewType.MONITOR && (
        <EditMonitorView projectId={projectId} view={view} afterEdit={requestClose} onClickDelete={onDelete} />
      )}
      {view?.type === ViewType.ALERT && <EditAlertView view={view} afterEdit={requestClose} onClickDelete={onDelete} />}
      {view?.type === ViewType.ASHRAE && <EditAshraeView projectId={projectId} view={view} onClose={requestClose} />}
      {view?.type === ViewType.LIGHT_BUDGET && (
        <EditLightBudgetView projectId={projectId} view={view} onClose={requestClose} />
      )}
      {view?.type === ViewType.DEW_POINT && (
        <EditTemperatureHumidityView projectId={projectId} view={view} onClose={requestClose} />
      )}
      {view?.type === ViewType.ABSOLUTE_HUMIDITY && (
        <EditTemperatureHumidityView projectId={projectId} view={view} onClose={requestClose} />
      )}
      {view?.type === ViewType.CUMULATIVE_SUM_LIGHT_HOURS && (
        <EditCumulativeLightView projectId={projectId} view={view} afterEdit={requestClose} onClickDelete={onDelete} />
      )}
    </DrawerLayout>
  );
};
