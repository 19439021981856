import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { useSnackbar } from 'components/Snackbar';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { FormEvent, useCallback, useContext, useMemo, useState, VFC } from 'react';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { isNonEmptyString } from 'utils/validation';
import { upperFirst } from 'utils/visualize';
import { useQryGetProjectUserByProjectAndUser, ProjectUser } from 'graphql/generated';
import { useEditProjectUserRole } from 'graphql/mutation/useEditProjectUserRole';
import { ProjectUserRole, projectUserRoles } from 'types/projectUser';
import { useTranslation } from 'react-i18next';
import { SelectInput } from 'components/form/SelectInput';

type Props = DrawerComponentProps & {
  projectId: string;
  user: ProjectUser;
  callback: () => void;
};

export const EditProjectUserDrawer: VFC<Props> = ({ projectId, user, requestClose, callback }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();
  const authContext = useContext(AuthContext);
  const [{ data: projectUser }] = useQryGetProjectUserByProjectAndUser(
    {
      id: true,
      role: true,
      email: true,
      userId: true,
      firstName: true,
      lastName: true,
      createdAt: true,
    },
    { uuid: projectId, userId: user.userId ?? '' },
    {
      pause: !user.id || !projectId,
    },
  );
  const [role, setRole] = useState<ProjectUserRole>(user.role as ProjectUserRole);

  const [{ fetching: loading }, editProjectUserRole] = useEditProjectUserRole();

  const snackbar = useSnackbar();

  const isFormValid = isNonEmptyString(role);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isFormValid && projectUser?.id) {
        const editUserRoleResult = await editProjectUserRole({
          uuid: projectUser.id,
          data: {
            role,
          },
        });
        if (editUserRoleResult.data) {
          snackbar.addAlert(
            t('The role of {{firstName}} {{lastName}} is updated to {{role}}', {
              role: t(role, { ns: 'db-values' }),
              firstName: user.firstName,
              lastName: user.lastName,
            }),
            Severity.SUCCESS,
          );
          callback();
          requestClose();
        }
        if (editUserRoleResult.error) {
          dispatchErrors(snackbar, editUserRoleResult.error, authContext, t);
        }
      }
    },
    [
      isFormValid,
      editProjectUserRole,
      projectUser?.id,
      user.firstName,
      user.lastName,
      role,
      snackbar,
      callback,
      requestClose,
      authContext,
      t,
    ],
  );

  const projectUserOptions = useMemo(
    () => projectUserRoles.map((v) => ({ value: v, label: upperFirst(t(v, { ns: 'db-values' })) })),
    [t],
  );

  return (
    <DrawerLayout title={`${user.firstName} ${user.lastName}`} onClose={requestClose}>
      <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }} onSubmit={handleSubmit}>
        <Body>
          <SelectInput
            value={projectUserOptions.find((v) => v.value === role)}
            options={projectUserOptions}
            placeholder={t('Select a role')}
            label={t('Select the role of the member:')}
            onChange={(value: string) => setRole(value as ProjectUserRole)}
            width="16.5rem"
          />
        </Body>
        <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
          <Button smallPad onClick={() => requestClose()} variant="plain">
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit" disabled={!isFormValid} loading={loading}>
            {t('actions.Save')}
          </Button>
        </DrawerFooter>
      </Form>
    </DrawerLayout>
  );
};
