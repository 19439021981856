import { LocationReference, Location } from 'graphql/generated';
import { sortByCreatedAt } from './sort';

export type DeviceLocationInput = {
  locations: Array<
    Pick<LocationReference, 'createdAt'> & {
      location: Pick<Location, 'name'>;
    }
  >;
};

export function getDeviceLocation(device: DeviceLocationInput): string | undefined {
  const locationReferences = [...(device?.locations ?? [])];
  return locationReferences.sort(sortByCreatedAt).at(0)?.location?.name;
}
