import { css } from '@styled-system/css';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme/colors';
import { MdCheck, MdOutlineRemove } from 'react-icons/md';

export interface ToggleProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Height of the Toggle.
   * */
  height?: string | number;
  /**
   * Id of the input element.
   * */
  id?: string;
  /**
   * Ref of the input element.
   * */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * Size of the Toggle.
   * */
  size?: number;
  /**
   * Value of the Toggle.
   * */
  value: boolean;
  /**
   * Width of the Toggle.
   * */
  width?: string | number;
  disabled?: boolean;
  /**
   * Props to be passed to the wrapper.
   * */
  wrapperProps?: React.HTMLAttributes<HTMLLabelElement>;
}

export const StyledToggle = styled.label<{
  height?: string;
  size: number;
  width?: string;
}>`
  display: inline-block;
  height: 24px;
  width: 48px;
  position: relative;
  padding: 0.1em;
  ${css({
    border: '1px solid base.600',
  })}

  & input {
    height: 0;
    opacity: 0;
    width: 0;
  }

  & .slider {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    padding: 2px;
    ${css({
      backgroundColor: 'error.700',
      border: '2px solid error.700',
    })}

    :hover {
      opacity: 0.8;
    }
  }

  & .round {
    content: '';
    height: 20px;
    left: 2px;
    position: absolute;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  & input:checked + .slider {
    ${css({
      backgroundColor: 'primary.700',
      border: '2px solid primary.700',
    })}

    .round {
      left: calc(100% + 2px - ${({ size, height }) => height || `${size / 2}px`});
      right: 2px;
    }
  }

  & input:focus,
  active + .slider {
    ${css({
      borderColor: '2px solid base.900',
    })}
  }

  & input:hover + .slider {
    opacity: 0.9;
  }

  & input:disabled + .slider {
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const ToggleButton: React.FC<ToggleProps> = React.forwardRef<HTMLLabelElement, ToggleProps>(
  ({ id, inputRef, value = false, size = 48, wrapperProps, ...props }: ToggleProps, ref): JSX.Element => {
    return (
      <StyledToggle htmlFor={id} ref={ref} size={size} {...wrapperProps}>
        <input ref={inputRef} id={id} type="checkbox" checked={value} {...props} />
        <div className="slider">
          <span className="round">
            {value ? <MdCheck color={colors.brand} /> : <MdOutlineRemove color={colors.error[700]} />}
          </span>
        </div>
      </StyledToggle>
    );
  },
);

ToggleButton.displayName = 'ToggleButton';
