import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';
import { File, FileItem } from 'graphql/generated';

const REMOVE_FILE_FROM_ITEM_KEY = 'removeFileFromItem';

const REMOVE_FILE_FROM_ITEM = `
  mutation ($organizationId: String!, $fileId: String!, $itemId: String!, $item: FileItem!) {
    ${REMOVE_FILE_FROM_ITEM_KEY}(organizationId: $organizationId, data: { fileId: $fileId, itemId: $itemId, item: $item }) {
      id
      name
      status
      mediaType
      createdAt
    }
  }
`;

type MutVarRemoveFileFromItem = {
  organizationId: string;
  fileId: string;
  itemId: string;
  item: FileItem;
};

type MutResDeleteDeviceData = DefaultQueryResponse<
  typeof REMOVE_FILE_FROM_ITEM_KEY,
  Pick<File, 'id' | 'name' | 'status' | 'mediaType' | 'createdAt'>
>;

export const useRemoveFileFromItem = () => {
  return useMutation<MutResDeleteDeviceData, MutVarRemoveFileFromItem>(REMOVE_FILE_FROM_ITEM);
};
