import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { MarginProps } from 'styled-system';
import { Domain } from 'types/data';
import { formatDateRange, genPeriodKey, getDefaultPeriods, PeriodSelection, searchPeriod } from 'utils/date';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { colors } from 'theme/colors';
import { Flex } from './layout/Flex';
import type { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import 'react-day-picker/dist/style.css';
import { MdKeyboardArrowDown, MdOutlineSave } from 'react-icons/md';
import { format, isAfter, isBefore } from 'date-fns';
import { useLocale } from 'context/LocaleContext';
import { Button } from './buttons';
import { Label } from './form/Label';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'hooks/useIsMobile';

export const datePickerStyles = {
  day: {
    borderRadius: 0,
    padding: '0.25rem 7px',
    fontSize: '1.125rem',
    color: colors.base[900],
  },
  button: { borderRadius: 0, color: colors.base[600] },
  caption: { color: colors.base[900], fontSize: '1.25rem', fontWeight: 600 },
  head_cell: { padding: '0.25rem 7px', fontSize: '1.125rem', fontWeight: 400, color: colors.base[900] },
  months: { flexFlow: 'column', gap: '0.5rem', padding: '0.25rem' },
  month: { height: '18.5rem', margin: 0 },
};

export const modifiersStyles = {
  range_start: {
    backgroundColor: colors.brand,
    borderRadius: '2px 0 0 2px',
  },
  range_end: {
    backgroundColor: colors.brand,
    borderRadius: '0 2px 2px 0',
  },
  selected: { backgroundColor: colors.primary[500], color: colors.base[100] },
  today: { fontWeight: 700 },
  nextSelection: {
    backgroundColor: colors.brand,
    border: '2px solid',
    borderColor: colors.base[900],
    borderRadius: '2px',
  },
};

const SArrowDown = styled(MdKeyboardArrowDown)`
  font-size: 1.125rem;
  margin-bottom: 1px;
  width: 1rem;
  height: 1rem;
  @media (max-width: 600px) {
    margin: 0.45rem;
  }
  ${css({
    color: colors.base[600],
  })}
`;

const SArrowUp = styled(SArrowDown)`
  transform: rotate(180deg);
`;

type Props = {
  relativeEndDate: Date;
  period: Domain<Date>;
  onChange: (domain: Domain<Date>) => void;
  loading?: boolean;
  dateFormat?: string;
  onSavePeriod?: (domain: Domain<Date>) => void;
} & MarginProps;

const SLabel = styled(Label)`
  @media (min-width: 601px) {
    top: 0.25rem;
    left: 1.5rem;
    position: absolute;
  }
  @media (max-width: 600px) {
    width: 8rem;
    align-self: center;
  }
  ${css({
    fontSize: '12px',
    color: 'base.600',
    fontWeight: 600,
    textTransform: 'uppercase',
  })}
`;

const SButton = styled.button`
  display: flex;
  font-size: 1.125rem;
  padding: 0 1.5rem 0.25rem 1.5rem;
  justify-content: space-between;
  height: 100%;
  &:disabled {
    cursor: default;
  }
  ${css({
    alignItems: 'end',
    color: colors.base[900],
  })};
  @media (max-width: 600px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const SPeriodSelector = styled.div`
  height: 100%;
  display: flex;
  @media (min-width: 601px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    flex-direction: row;
    padding: 0 1.5rem;
    width: 100%;
  }
  width: 17.25rem;
  position: relative;
  ${css({
    color: 'base.600',
  })}
`;

const SContainer = styled.div`
  display: flex;
  ${css({
    borderRight: '1px solid',
    borderColor: colors.base[400],
  })}
  @media (max-width: 600px) {
    border-right: none;
    border-bottom: solid 1px ${colors.grey[200]};
    padding: 0.3rem;
  }
`;

interface SOptionProps {
  active?: boolean;
}

const SOption = styled.button<SOptionProps>`
  padding: 0.5rem 1rem;
  display: flex;
  font-size: '1.125rem';
  background-color: ${(props) => (props.active ? colors.base[400] : 'inherit')};
  ${css({
    color: colors.base[900],
    '&:hover': {
      backgroundColor: colors.base[200],
    },
  })};
`;

const customOption = {
  key: 'new_custom',
  label: 'Custom',
};

export const PeriodSelector: FC<Props> = ({
  relativeEndDate,
  period,
  onChange,
  loading,
  onSavePeriod,
  dateFormat = 'P',
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [periods, setPeriods] = useState<PeriodSelection[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showPeriodSave, setShowPeriodSave] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const currentYear = new Date().getFullYear();
  // for the determination of what part of a range will be selected next: from or to
  const [nextAction, setNextAction] = useState<keyof DateRange>('from');

  const [range, setRange] = useState<DateRange>({ from: period[0], to: period[1] });

  const ref = useRef<HTMLDivElement>(null);
  const [selectedKey, setSelectedKey] = useState<string>(genPeriodKey(period));

  const defaultMonth = useMemo(() => range?.from && new Date(range.from), [range?.from]);

  const handleCancel = useCallback(() => {
    if (show) {
      setRange({ from: period[0], to: period[1] });
      setShow(false);
      setSelectedKey(genPeriodKey(period));
    }
  }, [show, setRange, setShow, setSelectedKey, period]);

  useOnClickOutside(ref, handleCancel);

  useEffect(() => {
    const periodKey = genPeriodKey(period);
    const existingPeriod = searchPeriod(periodKey, periods);
    if (!existingPeriod) {
      setPeriods((prevPeriods) =>
        prevPeriods.concat({
          key: periodKey,
          label: `${format(period[0], dateFormat, { locale: locale?.date })} to ${format(period[1], dateFormat, {
            locale: locale?.date,
          })}`,
          period: period,
        }),
      );
    }
  }, [dateFormat, locale, period, periods]);

  useEffect(() => {
    setPeriods(getDefaultPeriods(relativeEndDate));
  }, [relativeEndDate]);

  const onPeriodChange = useCallback(
    (key: string) => {
      setSelectedKey(key);
      if (key !== customOption.key) {
        const newSelectedPeriod = searchPeriod(key, periods);
        if (newSelectedPeriod) {
          const { period } = newSelectedPeriod;
          setRange({ from: period[0], to: period[1] });
        }
      }
    },
    [periods],
  );

  const handleRange = (newRange: DateRange) => {
    setSelectedKey(customOption.key);
    setRange(newRange);
  };

  const onRange = (newRange: DateRange) => {
    const { from, to } = newRange;
    if (from && to && range.from && range.to) {
      if (isAfter(range.to, from) && isBefore(to, range.to)) {
        if (nextAction === 'from') {
          handleRange({ from: to, to: range.to });
          setNextAction('to');
        } else {
          handleRange({ from: range.from, to: to });
          setNextAction('from');
        }
      } else if (isBefore(from, range.from)) {
        if (nextAction === 'from') {
          handleRange({ from: from, to: range.to });
          setNextAction('to');
        } else {
          handleRange({ from: from, to: range.from });
          setNextAction('from');
        }
      } else if (isAfter(to, range.to)) {
        if (nextAction === 'from') {
          handleRange({ from: range.to, to: to });
          setNextAction('to');
        } else {
          handleRange({ from: range.from, to: to });
          setNextAction('from');
        }
      }
    }
  };

  const handleSubmit = () => {
    if (selectedKey === customOption.key) {
      const { from, to } = range;
      if (from && to) {
        onChange([from, to]);
        setShowPeriodSave(true);
      }
    }
    const newPeriod = searchPeriod(selectedKey, periods);

    if (newPeriod) {
      onChange(newPeriod.period);
    }
    setShow(false);
  };

  const handlePeriodSave = useCallback(async () => {
    if (onSavePeriod) {
      await onSavePeriod(period);
    }
    setShowPeriodSave(false);
  }, [period, onSavePeriod]);

  const nextSelectionDate = range[nextAction] ?? new Date();
  const formatRange = formatDateRange(range?.from, range?.to) || t('Select start and end dates');

  return (
    <SContainer>
      <SPeriodSelector ref={ref}>
        <SLabel>{t('Period')}</SLabel>

        <SButton type="button" onClick={() => setShow(!show)}>
          {isMobile ? <SLabel>{formatRange}</SLabel> : formatRange}
          {show ? <SArrowUp /> : <SArrowDown />}
        </SButton>
        {show && (
          <Flex
            backgroundColor="white"
            position="absolute"
            overflow="auto"
            zIndex={100}
            top="100%"
            marginBottom="5rem"
            left="-1px"
            border="1px solid"
            borderColor={colors.base[400]}
            flexDirection="column"
          >
            <Flex flexDirection={isMobile ? 'column' : 'row'} maxHeight="100%">
              {!isMobile && (
                <Flex
                  width={isMobile ? '100%' : '13.75rem'}
                  flexDirection="column"
                  borderRight="1px solid"
                  borderColor={colors.base[400]}
                  maxHeight="100%"
                  overflowY="scroll"
                >
                  {periods.map((p) => (
                    <SOption
                      active={p.key === selectedKey}
                      key={p.key}
                      onClick={() => onPeriodChange(p.key)}
                      disabled={loading}
                    >
                      {t(p.label, { ns: 'db-values' })}
                    </SOption>
                  ))}
                  <SOption
                    active={selectedKey === customOption.key}
                    key={customOption.key}
                    onClick={() => onPeriodChange(customOption.key)}
                    disabled={loading}
                  >
                    {t(customOption.label, { ns: 'db-values' })}
                  </SOption>
                </Flex>
              )}

              <DayPicker
                id="period-selector-day-picker"
                initialFocus
                locale={locale?.date}
                numberOfMonths={isMobile ? 1 : 2}
                mode="range"
                onSelect={onRange as SelectRangeEventHandler}
                selected={range}
                defaultMonth={defaultMonth}
                styles={datePickerStyles}
                disabled={[{ after: new Date() }]}
                formatters={{ formatWeekdayName: (day) => format(day, 'EEEEE', { locale: locale?.date }) }}
                // custom modifier to show user next selection range part, from or to
                modifiers={{ nextSelection: [nextSelectionDate] }}
                modifiersStyles={modifiersStyles}
                captionLayout="dropdown-buttons"
                fromYear={currentYear - 10}
                toYear={currentYear}
              />
            </Flex>
            <Flex
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor={colors.base[400]}
              padding="0.5rem"
              zIndex={100}
              gap="0.25rem"
              backgroundColor="white"
              justifyContent="end"
            >
              <Button style={{ fontSize: '1rem', padding: '0 1rem' }} variant="outline" onClick={handleCancel}>
                {t('actions.Cancel')}
              </Button>
              <Button style={{ fontSize: '1rem', padding: '0 1rem' }} onClick={() => handleSubmit()}>
                {t('actions.Apply')}
              </Button>
            </Flex>
          </Flex>
        )}
      </SPeriodSelector>
      {showPeriodSave && onSavePeriod && (
        <Button variant="plain" iconLeft={MdOutlineSave} onClick={handlePeriodSave} style={{ paddingRight: '1.5rem' }}>
          {t('actions.Save')}
        </Button>
      )}
    </SContainer>
  );
};
