import { startOfDay, sub } from 'date-fns';
import { createContext, Dispatch, FC, SetStateAction, useMemo, useState, useEffect } from 'react';
import { Domain } from 'types/data';
import { getDefaultPeriods } from 'utils/date';

type PeriodContextType = {
  period: [period: Domain<Date>, setPeriod: Dispatch<SetStateAction<Domain<Date>>>];
  now: Date;
};

const PeriodContext = createContext<PeriodContextType>({
  period: [[new Date(), new Date()], () => {}],
  now: getNow(),
});

function getNow(): Date {
  return new Date();
}

const getInitialPeriod = (): Domain<Date> => {
  const savedPeriod = localStorage.getItem('monitorPeriod');
  if (savedPeriod) {
    const savedValue = JSON.parse(savedPeriod);
    if (savedValue.start && savedValue.end) {
      return [new Date(savedValue.start), new Date(savedValue.end)];
    }

    const defaultPeriods = getDefaultPeriods(getNow());
    const foundPeriod = defaultPeriods.find((p) => p.label === savedValue.label);
    return foundPeriod ? foundPeriod.period : defaultPeriods[0].period;
  }

  const now = getNow();
  return [startOfDay(sub(now, { weeks: 1 })), now];
};

const PeriodProvider: FC = (props) => {
  const now = useMemo(() => getNow(), []);
  const [period, setPeriod] = useState<Domain<Date>>(getInitialPeriod);

  useEffect(() => {
    const defaultPeriods = getDefaultPeriods(now);

    const normalizePeriod = (period: Domain<Date>) => [
      startOfDay(period[0]).getTime(),
      startOfDay(period[1]).getTime(),
    ];

    const matchingPeriod = defaultPeriods.find((p) => {
      const [storedStart, storedEnd] = normalizePeriod(p.period);
      const [currentStart, currentEnd] = normalizePeriod(period);
      return storedStart === currentStart && storedEnd === currentEnd;
    });

    localStorage.setItem(
      'monitorPeriod',
      JSON.stringify(
        matchingPeriod
          ? { label: matchingPeriod.label }
          : {
              start: period[0].toISOString(),
              end: period[1].toISOString(),
            },
      ),
    );
  }, [period, now]);

  return (
    <PeriodContext.Provider
      value={{
        period: [period, setPeriod],
        now,
      }}
      {...props}
    />
  );
};

export { PeriodContext, PeriodProvider };
