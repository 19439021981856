import { DrawerContext } from 'context/DrawerContext';
import { ComponentProps, FC, useContext, useEffect } from 'react';
import { WithDrawer } from './WithDrawer';

type WithGuardedDrawerProps = Omit<ComponentProps<typeof WithDrawer>, 'drawerChildren'>

export const WithGuardedDrawer: FC<WithGuardedDrawerProps> = props => {
  const { drawerChildren, drawerRequest, requestDrawer } = useContext(DrawerContext);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        requestDrawer(undefined);
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, [requestDrawer]);

  return (
    <WithDrawer
      {...props}
      drawerChildren={drawerChildren}
      drawerChildrenKey={drawerRequest?.key}
    />
  );
}
