import type { TFunction } from 'i18next';

export enum ProjectNotificationTime {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  NEVER = 'never',
  TWICE_A_DAY = 'twice_a_day',
}

const getProjectNotificationTimeDescription = (timeKey: string, t: TFunction): string => {
  switch (timeKey) {
    case 'NEVER':
      return t('Never', { ns: 'db-values' });
    case 'TWICE_A_DAY':
      return t('Twice a day', { ns: 'db-values' });
    default:
      return t(`Every ${timeKey.toLowerCase()}`, { ns: 'db-values' });
  }
};

export const getProjectNotificationTimes = (t: TFunction) => {
  return Object.keys(ProjectNotificationTime).map((projectNotificationTime) => ({
    key: getProjectNotificationTimeDescription(projectNotificationTime, t),
    value: projectNotificationTime,
  }));
};

export enum ProjectNotificationType {
  ALERT = 'ALERT',
  MISSING_VALUE = 'MISSING_VALUE',
  ANOMALY = 'ANOMALY',
}

export interface ProjectNotificationConfiguration {
  id: string;
  type: ProjectNotificationType;
  value: ProjectNotificationTime;
}

export const getProjectNotificationTypeDescription = (type: string, t: TFunction): string => {
  switch (type) {
    case 'ALERT':
      return t('Alert notifications');
    case 'ANOMALY':
      return t('Anomaly notifications');
    case 'MISSING_VALUE':
      return t('Missing value notifications');
    default:
      throw new Error(t('Unknown project notification type: {{data}}', { data: type }));
  }
};
