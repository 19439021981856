import { FC } from 'react';

import { Loader } from 'components/loader/loader';
import { Flex } from 'components/layout/Flex';
import { colors } from 'theme/colors';

type Props = {
  loading?: boolean;
  show?: boolean;
  label?: string;
};

export const DraftTableRow: FC<Props> = ({ loading = false, show = false, label }) => {
  if (!loading && !show) {
    return null;
  }

  return (
    <Flex
      style={{
        padding: '0.5rem 0',
        fontSize: '1.125rem',
        borderBottom: '1px solid',
        borderColor: colors.base[400],
        justifyContent: 'center',
        boxSizing: 'border-box',
        // height: '2.75rem',
      }}
    >
      {loading ? <Loader /> : label || 'No items'}
    </Flex>
  );
};
