import { useCallback, useState } from 'react';
import jschardet from 'jschardet';
import type { TFunction } from 'i18next';

export type DetectFileEncodingResult = {
  aborted: boolean;
  hasError: boolean;
  error?: string;
  encoding?: string;
};

type Props = {
  onFinish: (result: DetectFileEncodingResult) => void;
};
type Return = [boolean, (file: File) => void];

export async function detectFileEncoding(file: File, t: TFunction): Promise<Error | string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (typeof event.target?.result !== 'string') {
        resolve(new Error(t('File could not be read')));
        return;
      }
      const completeFile = event.target?.result;
      const encodingResult = jschardet.detect(completeFile);
      if (!encodingResult) {
        resolve(new Error(t('File encoding could not be detected')));
        return;
      }
      resolve(encodingResult.encoding);
    };
    reader.onabort = () => {
      resolve(new Error(t('File reading aborted')));
    };
    reader.readAsText(file);
  });
}

/**
 * @deprecated Doesn't seem that this is use - just detectFileEncoding above
 */
export const useDetectFileEncoding = ({ onFinish }: Props): Return => {
  const [loading, setLoading] = useState<boolean>(false);
  const onInternalFinish = useCallback(
    (data: DetectFileEncodingResult) => {
      setLoading(false);
      onFinish(data);
    },
    [onFinish],
  );

  const onAbort = useCallback(() => {
    onInternalFinish({
      aborted: true,
      hasError: false,
    });
  }, [onInternalFinish]);

  const onFileLoaded = useCallback(
    (event: ProgressEvent<FileReader>) => {
      if (typeof event.target?.result !== 'string') {
        onInternalFinish({
          aborted: false,
          hasError: true,
          error: 'File could not be read',
        });
        return;
      }
      const completeFile = event.target?.result;
      const encodingResult = jschardet.detect(completeFile);
      onInternalFinish({
        aborted: false,
        hasError: false,
        encoding: encodingResult.encoding,
      });
    },
    [onInternalFinish],
  );

  // renamed to avoid conflict with exported function here
  const internalDetectFileEncoding = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.onloadstart = () => setLoading(true);
      reader.onabort = () => onAbort();
      reader.onload = (event) => onFileLoaded(event);
      reader.readAsText(file);
    },
    [onAbort, onFileLoaded],
  );

  return [loading, internalDetectFileEncoding];
};
