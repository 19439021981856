import { gql } from '@apollo/client';
import { View } from 'graphql/generated';
import { useDefaultMutation } from '../useDefaultMutation';

const EDIT_VIEW_NAME_KEY = 'editViewName';

const EDIT_VIEW_NAME = gql`
  mutation ($viewId: String!, $name: String!) {
    ${EDIT_VIEW_NAME_KEY}(uuid: $viewId, data: 
      {
        item: $name,
      }
    ) {
      id
      name
      updatedAt
    }
  }
`;

type MutVarEditViewName = {
  viewId: string;
  name: string;
};

type MutResEditViewName = Pick<View, 'id' | 'name' | 'updatedAt'>;

export const useEditViewName = () => {
  return useDefaultMutation<MutResEditViewName, MutVarEditViewName>(EDIT_VIEW_NAME, EDIT_VIEW_NAME_KEY);
};
