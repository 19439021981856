export function max<T>(data: T[], accessor: (value: T) => number | null): number {
  const values = data.map(accessor);
  const valuesWithoutNull = values.filter(value => value !== null) as number[];
  return Math.max(...valuesWithoutNull);
}

export function min<T>(data: T[], accessor: (value: T) => number | null): number {
  const values = data.map(accessor);
  const valuesWithoutNull = values.filter(value => value !== null) as number[];
  return Math.min(...valuesWithoutNull);
}

export function mean<T>(data: T[], accessor: (value: T) => number | null): number {
  if (data.length === 0) {
    return 0;
  }
  return data.map(accessor).reduce((a: number, b: number | null) => a + (b ?? 0), 0) / data.length;
}
