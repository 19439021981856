import { ComponentPropsWithoutRef, FC, KeyboardEvent, MouseEvent, useCallback, useRef } from 'react';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'role'> & {
  disabled?: boolean;
  onClick?: () => void;
  ignoreInternalEvents?: boolean;
};

/** 
 * Mimicks the user interactions of a <button />, but isn't one and can contain any arbitrary content.
 */
export const ContentfulButton: FC<Props> = ({
  onClick,
  disabled,
  ignoreInternalEvents = false,
  ...props
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const ignore = ignoreInternalEvents && e.target !== elementRef.current;
    if (onClick && !ignore) {
      onClick();
    }
  }, [ignoreInternalEvents, onClick]);

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    const ignore = ignoreInternalEvents && e.target !== elementRef.current;
    // see: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role#required_javascript_features
    const buttonClickTriggerKeys = ['Enter', ' '];
    if (onClick && !ignore && buttonClickTriggerKeys.includes(e.key)) {
      onClick();
    }
    // pass through key events for transparency
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  }, [ignoreInternalEvents, onClick, props]);

  return (
    <div
      ref={elementRef}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={props.tabIndex ?? 0}
      role='button'
      style={{ cursor: disabled ? 'regular' : 'pointer' }}
      {...props}
    />
  );
};
