import { gql } from '@apollo/client';
import { Project } from 'types/project';
import { useDefaultMutation } from '../useDefaultMutation';

const EDIT_PROJECT_DESCRIPTION_KEY = 'editProjectDescription';

const EDIT_PROJECT_DESCRIPTION = gql`
  mutation ($projectId: String!, $description: String!) {
    ${EDIT_PROJECT_DESCRIPTION_KEY}(uuid: $projectId, data: { item: $description }) {
      id
      description
    }
  }
`;

type MutVarEditProjectDescription = {
  projectId: string;
  description: string;
}

type MutResEditProjectDescription = Pick<Project, 'id' | 'description'>;

export const useEditProjectDescription = () => {
  return useDefaultMutation<MutResEditProjectDescription, MutVarEditProjectDescription>(EDIT_PROJECT_DESCRIPTION, EDIT_PROJECT_DESCRIPTION_KEY);
};
