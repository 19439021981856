/** Simplify a string so it contains no more whitespace. Ideal for generating filenames. */
export function simplifyName(name: string): string {
  // replace all blocks of whitespace with underscores
  return name.replaceAll(/\s+/g, '_');
}

/** Cuts a string to a certain length, making sure never to cut a word. */
export function shortenText(str: string, length: number) {
  const trimmed = str.substring(0, Math.min(str.length, length));
  if(str.length > trimmed.length) {
    // prevent cutting up a word, add ellipsis
    return trimmed.substring(0, Math.min(trimmed.length, trimmed.lastIndexOf(' '))) + ' ...';
  } else {
    return str;
  }
}

export function firstCharUp(value: string): string {
  if (value.length === 0) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}
