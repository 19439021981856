import { z } from 'zod';
import { CommunicationChannel } from './notification';
import { User } from './user';

export interface ProjectUser extends User {
  role: ProjectUserRole;
  userId: string;
  communicationChannel: CommunicationChannel[];
}

export const ProjectUserRole = z.enum(['owner', 'collaborator', 'member']);
export type ProjectUserRole = z.infer<typeof ProjectUserRole>;

export const projectUserRoles = ProjectUserRole.options;

export const isProjectUserRole = (role: string): role is ProjectUserRole =>
  projectUserRoles.includes(role as ProjectUserRole);
