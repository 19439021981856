import type { SystemStyleObject } from '@styled-system/css';
import { css } from '@styled-system/css';
import styled from 'styled-components';
import type { ColorProps, LayoutProps, SpaceProps, TypographyProps } from 'styled-system';
import { color, compose, layout, space, typography, variant } from 'styled-system';

export type TextVariants = 'tiny' | 'small' | 'large';

type TextProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps & {
    variant?: TextVariants;
  };

export const commonTextStyle: SystemStyleObject = {
  fontFamily: 'main',
  fontSize: ['xs', 'sm', 'md', 'lg', 'xl'],
};

export const smallTextStyle: SystemStyleObject = {
  fontFamily: 'main',
  fontSize: ['xxs', 'xs', 'sm', 'md', 'lg'],
};

export const Text = styled.p<TextProps>(
  css({
    ...commonTextStyle,
  }),
  variant({
    variants: {
      tiny: {
        fontSize: ['xxs', 'xs'],
      },
      small: {
        fontSize: ['xxs', 'xs', 'sm', 'md', 'lg'],
      },
      large: {
        fontSize: ['sm', 'md', 'lg', 'xl', '2xl'],
      },
    },
  }),
  compose(color, space, layout, typography),
);
