import { UserInvitation } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { OrganizationUserRole } from 'types/organizationUser';
import { useMutation } from 'urql';

const INVITE_USER_KEY = 'inviteUser';

const INVITE_USER = `
  mutation ($data: InviteUserInput!) {
    ${INVITE_USER_KEY}(data: $data) {
      id
      role
      email
      status
      updatedAt
    }
  }
`;

type MutVarInviteUser = {
  data: {
    role: OrganizationUserRole;
    email: string;
  };
};

type MutResInviteUser = DefaultQueryResponse<
  typeof INVITE_USER_KEY,
  Pick<UserInvitation, 'id' | 'role' | 'email' | 'status' | 'updatedAt'>
>;

export const useInviteUser = () => {
  return useMutation<MutResInviteUser, MutVarInviteUser>(INVITE_USER);
};
